import { styled } from '@mui/system'
import { Chip } from '@mui/material'
import { ICustomChipProps } from '../types'

const options = {
  shouldForwardProp: (propName: string) => {
    switch (propName) {
      case 'bgColor':
      case 'textColor':
      case 'borderColor':
      case 'height':
        return false
      default:
        return true
    }
  }
}

export const StyledChip = styled(
  Chip,
  options
)<ICustomChipProps>(({ theme, bgColor, textColor, borderColor, height }) => ({
  color: theme.palette.customColors[`${textColor}`],
  backgroundColor: theme.palette.customColors[`${bgColor}`],
  borderRadius: '12px',
  border: `1px solid ${theme.palette.customColors[`${borderColor}`]}`,
  height: height
}))
