import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { deleteStudentCourse, getStudentActivities } from '../services'
import {
  IStudentActivities,
  IStudentDetailsState,
  IStudentProfileExtended
} from '../types'
import { getStudentProfileExtended } from '../../../core/services'

const initialState: IStudentDetailsState = {
  isProfileLoading: false,
  isActivitiesLoading: false,
  isDeleteCourseLoading: false,
  activities: null,
  profile: null
}

export const studentDetailsSlice = createSlice({
  name: 'studentDetails',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentProfileExtended.pending, (state) => {
      state.isProfileLoading = true
    })
    builder.addCase(
      getStudentProfileExtended.fulfilled,
      (state, { payload }: PayloadAction<IStudentProfileExtended>) => {
        state.isProfileLoading = false
        state.profile = payload
      }
    )
    builder.addCase(getStudentProfileExtended.rejected, (state) => {
      state.isProfileLoading = false
    })

    builder.addCase(getStudentActivities.pending, (state) => {
      state.isActivitiesLoading = true
    })
    builder.addCase(
      getStudentActivities.fulfilled,
      (state, { payload }: PayloadAction<IStudentActivities>) => {
        state.isActivitiesLoading = false
        state.activities = payload
      }
    )
    builder.addCase(getStudentActivities.rejected, (state) => {
      state.isActivitiesLoading = false
    })

    builder.addCase(deleteStudentCourse.pending, (state) => {
      state.isDeleteCourseLoading = true
    })
    builder.addCase(deleteStudentCourse.fulfilled, (state) => {
      state.isDeleteCourseLoading = false
    })
    builder.addCase(deleteStudentCourse.rejected, (state) => {
      state.isDeleteCourseLoading = false
    })
  }
})

export const { clearState } = studentDetailsSlice.actions
export default studentDetailsSlice.reducer
