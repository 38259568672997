import { styled } from '@mui/system'
import { Button, Typography } from '@mui/material'

export const StyledClassDetailsItem = styled('div')(() => ({
  display: 'flex',
  '&:not(:first-of-type)': {
    marginTop: '24px'
  },
  '@media (max-width: 768px)': {
    width: '100%',
    justifyContent: 'space-between'
  }
}))

export const StyledClassDetailsItemTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  color: theme.palette.customColors.grayMedium,
  width: '194px'
}))

export const StyledClassDetailsItemDescription = styled(Typography)(() => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  marginLeft: '8px',
  textAlign: 'end'
}))

export const StyledDescriptionsWrapper = styled('div')(() => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px'
}))

export const StyledDescriptionItem = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  color: theme.palette.customColors.blue,
  marginLeft: '8px',
  '&:not(:last-of-type)': {
    marginBottom: '6px'
  }
}))
