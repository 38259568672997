import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IAssignment,
  IStudentActivitiesList,
  ITeacherActivitiesList,
  IUnitDetailsState,
  IUserInfo
} from '../types'
import {
  assignToActivity,
  assignToUnit,
  getStudentActivities,
  getStudentsForClass,
  getTeacherActivities,
  deAssignFromUnit,
  deAssignFromActivity
} from '../services'

const initialState: IUnitDetailsState = {
  isLoading: false,
  isDeAssignFromUnitLoading: false,
  isDeAssignFromActivityLoading: false,
  studentActivities: [],
  teacherActivities: [],
  studentsClass: [],
  assignCourse: [],
  assignUnits: [],
  unitName: null,
  count: 0
}

export const unitDetailsSlice = createSlice({
  name: 'unitDetails',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentActivities.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getStudentActivities.fulfilled,
      (state, { payload }: PayloadAction<IStudentActivitiesList>) => {
        state.isLoading = false
        state.studentActivities = payload.data
        if (payload.data.length) {
          state.unitName = payload.data[0]?.unit.name
        }
        state.count = payload.count
      }
    )
    builder.addCase(getStudentActivities.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getTeacherActivities.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getTeacherActivities.fulfilled,
      (state, { payload }: PayloadAction<ITeacherActivitiesList>) => {
        state.isLoading = false
        state.teacherActivities = payload.data
        if (payload.data.length) {
          state.unitName = payload.data[0]?.unit.name
        }
        state.count = payload.count
      }
    )
    builder.addCase(getTeacherActivities.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getStudentsForClass.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getStudentsForClass.fulfilled,
      (state, { payload }: PayloadAction<IUserInfo[]>) => {
        state.isLoading = false
        state.studentsClass = payload
      }
    )
    builder.addCase(getStudentsForClass.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(assignToActivity.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      assignToActivity.fulfilled,
      (state, { payload }: PayloadAction<IAssignment[]>) => {
        state.isLoading = false
        state.assignCourse = payload
      }
    )
    builder.addCase(assignToActivity.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(assignToUnit.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      assignToUnit.fulfilled,
      (state, { payload }: PayloadAction<IAssignment[]>) => {
        state.isLoading = false
        state.assignUnits = payload
      }
    )
    builder.addCase(assignToUnit.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(deAssignFromUnit.pending, (state) => {
      state.isDeAssignFromUnitLoading = true
    })
    builder.addCase(deAssignFromUnit.fulfilled, (state) => {
      state.isDeAssignFromUnitLoading = false
    })
    builder.addCase(deAssignFromUnit.rejected, (state) => {
      state.isDeAssignFromUnitLoading = false
    })
    builder.addCase(deAssignFromActivity.pending, (state) => {
      state.isDeAssignFromActivityLoading = true
    })
    builder.addCase(deAssignFromActivity.fulfilled, (state) => {
      state.isDeAssignFromActivityLoading = false
    })
    builder.addCase(deAssignFromActivity.rejected, (state) => {
      state.isDeAssignFromActivityLoading = false
    })
  }
})
export const { clearState } = unitDetailsSlice.actions
export default unitDetailsSlice.reducer
