import React, { memo } from 'react'
import {
  StyledMenu,
  StyledMenuLink,
  StyledNotificationsLink,
  StyledUser,
  StyledUserName
} from './styles'
import { CustomSvgIcon } from '../CustomSvgIcon'
import { useAppSelector } from '../../hooks'
import { getCurrentUserSelector } from '../../core/selectors'
import CustomImage from '../CustomImage'

const UserMenu = memo(() => {
  const currentUser = useAppSelector(getCurrentUserSelector)

  return (
    <StyledMenu aria-label={'User menu'}>
      <StyledUser>
        <StyledMenuLink aria-label={'Link to my profile'} to={'/profile'}>
          <CustomImage
            borderRadius={'20px'}
            src={currentUser?.avatarUrl || '/assets/defaultLogo.svg'}
            width={'40px'}
            height={'40px'}
            alt={'Avatar'}
            errorSrc="/assets/defaultLogo.svg"
          />
          <StyledUserName aria-label={'Tooltip actions button'}>
            {currentUser?.fullName}
          </StyledUserName>
        </StyledMenuLink>
      </StyledUser>
      <StyledNotificationsLink
        aria-label={'Link to my notifications'}
        to={'/notifications'}
      >
        <CustomSvgIcon
          path={'/assets/bell-icon.svg'}
          title={'Notification icon'}
        />
      </StyledNotificationsLink>
    </StyledMenu>
  )
})

export default UserMenu
