import { createSlice } from '@reduxjs/toolkit'
import { IForgotPasswordState } from '../types'
import { sendResetLinkByEmail } from '../services'

const initialState: IForgotPasswordState = {
  isLoading: false,
  email: undefined,
  step: 1
}

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(sendResetLinkByEmail.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      sendResetLinkByEmail.fulfilled,
      (
        state,
        {
          meta: {
            arg: { email }
          }
        }
      ) => {
        state.email = email
        state.isLoading = false
        state.step = 2
      }
    )
    builder.addCase(sendResetLinkByEmail.rejected, (state) => {
      state.email = undefined
      state.isLoading = false
    })
  }
})

export const { clearState } = forgotPasswordSlice.actions
export default forgotPasswordSlice.reducer
