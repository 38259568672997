import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getNotifications } from './services'
import {
  getNotificationsCountSelector,
  getNotificationsSelector
} from './selectors'
import { StyledPageContentWrapper, StyledTitle } from '../Courses/styles'
import CustomTable from '../../components/CustomTable'
import { CLASSES_PAGE_SIZE } from '../../utils/constants'
import { ISortCallbackParams } from '../../types'
import NotificationText from './components/NotificationText'
import { clearState } from './slices'
import { formatDateByLocale } from '../../utils/helpers'

function Notifications() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const notifications = useAppSelector(getNotificationsSelector)
  const notificationsCount = useAppSelector(getNotificationsCountSelector)

  useEffect(() => {
    dispatch(getNotifications({ page: 1 }))
    return () => {
      dispatch(clearState())
    }
  }, [])

  const prepareTableHead = () => {
    return [
      {
        name: t('from'),
        ariaLabel: 'Sender name',
        cellName: 'from',
        sortable: false
      },
      {
        name: t('text'),
        ariaLabel: 'Text of notification',
        cellName: 'text',
        sortable: false
      },
      {
        name: t('sentOn'),
        ariaLabel: 'Sent date',
        cellName: 'sentOn',
        sortable: false
      }
    ]
  }
  const prepareTableData = () => {
    if (!notifications) return []

    return notifications.map((notification) => ({
      from: notification.sender,
      text: <NotificationText text={notification.text} />,
      sentOn: formatDateByLocale(notification.createdAt)
    }))
  }

  const sortCallbackHandler = (params: ISortCallbackParams) => {
    dispatch(getNotifications(params))
  }

  return (
    <StyledPageContentWrapper>
      <StyledTitle variant={'h2'} aria-label={'My notifications'}>
        {t('myNotifications')}
      </StyledTitle>
      {notifications && (
        <CustomTable
          tableAriaLabel={'Notifications table'}
          dataCount={notificationsCount}
          tableHead={prepareTableHead()}
          rowsCount={CLASSES_PAGE_SIZE}
          rows={prepareTableData()}
          defaultSortColumn={'name'}
          sortCallback={sortCallbackHandler}
          tableSubtitle={t('youHaveEntityAndName', {
            entityCount: notificationsCount,
            entityName: t('messages', {
              count: notificationsCount
            }).toLowerCase()
          })}
          searchPlaceholder={t('searchByMessages')}
        />
      )}
    </StyledPageContentWrapper>
  )
}

export default Notifications
