import React, { memo, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  StyledTitle,
  StyledTitleWrapper,
  StyledBackButton,
  StyledHeaderWrapper
} from './styles'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../../hooks'
import { getResultsStudentsSelector } from '../../selectors'

function StudentResultsTableHeader() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const redirectToPreviousPage = () => {
    navigate(-1)
  }

  const data = useAppSelector(getResultsStudentsSelector)

  const activityTitle = useMemo(() => {
    if (data.length > 0) {
      return `: ${data[0]?.activity?.title}`
    }
  }, [data])
  return (
    <StyledHeaderWrapper>
      <StyledTitleWrapper>
        <StyledBackButton
          aria-label={'Previous page button'}
          onClick={redirectToPreviousPage}
        />
        <StyledTitle variant={'h2'} aria-label={'Course details'}>
          {t('resultOfActivity')}
          {activityTitle}
        </StyledTitle>
      </StyledTitleWrapper>
    </StyledHeaderWrapper>
  )
}

export default memo(StudentResultsTableHeader)
