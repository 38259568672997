import { styled } from '@mui/system'
import { InputLabel, TextField } from '@mui/material'
import { IStyledTextField } from '../types'

const options = {
  shouldForwardProp: (propName: string) => {
    switch (propName) {
      case 'width':
        return false
      default:
        return true
    }
  }
}

export const StyledInputForm = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
}))

export const StyledTextField = styled(
  TextField,
  options
)<IStyledTextField>(({ theme, width }) => ({
  borderRadius: '20px',
  boxSizing: 'border-box',
  marginBottom: '24px',
  width: width,
  '& .MuiOutlinedInput-root': {
    borderRadius: '21px',
    backgroundColor: theme.palette.customColors.white,
    '& input:-webkit-autofill': {
      height: '36px',
      boxSizing: 'border-box',
      WebkitBoxShadow: '0 0 0 30px white inset !important'
    },
    '& fieldset': {
      borderColor: theme.palette.customColors.linear
    }
  },
  '& input:-internal-autofill-selected': {
    backgroundColor: theme.palette.customColors.white
  }
}))

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  marginLeft: '16px',
  marginBottom: '10px',
  fontSize: '16px',
  fontWeight: 600,
  color: theme.palette.customColors.black
}))
