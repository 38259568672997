import React, { memo, useEffect, useMemo } from 'react'
import {
  IStudentFieldsProps,
  IStudentForm,
  IStudentFormProps
} from '../../types'
import { Formik } from 'formik'
import { studentFormSchema } from '../../schemas'
import { useTranslation } from 'react-i18next'
import {
  StyledFormContainer,
  StyledFormMainContainer,
  StyledFormSupportContainer,
  StyledFormSupportContainerBottom
} from '../../styles'
import CustomImage from '../../../../components/CustomImage'
import { StyledStudentImgContainer } from '../../../StudentDetails/styles'
import FormTextInput from '../../../../components/Form/FormTextInput'
import FormMultiSelect from '../../../../components/Form/FormMultiSelect'
import { StyledButton } from '../../../../common/styles'
import { useNavigate } from 'react-router-dom'

const baseImg = '/assets/teacher-image-for-form.png'

const baseInitialValues: IStudentForm = {
  avatarPath: undefined,
  fullName: '',
  email: '',
  schoolIds: [],
  classIds: []
}

const StudentFields = (props: IStudentFieldsProps) => {
  const navigate = useNavigate()
  const {
    initialValues,
    handleSubmit,
    setFieldValue,
    values: { schoolIds, classIds },
    dirty,
    schools,
    classes,
    isLoading
  } = props

  const { t } = useTranslation()

  const studentClasses = useMemo(() => {
    if (classes && schoolIds.length) {
      return classes.filter((classItem) =>
        schoolIds.includes(classItem.school.id)
      )
    }

    return []
  }, [classes, schoolIds])

  const handleCancelClick = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (studentClasses.length) {
      if (classes && classes.length) {
        setFieldValue(
          'classIds',
          classIds.filter((id) =>
            studentClasses.some((classItem) => classItem.id === id)
          )
        )
      }
    } else {
      setFieldValue('classIds', [])
    }
  }, [studentClasses, classes])

  return (
    <form onSubmit={handleSubmit}>
      <StyledFormContainer>
        <StyledStudentImgContainer>
          <CustomImage
            borderRadius={'16px'}
            src={initialValues.avatarPath || baseImg}
            width={'100%'}
            height={'192px'}
            alt={'Avatar'}
            errorSrc={baseImg}
          />
        </StyledStudentImgContainer>
        <StyledFormMainContainer>
          <FormTextInput<IStudentForm>
            name={'fullName'}
            label={t('name')}
            placeholder={t('enterFullName')}
          />

          <FormTextInput<IStudentForm>
            name={'email'}
            label={t('email')}
            type={'email'}
            placeholder={t('enterEmail')}
          />

          <FormMultiSelect<IStudentForm>
            name="schoolIds"
            items={schools.map((school) => ({
              name: school.shortName,
              value: school.id
            }))}
            label={t('schools')}
            placeholder={t('enterSchoolName')}
          />

          <FormMultiSelect<IStudentForm>
            name="classIds"
            items={studentClasses.map((classItem) => ({
              name: classItem.name,
              value: classItem.id
            }))}
            label={t('classes')}
            placeholder={t('enterClassName')}
          />
        </StyledFormMainContainer>
        <StyledFormSupportContainer>
          <StyledFormSupportContainerBottom>
            <StyledButton
              style={{ width: '100%' }}
              type={'submit'}
              size={'large'}
              variant={'contained'}
              color={'primary'}
              disabled={isLoading || !dirty}
            >
              {t('saveChanges')}
            </StyledButton>
            <StyledButton
              style={{ width: '100%' }}
              type={'button'}
              size={'large'}
              variant={'outlined'}
              color={'primary'}
              onClick={handleCancelClick}
            >
              {t('cancel')}
            </StyledButton>
          </StyledFormSupportContainerBottom>
        </StyledFormSupportContainer>
      </StyledFormContainer>
    </form>
  )
}

const StudentForm = memo<IStudentFormProps>(
  ({
    initialValues = baseInitialValues,
    onSubmit,
    schools,
    classes,
    isLoading
  }) => {
    const { t } = useTranslation()
    return (
      <Formik<IStudentForm>
        initialValues={initialValues ?? baseInitialValues}
        onSubmit={onSubmit}
        validationSchema={studentFormSchema(t)}
      >
        {(data) => (
          <StudentFields
            {...data}
            schools={schools}
            classes={classes}
            isLoading={isLoading}
          />
        )}
      </Formik>
    )
  }
)

export default StudentForm
