import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getStudentCourses } from './services'
import { getCoursesCountSelector, getCoursesSelector } from './selectors'
import { useTranslation } from 'react-i18next'
import CustomTable from '../../components/CustomTable'
import { getCurrentUserSelector } from '../../core/selectors'
import { StyledPageContentWrapper, StyledTitle } from './styles'
import { useNavigate } from 'react-router-dom'
import { CLASSES_PAGE_SIZE } from '../../utils/constants'
import { UserType } from '../Login/types'
import { ISortCallbackParams } from '../../types'
import { getTeacherCourses } from '../../core/services'
import { clearState } from './slices'

function MyClasses() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const courses = useAppSelector(getCoursesSelector)
  const coursesCount = useAppSelector(getCoursesCountSelector)
  const currentUser = useAppSelector(getCurrentUserSelector)

  useEffect(() => {
    if (currentUser?.type === UserType.student) {
      dispatch(getStudentCourses({ page: 1, sort: 'name:asc' }))
    } else if (currentUser?.type === UserType.teacher) {
      dispatch(getTeacherCourses({ page: 1, sort: 'name:asc' }))
    }
    return () => {
      dispatch(clearState())
    }
  }, [])

  const sortCallbackHandler = (params: ISortCallbackParams) => {
    if (currentUser?.type === UserType.student) {
      dispatch(getStudentCourses(params))
    } else if (currentUser?.type === UserType.teacher) {
      dispatch(getTeacherCourses(params))
    }
  }

  const prepareTableHead = () => {
    return [
      {
        name: t('nameOfCourse'),
        ariaLabel: 'Name of Course',
        cellName: 'name',
        sortable: true
      },
      {
        name: t('units'),
        ariaLabel: 'Count of units',
        cellName: 'units',
        sortable: true
      }
    ]
  }

  const prepareTableData = () => {
    if (!courses) return []

    return courses.map((course) => ({
      name: course.name,
      units: course.unitsCount,
      rowAction: () => navigate(`/courses/${course.id}`)
    }))
  }

  return (
    <StyledPageContentWrapper>
      <StyledTitle variant={'h2'} aria-label={'My courses'}>
        {t('myCourses')}
      </StyledTitle>
      {courses && (
        <CustomTable
          tableAriaLabel={'Courses table'}
          dataCount={coursesCount}
          tableHead={prepareTableHead()}
          rowsCount={CLASSES_PAGE_SIZE}
          rows={prepareTableData()}
          defaultSortColumn={'name'}
          sortCallback={sortCallbackHandler}
          tableSubtitle={t('youHaveEntityAndName', {
            entityCount: coursesCount,
            entityName: t('courses', { count: coursesCount }).toLowerCase()
          })}
          searchPlaceholder={t('searchByCourses')}
          noDataText={t('youDontHaveAnyYet', {
            entity: t('noCourses').toLowerCase()
          })}
        />
      )}
    </StyledPageContentWrapper>
  )
}

export default MyClasses
