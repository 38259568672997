import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import { StyledButton } from '../../../../../common/styles'

export const StyledText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.palette.customColors.gray
}))

export const StyledCustomButton = styled(StyledButton)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.palette.customColors.gray,
  '&::after': {
    marginLeft: '8px',
    marginTop: '4px',
    content: 'url(/assets/pencil-icon.svg)'
  }
}))
