import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IClassesState } from '../types'
import { sendMessageToClass } from '../services'
import { findSchools, getClasses } from '../../../core/services'
import { IClassList, IFindSchools } from '../../../types'

const initialState: IClassesState = {
  isLoading: false,
  isMessageSending: false,
  isFindSchoolsLoading: false,
  classes: [],
  schools: [],
  count: 0
}

export const classSlice = createSlice({
  name: 'myClasses',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getClasses.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getClasses.fulfilled,
      (state, { payload }: PayloadAction<IClassList>) => {
        state.isLoading = false
        state.classes = payload.data
        state.count = payload.count
      }
    )
    builder.addCase(getClasses.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(sendMessageToClass.pending, (state) => {
      state.isMessageSending = true
    })
    builder.addCase(sendMessageToClass.fulfilled, (state) => {
      state.isMessageSending = false
    })
    builder.addCase(sendMessageToClass.rejected, (state) => {
      state.isMessageSending = false
    })
    builder.addCase(findSchools.pending, (state) => {
      state.isFindSchoolsLoading = true
    })
    builder.addCase(
      findSchools.fulfilled,
      (state, { payload }: PayloadAction<IFindSchools>) => {
        state.schools = payload.data
        state.isFindSchoolsLoading = false
      }
    )
    builder.addCase(findSchools.rejected, (state) => {
      state.isFindSchoolsLoading = false
    })
  }
})

export const { clearState } = classSlice.actions
export default classSlice.reducer
