import { basicString } from '../../../../../validation/primitives'
import { TFunction } from 'i18next'

type TType = TFunction

const sendTicketSchema = (t: TType) => ({
  message: basicString.required(t('requiredField')!),
  categoryId: basicString.required(t('requiredField')!),
  schoolId: basicString.required(t('requiredField')!)
})

export default sendTicketSchema
