import { RootState } from '../../../store'

export const getProfileExtendedSelector = (state: RootState) =>
  state.profile.profile

export const isPersonalInfoUpdatingSelector = (state: RootState) =>
  state.profile.isPersonalInfoUpdating

export const isAvatarUpdatingSelector = (state: RootState) =>
  state.profile.isAvatarUpdating
