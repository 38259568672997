import { createAsyncThunk } from '@reduxjs/toolkit'
import { get, post } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { IErrorResponse } from '../../../types'
import {
  ICurrentUserResponse,
  ILoginRequest,
  ILoginResponse,
  IMyLoginRequest
} from '../types'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const login = createAsyncThunk<
  ILoginResponse,
  ILoginRequest,
  ICommonChunkConfig
>('auth/login', async (_data, { rejectWithValue }) => {
  try {
    const response = await post({ path: API_ROUTES.LOGIN, data: _data })
    return response as ILoginResponse
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const loginByMyLogin = createAsyncThunk<
  ILoginResponse,
  IMyLoginRequest,
  ICommonChunkConfig
>(API_ROUTES.MY_LOGIN, async (_data, { rejectWithValue }) => {
  try {
    const response = await post({ path: API_ROUTES.MY_LOGIN, data: _data })
    return response as ILoginResponse
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getCurrentUser = createAsyncThunk<
  ICurrentUserResponse,
  void,
  ICommonChunkConfig
>('auth/fetchCurrentUser', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({ path: API_ROUTES.ME, params: {} })
    return response as ICurrentUserResponse
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
