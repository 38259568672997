import { IClassIdName, ITinyCourse, ITinySchool } from '../../Profile/types'
import React from 'react'

export interface ITinyUnit {
  id: string
  name: string
  logoUrl: string
}

export interface IDashboardActivity {
  id: string
  activityId: string
  title: string
  level: LevelType
  description: string
  questionsCount: number
  timer: number
  deadlineStart: Date
  deadlineEnd: Date
  course: ITinyCourse
  unit: ITinyUnit
}

export interface IDashboardState {
  isLoadingActivities: boolean
  isLoadingCourses: boolean
  activities: IDashboardActivity[] | null
  courses: ICourses | null
  students: IStudents[] | null
}

export interface ICoursesInfo {
  id: string
  name: string
  unitsCount: number
  activitiesCount: number
}

export interface ICourses {
  count: number
  data: ICoursesInfo[]
}

export enum LevelType {
  a1_beginner = 'a1_beginner',
  a2_elementary = 'a2_elementary',
  b1_preintermediate = 'b1_preintermediate',
  b2_intermediate = 'b2_intermediate',
  c1_upperIntermediate = 'c1_upperIntermediate',
  c2_advanced = 'c2_advanced'
}

export interface IGetStudentsPayload {
  limit: number
  filters?: string[]
}

export interface IStudents {
  id: string
  username: string
  fullName: string
  classes: IClassIdName[]
  schools: ITinySchool[]
  averageScore: number
}

export interface ITableStudentsData {
  position: React.ReactNode
  name: string
  school: string
  class: string
  score: number
}
