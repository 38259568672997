import React, { useEffect, useState } from 'react'
import { CourseCard } from './components/CourseCard'
import { UpcomingActivity } from './components/UpcomingActivity'
import {
  StyledCourseCards,
  StyledHomePage,
  StyledUpcomingActivities,
  StyledTitle,
  StyledBlock,
  StyledInfoText,
  StyledActivities,
  StyledBlockCourse,
  StyledActivitiesBlock
} from './styles'
import StudentsTable from './components/StudentsTable'
import { useAppSelector, useAppDispatch } from '../../hooks'
import { getCurrentUserSelector } from '../../core/selectors'
import { UserType } from '../Login/types'
import {
  getStudentsActivities,
  getTeacherActivities,
  getStudentCourses
} from './services'
import { getDashboardSelector } from './selectors'
import { useTranslation } from 'react-i18next'
import StartActivityModal from '../UnitDetails/components/StartActivity'
import { ActivityToStart } from '../../types'
import { getTeacherCourses } from '../../core/services'
import { COUNT_PER_PAGE } from '../../utils/constants'
import { clearState } from './slices'

function Home() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const currentUser = useAppSelector(getCurrentUserSelector)
  const currentAccesses = useAppSelector(getDashboardSelector)

  const [activityToStart, setActivityToStart] =
    useState<ActivityToStart | null>(null)
  const [isStartActivityModalOpen, setIsStartActivityModalOpen] =
    useState(false)
  const handleCloseStartActivityModal = () => {
    setIsStartActivityModalOpen(false)
    setActivityToStart(null)
  }

  useEffect(() => {
    if (currentUser?.type === UserType.teacher) {
      dispatch(getTeacherActivities())
      dispatch(
        getTeacherCourses({
          page: 1,
          pageSize: COUNT_PER_PAGE,
          sort: 'name:asc'
        })
      )
    } else {
      dispatch(getStudentsActivities())
      dispatch(getStudentCourses())
    }
    return () => {
      dispatch(clearState())
    }
  }, [])

  const getListActivities = () => {
    if (currentAccesses?.activities?.length === 0) {
      return <StyledInfoText>{t('noAssignedActivities')}</StyledInfoText>
    }
    return currentAccesses?.activities?.map((activity) => {
      return (
        <StyledActivities key={activity.id}>
          <UpcomingActivity
            subTitleUnit={activity.unit.name}
            subTitleCourse={activity.course.name}
            title={activity.title}
            role={currentUser?.type}
            dueDate={activity.deadlineEnd}
            level={activity.level}
            onButtonClick={() => {
              setActivityToStart({
                assignedActivityId: activity.id,
                activityId: activity.activityId,
                title: activity.title,
                level: activity.level,
                description: activity.description,
                questionsCount: activity.questionsCount,
                timer: activity.timer,
                logoUrl: activity.unit.logoUrl,
                courseId: activity.course.id,
                unitId: activity.unit.id
              })
              setIsStartActivityModalOpen(true)
            }}
          />
        </StyledActivities>
      )
    })
  }

  const getListCourses = () => {
    if (currentAccesses.courses?.data.length === 0) {
      return (
        <StyledInfoText>
          There are no courses assigned to you yet.
        </StyledInfoText>
      )
    }
    return currentAccesses?.courses?.data.map((course) => {
      return (
        <div key={course.id}>
          <CourseCard
            title={course.name}
            subTitle={`${course.unitsCount} ${t('units')}`}
            activities={course.activitiesCount}
          />
        </div>
      )
    })
  }

  return (
    <StyledHomePage>
      <StyledActivitiesBlock>
        <StyledBlock aria-labelledby={'activities'}>
          {currentUser?.type === UserType.teacher ? (
            <StyledTitle variant={'h2'} id={'activities'}>
              {t('mostPlayedActivities')}
            </StyledTitle>
          ) : (
            <StyledTitle variant={'h2'} id={'activities'}>
              {t('upcomingActivities')}
            </StyledTitle>
          )}
          <StyledUpcomingActivities aria-label={'two most played activities'}>
            {getListActivities()}
          </StyledUpcomingActivities>
        </StyledBlock>
        {currentUser?.type === UserType.teacher && (
          <StyledBlock aria-labelledby={'students'}>
            <StudentsTable />
          </StyledBlock>
        )}
      </StyledActivitiesBlock>
      <StyledBlockCourse aria-labelledby={'activities'}>
        <StyledTitle id={'courses'} variant={'h2'}>
          {t('courses')}
        </StyledTitle>
        <StyledCourseCards aria-label={'Courses Cards'}>
          {getListCourses()}
        </StyledCourseCards>
      </StyledBlockCourse>
      {currentUser?.type === UserType.student && activityToStart && (
        <StartActivityModal
          activityInfo={activityToStart}
          isOpen={isStartActivityModalOpen}
          handleClose={handleCloseStartActivityModal}
        />
      )}
    </StyledHomePage>
  )
}

export default Home
