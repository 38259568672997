import {
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState
} from 'react'

import { PropsItem, PropsItems } from './types/PageList.types'
import {
  StyledPageListItem,
  StyledPageListItemLabel,
  StyledPageList,
  StyledPageListContainer,
  StyledCustomButton
} from './styles/PageList.styles'
import { StyledButton } from '../../common/styles'
import { CustomSvgIcon } from '../CustomSvgIcon'
import { useTranslation } from 'react-i18next'

const PageListItem: FC<PropsWithChildren<PropsItem>> = ({
  children,
  id,
  onDelete
}) => {
  return (
    <StyledPageListItem>
      <StyledPageListItemLabel>{children}</StyledPageListItemLabel>
      {!!onDelete && (
        <StyledButton onClick={() => onDelete(id)}>
          <CustomSvgIcon path="/assets/delete.svg" color="black" />
        </StyledButton>
      )}
    </StyledPageListItem>
  )
}

export const PageList: FC<PropsWithChildren<PropsItems>> = ({
  items,
  onDelete,
  minItemsToShow = 2
}) => {
  const { t } = useTranslation()
  const [showAll, setShowAll] = useState(false)

  const itemsToRender = useMemo(() => {
    if (showAll || items.length <= minItemsToShow) {
      return items
    }

    return items.slice(0, minItemsToShow)
  }, [showAll])

  const moreToShowCount = items.length - itemsToRender.length

  const showMoreClickHandler = useCallback(() => {
    setShowAll((prevState) => !prevState)
  }, [])

  return (
    <StyledPageList>
      <StyledPageListContainer
        shouldMarginBottom={items.length > minItemsToShow}
      >
        {itemsToRender.map((classItem) => (
          <PageListItem
            key={classItem.id}
            id={classItem.id}
            onDelete={onDelete}
          >
            {classItem.name}
          </PageListItem>
        ))}
      </StyledPageListContainer>
      {items.length > minItemsToShow && (
        <StyledCustomButton
          variant="outlined"
          size="large"
          onClick={showMoreClickHandler}
        >
          {moreToShowCount > 0
            ? `${t('showMore')} ${moreToShowCount}`
            : `${t('showLess')}`}
        </StyledCustomButton>
      )}
    </StyledPageList>
  )
}

export default memo(PageList)
