import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useParams } from 'react-router-dom'
import { useActivitiesPageList, useStudentDetailsLoading } from './hooks'
import { deleteStudentCourse, getStudentActivities } from './services'
import { clearState } from './slices'
import {
  getStudentActivitiesSelector,
  getStudentProfileSelector
} from './selectors'
import customToast from '../../components/CustomToast'
import StudentDetailsWrapper from './components/StudentDetailsWrapper'
import { useTranslation } from 'react-i18next'
import { getStudentProfileExtended } from '../../core/services'

function StudentDetails() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const queryParams = useParams()

  const isLoading = useStudentDetailsLoading()
  const studentProfile = useAppSelector(getStudentProfileSelector)
  const studentActivities = useAppSelector(getStudentActivitiesSelector)

  const studentUsername = queryParams.username!

  const activitiesPageList = useActivitiesPageList()

  const [deleteCourseId, setDeleteCourseId] = useState<string | null>(null)

  useEffect(() => {
    dispatch(getStudentProfileExtended({ username: studentUsername }))
  }, [studentUsername])

  useEffect(() => {
    dispatch(getStudentActivities({ username: studentUsername }))
  }, [studentUsername])

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [])

  const closeDeleteCourseModal = useCallback(() => {
    setDeleteCourseId(null)
  }, [])

  const onDeleteClickHandler = useCallback((courseId: string) => {
    setDeleteCourseId(courseId)
  }, [])

  const deleteCourseHandler = useCallback(
    (courseId: string) => {
      dispatch(
        deleteStudentCourse({
          studentId: studentProfile?.id || '',
          courseId: courseId || '',
          sendNotification: false
        })
      )
        .then((res) => {
          if (deleteStudentCourse.fulfilled.match(res)) {
            customToast({ message: t('success'), type: 'success' })
            dispatch(getStudentActivities({ username: studentUsername }))
          }
        })
        .finally(() => {
          setDeleteCourseId(null)
        })
    },
    [studentProfile, studentUsername]
  )

  return (
    <StudentDetailsWrapper
      isLoading={isLoading}
      studentActivities={studentActivities}
      activitiesPageList={activitiesPageList}
      onClose={closeDeleteCourseModal}
      onDelete={deleteCourseHandler}
      studentProfile={studentProfile}
      studentUsername={studentUsername}
      deleteCourseId={deleteCourseId}
      onDeleteClick={onDeleteClickHandler}
    />
  )
}

export default StudentDetails
