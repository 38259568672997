import { styled } from '@mui/system'

export const StyledIFrame = styled('iframe')(() => ({
  width: '100%',
  maxWidth: '1024px',
  height: '80vh',
  marginBottom: '-5px',
  boxSizing: 'border-box',
  border: 'none'
}))
