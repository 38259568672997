import React, { memo } from 'react'
import { IUnitDeadlineProps } from './types'
import { StyledDate } from './styles'
import { formatDateByLocale } from '../../../../utils/helpers'
import { useTranslation } from 'react-i18next'

const UnitDeadline = (props: IUnitDeadlineProps) => {
  const { t } = useTranslation()

  if (!props.deadlineStart && !props.deadlineEnd) {
    return <StyledDate variant={'h6'}>N/A</StyledDate>
  }
  return (
    <div>
      <StyledDate variant={'h6'}>
        {formatDateByLocale(props.deadlineStart, t('anyDate'))}
      </StyledDate>
      <StyledDate variant={'h6'}>
        {formatDateByLocale(props.deadlineEnd, t('anyDate'))}
      </StyledDate>
    </div>
  )
}

export default memo(UnitDeadline)
