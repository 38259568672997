import React, { memo, useCallback } from 'react'
import CustomModal from '../../../../components/CustomModal'
import { IStartActivityModalProps } from './types'
import {
  formatSecondsToHours,
  getCustomChipOptions
} from '../../../../utils/helpers'
import {
  StyledActivityDescription,
  StyledButton,
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledInfoItem,
  StyledInfoItemWrapper
} from './styles'
import { CustomChip } from '../../../../components/CustomChip'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CustomImage from '../../../../components/CustomImage'
import { APP_ROUTES } from '../../../../core/router/appRoutes'

function StartActivityModal(props: IStartActivityModalProps) {
  const { activityInfo, isOpen, handleClose } = props

  const {
    assignedActivityId,
    activityId,
    courseId,
    unitId,
    title,
    level,
    description,
    questionsCount,
    logoUrl,
    timer
  } = activityInfo

  const navigate = useNavigate()
  const chipOptions = getCustomChipOptions(level)
  const { t } = useTranslation()

  const onStart = useCallback(() => {
    const currentRoute = APP_ROUTES.VR_INTERACTIVE.replace(
      ':activityId',
      activityId
    ).replace(':assignedActivityId', assignedActivityId)
    navigate(currentRoute)
  }, [assignedActivityId, activityId, navigate])

  return (
    <CustomModal
      open={isOpen}
      width={'356px'}
      title={`Start ${title}`}
      handleClose={handleClose}
    >
      <StyledContentWrapper>
        <CustomImage
          borderRadius={'24px'}
          src={logoUrl || '/assets/defaultLogo.svg'}
          width={'100%'}
          height={'232px'}
          alt={title}
          errorSrc="/assets/defaultLogo.svg"
        />
        <StyledInfoItemWrapper>
          <StyledInfoItem>{t('level')}</StyledInfoItem>
          <CustomChip
            aria-label={'Unit level'}
            bgColor={chipOptions?.bgColor}
            label={chipOptions?.name}
            textColor={chipOptions?.textColor}
            borderColor={chipOptions?.borderColor}
            height={'20px'}
          />
        </StyledInfoItemWrapper>
        <StyledActivityDescription>{description}</StyledActivityDescription>
        <StyledInfoItemWrapper>
          <StyledInfoItem>{t('questions')}</StyledInfoItem>
          <StyledInfoItem>{questionsCount}</StyledInfoItem>
        </StyledInfoItemWrapper>
        {timer && (
          <StyledInfoItemWrapper>
            <StyledInfoItem>{t('timer')}</StyledInfoItem>
            <StyledInfoItem>{formatSecondsToHours(timer)}</StyledInfoItem>
          </StyledInfoItemWrapper>
        )}
        <StyledButtonsWrapper>
          <StyledButton
            type={'button'}
            size={'large'}
            variant={'outlined'}
            color={'primary'}
            onClick={handleClose}
          >
            {t('cancel')}
          </StyledButton>
          <StyledButton
            type={'submit'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={onStart}
          >
            {t('startActivity')}
          </StyledButton>
        </StyledButtonsWrapper>
      </StyledContentWrapper>
    </CustomModal>
  )
}

export default memo(StartActivityModal)
