import React, {
  FC,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import Header from '../Header'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { clearState } from '../../slices'
import { getIsLoadingSelector, getStepSelector } from '../../selectors'
import { APP_ROUTES } from '../../../../core/router/appRoutes'
import { Form, Formik, FormikValues } from 'formik'
import { Button } from '@mui/material'
import schemas from './schemas'
import v from '../../../../validation/validation'
import { useTranslation } from 'react-i18next'
import { getDefaultFormValues } from '../../../../utils/helpers'
import { resetPasswordByToken } from '../../services'
import FormOutlinedInput from '../../../../components/FormOutlinedInput'

const ChangePasswordForm: FC = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false)
  const location = useLocation()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isLoading = useAppSelector(getIsLoadingSelector)
  const step = useAppSelector(getStepSelector)

  const token = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('token')
  }, [location])

  const fields = useMemo(() => ['password', 'confirmPassword'], [])
  const initialValues = useMemo(() => getDefaultFormValues({}, fields), [])
  const validationSchema = useMemo(
    () => v.utils.createPartialObjectSchema(schemas(t), fields),
    []
  )

  const onSubmit = (values: FormikValues) => {
    const { password } = values
    dispatch(
      resetPasswordByToken({
        token: token as string,
        password
      })
    )
  }

  const handleOkClick = useCallback(() => {
    navigate(APP_ROUTES.LOGIN)
  }, [navigate])

  const changePasswordVisibility = useCallback(() => {
    setIsPasswordVisible(!isPasswordVisible)
  }, [isPasswordVisible])

  const changeConfirmPasswordVisibility = useCallback(() => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
  }, [isConfirmPasswordVisible])

  useEffect(() => {
    if (!token) {
      navigate(APP_ROUTES.LOGIN)
    }
  }, [token])

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [])

  if (step === 1) {
    return (
      <Fragment>
        <Header title={t('enterNewPassword')} />
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <Form>
            <FormOutlinedInput
              name={'password'}
              type={isPasswordVisible ? 'text' : 'password'}
              width={'100%'}
              placeholder={t('yourPassword')}
              iconPath={'/assets/eye-icon.svg'}
              iconAlt={'Eye icon'}
              iconOnClick={changePasswordVisibility}
            />
            <FormOutlinedInput
              name={'confirmPassword'}
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              width={'100%'}
              placeholder={t('confirmPassword')}
              iconPath={'/assets/eye-icon.svg'}
              iconAlt={'Eye icon'}
              iconOnClick={changeConfirmPasswordVisibility}
            />
            <Button
              type="submit"
              disabled={isLoading}
              size={'large'}
              variant={'contained'}
              color={'primary'}
            >
              {t('save')}
            </Button>
          </Form>
        </Formik>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Header title={t('passwordChangedSuccessfully')} />
      <Button
        type="button"
        size={'large'}
        variant={'contained'}
        color={'primary'}
        onClick={handleOkClick}
      >
        {t('ok')}
      </Button>
    </Fragment>
  )
}

export default memo(ChangePasswordForm)
