import { styled as muiStyled } from '@mui/system'

export const StyledBottomMenuSidebarToggle = muiStyled('div')<any>(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    alignItems: 'center',
    color: theme.palette.customColors.white,
    borderRadius: '20px',
    width: 'fit-content',
    padding: '8px',
    minWidth: '56px',
    cursor: 'pointer',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
      boxSizing: 'border-box',
      padding: '4px 12px',
      minWidth: '0',
      width: 'auto'
    }
  })
)
