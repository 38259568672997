import React, { useMemo } from 'react'
import {
  StyledButton,
  StyledCongratulationsSubTitle,
  StyledCongratulationsTitle,
  StyledCongratulationsWrapper,
  StyledMainContentWrapper,
  StyledPageWrapper,
  StyledButtonWrapper,
  StyledLogoContainer
} from './styles'
import { CustomSvgIcon } from '../../components/CustomSvgIcon'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { BREAKPOINTS } from '../../utils/constants'
import useWindowSize from '../../utils/hooks'
import { LOGO_SIZE } from './constants'

function EndOfActivity() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const unitId = searchParams.get('unitId')
  const courseId = searchParams.get('courseId')
  const [windowWidth] = useWindowSize()
  const isMobile = useMemo(
    () => windowWidth <= BREAKPOINTS.mobile,
    [windowWidth]
  )
  const { width, height } = isMobile ? LOGO_SIZE.mobile : LOGO_SIZE.web

  return (
    <StyledPageWrapper>
      {!isMobile && (
        <CustomSvgIcon path={'/assets/logo.svg'} title={'Beyond words logo'} />
      )}
      <StyledMainContentWrapper>
        {isMobile ? (
          <StyledLogoContainer>
            <CustomSvgIcon
              path={'/assets/happy-student-icon.svg'}
              title={'Student icon'}
              width={width}
              height={height}
            />
          </StyledLogoContainer>
        ) : (
          <CustomSvgIcon
            path={'/assets/happy-student-icon.svg'}
            title={'Student icon'}
            width={width}
            height={height}
          />
        )}
        <StyledCongratulationsWrapper>
          <StyledCongratulationsTitle variant={'h2'}>
            {t('congratulations')}!
          </StyledCongratulationsTitle>
          <StyledCongratulationsSubTitle variant={'h3'}>
            {t('youHaveSuccessfullyCompletedActivity')}!
          </StyledCongratulationsSubTitle>
          <StyledButtonWrapper>
            <StyledButton
              startIcon={
                <CustomSvgIcon
                  path={'/assets/arrow-left-icon.svg'}
                  color={'white'}
                />
              }
              variant={'contained'}
              size={'large'}
              onClick={() => navigate(`/courses/${courseId}/${unitId}`)}
            >
              {t('goBackToActivities')}
            </StyledButton>
            <StyledButton
              startIcon={
                <CustomSvgIcon
                  path={'/assets/results-icon.svg'}
                  color={'white'}
                />
              }
              variant={'contained'}
              size={'large'}
              onClick={() => navigate('/results')}
            >
              {t('goToResults')}
            </StyledButton>
          </StyledButtonWrapper>
        </StyledCongratulationsWrapper>
      </StyledMainContentWrapper>
    </StyledPageWrapper>
  )
}

export default EndOfActivity
