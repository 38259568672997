import React, { memo, useMemo } from 'react'
import { StyledNavMenu } from './styles'
import { CustomSvgIcon } from '../../../../../components/CustomSvgIcon'
import TeacherSidebar from '../TeacherSidebar'
import useWindowSize from '../../../../../utils/hooks'
import { LOGO_SIZE } from './constants'
import { BREAKPOINTS } from '../../../../../utils/constants'

const Sidebar = memo(() => {
  const [windowWidth] = useWindowSize()
  const isTablet = useMemo(
    () => windowWidth <= BREAKPOINTS.tabletLg,
    [windowWidth]
  )
  const isMobile = useMemo(
    () => windowWidth <= BREAKPOINTS.mobile,
    [windowWidth]
  )
  const { width, height } = isTablet ? LOGO_SIZE.tablet : LOGO_SIZE.web

  return isMobile ? (
    <TeacherSidebar />
  ) : (
    <StyledNavMenu>
      <CustomSvgIcon
        path={'/assets/logo.svg'}
        title={'Beyond Words Logo'}
        width={width}
        height={height}
      />
      <TeacherSidebar />
    </StyledNavMenu>
  )
})

export default Sidebar
