import { FC, memo, PropsWithChildren } from 'react'
import { Props } from './types'

import {
  StyledStudentInfoItem,
  StyledStudentInfoItemContent,
  StyledStudentInfoItemLabel
} from './styles'

const StudentInfoItem: FC<PropsWithChildren<Props>> = ({ label, children }) => {
  return (
    <StyledStudentInfoItem>
      <StyledStudentInfoItemLabel>{label}</StyledStudentInfoItemLabel>
      <StyledStudentInfoItemContent>{children}</StyledStudentInfoItemContent>
    </StyledStudentInfoItem>
  )
}

export default memo(StudentInfoItem)
