import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { IErrorResponse } from '../../../types'
import { IClassDetails, IGetClassDetailsPayload } from '../types'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const getClassDetails = createAsyncThunk<
  IClassDetails,
  IGetClassDetailsPayload,
  ICommonChunkConfig
>('classDetails/fetchClassDetails', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.CLASSES}/${_data.id}`,
      params: {}
    })
    return response as IClassDetails
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
