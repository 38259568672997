import { styled } from '@mui/system'
import { Typography } from '@mui/material'

export const StyledContentWrapper = styled('div')(() => ({
  width: '100%'
}))

export const StyledTableTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.customColors.black,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  marginBottom: '8px'
}))
