import { ITinyCourse, ITinySchool } from '../../Profile/types'

export interface ICourseUnitsItem {
  id: string
  name: string
  activitiesCount: number
}

export interface IStudentCourseDetailsList {
  count: number
  data: ICourseUnitsItem[]
}

export interface ITeacherCourseDetailsList {
  count: number
  data: ICourseUnitsItem[]
}

export interface IGetCourseById {
  id: string
}
export interface ICourseById extends ITinyCourse {
  category: {
    id: string
    name: string
  }
  logoUrl: string
}

export interface IGetCourseDetailsList {
  id: string
  page: number
  search?: string
  sort?: string
}

export interface ICourseDetailsState {
  isLoading: boolean
  isDeAssignLoading: boolean
  studentUnits: ICourseUnitsItem[] | null
  teacherUnits: ICourseUnitsItem[] | null
  studentsClass: IUserInfo[] | null
  assignCourse: IAssignment[] | null
  count: number
  course: ICourseById | null
}

export type TableColumn = {
  name: string
  ariaLabel: string
  cellName: string
  sortable: boolean
  cellAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined
}

export interface IUserInfo {
  id: string
  username: string
  email: string
  fullName: string
}

export interface IClass {
  id: string
}

export interface AssignToCourse {
  courseId: string
  classId: string
  studentId?: string
  deadlineStart?: string
  deadlineEnd?: string
  timer?: number
  sendNotification: boolean
}

export interface IDeAssignToCourse {
  courseId: string
  classId: string
  studentId?: string
}

export interface IAssignment {
  id: string
  title: string
  score: number
  timer: number
  deadlineStart: string
  deadlineEnd: string
  teacher: IUserInfo
  student: IUserInfo
  class: {
    id: string
    name: string
    school: ITinySchool
  }
  activity: {
    id: string
    title: string
  }
}

export enum AssignmentTypes {
  assignment = 'assignment',
  deAssignment = 'deAssignment'
}

export type AssignmentType = AssignmentTypes | undefined
