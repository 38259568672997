import { Fragment, memo, useCallback, useMemo } from 'react'
import {
  StyledBackButton,
  StyledClassDetailsWrapper,
  StyledColoredSpanItem,
  StyledDetailsContainer,
  StyledLegendAnswer,
  StyledLegendAnswersContainer,
  StyledLegendContainer,
  StyledLegendCorrectAnswer,
  StyledLegendQuestion,
  StyledLegendWrapper,
  StyledPageContentWrapper,
  StyledVoiceButton,
  StyledTableCaption,
  StyledTableContainer,
  StyledTableResultItem,
  StyledTableWrapper,
  StyledTitle,
  StyledTitleContent,
  StyledTitleWrapper,
  StyledSwitchWrapper,
  StyledSwitchText
} from '../../styles'
import ClassDetailsItem from '../../../ClassDetails/components/ClassDetailsItem'
import uuid from 'react-uuid'
import CustomTable from '../../../../components/CustomTable'
import { IExtendedResultResponse, IPhonemeResultWords } from '../../types'
import PronounceItem from '../PronounceItem'
import { useTranslation } from 'react-i18next'
import { formatSecondsToHours } from '../../../../utils/helpers'
import { isEmpty } from 'lodash'
import ComingSoonPage from '../ComingSoonPage'
import Loader from '../../../../components/Loader'
import AccordionContent from '../../../../components/AccordionContent'
import { BREAKPOINTS, SPEECH_LANGUAGES } from '../../../../utils/constants'
import { Language, UserType } from '../../../Login/types'
import Suggestions from '../Suggestions'
import CustomSwitch from '../../../../components/CustomSwitch'
import Tags from '../../../../components/Tags'
import { getCurrentUserSelector } from '../../../../core/selectors'
import { useAppSelector } from '../../../../hooks'
import useWindowSize from '../../../../utils/hooks'
import AssignedActivities from '../AssignedActivities'

interface IResultDetailsPageProps {
  resultDetails: IExtendedResultResponse | null
  toggleSuggestionsSwitch: (isShow: boolean) => void
  isShowSuggestions: boolean
  onClickBack: () => void
  isLoading?: boolean
}

const ResultDetailsPageWrapper = memo<IResultDetailsPageProps>(
  ({
    resultDetails,
    onClickBack,
    isLoading,
    toggleSuggestionsSwitch,
    isShowSuggestions
  }) => {
    const { t } = useTranslation()
    const currentUser = useAppSelector(getCurrentUserSelector)

    const [windowWidth] = useWindowSize()
    const isTablet = useMemo(
      () => windowWidth <= BREAKPOINTS.tabletSm,
      [windowWidth]
    )

    const lang = useMemo(() => {
      return resultDetails?.course.language ?? Language.en
    }, [resultDetails])

    const prepareTableHead = () => {
      return [
        {
          name: t('word'),
          ariaLabel: 'Word',
          cellName: 'word',
          sortable: false
        },
        {
          name: t('phonemesReference'),
          ariaLabel: 'Phonemes reference',
          cellName: 'phonemesReference',
          sortable: false
        },
        {
          name: t('phonemesPronounced'),
          ariaLabel: 'Phonemes pronounced',
          cellName: 'phonemesPronounced',
          sortable: false
        }
      ]
    }

    const getHighlightedValues = useCallback(
      (ref: string[], actually: string[]) => {
        return actually.map((actuallyItem, index) => (
          <PronounceItem
            color={actuallyItem === ref[index] ? 'greenLight' : 'redLight'}
          >
            <>'{actuallyItem}'</>
          </PronounceItem>
        ))
      },
      []
    )

    const isVoiceEnabled = useMemo(() => {
      return !!window.SpeechSynthesisUtterance
    }, [])

    const playPhonemeCallback = useCallback(
      (phoneme: string) => {
        if (isVoiceEnabled) {
          const sound = new window.SpeechSynthesisUtterance()
          sound.text = phoneme
          sound.lang = SPEECH_LANGUAGES[lang]
          window.speechSynthesis.speak(sound)
        } else {
          alert('window.SpeechSynthesisUtterance не существует')
        }
      },
      [lang]
    )

    const prepareTableData = useCallback(
      (resultWordLevel: IPhonemeResultWords[]) => {
        if (!resultWordLevel) return []

        return resultWordLevel.map((item) => {
          return {
            word: (
              <StyledTableResultItem key={uuid()}>
                <span key={uuid()}>{item.word}</span>{' '}
                <span key={uuid()}>
                  Score:{' '}
                  <StyledColoredSpanItem
                    key={uuid()}
                    $color={item.score === 100 ? 'greenDark' : 'redDark'}
                  >
                    {item.score}%
                  </StyledColoredSpanItem>
                </span>
              </StyledTableResultItem>
            ),
            phonemesReference: (
              <StyledTableResultItem key={uuid()}>
                {item.phonemes_reference.map((phoneme) => (
                  <PronounceItem>
                    <>'{phoneme}'</>
                  </PronounceItem>
                ))}
              </StyledTableResultItem>
            ),
            phonemesPronounced: (
              <StyledTableResultItem key={uuid()}>
                {getHighlightedValues(
                  item.phonemes_reference,
                  item.phonemes_pronounced
                )}
              </StyledTableResultItem>
            )
          }
        })
      },
      [getHighlightedValues]
    )

    const tags = useMemo(() => {
      if (!resultDetails?.activity) return []
      return [
        ...resultDetails?.activity.grammarTags,
        ...resultDetails?.activity.syntaxTags,
        ...resultDetails?.activity.vocabTags
      ]
    }, [resultDetails?.activity])

    return (
      <StyledPageContentWrapper>
        {isLoading && <Loader />}
        <>
          <StyledTitleWrapper>
            <StyledTitleContent>
              <StyledBackButton
                aria-label={'Previous page button'}
                onClick={onClickBack}
              />
              {resultDetails?.activity?.title && (
                <StyledTitle variant={'h2'}>{`${t('detailsOf')}: '${
                  resultDetails.activity.title
                }'`}</StyledTitle>
              )}
            </StyledTitleContent>
            <StyledTitleContent>
              {resultDetails?.finishedAt
                ? new Date(resultDetails?.finishedAt).toLocaleDateString()
                : ''}
            </StyledTitleContent>
          </StyledTitleWrapper>
          {resultDetails && !isEmpty(resultDetails?.analysis) && (
            <>
              <StyledClassDetailsWrapper>
                <ClassDetailsItem
                  name={t('student')}
                  description={resultDetails.student.fullName}
                />
                <ClassDetailsItem
                  name={t('email')}
                  description={resultDetails.student.email}
                />
                <ClassDetailsItem
                  name={t('school')}
                  description={resultDetails.school.longName}
                />
                <ClassDetailsItem
                  name={t('class')}
                  description={resultDetails.class.name}
                />
                <ClassDetailsItem
                  name={t('course')}
                  description={resultDetails.course.name}
                />
                <ClassDetailsItem
                  name={t('resultOfActivity')}
                  description={resultDetails.score}
                />
                <ClassDetailsItem
                  name={t('timeSpentOnTheActivity')}
                  description={`${formatSecondsToHours(
                    resultDetails?.duration ?? 0
                  )}`}
                />
                {tags.length > 0 && (
                  <ClassDetailsItem
                    name={t('tags')}
                    description={[
                      <Tags
                        tags={tags}
                        style={{
                          maxWidth: '250px',
                          justifyContent: isTablet ? 'flex-end' : 'flex-start'
                        }}
                      />
                    ]}
                  />
                )}
              </StyledClassDetailsWrapper>
              {currentUser?.type === UserType.teacher && (
                <StyledSwitchWrapper>
                  <StyledSwitchText>
                    {t('showSuggestionsSwitch')}
                  </StyledSwitchText>
                  <CustomSwitch
                    checked={isShowSuggestions}
                    onChange={() => toggleSuggestionsSwitch(!isShowSuggestions)}
                  />
                </StyledSwitchWrapper>
              )}

              {isShowSuggestions && (
                <Fragment>
                  <Suggestions
                    classId={resultDetails.class.id}
                    studentId={resultDetails.student.id}
                  />
                  <AssignedActivities
                    classId={resultDetails.class.id}
                    studentId={resultDetails.student.id}
                  />
                </Fragment>
              )}
              {resultDetails.analysis?.map((details, index) => (
                <StyledDetailsContainer>
                  <StyledTableCaption key={uuid()}>
                    {index + 1}. {t('textReference')} : '
                    {details.analysisData.answer}'
                    {isVoiceEnabled && (
                      <StyledVoiceButton
                        type="button"
                        onClick={() =>
                          playPhonemeCallback(details.analysisData.answer)
                        }
                      >
                        <img src={'/assets/voice.png'} alt="voice icon" />
                      </StyledVoiceButton>
                    )}
                  </StyledTableCaption>
                  <StyledLegendWrapper>
                    <StyledLegendContainer>
                      <div>
                        <span>{t('wordsPronounced')}: </span>
                        <span>
                          [
                          {details.analysisData.words.words_pronounced
                            .map((word) => "'" + `${word}` + "'")
                            .join(', ')}
                          ]
                        </span>
                      </div>
                      <div>
                        <span>{t('wordsError')}: </span>
                        <StyledColoredSpanItem
                          key={uuid()}
                          $color={
                            details.analysisData.words.words_error > 0
                              ? 'redDark'
                              : 'greenDark'
                          }
                        >
                          {details.analysisData.words.words_error.toFixed(2) ??
                            'N/A'}
                        </StyledColoredSpanItem>
                      </div>
                      <div>
                        <span>{t('phonemesPronounced')}: </span>
                        <span>
                          [
                          {details.analysisData.phonemes.phonemes_pronounced
                            ?.map((word) => "'" + `${word}` + "'")
                            .join(', ')}
                          ]
                        </span>
                      </div>
                      <div>
                        <span>{t('phonemesError')}: </span>
                        <StyledColoredSpanItem
                          key={uuid()}
                          $color={
                            details.analysisData.phonemes.phonemes_error > 0
                              ? 'redDark'
                              : 'greenDark'
                          }
                        >
                          {details.analysisData.phonemes.phonemes_error
                            ? details.analysisData.phonemes.phonemes_error.toFixed(
                                2
                              )
                            : 'N/A'}
                        </StyledColoredSpanItem>
                      </div>
                      <div>
                        <span>{t('pronunciationLevel')}: </span>
                        <span>
                          {details.analysisData.pronounceLevel.label_argmax}
                        </span>
                      </div>
                    </StyledLegendContainer>
                    <StyledLegendAnswersContainer>
                      <StyledLegendQuestion>
                        {details.analysisData.question}
                      </StyledLegendQuestion>
                      <StyledLegendCorrectAnswer>
                        {t('correctAnswer')}:{' '}
                        {
                          details.question.answers.find(
                            (answer) => answer.isCorrect
                          )?.text
                        }
                      </StyledLegendCorrectAnswer>
                      {details.question.answers
                        .filter((answer) => !answer.isCorrect)
                        .map((answer) => (
                          <StyledLegendAnswer>
                            {t('incorrectAnswer')}: {answer.text}
                          </StyledLegendAnswer>
                        ))}
                    </StyledLegendAnswersContainer>
                  </StyledLegendWrapper>

                  <AccordionContent
                    hideTitle={t('hidePhonemeAnalysis')}
                    showTitle={t('showPhonemeAnalysis')}
                  >
                    <StyledTableWrapper>
                      <StyledTableContainer>
                        <CustomTable
                          key={uuid()}
                          tableAriaLabel={'Result Details table'}
                          dataCount={
                            details.analysisData.phonemes.result_words_level
                              ? details.analysisData.phonemes.result_words_level
                                  .length
                              : 0
                          }
                          tableHead={prepareTableHead()}
                          rowsCount={
                            details.analysisData.phonemes.result_words_level
                              ? details.analysisData.phonemes.result_words_level
                                  .length
                              : 0
                          }
                          rows={prepareTableData(
                            details.analysisData.phonemes.result_words_level ||
                              []
                          )}
                          tableSubtitle={``}
                        />
                      </StyledTableContainer>
                    </StyledTableWrapper>
                  </AccordionContent>
                </StyledDetailsContainer>
              ))}
              {!isLoading && isEmpty(resultDetails?.analysis) && (
                <ComingSoonPage />
              )}
            </>
          )}
          {!isLoading && isEmpty(resultDetails?.analysis) && <ComingSoonPage />}
        </>
      </StyledPageContentWrapper>
    )
  }
)

export default ResultDetailsPageWrapper
