import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getCourseById, getStudentUnits, getTeacherUnits } from './services'
import {
  getCourse,
  getStudentUnitsSelector,
  getTeacherUnitsSelector,
  getUnitsCountSelector
} from './selectors'
import CustomTable from '../../components/CustomTable'
import { getCurrentUserSelector } from '../../core/selectors'
import { useNavigate, useParams } from 'react-router-dom'
import { CLASSES_PAGE_SIZE } from '../../utils/constants'
import { UserType } from '../Login/types'
import {
  StyledAssignmentButtonsWrapper,
  StyledBackButton,
  StyledButton,
  StyledPageActions,
  StyledPageContentWrapper,
  StyledTitle,
  StyledTitleWrapper
} from './styles'
import UnitName from '../../components/UnitName'
import { AssignmentType, AssignmentTypes, TableColumn } from './types'
import { ISortCallbackParams } from '../../types'
import { useTranslation } from 'react-i18next'
import AssignCourseModal from './components/AssignCourseModal'
import { clearState } from './slices'

function UnitsByCourse() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const { t } = useTranslation()

  const [isAssignCourseModalOpen, setIsAssignCourseModalOpen] = useState(false)
  const [assignmentType, setAssignmentType] = useState<AssignmentType>()

  const handleAssignCourseModalOpen = useCallback(
    (assignmentType: AssignmentTypes) => {
      setAssignmentType(assignmentType)
      setIsAssignCourseModalOpen(true)
    },
    []
  )

  const studentUnits = useAppSelector(getStudentUnitsSelector)
  const teacherUnits = useAppSelector(getTeacherUnitsSelector)
  const unitsCount = useAppSelector(getUnitsCountSelector)
  const currentUser = useAppSelector(getCurrentUserSelector)
  const course = useAppSelector(getCourse)

  useEffect(() => {
    if (currentUser?.type === UserType.student && id) {
      dispatch(getStudentUnits({ page: 1, sort: 'unit:asc', id }))
    } else if (currentUser?.type === UserType.teacher && id) {
      dispatch(getTeacherUnits({ page: 1, sort: 'unit:asc', id }))
    }
    if (id) {
      dispatch(getCourseById({ id }))
    }
    return () => {
      dispatch(clearState())
    }
  }, [])

  const sortCallbackHandler = (params: ISortCallbackParams) => {
    if (currentUser?.type === UserType.student && id) {
      dispatch(getStudentUnits({ ...params, id }))
    } else if (currentUser?.type === UserType.teacher && id) {
      dispatch(getTeacherUnits({ ...params, id }))
    }
  }

  const prepareTableHead = () => {
    const tableHead: TableColumn[] = [
      {
        name: t('unitName'),
        ariaLabel: 'Unit Name',
        cellName: 'unit',
        sortable: true
      },
      {
        name: t('activitiesCount'),
        ariaLabel: 'activities count',
        cellName: 'activities',
        sortable: true
      }
    ]
    return tableHead
  }

  const prepareTableData = () => {
    if (currentUser?.type === UserType.student && studentUnits) {
      return studentUnits.map((item) => {
        return {
          unit: <UnitName unitName={item.name} />,
          activities: item.activitiesCount,
          rowAction: () => navigate(`/courses/${id}/${item.id}`)
        }
      })
    } else if (currentUser?.type === UserType.teacher && teacherUnits) {
      return teacherUnits.map((item) => {
        return {
          unit: <UnitName unitName={item.name} />,
          activities: item.activitiesCount,
          rowAction: () => navigate(`/courses/${id}/${item.id}`)
        }
      })
    } else return []
  }

  const redirectToPreviousPage = () => {
    navigate(-1)
  }

  const handleCloseAssignCourseModal = useCallback(() => {
    setAssignmentType(undefined)
    setIsAssignCourseModalOpen(false)
  }, [])

  return (
    <StyledPageContentWrapper>
      <StyledPageActions>
        <StyledTitleWrapper>
          <StyledBackButton
            aria-label={'Previous page button'}
            onClick={redirectToPreviousPage}
          />
          {course && (
            <StyledTitle variant={'h2'} aria-label={'Course details'}>
              {course.name}
            </StyledTitle>
          )}
        </StyledTitleWrapper>
        <StyledAssignmentButtonsWrapper>
          {currentUser?.type === UserType.teacher && course && (
            <StyledButton
              size={'large'}
              variant={'contained'}
              color={'primary'}
              onClick={() =>
                handleAssignCourseModalOpen(AssignmentTypes.assignment)
              }
            >
              {t('assignCourse')}
            </StyledButton>
          )}
          {currentUser?.type === UserType.teacher && course && (
            <StyledButton
              size={'large'}
              variant={'contained'}
              color={'primary'}
              onClick={() =>
                handleAssignCourseModalOpen(AssignmentTypes.deAssignment)
              }
            >
              {t('deAssignCourse')}
            </StyledButton>
          )}
        </StyledAssignmentButtonsWrapper>
      </StyledPageActions>
      <CustomTable
        tableAriaLabel={'Course details table'}
        dataCount={unitsCount}
        tableHead={prepareTableHead()}
        rowsCount={CLASSES_PAGE_SIZE}
        rows={prepareTableData()}
        defaultSortColumn={'unit'}
        sortCallback={sortCallbackHandler}
        tableSubtitle={t('youHaveEntityAndName', {
          entityCount: unitsCount,
          entityName: t('units', { count: unitsCount }).toLowerCase()
        })}
        searchPlaceholder={t('searchByUnits')}
        noDataText={t('youDontHaveAnyYet', {
          entity: t('noUnits').toLowerCase()
        })}
      />
      {currentUser?.type === UserType.teacher && course && assignmentType && (
        <AssignCourseModal
          id={course.id}
          assignmentType={assignmentType}
          title={course.name}
          isOpen={isAssignCourseModalOpen}
          handleClose={handleCloseAssignCourseModal}
        />
      )}
    </StyledPageContentWrapper>
  )
}

export default UnitsByCourse
