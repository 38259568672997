import React, { memo, useState } from 'react'
import { IAccordionContentProps } from './types'
import uuid from 'react-uuid'
import { StyledShowMoreButton } from '../../common/styles'
import { AccordionContentWrapper } from './styles'

const AccordionContent = memo<IAccordionContentProps>(
  ({ children, showTitle, hideTitle, defaultState = false }) => {
    const [isShow, show] = useState(defaultState)
    return (
      <AccordionContentWrapper>
        <StyledShowMoreButton
          key={uuid()}
          variant={'outlined'}
          color={'primary'}
          onClick={() => show((currentState) => !currentState)}
        >
          {isShow ? hideTitle : showTitle}
        </StyledShowMoreButton>
        {isShow && children}
      </AccordionContentWrapper>
    )
  }
)

export default AccordionContent
