import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUnitInfo, IUnitInfoState } from '../types'
import { getUnitInfo } from '../services'

const initialState: IUnitInfoState = {
  isLoading: false,
  unitInfo: undefined
}

export const unitInfoSlice = createSlice({
  name: 'unitInfo',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getUnitInfo.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getUnitInfo.fulfilled,
      (state, { payload }: PayloadAction<IUnitInfo>) => {
        state.isLoading = false
        state.unitInfo = payload
      }
    )
    builder.addCase(getUnitInfo.rejected, (state) => {
      state.isLoading = false
    })
  }
})

export const { clearState } = unitInfoSlice.actions
export default unitInfoSlice.reducer
