import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IClassDetails, IClassDetailsState } from '../types'
import { getClassDetails } from '../services'

const initialState: IClassDetailsState = {
  isLoading: false,
  classDetails: null
}

export const profileSlice = createSlice({
  name: 'classDetails',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getClassDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getClassDetails.fulfilled,
      (state, { payload }: PayloadAction<IClassDetails>) => {
        state.isLoading = false
        state.classDetails = payload
      }
    )
    builder.addCase(getClassDetails.rejected, (state) => {
      state.isLoading = false
    })
  }
})

export const { clearState } = profileSlice.actions
export default profileSlice.reducer
