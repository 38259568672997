import React, { FC, memo } from 'react'
import {
  StyledTitleActionContainer,
  StyledTitleDescriptionWrapper,
  StyledTitleHeading,
  StyledTitleTop,
  StyledWrapper
} from './styles'
import { ICustomTitleDescription } from './types'
import CustomImage from '../CustomImage'

const Index: FC<ICustomTitleDescription> = ({ img, title, action }) => {
  return (
    <StyledWrapper>
      <StyledTitleDescriptionWrapper>
        <CustomImage
          src={img || '/assets/defaultLogo.svg'}
          width={'80px'}
          alt={title}
          borderRadius={'16px'}
          errorSrc="/assets/defaultLogo.svg"
        />

        <StyledTitleTop>
          <StyledTitleHeading>{title}</StyledTitleHeading>
          {!!action ? (
            <StyledTitleActionContainer>{action}</StyledTitleActionContainer>
          ) : null}
        </StyledTitleTop>
      </StyledTitleDescriptionWrapper>
    </StyledWrapper>
  )
}

export default memo(Index)
