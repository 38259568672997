import { createSlice } from '@reduxjs/toolkit'
import { IResetPasswordState } from '../types'
import { resetPasswordByToken } from '../services'

const initialState: IResetPasswordState = {
  isLoading: false,
  step: 1
}

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(resetPasswordByToken.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(resetPasswordByToken.fulfilled, (state) => {
      state.isLoading = false
      state.step = 2
    })
    builder.addCase(resetPasswordByToken.rejected, (state) => {
      state.isLoading = false
    })
  }
})

export const { clearState } = resetPasswordSlice.actions
export default resetPasswordSlice.reducer
