import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IChangeProfileInfoPayload,
  IProfileExtended,
  IProfileState,
  IUpdateCurrentUserScoreFormatPayload
} from '../types'
import {
  changeProfileAvatar,
  changeProfileInfo,
  getProfileExtended
} from '../services'
import i18n from 'i18next'

const initialState: IProfileState = {
  isLoading: false,
  isPersonalInfoUpdating: false,
  isAvatarUpdating: false,
  profile: null
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    clearState: () => initialState,
    updateCurrentUserScoreFormat: (
      state,
      { payload }: PayloadAction<IUpdateCurrentUserScoreFormatPayload>
    ) => {
      if (state.profile) {
        state.profile.scoreFormat = payload.scoreFormat
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileExtended.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getProfileExtended.fulfilled,
      (state, { payload }: PayloadAction<IProfileExtended>) => {
        state.isLoading = false
        state.profile = payload
      }
    )
    builder.addCase(getProfileExtended.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(changeProfileInfo.pending, (state) => {
      state.isPersonalInfoUpdating = true
    })
    builder.addCase(
      changeProfileInfo.fulfilled,
      (state, { payload }: PayloadAction<IChangeProfileInfoPayload>) => {
        state.isPersonalInfoUpdating = false
        if (state.profile) {
          state.profile.fullName = payload.fullName
            ? payload.fullName
            : state.profile.fullName
          state.profile.email = payload.email
            ? payload.email
            : state.profile.email
          state.profile.language = payload.language
            ? payload.language
            : state.profile.language
        }
        if (payload.language) {
          i18n.changeLanguage(payload.language)
        }
      }
    )
    builder.addCase(changeProfileInfo.rejected, (state) => {
      state.isPersonalInfoUpdating = false
    })
    builder.addCase(changeProfileAvatar.pending, (state) => {
      state.isAvatarUpdating = true
    })
    builder.addCase(changeProfileAvatar.fulfilled, (state) => {
      state.isAvatarUpdating = false
    })
    builder.addCase(changeProfileAvatar.rejected, (state) => {
      state.isAvatarUpdating = false
    })
  }
})

export const { clearState } = profileSlice.actions
export default profileSlice.reducer
