import React, { memo, useMemo } from 'react'
import { IFormikValues, ISendTicketModalProps } from './types'
import { Formik } from 'formik'
import v from '../../../../validation/validation'
import { useTranslation } from 'react-i18next'
import {
  StyledButton,
  StyledButtonsWrapper,
  StyledForm,
  StyledSelectWrapper
} from './styles'
import FormInput from '../../../../components/FormInput'
import { getDefaultFormValues } from '../../../../utils/helpers'
import sendTicketSchema from './schemas'
import CustomModal from '../../../../components/CustomModal'
import FormSelectInput from '../../../../components/FormSelectInput'
import { ISelectOption } from '../../../../types'

export const ticketFields = ['schoolId', 'categoryId', 'message']
export const ticketValues = getDefaultFormValues({}, ticketFields)

function SendTicketModal(props: ISendTicketModalProps) {
  const {
    isTicketModalOpen,
    handleCloseModal,
    title,
    onSubmit,
    isLoading,
    schools,
    categories
  } = props

  const { t } = useTranslation()

  const validationSchema = v.utils.createPartialObjectSchema(
    sendTicketSchema(t),
    ticketFields
  )

  const handleSubmit = (values: IFormikValues) => {
    onSubmit(values)
  }

  const categoriesOptions = useMemo(() => {
    return categories.map((category) => ({
      name: category.name,
      value: category.id
    }))
  }, [categories])

  const schoolOptions = useMemo(() => {
    const tableFiltersData: {
      school: ISelectOption[]
    } = {
      school: []
    }
    const uniqIdsSet = new Set()
    schools?.forEach((school) => {
      if (!uniqIdsSet.has(school.id)) {
        tableFiltersData.school.push({
          value: school.id,
          name: school.longName
        })
        uniqIdsSet.add(school.id)
      }
    })
    return tableFiltersData.school
  }, [schools])

  return (
    <CustomModal
      width={'458px'}
      open={isTicketModalOpen}
      title={title}
      handleClose={handleCloseModal}
    >
      <Formik<IFormikValues>
        onSubmit={handleSubmit}
        initialValues={ticketValues}
        validationSchema={validationSchema}
      >
        <StyledForm>
          <StyledSelectWrapper margin={'0 0 8px 0'}>
            <FormSelectInput
              name={'schoolId'}
              label={t('school')}
              options={schoolOptions}
            />
          </StyledSelectWrapper>
          <StyledSelectWrapper margin={'0 0 16px 0'}>
            <FormSelectInput
              name={'categoryId'}
              label={t('category')}
              options={categoriesOptions}
            />
          </StyledSelectWrapper>
          <FormInput
            name={'message'}
            width={'458px'}
            placeholder={t('typeYourProblem')}
            multiline={true}
          />
          <StyledButtonsWrapper>
            <StyledButton
              type={'button'}
              size={'large'}
              variant={'outlined'}
              color={'primary'}
              onClick={handleCloseModal}
            >
              {t('cancel')}
            </StyledButton>
            <StyledButton
              type={'submit'}
              size={'large'}
              variant={'contained'}
              color={'primary'}
              disabled={isLoading}
            >
              {t(isLoading ? 'sending' : 'sendMessage')}
            </StyledButton>
          </StyledButtonsWrapper>
        </StyledForm>
      </Formik>
    </CustomModal>
  )
}

export default memo(SendTicketModal)
