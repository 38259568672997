import { AxiosRequestConfig, ResponseType } from 'axios'
import axios from './interceptors'
import customToast from '../components/CustomToast'
import Config from '../config'

interface IDeleteOptions {
  path: string
  data: { [key: string]: any }
  responseType?: ResponseType
}

interface IAxiosResponse {
  [key: string]: any
}

export default async function del<T>(options: IDeleteOptions): Promise<T> {
  const { path, data = {}, responseType = 'json' } = options
  const axiosOptions: AxiosRequestConfig = {
    withCredentials: false,
    data,
    responseType
  }

  let res: IAxiosResponse
  try {
    res = await axios.delete<IAxiosResponse>(
      `${Config.apiUrl + path}`,
      axiosOptions
    )
  } catch (e: any) {
    if (e.response.data.message) {
      customToast({ message: e.response.data.message, type: 'error' })
    } else customToast({ message: 'Something went wrong...', type: 'error' })
    throw e.response.data
  }

  if (res.data.error) {
    customToast({ message: 'Something went wrong...', type: 'error' })
    throw res.data.error
  }

  return res.data
}
