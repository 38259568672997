import React, { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledNavMenu, StyledSidebarItems } from '../TeacherSidebar/styles'
import { APP_ROUTES } from '../../../../router/appRoutes'
import { IWebSidebarProps } from '../TeacherSidebar/types'
import BottomMenuSidebarToggle from './components/BottomMenuSidebarToggle'
import MobileSidebar from './components/MobileSidebar'
import BottomMenuItem from './components/BottomMenuItem'

const BottomMenu = ({
  menuOptions,
  logout,
  options,
  isTablet,
  handleLanguagesChange,
  currentUser,
  language
}: IWebSidebarProps) => {
  const { t } = useTranslation()
  const [isMenuOpen, setMenuOpen] = useState(false)

  const toggleOpen = () => {
    setMenuOpen(!isMenuOpen)
  }

  const closeMenu = useCallback(() => {
    setMenuOpen(false)
  }, [])

  return (
    <>
      <StyledNavMenu>
        <StyledSidebarItems>
          <BottomMenuItem
            locationName={t('dashboard')}
            path={APP_ROUTES.MAIN}
            iconPath={'/assets/home-icon.svg'}
            isTablet={isTablet}
          />
          <BottomMenuItem
            locationName={menuOptions?.building}
            path={APP_ROUTES.SCHOOLS}
            iconPath={'/assets/school-icon.svg'}
            isTablet={isTablet}
          />
          <BottomMenuItem
            locationName={menuOptions.place}
            path={APP_ROUTES.CLASSES}
            iconPath={'/assets/classes-icon.svg'}
            isTablet={isTablet}
          />
          <BottomMenuItem
            locationName={t('courses')}
            path={APP_ROUTES.COURSES}
            iconPath={'/assets/courses-icon.svg'}
            isTablet={isTablet}
          />
          <BottomMenuSidebarToggle
            iconPath={'/assets/menu-icon.svg'}
            toggleOpen={toggleOpen}
          />
        </StyledSidebarItems>
      </StyledNavMenu>
      {isMenuOpen && (
        <MobileSidebar
          logout={logout}
          closeMenu={closeMenu}
          options={options}
          isTablet={isTablet}
          menuOptions={menuOptions}
          handleLanguagesChange={handleLanguagesChange}
          currentUser={currentUser}
          language={language}
        />
      )}
    </>
  )
}

export default memo(BottomMenu)
