import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import type { RootState, AppDispatch } from '../store'
import customToast from '../components/CustomToast'
import { useTranslation } from 'react-i18next'
import { IFilesSettings } from '../types'
import { useDropzone } from 'react-dropzone'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useDropZoneBags = (
  file: File | undefined,
  setFile: (file: File) => void,
  filesSettings: IFilesSettings,
  isMultiple = false
) => {
  const { t } = useTranslation()

  return useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: isMultiple,
    ...filesSettings,
    onDrop: async (acceptedFiles, fileRejections) => {
      const file = acceptedFiles[0]
      const rejectedFile = fileRejections[0]
      if (file) {
        if (file.type.includes('image')) {
          setFile(file)
        } else {
          customToast({ message: t('forbiddenFile'), type: 'error' })
        }
      } else if (rejectedFile) {
        customToast({ message: t('forbiddenFile'), type: 'error' })
      }
    }
  })
}
