import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IActivityInfo, IActivityState } from '../types'
import { getActivityInfo } from '../services'

const initialState: IActivityState = {
  isFetchingActivity: false,
  activityInfo: undefined
}

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getActivityInfo.pending, (state) => {
      state.isFetchingActivity = true
    })
    builder.addCase(
      getActivityInfo.fulfilled,
      (state, { payload }: PayloadAction<IActivityInfo>) => {
        state.isFetchingActivity = false
        state.activityInfo = payload
      }
    )
    builder.addCase(getActivityInfo.rejected, (state) => {
      state.isFetchingActivity = false
    })
  }
})
export const { clearState } = activitySlice.actions
export default activitySlice.reducer
