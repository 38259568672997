import { styled as muiStyled } from '@mui/system'
import { Select, Typography } from '@mui/material'

export const StyledSidebar = muiStyled('nav')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  width: '100%',
  '@media (max-width: 1140px)': {
    alignItems: 'center',
    padding: '24px',
    boxSizing: 'border-box'
  }
}))

export const StyledSidebarMainItems = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  '@media (max-width: 1140px)': {
    alignItems: 'center'
  }
}))

export const BreakLine = muiStyled('hr')(() => ({
  size: '1px',
  width: '100%',
  border: '0.5px solid #C7DAFE'
}))

export const SidebarLogout = muiStyled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: 'none',
  textDecoration: 'none',
  backgroundColor: theme.palette.customColors.bg,
  boxSizing: 'border-box',
  width: '100%',
  '&:hover': {
    cursor: 'pointer'
  },
  gap: '8px',
  '@media (max-width: 1140px)': {
    justifyContent: 'center'
  }
}))
export const StyledText = muiStyled(Typography)(() => ({
  color: '#374957',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px'
}))

export const StyledSelect = muiStyled(Select)(({ theme }) => ({
  width: '70px',
  height: '32px',
  backgroundColor: theme.palette.customColors.bg,
  border: '0px solid #F0F7FC !important',
  '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }
}))

export const StyledNavMenu = muiStyled('div')(({ theme }) => ({
  position: 'fixed',
  height: '76px',
  backgroundColor: theme.palette.customColors.bg,
  padding: '8px 10px 28px 10px',
  width: '100%',
  boxSizing: 'border-box',
  bottom: 0,
  boxShadow: '0px 2px 8px 0px #1D1F212E',
  zIndex: 5
}))

export const StyledSidebarItems = muiStyled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: '16px',
  justifyContent: 'center',
  '@media (max-width: 600px)': {
    gap: '0',
    justifyContent: 'space-between'
  }
}))

export const StyledNavItem = muiStyled('div')(({ theme }) => ({
  color: theme.palette.customColors.white,
  textDecoration: 'none',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: '8px',
  '@media (max-width: 1140px)': {
    justifyContent: 'center'
  }
}))
