import React, { memo, useMemo, useState } from 'react'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../hooks'
import { authSlice } from '../../../../../containers/Login/slices'
import { Language } from '../../../../../containers/Login/types'
import { getCurrentUserSelector } from '../../../../selectors'
import { changeProfileInfo } from '../../../../../containers/Profile/services'
import { refreshAccessToken } from '../../../../../axios/refreshToken'
import { InterfaceType } from '../../../../../containers/Profile/types'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import useWindowSize from '../../../../../utils/hooks'
import { BREAKPOINTS } from '../../../../../utils/constants'
import WebSidebar from '../WebSidebar'
import BottomMenu from '../BottomMenu'

const TeacherSidebar = memo(() => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [windowWidth] = useWindowSize()
  const isTablet = useMemo(
    () => windowWidth <= BREAKPOINTS.tabletLg,
    [windowWidth]
  )
  const isMobile = useMemo(
    () => windowWidth <= BREAKPOINTS.mobile,
    [windowWidth]
  )
  const { resetCurrentUser } = authSlice.actions
  const currentUser = useAppSelector(getCurrentUserSelector)
  const { t } = useTranslation()
  const logout = () => {
    Cookies.remove('accessToken')
    Cookies.remove('refreshToken')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    dispatch(resetCurrentUser())
    navigate('/login')
    i18n.changeLanguage(navigator.language)
  }

  const options = [
    { value: Language.fr, label: 'FR' },
    { value: Language.en, label: 'EN' },
    { value: Language.it, label: 'IT' },
    { value: Language.sp, label: 'SP' },
    { value: Language.ru, label: 'RU' },
    { value: Language.lv, label: 'LV' },
    { value: Language.ko, label: 'KO' }
  ]
  const [language, setLanguage] = useState(currentUser?.language)
  const handleLanguagesChange = (selectLanguage: Language) => {
    if (language === selectLanguage) {
      return
    }
    setLanguage(selectLanguage)
    dispatch(changeProfileInfo({ language: selectLanguage })).then((res) => {
      if (changeProfileInfo.fulfilled.match(res)) {
        refreshAccessToken()
      }
    })
  }

  const getMenuOptions = () => {
    switch (currentUser?.interfaceType) {
      case InterfaceType.school:
        return {
          building: t('schools'),
          typeUser: t('students'),
          place: t('classes')
        }
      case InterfaceType.company:
        return {
          building: t('companies'),
          typeUser: t('employees'),
          place: t('departments')
        }
      default:
        return {
          building: t('schools'),
          typeUser: t('students'),
          place: t('classes')
        }
    }
  }

  const menuOptions = getMenuOptions()

  return isMobile ? (
    <BottomMenu
      logout={logout}
      options={options}
      isTablet={isTablet}
      menuOptions={menuOptions}
      handleLanguagesChange={handleLanguagesChange}
      currentUser={currentUser}
      language={language}
    />
  ) : (
    <WebSidebar
      logout={logout}
      options={options}
      isTablet={isTablet}
      menuOptions={menuOptions}
      handleLanguagesChange={handleLanguagesChange}
      currentUser={currentUser}
      language={language}
    />
  )
})

export default TeacherSidebar
