import { styled } from '@mui/system'
import { TextField } from '@mui/material'

export const StyledTextField = styled(TextField)<{ width?: string }>(
  ({ theme, width = '100%' }) => ({
    borderRadius: '20px',
    boxSizing: 'border-box',
    width: width,
    '& .MuiInputBase-root': {
      backgroundColor: `${theme.palette.customColors.white}`,
      width: width,
      inputMozAppearance: 'textfield',
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
        {
          WebkitAppearance: 'none'
        }
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
      '& input:-webkit-autofill': {
        height: '36px',
        boxSizing: 'border-box',
        WebkitBoxShadow: '0 0 0 30px white inset !important'
      }
    },
    '& input:-internal-autofill-selected': {
      backgroundColor: theme.palette.customColors.white
    }
  })
)
