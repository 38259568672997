import { StyledSvgIcon } from './styles'
import { ISvgIconProps } from './types'

export const CustomSvgIcon = (props: ISvgIconProps) => {
  return (
    <StyledSvgIcon
      src={props.path}
      color={props.color}
      width={props.width}
      height={props.height}
      title={props.title}
    />
  )
}
