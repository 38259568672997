import { basicString } from '../../../validation/primitives'
import { TFunction } from 'i18next'

type TType = TFunction

const authSchema = (t: TType) => ({
  login: basicString.required(t('requiredField')!),
  password: basicString.required(t('requiredField')!)
})

export default authSchema
