import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import CustomModal from '../../../../components/CustomModal'
import { IAssignUnitModalProps } from './types'
import {
  StyledButton,
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledInfoItem,
  StyledInfoItemWrapper,
  StyledSwitch
} from './styles'
import {
  assignToUnit,
  deAssignFromUnit,
  getStudentsForClass
} from '../../services'
import { useTranslation } from 'react-i18next'
import customToast from '../../../../components/CustomToast'

import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { getClassesSelector } from '../../../MyClasses/selectors'
import { getStudentsForClassSelector } from '../../selectors'
import { AssignmentTypes } from '../../../UnitsByCourse/types'
import StudentClassSelector from '../../../../components/StudentClassSelector'
import { getModalTitle, getSubtitle } from '../../../../utils/helpers'
import { StyledInfoWrapper } from '../../../../common/styles'
import { getClasses } from '../../../../core/services'

export const Index: FC<IAssignUnitModalProps> = ({
  unitId,
  title,
  courseId,
  isOpen,
  handleClose,
  assignmentType
}) => {
  const classes = useAppSelector(getClassesSelector)
  const students = useAppSelector(getStudentsForClassSelector)
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const [classCourse, setClassCourse] = useState('')
  const [studentCourse, setStudentCourse] = useState<string>()
  const [isNotification, setIsNotification] = useState(true)

  const handleClassChange = useCallback(
    (selectClass: string) => {
      if (classCourse === selectClass) return

      setClassCourse(selectClass)
      setStudentCourse('reset')
    },
    [classCourse]
  )

  useEffect(() => {
    const isActiveClassCourse = classCourse && classCourse !== 'reset'

    if (!isActiveClassCourse) return

    dispatch(getStudentsForClass({ id: classCourse }))
  }, [classCourse])

  useEffect(() => {
    dispatch(
      getClasses({
        page: 1,
        filters: [`course:${courseId}`],
        sort: 'name:asc'
      })
    )
  }, [])

  const handleStudentChange = useCallback(
    (selectStudent: string) => {
      if (studentCourse === selectStudent) return

      setStudentCourse(selectStudent)
    },
    [studentCourse]
  )

  const handleAssignToUnit = useCallback(() => {
    if (!unitId) return

    dispatch(
      assignToUnit({
        unitId: unitId,
        classId: classCourse,
        studentId: studentCourse !== 'reset' ? studentCourse : undefined,
        sendNotification: isNotification
      })
    ).then((res) => {
      if (assignToUnit.fulfilled.match(res)) {
        customToast({
          message: 'Unit assigned successfully',
          type: 'success'
        })
      }
    })
    handleClose()
  }, [
    unitId,
    dispatch,
    classCourse,
    studentCourse,
    isNotification,
    handleClose
  ])

  const handleDeAssignFromUnit = useCallback(() => {
    if (!unitId) return

    dispatch(
      deAssignFromUnit({
        unitId: unitId,
        classId: classCourse,
        studentId: studentCourse !== 'reset' ? studentCourse : undefined,
        sendNotification: isNotification
      })
    ).then((res) => {
      if (deAssignFromUnit.fulfilled.match(res)) {
        customToast({
          message: 'Unit de-assigned successfully',
          type: 'success'
        })
      }
    })
    handleClose()
  }, [
    classCourse,
    dispatch,
    handleClose,
    isNotification,
    studentCourse,
    unitId
  ])

  const handleSubmitModal = useCallback(() => {
    if (assignmentType === AssignmentTypes.assignment) {
      handleAssignToUnit()
    } else if (assignmentType === AssignmentTypes.deAssignment) {
      handleDeAssignFromUnit()
    }
  }, [assignmentType, handleAssignToUnit, handleDeAssignFromUnit])

  const modalTitle = useMemo(
    () => getModalTitle(assignmentType, t),
    [assignmentType, t]
  )

  const subtitle = useMemo(
    () => getSubtitle(assignmentType, t),
    [assignmentType, t]
  )

  return (
    <CustomModal
      open={isOpen}
      width={'444px'}
      title={`${modalTitle} ${title}`}
      handleClose={handleClose}
    >
      <StyledContentWrapper>
        <StyledInfoItemWrapper>
          <StyledInfoItem>{subtitle}</StyledInfoItem>
        </StyledInfoItemWrapper>
        <StudentClassSelector
          classCourse={classCourse}
          studentCourse={studentCourse}
          classes={classes}
          students={students}
          handleClassChange={handleClassChange}
          handleStudentChange={handleStudentChange}
        />
        <StyledInfoWrapper>
          <StyledInfoItem>{t('sendNotification')}</StyledInfoItem>
          <StyledSwitch
            defaultChecked
            inputProps={{ 'aria-label': 'Switch notification' }}
            onChange={() => setIsNotification(!isNotification)}
          />
        </StyledInfoWrapper>
        <StyledButtonsWrapper>
          <StyledButton
            type={'button'}
            size={'large'}
            variant={'outlined'}
            color={'primary'}
            onClick={handleClose}
          >
            {t('cancel')}
          </StyledButton>
          <StyledButton
            type={'submit'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={handleSubmitModal}
            disabled={!classCourse || classCourse === 'reset'}
          >
            {t('confirm')}
          </StyledButton>
        </StyledButtonsWrapper>
      </StyledContentWrapper>
    </CustomModal>
  )
}

export default memo(Index)
