import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  StyledPageContentWrapper,
  StyledTitle,
  StyledTitleWrapper,
  StyledBackButton,
  StyledHeaderWrapper,
  StyledMobileText
} from './styles'
import { UserType } from '../Login/types'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getCurrentUserSelector } from '../../core/selectors'
import {
  getAllStudentCourses,
  getStudentResults,
  getTeacherResults
} from './services'
import {
  getAllClassesSelector,
  getAllCoursesSelector,
  getResultsCountSelector,
  getStudentResultsSelector,
  getTeacherResultsSelector
} from './selectors'
import { BREAKPOINTS, STUDENTS_PAGE_SIZE } from '../../utils/constants'
import CustomTable from '../../components/CustomTable'
import UnitName from '../../components/UnitName'
import {
  CreateEventSourcePolyfill,
  getCustomChipOptions
} from '../../utils/helpers'
import { CustomChip } from '../../components/CustomChip'
import { TableColumn } from './types'
import ResultDetailsModal from './components/ResultDetailsModal'
import { IExportCallbackParams, ISortCallbackParams } from '../../types'
import { post } from '../../axios'
import { useTranslation } from 'react-i18next'
import { getClasses, getTeacherCourses } from '../../core/services'
import { COURSES_COUNT_PER_PAGE } from './constants'
import uuid from 'react-uuid'
import { API_ROUTES } from '../../core/router/apiRoutes'
import { clearState } from './slices'
import { APP_ROUTES } from '../../core/router/appRoutes'
import useWindowSize from '../../utils/hooks'
import { RowTextRight } from '../../components/CustomTableMobile/styles'
import MobileRow from '../../components/CustomTableMobile/components/MobileRow'
import CustomTableMobile from '../../components/CustomTableMobile'

function Results() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [windowWidth] = useWindowSize()
  const isTablet = useMemo(
    () => windowWidth <= BREAKPOINTS.tabletSm,
    [windowWidth]
  )
  const currentUser = useAppSelector(getCurrentUserSelector)
  const studentResults = useAppSelector(getStudentResultsSelector)
  const teacherResults = useAppSelector(getTeacherResultsSelector)
  const resultsCount = useAppSelector(getResultsCountSelector)
  const allCourses = useAppSelector(getAllCoursesSelector)
  const allClasses = useAppSelector(getAllClassesSelector)
  const [isResultDetailsModalOpen, setIsResultDetailsModalOpen] =
    useState(false)
  const [resultToShow, setResultToShow] = useState<{
    id: string
    title: string
    averageScore: number
  } | null>(null)
  const redirectToPreviousPage = () => {
    navigate(-1)
  }

  const userType = useMemo(() => currentUser?.type, [currentUser?.type])

  const handleCloseResultDetailsModal = () => {
    setIsResultDetailsModalOpen(false)
    setResultToShow(null)
  }

  useEffect(() => {
    if (userType === UserType.student) {
      dispatch(getStudentResults({ page: 1, sort: 'course:asc' }))
      dispatch(getAllStudentCourses())
    }
    if (userType === UserType.teacher) {
      dispatch(
        getTeacherResults({
          page: 1,
          sort: 'course:asc',
          pageSize: STUDENTS_PAGE_SIZE
        })
      )
      dispatch(
        getTeacherCourses({
          page: 1,
          pageSize: COURSES_COUNT_PER_PAGE,
          sort: 'name:asc'
        })
      )
      dispatch(
        getClasses({
          page: 1,
          pageSize: COURSES_COUNT_PER_PAGE,
          sort: 'name:asc'
        })
      )
    }
    return () => {
      dispatch(clearState())
    }
  }, [])

  const exportDataCallback = useCallback(
    (params: IExportCallbackParams) => {
      const route =
        userType === UserType.student
          ? API_ROUTES.EXPORT_STUDENT_RESULTS
          : API_ROUTES.EXPORT_TEACHER_RESULTS
      post({
        path: route,
        data: params
      }).then((res) => {
        const sse = CreateEventSourcePolyfill(
          `${API_ROUTES.EXPORT_RESULTS}/${res.id}`
        )

        sse.onmessage = (e) => {
          const data = JSON.parse(e.data)

          if (data.status === 'completed' && data.url) {
            window.open(data.url, '_blank')
            sse.close()
          } else if (data.status === 'failed') {
            sse.close()
          }
        }

        sse.onerror = () => {
          sse.close()
        }
      })
    },
    [userType]
  )

  const prepareTableHead = (): TableColumn[] => {
    const header: TableColumn[] = [
      {
        name: t('nameOfTheCourse'),
        ariaLabel: 'Name of the Course',
        cellName: 'course',
        sortable: true
      },
      {
        name: t('nameOfActivity'),
        ariaLabel: 'Name of the Activity',
        cellName: 'activity',
        sortable: true
      }
    ]
    if (userType === UserType.student) {
      header.push(
        {
          name: t('level'),
          ariaLabel: 'Level of the unit',
          cellName: 'level',
          cellAlign: 'center',
          sortable: true
        },
        {
          name: t('bestScore'),
          ariaLabel: 'Best score',
          cellName: 'bestScore',
          sortable: false
        }
      )
    }
    if (userType === UserType.teacher) {
      header.push(
        {
          name: t('class'),
          ariaLabel: 'Name of the Course',
          cellName: 'class',
          sortable: false
        },
        {
          name: t('level'),
          ariaLabel: 'Level of the unit',
          cellName: 'level',
          cellAlign: 'center',
          sortable: true
        },
        {
          name: t('rate'),
          ariaLabel: 'Rate of finished scores',
          cellName: 'rate',
          sortable: false
        }
      )
    }
    return header
  }

  const getTableData = useCallback(() => {
    if (userType === UserType.student && studentResults) {
      return prepareTableDataStudents
    }
    if (userType === UserType.teacher && teacherResults) {
      return prepareTableDataTeacher
    }
    return []
  }, [studentResults, teacherResults, userType])

  const getTableDataMobile = useCallback(() => {
    if (userType === UserType.student && studentResults) {
      return prepareTableDataStudentsMobile
    }
    if (userType === UserType.teacher && teacherResults) {
      return prepareTableDataTeacherMobile
    }
    return []
  }, [studentResults, teacherResults, userType, isTablet])

  const prepareTableDataStudents = useMemo(() => {
    if (!studentResults) return []
    return studentResults.map((result) => {
      const chipOptions = getCustomChipOptions(result.level)
      return {
        course: <UnitName unitName={result.course.name} />,
        activity: (
          <UnitName unitName={result.unit.name} activityName={result.title} />
        ),
        level: (
          <CustomChip
            aria-label={'Unit level'}
            bgColor={chipOptions?.bgColor}
            label={chipOptions?.name}
            textColor={chipOptions?.textColor}
            borderColor={chipOptions?.borderColor}
            height={'20px'}
          />
        ),
        bestScore: result.bestScore || 'N/A',
        rowAction: () => {
          setResultToShow({
            id: result.id,
            title: result.title,
            averageScore: result.averageScore
          })
          setIsResultDetailsModalOpen(true)
        }
      }
    })
  }, [studentResults])

  const prepareTableDataStudentsMobile = useMemo(() => {
    if (!studentResults) return []
    const header = prepareTableHead()
    return studentResults.map((result) => {
      const chipOptions = getCustomChipOptions(result.level)
      const row = {
        activity: (
          <StyledMobileText>
            <RowTextRight>{result.unit.name}</RowTextRight>
            {'-'}
            <RowTextRight>{result.title}</RowTextRight>
          </StyledMobileText>
        ),
        level: (
          <CustomChip
            aria-label={'Unit level'}
            bgColor={chipOptions?.bgColor}
            label={chipOptions?.name}
            textColor={chipOptions?.textColor}
            borderColor={chipOptions?.borderColor}
            height={'20px'}
          />
        ),
        bestScore: <RowTextRight>{result.bestScore || 'N/A'}</RowTextRight>
      }
      return (
        <MobileRow
          row={row}
          tableHead={header}
          rowTitle={result.course.name}
          rowAction={() => {
            setResultToShow({
              id: result.id,
              title: result.title,
              averageScore: result.averageScore
            })
            setIsResultDetailsModalOpen(true)
          }}
        />
      )
    })
  }, [studentResults])

  const prepareTableDataTeacher = useMemo(() => {
    if (!teacherResults) return []

    return teacherResults.map((result) => {
      const chipOptions = getCustomChipOptions(result.level)
      return {
        course: <UnitName unitName={result.course.name} />,
        activity: (
          <UnitName unitName={result.unit.name} activityName={result.title} />
        ),
        class: Array.from(
          new Set(result.classes.map((obj) => JSON.stringify(obj)))
        )
          .map((str) => JSON.parse(str))
          .map((classItem) => (
            <UnitName key={uuid()} unitName={classItem.name} />
          )),
        level: (
          <CustomChip
            key={uuid()}
            aria-label={'Unit level'}
            bgColor={chipOptions?.bgColor}
            label={chipOptions?.name}
            textColor={chipOptions?.textColor}
            borderColor={chipOptions?.borderColor}
            height={'20px'}
          />
        ),
        rate: result.rate || 'N/A',
        rowAction: () => {
          navigate(APP_ROUTES.RESULTS_ACTIVITY.replace(':id', result.id))
        }
      }
    })
  }, [teacherResults])

  const prepareTableDataTeacherMobile = useMemo(() => {
    if (!teacherResults) return []
    const header = prepareTableHead()
    return teacherResults.map((result) => {
      const chipOptions = getCustomChipOptions(result.level)
      const row = {
        activity: (
          <StyledMobileText>
            <RowTextRight>{result.unit.name}</RowTextRight>
            {'-'}
            <RowTextRight>{result.title}</RowTextRight>
          </StyledMobileText>
        ),
        class: (
          <span>
            {Array.from(
              new Set(result.classes.map((obj) => JSON.stringify(obj)))
            )
              .map((str) => JSON.parse(str))
              .map((classItem) => (
                <RowTextRight key={uuid()}>{classItem.name}</RowTextRight>
              ))}
          </span>
        ),
        level: (
          <CustomChip
            key={uuid()}
            aria-label={'Unit level'}
            bgColor={chipOptions?.bgColor}
            label={chipOptions?.name}
            textColor={chipOptions?.textColor}
            borderColor={chipOptions?.borderColor}
            height={'20px'}
          />
        ),
        rate: <RowTextRight>{result.rate || 'N/A'}</RowTextRight>
      }
      return (
        <MobileRow
          row={row}
          tableHead={header}
          rowTitle={result.course.name}
          rowAction={() => {
            navigate(APP_ROUTES.RESULTS_ACTIVITY.replace(':id', result.id))
          }}
        />
      )
    })
  }, [teacherResults])

  const sortCallbackHandler = (params: ISortCallbackParams) => {
    if (userType === UserType.student) {
      dispatch(getStudentResults(params))
    }
    if (userType === UserType.teacher) {
      dispatch(getTeacherResults(params))
    }
  }

  const getFilterData = useCallback(() => {
    const tableResultsFiltersData: {
      course: { value: string; name: string }[]
    } = {
      course: []
    }
    const uniqIdsSet = new Set()

    allCourses?.forEach((course) => {
      if (!uniqIdsSet.has(course.id)) {
        tableResultsFiltersData.course.push({
          value: course.id,
          name: course.name
        })
        uniqIdsSet.add(course.id)
      }
    })

    if (userType === UserType.student) {
      return tableResultsFiltersData
    }

    if (userType === UserType.teacher) {
      const additionalClassFilterData: {
        class: { value: string; name: string }[]
      } = {
        class: []
      }

      allClasses?.forEach((classItem) => {
        if (!uniqIdsSet.has(classItem.id)) {
          additionalClassFilterData.class.push({
            value: classItem.id,
            name: classItem.name
          })
          uniqIdsSet.add(classItem.id)
        }
      })
      return { ...tableResultsFiltersData, ...additionalClassFilterData }
    }
  }, [allCourses, allClasses, userType])

  return (
    <StyledPageContentWrapper>
      <StyledHeaderWrapper>
        <StyledTitleWrapper>
          <StyledBackButton
            aria-label={'Previous page button'}
            onClick={redirectToPreviousPage}
          />
          <StyledTitle variant={'h2'} aria-label={'Course details'}>
            {t('results')}
          </StyledTitle>
        </StyledTitleWrapper>
      </StyledHeaderWrapper>
      {isTablet ? (
        <CustomTableMobile
          tableAriaLabel={'Results Table'}
          dataCount={resultsCount}
          tableHead={prepareTableHead()}
          rowsCount={getTableDataMobile().length}
          rows={getTableDataMobile()}
          defaultSortColumn={'course'}
          sortCallback={sortCallbackHandler}
          tableSubtitle={''}
          searchPlaceholder={t('searchByActivity')}
          tableFiltersData={getFilterData()}
          exportDataCallback={exportDataCallback}
          isShowRangePicker
        />
      ) : (
        <CustomTable
          tableAriaLabel={'Results Table'}
          dataCount={resultsCount}
          tableHead={prepareTableHead()}
          rowsCount={getTableData().length}
          rows={getTableData()}
          defaultSortColumn={'course'}
          sortCallback={sortCallbackHandler}
          tableSubtitle={''}
          searchPlaceholder={t('searchByActivity')}
          tableFiltersData={getFilterData()}
          exportDataCallback={exportDataCallback}
          isShowRangePicker
        />
      )}
      {resultToShow && (
        <ResultDetailsModal
          id={resultToShow.id}
          title={resultToShow.title}
          averageScore={resultToShow.averageScore}
          isOpen={isResultDetailsModalOpen}
          handleClose={handleCloseResultDetailsModal}
        />
      )}
    </StyledPageContentWrapper>
  )
}

export default Results
