import { FC, memo, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import CustomModal from '../../../../components/CustomModal'
import { StyledIFrame } from './styles'
import Cookies from 'js-cookie'
import {
  ACCESS_TOKEN_NAME,
  REFRESH_TOKEN_NAME
} from '../../../../utils/constants'
import Config from '../../../../config'

interface Props {
  isOpen: boolean
  activityId: string
  handleClose: () => void
}

const PreviewModal: FC<Props> = ({ isOpen, activityId, handleClose }) => {
  const { t } = useTranslation()
  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  const src = useMemo(
    () => `${Config.vrUrl}/vr/${activityId}/preview`,
    [activityId]
  )

  const onLoad = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(
        {
          key: 'tokens',
          data: {
            accessToken: Cookies.get(ACCESS_TOKEN_NAME),
            refreshToken: Cookies.get(REFRESH_TOKEN_NAME)
          }
        },
        '*'
      )
    }
  }

  return (
    <CustomModal
      width="800px"
      open={isOpen}
      title={t('preview')}
      handleClose={handleClose}
    >
      <StyledIFrame
        src={src}
        ref={iframeRef}
        onLoad={onLoad}
        allowFullScreen={true}
      />
    </CustomModal>
  )
}

export default memo(PreviewModal)
