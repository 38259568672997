import { RootState } from '../../../store'

export const getStudentsSelector = (state: RootState) => state.students.students
export const getTeacherSchoolsSelector = (state: RootState) =>
  state.students.schools
export const getTeacherCoursesSelector = (state: RootState) =>
  state.students.courses
export const getTeacherClassesSelector = (state: RootState) =>
  state.students.classes
export const getStudentCountSelector = (state: RootState) =>
  state.students.count
export const isMessageSendingSelector = (state: RootState) =>
  state.students.isMessageSending
