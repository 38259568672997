import { styled } from '@mui/system'
import { Button } from '@mui/material'

export const StyledInfoWrapper = styled('div')(() => ({
  display: 'flex',
  marginTop: '16px',
  alignItems: 'center'
}))

export const StyledButton = styled(Button)(() => ({
  boxSizing: 'border-box',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  width: 'fit-content',
  padding: '10px 32px'
}))

export const StyledShowMoreButton = styled(Button)(() => ({
  boxSizing: 'border-box',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  width: 'fit-content',
  marginTop: '12px',
  padding: '8px 16px'
}))
