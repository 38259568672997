import React, { memo, useMemo } from 'react'
import { CustomSvgIcon } from '../../../../../../../components/CustomSvgIcon'
import { StyledBottomMenuSidebarToggle } from './styles'

export interface ISidebarItemProps {
  iconPath: string
  toggleOpen: () => void
}

const BottomMenuSidebarToggle = memo(
  ({ iconPath, toggleOpen }: ISidebarItemProps) => {
    return (
      <StyledBottomMenuSidebarToggle onClick={toggleOpen}>
        <CustomSvgIcon path={iconPath} color={'black'} />
      </StyledBottomMenuSidebarToggle>
    )
  }
)

export default BottomMenuSidebarToggle
