import { RootState } from '../../../store'

export const getTeacherActivitiesSelector = (state: RootState) =>
  state.unitDetails.teacherActivities
export const getStudentActivitiesSelector = (state: RootState) =>
  state.unitDetails.studentActivities
export const getActivitiesCountSelector = (state: RootState) =>
  state.unitDetails.count
export const getUnitNameSelector = (state: RootState) =>
  state.unitDetails.unitName
export const getStudentsForClassSelector = (state: RootState) =>
  state.unitDetails.studentsClass
export const getAssignCourse = (state: RootState) =>
  state.unitDetails.assignCourse
