import React, {
  FC,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo
} from 'react'
import { StyledEmail, StyledText } from './styles'
import { Form, Formik, FormikValues } from 'formik'
import FormInput from '../../../../components/FormInput'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import schemas from './schemas'
import v from '../../../../validation/validation'
import { getDefaultFormValues } from '../../../../utils/helpers'
import Header from '../Header'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '../../../../core/router/appRoutes'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { sendResetLinkByEmail } from '../../services'
import { ISendLinkPayload } from '../../types'
import {
  getEmailSelector,
  getIsLoadingSelector,
  getStepSelector
} from '../../selectors'
import { clearState } from '../../slices'

const SendLink: FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isLoading = useAppSelector(getIsLoadingSelector)
  const step = useAppSelector(getStepSelector)
  const email = useAppSelector(getEmailSelector)

  const fields = useMemo(() => ['email'], [])
  const initialValues = useMemo(() => getDefaultFormValues({}, fields), [])
  const validationSchema = useMemo(
    () => v.utils.createPartialObjectSchema(schemas(t), fields),
    []
  )

  const onSubmit = (values: FormikValues) => {
    dispatch(sendResetLinkByEmail(values as ISendLinkPayload))
  }

  const handleOkClick = useCallback(() => {
    navigate(APP_ROUTES.LOGIN)
  }, [navigate])

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [])

  if (step === 1) {
    return (
      <Fragment>
        <Header showBtn title={t('accessRecovery')} />
        <StyledText>{t('loginLinkSended')}</StyledText>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <Form>
            <FormInput
              label={t('email')}
              name={'email'}
              placeholder={t('email')}
            />
            <Button
              type="submit"
              disabled={isLoading}
              size={'large'}
              variant={'contained'}
              color={'primary'}
            >
              {t('sendLink')}
            </Button>
          </Form>
        </Formik>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Header title={t('loginLink')} />
      <StyledText>
        {t('checkYourEmail')} <StyledEmail>{email}</StyledEmail>
      </StyledText>
      <Button
        type="button"
        size={'large'}
        variant={'contained'}
        color={'primary'}
        onClick={handleOkClick}
      >
        {t('ok')}
      </Button>
    </Fragment>
  )
}

export default memo(SendLink)
