import React, { FC, memo } from 'react'
import { StyledHeaderContainer, StyledLink, StyledTitle } from './styles'
import { APP_ROUTES } from '../../../../core/router/appRoutes'
import { CustomSvgIcon } from '../../../../components/CustomSvgIcon'

interface Props {
  title: string
  showBtn?: boolean
}

const Header: FC<Props> = ({ showBtn, title }) => {
  return (
    <StyledHeaderContainer>
      {showBtn && (
        <StyledLink aria-label={'Link to login page'} to={APP_ROUTES.LOGIN}>
          <CustomSvgIcon
            path={'/assets/arrow-left-icon.svg'}
            width="24px"
            height="24px"
            title={'Arrow'}
          />
        </StyledLink>
      )}

      <StyledTitle>{title}</StyledTitle>
    </StyledHeaderContainer>
  )
}

export default memo(Header)
