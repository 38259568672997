import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableSortLabel
} from '@mui/material'
import {
  StyledButton,
  StyledButtonWrapper,
  StyledPagination,
  StyledPaginationItem,
  StyledPaginationWrapper,
  StyledPaper,
  StyledRangePicker,
  StyledTableActionsWrapper,
  StyledTableBodyCell,
  StyledTableFiltersWrapper,
  StyledTableHead,
  StyledTableHeadCell,
  StyledTableRow,
  StyledTableSubtitle,
  StyledTableWrapper,
  StyledWrapper
} from './styles'
import { ICustomTableProps, RangeValue } from './types'
import CustomSelect from '../CustomSelect'
import { IFilterStates } from '../../containers/MyClasses/types'
import CustomOutlinedInput from '../CustomOutlinedInput'
import { useTranslation } from 'react-i18next'
import { ANTD_LOCALES } from '../../utils/constants'
import dayjs, { Dayjs } from 'dayjs'
import uuid from 'react-uuid'
import i18n from 'i18next'
import { getDateFormatByLanguage } from '../../utils/helpers'
import { LOCALES } from '../../types'
import { CustomSvgIcon } from '../CustomSvgIcon'

const CustomTable = <T extends Record<string, any>>(
  props: ICustomTableProps<T>
) => {
  const {
    style,
    wrapperStyle,
    tableHead,
    rows,
    defaultSortColumn,
    sortCallback,
    rowsCount,
    dataCount,
    tableFiltersData,
    tableSubtitle,
    searchPlaceholder,
    tableAriaLabel,
    exportDataCallback,
    noDataText,
    isShowRangePicker,
    stickyHeader
  } = props

  const [sortBy, setSortBy] = useState(defaultSortColumn)
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc')
  const [isInitial, setIsInitial] = useState(true)
  const [page, setPage] = useState(1)
  const [filterStates, setFilterStates] = useState<IFilterStates>({})
  const [search, setSearch] = useState('')
  const [searchValueToSend, setSearchValueToSend] = useState('')
  const { t } = useTranslation()

  const handleFilterChange = useCallback(
    (filterName: string, selectedValue: string) => {
      setDirection('asc')
      setPage(1)
      setSortBy(defaultSortColumn)
      setFilterStates((prevStates) => ({
        ...prevStates,
        [filterName]: {
          selectedFilter: filterName,
          filterValue: selectedValue
        }
      }))
    },
    [defaultSortColumn]
  )

  const handleSetSortedBy = (name: string) => {
    setSortBy((prev) => {
      if (prev === name) {
        return name
      } else {
        setDirection('asc')
        return name
      }
    })
  }

  const handleSetDirection = () => {
    setDirection((prev) => {
      return prev === 'desc' ? 'asc' : 'desc'
    })
  }

  const sortHandler = (name: string) => {
    handleSetSortedBy(name)
    handleSetDirection()
  }

  const searchOnClickHandler = () => {
    setDirection('asc')
    setPage(1)
    setSortBy(defaultSortColumn)
    setSearchValueToSend(search)
  }

  const onKeyDown = (key: string) => {
    if (key === 'Enter') {
      searchOnClickHandler()
    }
  }

  const renderItemSlots = useMemo(() => {
    return {
      next: () => {
        return (
          <CustomSvgIcon path="/assets/arrow-right-icon.svg" color="blue" />
        )
      },
      previous: () => {
        return <CustomSvgIcon path="/assets/arrow-left-icon.svg" color="blue" />
      }
    }
  }, [])

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false)
    } else if (sortCallback) {
      const preparedFilters = Object.keys(filterStates)
        .map((filter) => {
          return `${filter}:${filterStates[filter].filterValue}`
        })
        .filter((el) => !el.includes('reset'))

      const params = {
        page,
        sort: `${sortBy}:${direction}`,
        filters: preparedFilters,
        search: search ? search : undefined
      }

      sortCallback(params)
    }
  }, [sortBy, direction, page, filterStates, searchValueToSend])

  const getExportData = (format: 'pdf' | 'csv') => {
    const preparedFilters = Object.keys(filterStates)
      .map((filter) => {
        return `${filter}:${filterStates[filter].filterValue}`
      })
      .filter((el) => !el.includes('reset'))

    return {
      format,
      page,
      sort: `${sortBy}:${direction}`,
      search: search ? search : undefined,
      filter: preparedFilters
    }
  }

  const disabledDate = useCallback((current: Dayjs) => {
    return current.isAfter(dayjs())
  }, [])

  const handleDateChange = useCallback(
    (values: RangeValue<Dayjs>, formatString: [string, string]) => {
      const formattedDays = values?.map((val) => val?.format('YYYY-MM-DD'))
      const from =
        formattedDays && formattedDays[0] ? formattedDays[0] : 'reset'
      const to = formattedDays && formattedDays[1] ? formattedDays[1] : 'reset'
      handleFilterChange('from', from)
      handleFilterChange('to', to)
    },
    [handleFilterChange]
  )

  return (
    <StyledWrapper>
      {exportDataCallback && (
        <StyledButtonWrapper>
          <StyledButton
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={() => exportDataCallback(getExportData('pdf'))}
          >
            {t('exportPDF')}
          </StyledButton>
          <StyledButton
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={() => exportDataCallback(getExportData('csv'))}
          >
            {t('exportCSV')}
          </StyledButton>
        </StyledButtonWrapper>
      )}
      <StyledTableActionsWrapper style={style && { marginBottom: '16px' }}>
        <div>
          <StyledTableSubtitle style={style}>
            {tableSubtitle}
          </StyledTableSubtitle>
        </div>
        <StyledTableFiltersWrapper>
          {searchPlaceholder && (
            <CustomOutlinedInput
              placeholder={searchPlaceholder}
              value={search}
              onChange={(val) => {
                setSearch(val)
              }}
              iconOnClick={searchOnClickHandler}
              iconPath={'/assets/search-icon.svg'}
              adornmentBg={'bg'}
              onKeyDown={onKeyDown}
            />
          )}
          {isShowRangePicker && (
            <StyledRangePicker
              placeholder={[t('from'), t('to')]}
              format={getDateFormatByLanguage(i18n.language)}
              disabledDate={disabledDate}
              onChange={handleDateChange}
              locale={ANTD_LOCALES[i18n.language as LOCALES]}
            />
          )}
          {tableFiltersData &&
            Object.keys(tableFiltersData).map((filter) => {
              return (
                <CustomSelect
                  key={uuid()}
                  aria-label={'Select'}
                  value={filterStates[filter]?.filterValue || 'reset'}
                  options={[
                    { value: 'reset', name: t(filter) },
                    ...(tableFiltersData ? tableFiltersData[filter] : [])
                  ]}
                  onChange={(event) => {
                    handleFilterChange(filter, event.target.value as string)
                  }}
                  width={'140px'}
                />
              )
            })}
        </StyledTableFiltersWrapper>
      </StyledTableActionsWrapper>
      <StyledTableWrapper style={wrapperStyle}>
        <TableContainer
          style={stickyHeader ? { maxHeight: '75vh', overflow: 'auto' } : {}}
          component={StyledPaper}
        >
          <Table aria-label={tableAriaLabel} stickyHeader>
            <StyledTableHead aria-label={'Table head'}>
              <TableRow>
                {tableHead.map((cellHead) => {
                  return (
                    <StyledTableHeadCell
                      key={`tableHead_${cellHead.ariaLabel}`}
                      aria-label={cellHead.ariaLabel}
                      align={cellHead.cellAlign}
                    >
                      {cellHead.sortable ? (
                        <TableSortLabel
                          active={sortBy === cellHead.cellName}
                          direction={direction}
                          onClick={() => sortHandler(String(cellHead.cellName))}
                        >
                          {cellHead.name}
                        </TableSortLabel>
                      ) : (
                        cellHead.name
                      )}
                    </StyledTableHeadCell>
                  )
                })}
              </TableRow>
            </StyledTableHead>
            <TableBody
              aria-label={'Table body'}
              sx={{
                borderRadius: '50px'
              }}
            >
              {rows?.map((row, i) => {
                return (
                  <StyledTableRow
                    key={uuid()}
                    onClick={row.rowAction ? row.rowAction : undefined}
                  >
                    {tableHead.map((item, i) => {
                      return (
                        <StyledTableBodyCell
                          key={uuid()}
                          align={item.cellAlign ? item.cellAlign : 'left'}
                        >
                          {row[item.cellName]}
                        </StyledTableBodyCell>
                      )
                    })}
                  </StyledTableRow>
                )
              })}
              {rows.length === 0 && (
                <TableRow>
                  <StyledTableBodyCell
                    colSpan={tableHead.length}
                    align={'center'}
                  >
                    {noDataText || t('noDataToDisplay')}
                  </StyledTableBodyCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {dataCount > rowsCount && (
          <StyledPaginationWrapper>
            <StyledPagination
              count={Math.ceil(dataCount / rowsCount)}
              page={page}
              onChange={(_, num) => setPage(num)}
              variant="outlined"
              color="primary"
              renderItem={(itemProps) => (
                <StyledPaginationItem {...itemProps} slots={renderItemSlots} />
              )}
            />
          </StyledPaginationWrapper>
        )}
      </StyledTableWrapper>
    </StyledWrapper>
  )
}

export default memo(CustomTable)
