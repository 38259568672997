export const LOGO_SIZE = {
  mobile: {
    width: '290px',
    height: '344px'
  },
  web: {
    width: '592px',
    height: '702px'
  },
  tablet: {
    width: '363px',
    height: '430px'
  }
}
