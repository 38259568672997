import { keyframes, styled } from '@mui/system'

const rotate = keyframes`
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(90deg);
  }
`

const rotateInvert = keyframes`
  from {
    transform: rotateZ(90deg);
  }

  to {
    transform: rotateZ(0deg);
  }
`

export const StyledLoaderContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
`

export const StyledLoaderGear1 = styled('div')`
  background-image: url('/assets/big-gear.svg');
  background-size: cover;
  background-position: center;
  width: 45px;
  height: 45px;
  position: absolute;
  animation: ${rotate} 0.75s linear infinite;
  top: 0;
  left: 0;
`

export const StyledLoaderGear2 = styled('div')`
  background-image: url('/assets/middle-gear.svg');
  background-size: cover;
  background-position: center;
  animation: ${rotate} 1s linear infinite;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 35px;
  right: 2px;
`

export const StyledLoaderGear3 = styled('div')`
  background-image: url('/assets/small-gear.svg');
  background-size: cover;
  background-position: center;
  animation: ${rotateInvert} 0.65s linear infinite;
  width: 33px;
  height: 33px;
  position: absolute;
  bottom: 4px;
  right: 32px;
`
