import { styled } from '@mui/system'

export const StyledPageContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '172px',
  width: '100%',
  height: '100%',
  margin: '0 auto',
  padding: '16px',
  boxSizing: 'border-box',
  '@media (max-width: 1140px)': {
    flexDirection: 'column',
    gap: '8px'
  }
}))

export const StyledContentContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '328px',
  '@media (max-width: 1140px)': {
    maxWidth: '398px'
  },
  '@media (max-width: 600)': {
    maxWidth: '288px'
  }
}))
