export const LOGO_SIZE = {
  web: {
    width: '120px',
    height: '60px'
  },
  tablet: {
    width: '100px',
    height: '60px'
  }
}
