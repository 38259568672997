export const APP_ROUTES = {
  MAIN: '/',
  LOGIN: '/login',
  OIDC_ERAM: '/oidc/eram',
  OAUTH_MY_LOGIN: '/oauth/my-login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  PROFILE: '/profile',
  SCHOOLS: '/schools',
  CLASSES: '/classes',
  COURSES: '/courses',
  STUDENTS: '/students',
  CLASSES_DETAIL: '/classes/:id',
  STUDENT_DETAIL: '/students/:username',
  STUDENT_DETAIL_EDIT: '/students/:username/edit',
  COURSE_DETAILS: '/courses/:id',
  COURSE_UNIT_DETAILS: '/courses/:id/:unitId',
  RESULTS: '/results',
  RESULTS_ACTIVITY: '/results/:id',
  TICKETS: '/tickets',
  VR_INTERACTIVE: '/vr/:activityId/:assignedActivityId',
  END_OF_ACTIVITY: '/end_of_activity',
  NOTIFICATIONS: '/notifications',
  ACTIVITY_DEEP_RESULT: '/results/analyse/:assignedActivityResultId'
}
