import React, { useEffect, useState } from 'react'
import { StyledPageContentWrapper } from './styles'
import { useAppDispatch } from '../../hooks'
import { IResultsByStudentToShow } from './types'
import TeacherActivityResultDetailsModal from './components/TeacherActivityResultDetailsModal'
import ResultsStudentsTable from './components/StudentsResultsTable'
import StudentResultsTableHeader from './components/StudentResultsTableHeader'
import { getStudentsResults } from './services'
import { clearState } from './slices'
import { useParams } from 'react-router-dom'

function ResultsByStudents() {
  const dispatch = useAppDispatch()
  const { id } = useParams()

  const [activityResults, setActivityResults] =
    useState<IResultsByStudentToShow | null>(null)

  useEffect(() => {
    if (id) {
      dispatch(getStudentsResults({ id: id, page: 1, sort: 'student:asc' }))
    }
    return () => {
      dispatch(clearState())
    }
  }, [])

  return (
    <StyledPageContentWrapper>
      <StudentResultsTableHeader />
      <ResultsStudentsTable setActivityResults={setActivityResults} />
      <TeacherActivityResultDetailsModal
        activityResults={activityResults}
        isOpen={!!activityResults}
        handleClose={() => setActivityResults(null)}
      />
    </StyledPageContentWrapper>
  )
}

export default ResultsByStudents
