import React, { FC } from 'react'
import AuthLayout from '../../core/Layouts/AuthLayout'
import ChangePasswordForm from './components/ChangePasswordForm'

const ResetPassword: FC = () => {
  return (
    <AuthLayout>
      <ChangePasswordForm />
    </AuthLayout>
  )
}

export default ResetPassword
