import React, { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  getResultsStudentsCountSelector,
  getResultsStudentsSelector
} from '../../selectors'
import { IResultsByStudentToShow } from '../../types'
import { TableColumn } from '../../../Results/types'
import UnitName from '../../../../components/UnitName'
import {
  formatDateByLocale,
  formatSecondsToHours
} from '../../../../utils/helpers'
import { ISortCallbackParams } from '../../../../types'
import { getStudentsResults } from '../../services'
import CustomTable from '../../../../components/CustomTable'
import { IResultsStudentsTable } from './types'
import { useParams } from 'react-router-dom'
import { InterfaceType } from '../../../Profile/types'
import { getCurrentUserSelector } from '../../../../core/selectors'
import CustomTableMobile from '../../../../components/CustomTableMobile'
import useWindowSize from '../../../../utils/hooks'
import { BREAKPOINTS } from '../../../../utils/constants'
import { RowTextRight } from '../../../../components/CustomTableMobile/styles'
import MobileRow from '../../../../components/CustomTableMobile/components/MobileRow'

function ResultsStudentsTable(props: IResultsStudentsTable) {
  const { setActivityResults } = props
  const { id } = useParams()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(getCurrentUserSelector)
  const studentsResults = useAppSelector(getResultsStudentsSelector)
  const studentsResultsCount = useAppSelector(getResultsStudentsCountSelector)
  const [windowWidth] = useWindowSize()
  const isTablet = useMemo(
    () => windowWidth <= BREAKPOINTS.tabletSm,
    [windowWidth]
  )

  const handleOpenCurrentTeacherActivityResultClick = useCallback(
    (item: IResultsByStudentToShow) => {
      setActivityResults(item)
    },
    []
  )

  const searchPlaceholder = useMemo(() => {
    switch (currentUser?.interfaceType) {
      case InterfaceType.school:
        return t('searchByStudents')
      case InterfaceType.company:
        return t('searchByEmployee')
      default:
        return t('searchByStudents')
    }
  }, [currentUser, t])

  const prepareTableHead = useMemo((): TableColumn[] => {
    return [
      {
        name: t('student'),
        ariaLabel: 'Student name',
        cellName: 'student',
        sortable: true
      },
      {
        name: t('resultOfActivity'),
        ariaLabel: 'Result of the activity: ',
        cellName: 'score',
        sortable: true
      },
      {
        name: t('dateOfLastAttempt'),
        ariaLabel: 'Date of last attempt',
        cellName: 'startedAt',
        sortable: false
      },
      {
        name: t('timeSpentOnTheActivity'),
        ariaLabel: 'Time spent on the activity',
        cellName: 'duration',
        sortable: false
      },
      {
        name: t('numberOfAttempts'),
        ariaLabel: 'Number of attempts',
        cellName: 'attempts',
        sortable: true
      }
    ]
  }, [t])

  const prepareTableDataTeacher = useMemo(() => {
    if (!studentsResults) return []

    return studentsResults.map((result) => {
      return {
        student: <UnitName unitName={result.student.fullName} />,
        score: <UnitName unitName={result.bestScore} />,
        startedAt: formatDateByLocale(result.lastResultDate) || 'N/A',
        duration: formatSecondsToHours(result.results[0]?.duration),
        attempts: result.results.length,
        rowAction: () => {
          handleOpenCurrentTeacherActivityResultClick({
            studentName: result.student.fullName,
            results: result.results,
            averageScore: result.averageScore,
            language: result.activity.language
          })
        }
      }
    })
  }, [studentsResults])

  const prepareTableDataTeacherMobile = useMemo(() => {
    if (!studentsResults.map) return []
    const header = prepareTableHead

    return studentsResults.map((result) => {
      const row = {
        student: <RowTextRight>{result.student.fullName}</RowTextRight>,
        score: <RowTextRight>{result.bestScore}</RowTextRight>,
        startedAt: (
          <RowTextRight>
            {formatDateByLocale(result.lastResultDate) || 'N/A'}
          </RowTextRight>
        ),
        duration: (
          <RowTextRight>
            {formatSecondsToHours(result.results[0]?.duration)}
          </RowTextRight>
        ),
        attempts: <RowTextRight>{result.results.length}</RowTextRight>
      }
      return (
        <MobileRow
          row={row}
          tableHead={header}
          rowTitle={result.student.fullName}
          rowAction={() => {
            handleOpenCurrentTeacherActivityResultClick({
              studentName: result.student.fullName,
              results: result.results,
              averageScore: result.averageScore,
              language: result.activity.language
            })
          }}
        />
      )
    })
  }, [studentsResults])

  const sortCallbackHandler = (params: ISortCallbackParams) => {
    if (id) {
      dispatch(getStudentsResults({ id: id, ...params }))
    }
  }

  return (
    <>
      {isTablet ? (
        <CustomTableMobile
          tableAriaLabel={'Results Table'}
          dataCount={studentsResultsCount}
          tableHead={prepareTableHead}
          rowsCount={prepareTableDataTeacher.length}
          rows={prepareTableDataTeacherMobile}
          defaultSortColumn={'student'}
          sortCallback={sortCallbackHandler}
          tableSubtitle={''}
          searchPlaceholder={searchPlaceholder}
        />
      ) : (
        <CustomTable
          tableAriaLabel={'Results Table'}
          dataCount={studentsResultsCount}
          tableHead={prepareTableHead}
          rowsCount={prepareTableDataTeacher.length}
          rows={prepareTableDataTeacher}
          defaultSortColumn={'student'}
          sortCallback={sortCallbackHandler}
          tableSubtitle={''}
          searchPlaceholder={searchPlaceholder}
        />
      )}
    </>
  )
}

export default memo(ResultsStudentsTable)
