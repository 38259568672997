import { styled as muiStyled } from '@mui/system'
import styled from 'styled-components'
import { Typography } from '@mui/material'

export const StyledCourseCard = muiStyled('div')(({ theme }) => ({
  maxWidth: '396px',
  maxHeight: '124px',
  padding: '20px',
  backgroundColor: theme.palette.customColors.bg,
  border: `1px solid ${theme.palette.customColors.linear}`,
  borderRadius: '16px',
  marginBottom: '12px',
  '@media (max-width: 1140px)': {
    maxWidth: '100%'
  }
}))

export const StyledCourseInfo = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

export const StyledDuration = styled('div')`
  display: flex;
  align-items: center;
`

export const StyledTitleCard = muiStyled(Typography)(() => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
  wordBreak: 'break-all',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}))

export const StyledUnitsCard = muiStyled(Typography)(() => ({
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  wordBreak: 'break-all',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}))

export const StyledCourseDuration = muiStyled(Typography)(
  ({ theme, color }) => ({
    fontSize: '10px',
    fontWeight: 500,
    color: theme.palette.customColors[`${color}`],
    margin: '0 0 0 4px',
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
)
