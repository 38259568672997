import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { InterfaceType } from '../../../Profile/types'
import { getCurrentUserSelector } from '../../../../core/selectors'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { useTranslation } from 'react-i18next'
import { getDashboardSelector } from '../../selectors'
import { UserType } from '../../../Login/types'
import { getTeacherStudents } from '../../services'
import { BREAKPOINTS, STUDENTS_PAGE_SIZE } from '../../../../utils/constants'
import { ITableStudentsData } from '../../types'
import CustomTable from '../../../../components/CustomTable'
import { colors, ISortCallbackParams } from '../../../../types'
import { StyledPositionBlackText, StyledPositionText } from './styles'
import useWindowSize from '../../../../utils/hooks'
import CustomTableMobile from '../../../../components/CustomTableMobile'
import MobileRow from '../../../../components/CustomTableMobile/components/MobileRow'
import {
  RowTextRight,
  StyledTableTitleRow
} from '../../../../components/CustomTableMobile/styles'

const StudentsTable = memo(() => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(getCurrentUserSelector)
  const studentsTable = useAppSelector(getDashboardSelector)

  useEffect(() => {
    if (currentUser?.type === UserType.teacher) {
      dispatch(getTeacherStudents({ limit: STUDENTS_PAGE_SIZE }))
    }
  }, [])

  const prepareTableHead = useCallback(() => {
    const isCompany = currentUser?.interfaceType === InterfaceType.company

    const tableHead = [
      {
        name: '#',
        ariaLabel: 'Position',
        cellName: 'position',
        sortable: false
      },
      {
        name: t('name'),
        ariaLabel: 'Name',
        cellName: 'name',
        sortable: false
      },
      {
        name: isCompany ? t('company') : t('school'),
        ariaLabel: 'School Name',
        cellName: 'school',
        sortable: false
      },
      {
        name: isCompany ? t('nameOfDepartment') : t('nameOfClass'),
        ariaLabel: 'Name of Class',
        cellName: 'class',
        sortable: false
      },
      {
        name: t('averageScore'),
        ariaLabel: 'Score',
        cellName: 'score',
        sortable: false
      }
    ]
    return tableHead
  }, [currentUser, t])

  const prepareTableData = (): ITableStudentsData[] => {
    if (!studentsTable.students) return []

    return studentsTable.students.map((student, index) => {
      const tableData: ITableStudentsData = {
        position: <StyledPositionText>#{index + 1}</StyledPositionText>,
        name: student.fullName,
        school: student.schools[0]?.longName,
        class: student.classes[0]?.name,
        score: student.averageScore
      }

      return tableData
    })
  }

  const prepareTableMobileData = useCallback(() => {
    if (!studentsTable.students) return []

    const header = prepareTableHead()

    return studentsTable.students.map((student, index) => {
      const row = {
        school: <RowTextRight>{student.schools[0]?.longName}</RowTextRight>,
        class: <RowTextRight>{student.classes[0]?.name}</RowTextRight>,
        score: <RowTextRight>{student.averageScore}</RowTextRight>
      }

      const rowTitle = (
        <StyledTableTitleRow>
          <StyledPositionBlackText>{student.fullName}</StyledPositionBlackText>
          <StyledPositionText>#{index + 1}</StyledPositionText>
        </StyledTableTitleRow>
      )
      return <MobileRow row={row} tableHead={header} rowTitle={rowTitle} />
    })
  }, [studentsTable])

  const getTableCourseFiltersData = () => {
    const tableResultsFiltersData: {
      course: { value: string; name: string }[]
    } = {
      course: []
    }
    const seenIds = new Set()

    studentsTable.courses?.data.forEach((course) => {
      if (!seenIds.has(course.id)) {
        tableResultsFiltersData.course.push({
          value: course.id,
          name: course.name
        })
        seenIds.add(course.id)
      }
    })

    return tableResultsFiltersData
  }

  const sortCallbackHandler = (params: ISortCallbackParams) => {
    dispatch(getTeacherStudents({ limit: STUDENTS_PAGE_SIZE, ...params }))
  }

  const [windowWidth] = useWindowSize()

  const isMobile = useMemo(
    () => windowWidth <= BREAKPOINTS.mobile,
    [windowWidth]
  )

  if (isMobile) {
    return (
      <CustomTableMobile
        style={{
          fontSize: '24px',
          fontWeight: 700,
          color: colors.black
        }}
        headerStyle={{ marginBottom: '8px' }}
        tableAriaLabel={'Course students table'}
        dataCount={studentsTable.students?.length || 0}
        tableHead={prepareTableHead()}
        rowsCount={STUDENTS_PAGE_SIZE}
        rows={prepareTableMobileData()}
        defaultSortColumn={''}
        sortCallback={sortCallbackHandler}
        tableFiltersData={getTableCourseFiltersData()}
        tableSubtitle={
          currentUser?.interfaceType === InterfaceType.school
            ? t('students')
            : t('employees')
        }
        noDataText={
          currentUser?.interfaceType === InterfaceType.school
            ? t('youDontHaveAnyYet', {
                entity: t('noStudents').toLowerCase()
              })
            : t('youDontHaveAnyYet', {
                entity: t('noEmployees').toLowerCase()
              })
        }
      />
    )
  }

  return (
    <CustomTable
      style={{ fontSize: '24px', fontWeight: 700, color: colors.black }}
      wrapperStyle={{ paddingTop: '8px' }}
      tableAriaLabel={'Course students table'}
      dataCount={studentsTable.students?.length || 0}
      tableHead={prepareTableHead()}
      rowsCount={STUDENTS_PAGE_SIZE}
      rows={prepareTableData()}
      defaultSortColumn={''}
      sortCallback={sortCallbackHandler}
      tableFiltersData={getTableCourseFiltersData()}
      tableSubtitle={
        currentUser?.interfaceType === InterfaceType.school
          ? t('students')
          : t('employees')
      }
      noDataText={
        currentUser?.interfaceType === InterfaceType.school
          ? t('youDontHaveAnyYet', {
              entity: t('noStudents').toLowerCase()
            })
          : t('youDontHaveAnyYet', {
              entity: t('noEmployees').toLowerCase()
            })
      }
    />
  )
})

export default StudentsTable
