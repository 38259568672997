export const API_ROUTES = {
  LOGIN: '/auth/login',
  MY_LOGIN: '/auth/oauth/my-login',
  ME: '/profile/info',
  PASSWORD_RESET: '/auth/password/reset',
  PASSWORD_TOKEN_VERIFY: '/auth/password/verify',
  PROFILE_EXTENDED: '/profile/extended',
  CHANGE_PROFILE_INFO: '/profile',
  CHANGE_PROFILE_AVATAR: '/profile/avatar',
  EDIT_STUDENT: '/profile/:username',
  EDIT_STUDENT_CLASSES: '/students/:id/classes',
  STUDENTS_SCHOOLS: '/students/schools',
  TEACHERS_SCHOOLS: '/teachers/schools',
  STUDENTS_UPCOMING_ACTIVITIES: '/students/dashboard/upcoming-activities',
  TEACHERS_MOST_PLAYED_ACTIVITIES: '/teachers/dashboard/most-played-activities',
  TEACHERS_RESULTS: '/teachers/results',
  TEACHERS_RESULTS_BY_ACTIVITY: '/teachers/activity/:id',
  TEACHERS_RESULTS_ID: '/teachers/results/:id',
  TEACHERS_RESULTS_ID_SUGGESTIONS: '/teachers/results/:id/suggestions',
  TEACHERS_RESULTS_ID_ASSIGNED_ACTIVITIES: '/teachers/results/:id/assigns',
  STUDENTS_COURSES: '/students/courses',
  TEACHERS_STUDENTS: '/teachers/students',
  TEACHERS_STUDENT_INFO: '/teachers/students/:username',
  TEACHERS_DASHBOARD_STUDENTS: '/teachers/dashboard/students',
  CLASSES: '/classes',
  SEND_MESSAGE_TO_CLASS: '/notifications/class',
  SEND_MESSAGE_TO_ACCOUNT: '/notifications/account/:id',
  STUDENT_COURSES: '/students/courses',
  TEACHER_COURSES: '/teachers/courses',
  FIND_SCHOOLS: '/find/schools',
  STUDENT_COURSE_DETAILS: '/students/courses',
  TEACHER_COURSE_DETAILS: '/teachers/courses',
  STUDENT_ASSIGNED_ACTIVITIES: '/students/units',
  TEACHER_ACTIVITIES: '/teachers/units',
  UNIT_INFO: '/units',
  ACTIVITY_BY_ID: '/activities',
  STUDENT_RESULTS: '/students/results',
  STUDENT_RESULTS_ID: '/students/results/:id/extended',
  STUDENT_RESULTS_ID_SUGGESTIONS: '/students/results/:id/suggestions',
  STUDENT_RESULTS_ID_ASSIGNED_ACTIVITIES: '/students/results/:id/assigns',
  STUDENT_ANALYZE: '/activities/answer/:id/analyze',
  ASSIGN_COURSE: '/assign/course',
  ASSIGN_UNIT: '/assign/unit',
  ASSIGN_ACTIVITY: '/assign/activity',
  NOTIFICATIONS: '/notifications',
  TICKETS: '/tickets',
  TICKET_CATEGORIES: '/tickets/categories',
  COURSES_BY_ID: '/courses',
  TRANSCRIBE: '/activities/answer/transcribe',
  EXPORT_STUDENT_RESULTS: '/export/student/results',
  EXPORT_TEACHER_RESULTS: '/export/teacher/results',
  EXPORT_RESULTS: '/export/results'
}
