import React, { memo } from 'react'
import { APP_ROUTES } from '../../../../../../router/appRoutes'
import SidebarItem from '../../../SidebarItem'
import {
  BreakLine,
  StyledSelect,
  StyledText
} from '../../../TeacherSidebar/styles'
import { Language, UserType } from '../../../../../../../containers/Login/types'
import { MenuItem } from '@mui/material'
import { CustomSvgIcon } from '../../../../../../../components/CustomSvgIcon'
import { useTranslation } from 'react-i18next'
import { IDrawerProps } from '../../../TeacherSidebar/types'
import CustomImage from '../../../../../../../components/CustomImage'
import {
  StyledSidebar,
  StyledSidebarMainItems,
  SidebarLogout,
  StyledAvatarContainer,
  StyledUserName,
  StyledSidebarHeader,
  StyledSidebarCloseBtn,
  StyledSidebarContainer
} from './styles'

const MobileSidebar = ({
  menuOptions,
  currentUser,
  isTablet,
  language,
  handleLanguagesChange,
  options,
  logout,
  closeMenu
}: IDrawerProps) => {
  const onBlurClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  const { t } = useTranslation()

  return (
    <StyledSidebarContainer onClick={closeMenu}>
      <StyledSidebar aria-label="Navigation menu" onClick={onBlurClick}>
        <StyledSidebarHeader>
          <StyledAvatarContainer
            aria-label={'Link to my profile'}
            to={'/profile'}
          >
            <CustomImage
              borderRadius={'20px'}
              src={currentUser?.avatarUrl || '/assets/defaultLogo.svg'}
              width={'40px'}
              height={'40px'}
              alt={'Avatar'}
            />
            <StyledUserName aria-label={'Tooltip actions button'}>
              {currentUser?.fullName}
            </StyledUserName>
          </StyledAvatarContainer>
          <StyledSidebarCloseBtn onClick={closeMenu}>
            <CustomSvgIcon
              path={'/assets/close-icon.svg'}
              width={'24px'}
              height={'24px'}
            />
          </StyledSidebarCloseBtn>
        </StyledSidebarHeader>

        <StyledSidebarMainItems>
          <SidebarItem
            locationName={t('dashboard')}
            path={APP_ROUTES.MAIN}
            iconPath={'/assets/home-icon.svg'}
          />
          <SidebarItem
            locationName={menuOptions?.building}
            path={APP_ROUTES.SCHOOLS}
            iconPath={'/assets/school-icon.svg'}
          />
          {currentUser?.type === UserType.teacher && (
            <SidebarItem
              locationName={menuOptions?.typeUser}
              path={APP_ROUTES.STUDENTS}
              iconPath={'/assets/person-icon.svg'}
            />
          )}
          <SidebarItem
            locationName={menuOptions.place}
            path={APP_ROUTES.CLASSES}
            iconPath={'/assets/classes-icon.svg'}
          />
          <SidebarItem
            locationName={t('courses')}
            path={APP_ROUTES.COURSES}
            iconPath={'/assets/courses-icon.svg'}
          />
          <SidebarItem
            locationName={t('results')}
            path={APP_ROUTES.RESULTS}
            iconPath={'/assets/results-icon.svg'}
          />
          {currentUser?.type === UserType.teacher && (
            <SidebarItem
              style={{ padding: '8px' }}
              locationName={t('askForAssistance')}
              path={APP_ROUTES.TICKETS}
              iconPath={'/assets/info-icon.svg'}
              color={'gray'}
            />
          )}
        </StyledSidebarMainItems>
        <BreakLine />
        <SidebarLogout aria-label={'Logout button'} onClick={logout}>
          <CustomSvgIcon path={'/assets/logout.svg'} title={'LogOut'} />
          {!isTablet && <StyledText>Log out</StyledText>}
        </SidebarLogout>
        <StyledSelect
          MenuProps={{
            sx: {
              '& .MuiMenu-paper': {
                height: '130px',
                width: '112px',
                borderRadius: '16px',
                padding: '8px 6px',
                boxShadow: '0px 2px 8px 0px rgba(55, 82, 102, 0.16)'
              },
              '& .MuiMenuItem-root:hover': {
                borderRadius: '16px'
              },
              '& .Mui-selected': {
                borderRadius: '16px'
              }
            }
          }}
          value={language}
          onChange={(event) =>
            handleLanguagesChange(event.target.value as Language)
          }
          aria-label={'Select language'}
        >
          {options.map((opt) => {
            return (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.label}
              </MenuItem>
            )
          })}
        </StyledSelect>
      </StyledSidebar>
    </StyledSidebarContainer>
  )
}

export default memo(MobileSidebar)
