import { createAsyncThunk } from '@reduxjs/toolkit'
import { get, patch, post } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { IErrorResponse } from '../../../types'
import {
  IChangeProfileAvatarPayload,
  IChangeProfileInfoPayload,
  IProfileExtended
} from '../types'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const getProfileExtended = createAsyncThunk<
  IProfileExtended,
  void,
  ICommonChunkConfig
>('profile/fetchProfileExtended', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.PROFILE_EXTENDED,
      params: {}
    })
    return response as IProfileExtended
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const changeProfileInfo = createAsyncThunk<
  IChangeProfileInfoPayload,
  IChangeProfileInfoPayload,
  ICommonChunkConfig
>('profile/patchProfileInfo', async (_data, { rejectWithValue }) => {
  try {
    await patch({
      path: API_ROUTES.CHANGE_PROFILE_INFO,
      data: _data
    })
    return _data
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const changeProfileAvatar = createAsyncThunk<
  void,
  IChangeProfileAvatarPayload,
  ICommonChunkConfig
>('profile/postProfileAvatar', async (_data, { rejectWithValue }) => {
  try {
    await post({
      path: API_ROUTES.CHANGE_PROFILE_AVATAR,
      data: _data.file
    })
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
