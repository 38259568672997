import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IAssignedActivitySuggestion,
  IExtendedResultResponse,
  IResultDetailsState,
  ISuggestionActivity,
  IToggleShowSuggestionsResponse
} from '../types'
import {
  getAssignedActivitiesSuggestions,
  getStudentResult,
  getStudentResultSuggestions,
  getTeacherResult,
  getTeacherResultSuggestions,
  toggleShowSuggestions
} from '../services'

const initialState: IResultDetailsState = {
  isLoading: false,
  resultDetails: null,
  showSuggestions: false,
  suggestions: [],
  assignedActivities: []
}

export const resultDetailsSlice = createSlice({
  name: 'resultDetails',
  initialState,
  reducers: {
    clearState: () => initialState,
    setDetails: (
      state,
      { payload }: PayloadAction<IExtendedResultResponse>
    ) => {
      state.resultDetails = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTeacherResult.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getTeacherResult.fulfilled,
      (state, { payload }: PayloadAction<IExtendedResultResponse>) => {
        state.isLoading = false
        state.resultDetails = payload
        state.showSuggestions = payload.showSuggestions
      }
    )
    builder.addCase(getTeacherResult.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getStudentResult.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getStudentResult.fulfilled,
      (state, { payload }: PayloadAction<IExtendedResultResponse>) => {
        state.isLoading = false
        state.resultDetails = payload
        state.showSuggestions = payload.showSuggestions
      }
    )
    builder.addCase(getStudentResult.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(
      toggleShowSuggestions.fulfilled,
      (state, { payload }: PayloadAction<IToggleShowSuggestionsResponse>) => {
        state.showSuggestions = payload.showSuggestions
      }
    )
    builder.addCase(
      getTeacherResultSuggestions.fulfilled,
      (state, { payload }: PayloadAction<ISuggestionActivity[]>) => {
        state.suggestions = payload
      }
    )
    builder.addCase(
      getStudentResultSuggestions.fulfilled,
      (state, { payload }: PayloadAction<ISuggestionActivity[]>) => {
        state.suggestions = payload
      }
    )
    builder.addCase(
      getAssignedActivitiesSuggestions.fulfilled,
      (state, { payload }: PayloadAction<IAssignedActivitySuggestion[]>) => {
        state.assignedActivities = payload
      }
    )
  }
})

export const { clearState, setDetails } = resultDetailsSlice.actions
export default resultDetailsSlice.reducer
