import { styled } from '@mui/system'
import { FormHelperText, InputLabel, OutlinedInput } from '@mui/material'
import { IStyledTextField } from '../types'
import { colors } from '../../../types'

const options = {
  shouldForwardProp: (propName: string) => {
    switch (propName) {
      case 'width':
        return false
      default:
        return true
    }
  }
}

export const StyledInputWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  // marginRight: '12px',
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.customColors.violet
    }
  }
}))

export const StyledOutlinedInput = styled(
  OutlinedInput,
  options
)<IStyledTextField>(({ theme, width }) => ({
  borderRadius: '12px',
  height: '32px',
  minWidth: '180px',
  boxSizing: 'border-box',
  width: width,
  backgroundColor: `${theme.palette.customColors.bg}`,
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    height: '32px',
    '& input:-webkit-autofill': {
      height: '32px',
      boxSizing: 'border-box',
      WebkitBoxShadow: '0 0 0 30px white inset !important'
    }
  },
  '& input:-internal-autofill-selected': {
    height: '36px',
    boxSizing: 'border-box',
    backgroundColor: `${theme.palette.customColors.white} !important`,
    WebkitBoxShadow: '0 0 0 30px white inset !important'
  },
  '& fieldset': {
    borderColor: theme.palette.customColors.linear
  }
}))

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  marginLeft: '16px',
  marginBottom: '10px',
  fontSize: '16px',
  fontWeight: 600,
  color: theme.palette.customColors.black
}))

export const StyledIconButton = styled('button')<{
  adornmentBg?: keyof typeof colors
}>(({ theme, adornmentBg }) => ({
  border: 'none',
  display: 'flex',
  padding: 0,
  boxSizing: 'content-box',
  backgroundColor: adornmentBg
    ? theme.palette.customColors[adornmentBg]
    : theme.palette.customColors.white,
  '&:hover': {
    cursor: 'pointer'
  }
}))

export const StyledFormHelperText = styled(FormHelperText)(() => ({
  marginLeft: '14px'
}))
