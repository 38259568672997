import { styled } from '@mui/system'
import { Form } from 'formik'
import { Link } from 'react-router-dom'

export const StyledLoginFormWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '20px',
  width: '100%',
  height: '100%',
  margin: '0 auto',
  '@media (max-width: 1140px)': {
    flexDirection: 'column',
    gap: '8px'
  },
  '@media (max-width: 768px)': {
    maxWidth: '398px'
  },

  '& .my-login-btn': {
    cursor: 'pointer',
    padding: 0,
    margin: '24px 0 0',
    border: 'none',
    background: 'none'
  }
}))

export const StyledForm = styled(Form)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  maxWidth: '328px',
  '@media (max-width: 1140px)': {
    maxWidth: '398px'
  },
  '@media (max-width: 768px)': {
    maxWidth: '288px'
  }
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.customColors.gray,
  textDecoration: 'none',
  marginTop: '10px'
}))
