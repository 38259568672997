import { IUser, Language } from '../../Login/types'

export enum InterfaceType {
  school = 'school',
  company = 'company'
}

export interface IExtendedProfileInfo {}

export interface ITinySchool {
  id: string
  shortName: string
  longName: string
}

export interface IClassIdName {
  id: string
  name: string
}

export interface ITinyCourse {
  id: string
  name: string
}

export interface IProfileExtended extends IUser {
  schools: ITinySchool[] | null
  classes: IClassIdName[] | null
}

export interface IUpdateCurrentUserScoreFormatPayload {
  scoreFormat: ScoreFormatType
}

export interface IProfileState {
  isLoading: boolean
  isPersonalInfoUpdating: boolean
  isAvatarUpdating: boolean
  profile: IProfileExtended | null
}

export interface IChangeProfileInfoPayload {
  fullName?: string
  email?: string
  language?: Language
  scoreFormat?: ScoreFormatType
}

export enum ScoreFormatType {
  percent = 'percent',
  ten = 'ten',
  twenty = 'twenty',
  thirty = 'thirty',
  letters_af = 'letters_af'
}

export interface IChangeProfileAvatarPayload {
  file: FormData
}
