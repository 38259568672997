import React, { FC, memo, PropsWithChildren } from 'react'
import { Props } from './types'
import CustomModal from '../../../../components/CustomModal'
import { StyledButton } from '../../../../common/styles'
import { useTranslation } from 'react-i18next'
import {
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledInfoItem,
  StyledInfoItemWrapper
} from './styles'

const DeleteStudentCourseModal: FC<PropsWithChildren<Props>> = ({
  courseId,
  onCloseModal,
  onDelete,
  children
}) => {
  const { t } = useTranslation()
  return (
    <CustomModal
      open={!!courseId}
      width={'444px'}
      title={t('deleteCourse')}
      handleClose={onCloseModal}
    >
      <StyledContentWrapper>
        <div>{children}</div>
        <StyledButtonsWrapper>
          <StyledButton
            type={'button'}
            size={'large'}
            variant={'outlined'}
            color={'primary'}
            style={{ width: '100%' }}
            onClick={onCloseModal}
          >
            {t('cancel')}
          </StyledButton>
          <StyledButton
            type={'submit'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            style={{ width: '100%' }}
            onClick={() => courseId && onDelete(courseId)}
          >
            {t('delete')}
          </StyledButton>
        </StyledButtonsWrapper>
      </StyledContentWrapper>
    </CustomModal>
  )
}

export default memo(DeleteStudentCourseModal)
