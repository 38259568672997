import React from 'react'
import { Field, FieldAttributes } from 'formik'
import { StyledInputForm, StyledInputLabel, StyledTextField } from './styles'
import { IFormInputProps } from './types'

const FormInput = (props: IFormInputProps) => {
  const { name, label, id, onChange, type, width, placeholder, multiline } =
    props

  return (
    <Field name={name}>
      {({
        field: { value },
        form: { errors, touched, setFieldTouched, setFieldValue }
      }: FieldAttributes<any>) => {
        const elementId = id || 'form-el-' + name
        return (
          <StyledInputForm>
            {label && <StyledInputLabel>{label}</StyledInputLabel>}
            <StyledTextField
              name={name}
              id={elementId}
              value={value}
              width={width}
              error={!!errors[name] && touched[name]}
              type={type}
              multiline={multiline}
              placeholder={placeholder}
              helperText={errors[name] && touched[name] ? errors[name] : ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (setFieldValue) {
                  setFieldValue(e.target.name, e.target.value, true)
                }
                setFieldTouched(name, true, false)
                if (onChange) {
                  onChange(e)
                }
              }}
            />
          </StyledInputForm>
        )
      }}
    </Field>
  )
}

export default FormInput
