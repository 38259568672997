export const LOGO_SIZE = {
  web: {
    width: '450px',
    height: '225px'
  },
  tablet: {
    width: '398px',
    height: '200px'
  },
  mobile: {
    width: '288px',
    height: '144px'
  }
}
