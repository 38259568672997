import { styled as muiStyled } from '@mui/system'
import SVG from 'react-inlinesvg'
import { IStyledSvgIconProps } from '../types'
import { useTheme } from '@mui/material/styles'

const options = {
  shouldForwardProp: (propName: string) => {
    switch (propName) {
      case 'width':
      case 'height':
        return false
      default:
        return true
    }
  }
}

export const StyledSvgIcon = muiStyled(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  SVG,
  options
)<IStyledSvgIconProps>(({ color, width, height }) => {
  const theme = useTheme()
  return {
    width,
    height,
    '& path': {
      fill: color ? theme.palette.customColors[color] : ''
    }
  }
})
