import React, { memo } from 'react'
import { APP_ROUTES } from '../../../../router/appRoutes'
import SidebarItem from '../SidebarItem'
import {
  BreakLine,
  SidebarLogout,
  StyledSelect,
  StyledSidebar,
  StyledSidebarMainItems,
  StyledText
} from '../TeacherSidebar/styles'
import {
  IUser,
  Language,
  UserType
} from '../../../../../containers/Login/types'
import { MenuItem } from '@mui/material'
import { CustomSvgIcon } from '../../../../../components/CustomSvgIcon'
import { useTranslation } from 'react-i18next'
import { IWebSidebarProps } from '../TeacherSidebar/types'

const WebSidebar = ({
  menuOptions,
  currentUser,
  isTablet,
  language,
  handleLanguagesChange,
  options,
  logout
}: IWebSidebarProps) => {
  const { t } = useTranslation()
  return (
    <StyledSidebar aria-label="Navigation menu">
      <StyledSidebarMainItems>
        <SidebarItem
          locationName={t('dashboard')}
          path={APP_ROUTES.MAIN}
          iconPath={'/assets/home-icon.svg'}
          isTablet={isTablet}
        />
        <SidebarItem
          locationName={menuOptions?.building}
          path={APP_ROUTES.SCHOOLS}
          iconPath={'/assets/school-icon.svg'}
          isTablet={isTablet}
        />
        {currentUser?.type === UserType.teacher && (
          <SidebarItem
            locationName={menuOptions?.typeUser}
            path={APP_ROUTES.STUDENTS}
            iconPath={'/assets/person-icon.svg'}
            isTablet={isTablet}
          />
        )}
        <SidebarItem
          locationName={menuOptions.place}
          path={APP_ROUTES.CLASSES}
          iconPath={'/assets/classes-icon.svg'}
          isTablet={isTablet}
        />
        <SidebarItem
          locationName={t('courses')}
          path={APP_ROUTES.COURSES}
          iconPath={'/assets/courses-icon.svg'}
          isTablet={isTablet}
        />
        <SidebarItem
          locationName={t('results')}
          path={APP_ROUTES.RESULTS}
          iconPath={'/assets/results-icon.svg'}
          isTablet={isTablet}
        />
      </StyledSidebarMainItems>
      {currentUser?.type === UserType.teacher && (
        <SidebarItem
          locationName={t('askForAssistance')}
          path={APP_ROUTES.TICKETS}
          iconPath={'/assets/info-icon.svg'}
          color={'gray'}
          isTablet={isTablet}
        />
      )}
      <BreakLine />
      <SidebarLogout aria-label={'Logout button'} onClick={logout}>
        <CustomSvgIcon path={'/assets/logout.svg'} title={'LogOut'} />
        {!isTablet && <StyledText>Log out</StyledText>}
      </SidebarLogout>
      <StyledSelect
        MenuProps={{
          sx: {
            '& .MuiMenu-paper': {
              height: '130px',
              width: '112px',
              borderRadius: '16px',
              padding: '8px 6px',
              boxShadow: '0px 2px 8px 0px rgba(55, 82, 102, 0.16)'
            },
            '& .MuiMenuItem-root:hover': {
              borderRadius: '16px'
            },
            '& .Mui-selected': {
              borderRadius: '16px'
            }
          }
        }}
        value={language}
        onChange={(event) =>
          handleLanguagesChange(event.target.value as Language)
        }
        aria-label={'Select language'}
      >
        {options.map((opt) => {
          return (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          )
        })}
      </StyledSelect>
    </StyledSidebar>
  )
}

export default memo(WebSidebar)
