import { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AccordionContent from '../../../../components/AccordionContent'
import {
  BREAKPOINTS,
  DEFAULT_LIST_ITEM_SIZE
} from '../../../../utils/constants'
import useWindowSize from '../../../../utils/hooks'
import { TableColumn } from '../../../UnitDetails/types'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { getCurrentUserSelector } from '../../../../core/selectors'
import { UserType } from '../../../Login/types'
import { CustomChip } from '../../../../components/CustomChip'
import { getCustomChipOptions } from '../../../../utils/helpers'
import UnitName from '../../../../components/UnitName'
import CustomTable from '../../../../components/CustomTable'
import CustomTableMobile from '../../../../components/CustomTableMobile'
import { Button } from '@mui/material'
import Tags from '../../../../components/Tags'
import MobileRow from '../../../../components/CustomTableMobile/components/MobileRow'
import { useNavigate, useParams } from 'react-router-dom'
import { LevelType } from '../../../Home/types'
import { getAssignedActivitiesSelector } from '../../selectors'
import { getAssignedActivitiesSuggestions } from '../../services'
import { APP_ROUTES } from '../../../../core/router/appRoutes'
import { RecommendationBlock } from '../../styles'

interface Props {
  classId: string
  studentId: string
}

const AssignedActivities: FC<Props> = ({ classId, studentId }) => {
  const params = useParams()
  const id = params.assignedActivityResultId!
  const assignedActivities = useAppSelector(getAssignedActivitiesSelector)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const currentUser = useAppSelector(getCurrentUserSelector)
  const { t } = useTranslation()
  const [windowWidth] = useWindowSize()
  const isTablet = useMemo(
    () => windowWidth <= BREAKPOINTS.tabletSm,
    [windowWidth]
  )

  const fetchAssignedActivities = useCallback(() => {
    if (!currentUser) return
    dispatch(getAssignedActivitiesSuggestions({ id, type: currentUser.type }))
  }, [id, currentUser])

  const prepareTableHead = useCallback(() => {
    if (!currentUser) return []

    const tableHead: TableColumn[] = [
      {
        name: t('activityName'),
        ariaLabel: 'Activity name',
        cellName: 'activity',
        sortable: false
      },
      {
        name: t('level'),
        ariaLabel: 'Unit level',
        cellName: 'level',
        cellAlign: 'center',
        sortable: false
      },
      {
        name: t('tags'),
        ariaLabel: 'Tags',
        cellName: 'tags',
        cellAlign: 'center',
        sortable: false
      }
    ]

    if (currentUser.type === UserType.student && !isTablet) {
      tableHead.push({
        name: t('start'),
        ariaLabel: 'Start activity action',
        cellName: 'start',
        sortable: false,
        cellAlign: 'center'
      })
    }

    return tableHead
  }, [currentUser, isTablet, t])

  const handleStartActivity = useCallback(
    (activityId: string, assignedId: string) => {
      const startRoute = APP_ROUTES.VR_INTERACTIVE.replace(
        ':activityId',
        activityId
      ).replace(':assignedActivityId', assignedId)
      navigate(startRoute)
    },
    [navigate]
  )

  const prepareLevel = useCallback(
    (level: LevelType) => {
      const chipOptions = getCustomChipOptions(level)
      return (
        <CustomChip
          aria-label="Unit level"
          bgColor={chipOptions.bgColor}
          label={chipOptions.name}
          textColor={chipOptions.textColor}
          borderColor={chipOptions.borderColor}
          height="20px"
        />
      )
    },
    [getCustomChipOptions]
  )

  const prepareTableData = useCallback(() => {
    if (!currentUser) return []

    return assignedActivities.map((assignedActivity) => {
      const tags = [
        ...(assignedActivity.grammarTags || []),
        ...(assignedActivity.syntaxTags || []),
        ...(assignedActivity.vocabTags || [])
      ]

      const row: Record<string, JSX.Element> = {
        activity: <UnitName activityName={assignedActivity.title} />,
        level: prepareLevel(assignedActivity.level),
        tags: <Tags tags={tags} style={{ justifyContent: 'center' }} />
      }

      if (currentUser.type === UserType.student) {
        row.start = (
          <Button
            type="button"
            size="medium"
            variant="contained"
            color="primary"
            style={{ width: 'max-content' }}
            onClick={() => {
              handleStartActivity(
                assignedActivity.activityId,
                assignedActivity.id
              )
            }}
          >
            {t('start')}
          </Button>
        )
      }

      return row
    })
  }, [t, assignedActivities, currentUser, prepareLevel, handleStartActivity])

  const prepareTableDataMobile = useCallback(() => {
    if (!currentUser) return []
    const header = prepareTableHead()

    return assignedActivities.map((assignedActivity) => {
      const buttons: JSX.Element[] = []
      if (currentUser.type === UserType.student) {
        buttons.push(
          <Button
            type="button"
            size="medium"
            variant="contained"
            color="primary"
            onClick={() => {
              handleStartActivity(
                assignedActivity.activityId,
                assignedActivity.id
              )
            }}
          >
            {t('start')}
          </Button>
        )
      }

      const tags = [
        ...(assignedActivity.grammarTags || []),
        ...(assignedActivity.syntaxTags || []),
        ...(assignedActivity.vocabTags || [])
      ]

      const row: Record<string, JSX.Element> = {
        activity: <UnitName activityName={assignedActivity.title} />,
        level: prepareLevel(assignedActivity.level),
        tags: <Tags tags={tags} style={{ justifyContent: 'flex-end' }} />
      }

      return (
        <MobileRow
          row={row}
          rowTitle={assignedActivity.title}
          buttons={buttons}
          tableHead={header}
        />
      )
    })
  }, [
    t,
    assignedActivities,
    currentUser,
    prepareLevel,
    handleStartActivity,
    prepareTableHead
  ])

  const desktopContent = useMemo(() => {
    return (
      <RecommendationBlock>
        <CustomTable
          tableAriaLabel={'Activities table'}
          tableHead={prepareTableHead()}
          dataCount={assignedActivities.length}
          rowsCount={DEFAULT_LIST_ITEM_SIZE}
          rows={prepareTableData()}
          tableSubtitle=""
        />
      </RecommendationBlock>
    )
  }, [t, assignedActivities, prepareTableHead, prepareTableData])

  const tabletContent = useMemo(() => {
    return (
      <RecommendationBlock>
        <CustomTableMobile
          tableAriaLabel={'Activities table'}
          tableHead={prepareTableHead()}
          dataCount={assignedActivities.length}
          rowsCount={DEFAULT_LIST_ITEM_SIZE}
          rows={prepareTableDataMobile()}
          tableSubtitle=""
        />
      </RecommendationBlock>
    )
  }, [t, assignedActivities, prepareTableHead, prepareTableDataMobile])

  useEffect(() => {
    fetchAssignedActivities()
  }, [])

  return (
    <AccordionContent
      hideTitle={t('hideAssignedActivities')}
      showTitle={t('showAssignedActivities')}
    >
      {isTablet ? tabletContent : desktopContent}
    </AccordionContent>
  )
}

export default AssignedActivities
