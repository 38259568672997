import { styled } from '@mui/system'
import { FormHelperText, InputLabel, Select } from '@mui/material'
import { IStyledTextField } from '../types'

const options = {
  shouldForwardProp: (propName: string) => {
    switch (propName) {
      case 'width':
        return false
      default:
        return true
    }
  }
}

export const StyledInputForm = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
}))

export const StyledSelect = styled(
  Select,
  options
)<IStyledTextField>(({ theme, width }) => ({
  borderRadius: '20px',
  boxSizing: 'border-box',
  marginBottom: '2px',
  width: width,
  backgroundColor: `${theme.palette.customColors.white}`,
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    '& input:-webkit-autofill': {
      height: '36px',
      boxSizing: 'border-box',
      WebkitBoxShadow: '0 0 0 30px white inset !important'
    }
  },
  '& input:-internal-autofill-selected': {
    backgroundColor: theme.palette.customColors.white
  }
}))

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  marginLeft: '16px',
  marginBottom: '10px',
  fontSize: '16px',
  fontWeight: 600,
  color: theme.palette.customColors.black
}))

export const StyledHelperText = styled(FormHelperText)(({ theme }) => ({
  marginLeft: '8px',
  color: theme.palette.customColors.muiDarkRed
}))
