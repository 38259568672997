import React, { useMemo } from 'react'
import useWindowSize from '../../../../utils/hooks'
import { CustomSvgIcon } from '../../../../components/CustomSvgIcon'
import { BREAKPOINTS } from '../../../../utils/constants'
import { LOGO_SIZE } from '../../constants'

const LogoMobile = () => {
  const [windowWidth] = useWindowSize()
  const isTablet = useMemo(
    () =>
      windowWidth > BREAKPOINTS.tabletSm && windowWidth < BREAKPOINTS.desktop,
    [windowWidth]
  )
  const isWeb = useMemo(() => windowWidth >= BREAKPOINTS.desktop, [windowWidth])

  const { width, height } = isWeb
    ? LOGO_SIZE.web
    : isTablet
    ? LOGO_SIZE.tablet
    : LOGO_SIZE.mobile

  return (
    <CustomSvgIcon
      path={'/assets/logo.svg'}
      width={width}
      height={height}
      title={'Beyond Words Logo'}
    />
  )
}

export default LogoMobile
