import React from 'react'
import { ICourseCardProps } from './types'
import {
  StyledCourseCard,
  StyledCourseDuration,
  StyledCourseInfo,
  StyledDuration,
  StyledTitleCard,
  StyledUnitsCard
} from './styles'
import { CustomSvgIcon } from '../../../../components/CustomSvgIcon'
import { useTranslation } from 'react-i18next'

export const CourseCard = (props: ICourseCardProps) => {
  const { t } = useTranslation()

  return (
    <StyledCourseCard aria-label={props.title}>
      <StyledTitleCard variant={'h3'}>{props.title}</StyledTitleCard>
      <StyledUnitsCard aria-label={'Units count'}>
        {props.subTitle}
      </StyledUnitsCard>
      <StyledCourseInfo>
        <StyledDuration aria-label={'Course duration'}>
          <CustomSvgIcon
            color={'gray'}
            path={'/assets/bag.svg'}
            title={'clock icon'}
          />
          <StyledCourseDuration color={'gray'}>
            {props.activities} {t('activities', { count: props.activities })}
          </StyledCourseDuration>
        </StyledDuration>
      </StyledCourseInfo>
    </StyledCourseCard>
  )
}
