import React from 'react'
import { StyledChip } from './styles'
import { ICustomChipProps } from './types'

export const CustomChip = (props: ICustomChipProps) => {
  return (
    <StyledChip
      aria-label={props['aria-label']}
      label={props.label}
      bgColor={props.bgColor}
      textColor={props.textColor}
      borderColor={props.borderColor}
      height={props.height}
    />
  )
}
