import { styled } from '@mui/system'
import { Button, Typography } from '@mui/material'

export const StyledPageWrapper = styled('div')(() => ({
  width: '100%',
  height: '100%',
  padding: '24px',
  boxSizing: 'border-box',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    justifyContent: 'center',
    alignItems: 'flex-end',
    margin: '0 auto',
    padding: '16px'
  }
}))

export const StyledMainContentWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '42px',
  width: '100%',
  position: 'relative',
  '@media (max-width: 1140px)': {
    gap: '16px'
  },
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    height: '50%',
    justifyContent: 'flex-end'
  }
}))

export const StyledCongratulationsWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '24px',
  '@media (max-width: 600px)': {
    gap: '16px',
    width: '100%',
    height: '100%',
    position: 'absolute',
    justifyContent: 'flex-end',
    zIndex: 1,
    background: `linear-gradient(to top, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 80%)`
  }
}))

export const StyledButtonWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  gap: '12px'
}))

export const StyledCongratulationsTitle = styled(Typography)(({ theme }) => ({
  fontSize: '42px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '138%',
  color: theme.palette.customColors.black,
  '@media (max-width: 600px)': {
    fontSize: '28px',
    lineHeight: '38px'
  }
}))

export const StyledCongratulationsSubTitle = styled(Typography)(
  ({ theme }) => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '125%',
    color: theme.palette.customColors.black
  })
)

export const StyledButton = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  width: 'fit-content',
  padding: '8px 40px 8px 32px',
  '@media (max-width: 600px)': {
    width: '100%'
  }
}))

export const StyledLogoContainer = styled('div')(() => ({
  width: 'fit-content',
  height: 'fit-content',
  '@media (max-width: 600px)': {
    position: 'absolute',
    bottom: '100px',
    zIndex: 0
  }
}))
