import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Button, Paper, Typography } from '@mui/material'
import { DatePicker } from 'antd'
const { RangePicker } = DatePicker
export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.customColors.bg,
    color: theme.palette.common.black,
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '12px'
  },
  border: 0,
  '&:last-child': {
    textAlign: 'center'
  }
}))

export const StyledTableBodyCell = styled(TableCell)(() => ({
  border: 0,
  wordBreak: 'break-word',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  boxSizing: 'border-box',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  '&:last-of-type': {
    textAlign: 'center'
  }
}))

export const StyledTableRow = styled(TableRow)(({ theme, onClick }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.customColors.white
  },
  '&:nth-of-type(1)': {
    'td:first-of-type': {
      borderRadius: '16px 0 0 0'
    },
    'td:last-of-type': {
      borderRadius: '0 16px 0 0'
    }
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.customColors.bg
  },
  '&:hover': {
    cursor: onClick ? 'pointer' : '',
    boxShadow: onClick ? '0px 2px 8px 0px rgba(55, 82, 102, 0.16)' : '',
    transform: onClick ? 'translateY(-2px)' : '',
    transition: onClick ? 'transform 0.2s ease-in-out' : ''
  }
}))

export const StyledPaper = styled(Paper)(({ theme }) => ({
  minWidth: '250px',
  border: 'none',
  boxShadow: 'none',
  borderRadius: '16px',
  backgroundColor: theme.palette.customColors.bg
}))

export const StyledTableWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.customColors.bg,
  border: `1px solid ${theme.palette.customColors.bg}`,
  borderRadius: '24px',
  boxSizing: 'border-box',
  padding: '14px 12px'
}))

export const StyledPaginationWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '24px'
}))

export const StyledWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}))

export const StyledTableActionsWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginBottom: '8px',
  boxSizing: 'border-box',
  width: '100%',
  gap: '12px',
  overflowX: 'scroll',
  overflowY: 'hidden',
  '::-webkit-scrollbar': {
    display: 'none'
  }
}))

export const StyledTableSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
  color: theme.palette.customColors.gray
}))

export const StyledButton = styled(Button)(() => ({
  boxSizing: 'border-box',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  width: 'fit-content',
  padding: '10px 32px',
  '&:first-of-type': {
    marginRight: '12px'
  },
  '@media (max-width: 600px)': {
    width: '100%'
  }
}))

export const StyledButtonWrapper = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    gap: '10px'
  }
}))

export const StyledRangePicker = styled(RangePicker)(({ theme }) => ({
  borderRadius: '24px',
  minWidth: '100px',
  backgroundColor: theme.palette.customColors.bg,
  borderColor: theme.palette.customColors.muiDarkGrey,
  '&:hover': {
    borderColor: `${theme.palette.customColors.black}!important`
  },
  '&.ant-picker': {
    border: `1px solid ${theme.palette.customColors.muiDarkGrey}`,
    transition: 'none'
  },
  '&.ant-picker-focused': {
    borderColor: `${theme.palette.customColors.violet}!important`,
    border: '2px solid',
    boxShadow: 'none',
    padding: '2px 10px 2px',
    transition: 'none'
  }
}))

export const SortChip = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.customColors.bg,
  borderColor: theme.palette.customColors.linear,
  border: `1px solid ${theme.palette.customColors.linear}`,
  padding: '8px 20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  borderRadius: '16px',
  paddingRight: '10px',
  boxSizing: 'border-box',
  height: '32px',
  whiteSpace: 'nowrap'
}))

export const ChipText = styled(Typography)(({}) => ({
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 500
}))

export const StyledTableMobileRow = styled('td')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  padding: '12px',
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.customColors.white
  },
  width: '100%',
  boxSizing: 'border-box'
}))

export const StyledTableContents = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '12px',
  boxSizing: 'border-box'
}))

export const RowTitle = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 500,
  width: '100%'
}))

export const SmallRow = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  gap: '4px',
  boxSizing: 'border-box'
}))

export const Divider = styled('div')(({ theme }) => ({
  width: '100%',
  borderBottom: `0.5px dotted ${theme.palette.customColors.violetGrey}`,
  boxSizing: 'border-box'
}))

export const RowTextLeft = styled(Typography)(() => ({
  fontSize: '10px',
  fontWeight: 600,
  lineHeight: '12px',
  whiteSpace: 'nowrap'
}))

export const RowTextRight = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
  whiteSpace: 'nowrap'
}))

export const StyledTableTitleRow = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
  fontSize: '12px',
  lineHeight: '16px',
  fontStyle: 'normal',
  fontWeight: 600
}))
