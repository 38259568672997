import { FC, memo } from 'react'
import { StyledSwitch } from './styles'

interface Props {
  checked: boolean
  onChange: () => void
}

const CustomSwitch: FC<Props> = ({ checked, onChange }) => {
  return (
    <StyledSwitch
      onChange={onChange}
      checked={checked}
      inputProps={{ 'aria-label': 'Switch' }}
    />
  )
}

export default memo(CustomSwitch)
