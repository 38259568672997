import { ChangeEvent, FC, memo, useCallback, useMemo, useState } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'

import {
  StyledCategoryContainer,
  StyledCategoryItem,
  StyledErrorMessage,
  StyledInputRow
} from './styles'
import { Props } from './types'
import FormRow from '../Form/FormRow'
import TextInput from '../Form/TextInput'

export const CustomMultySelect: FC<Props> = memo(
  ({ items, values, setValues, error, label, placeholder }) => {
    const [filterString, setFilterString] = useState('')

    const itemsFormShow = useMemo(() => {
      if (!filterString) {
        return items
      }
      return items.filter(({ name }) =>
        name.toLowerCase().includes(filterString.toLowerCase())
      )
    }, [filterString, items])

    const handleItemCheck = (e: ChangeEvent<HTMLInputElement>, value: any) => {
      const isChecked = e.target.checked
      if (isChecked) {
        setValues([...values, value])
      } else {
        setValues(values.filter((selectedValue) => selectedValue !== value))
      }
    }

    const onChangeHandler = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => setFilterString(e.target.value),
      []
    )

    return (
      <div>
        <FormRow label={label} />
        <StyledInputRow>
          <TextInput
            value={filterString}
            onChange={onChangeHandler}
            autoComplete={'off'}
            placeholder={placeholder}
            error={!!error}
          />
        </StyledInputRow>

        {!!error && <StyledErrorMessage>{error}</StyledErrorMessage>}

        <StyledCategoryContainer>
          {itemsFormShow.length ? (
            itemsFormShow.map(({ name, value }) => (
              <StyledCategoryItem key={value}>
                <FormControlLabel
                  value={value}
                  control={
                    <Checkbox
                      checked={values.includes(value)}
                      onChange={(e) => handleItemCheck(e, value)}
                    />
                  }
                  label={name}
                  checked={values.includes(value)}
                />
              </StyledCategoryItem>
            ))
          ) : (
            <div>
              <div>Not found.</div>
            </div>
          )}
        </StyledCategoryContainer>
      </div>
    )
  }
)
