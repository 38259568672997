import { ISchoolInfo } from '../../Schools/types'

export interface IGetTicketsPayload {
  page: number
  search?: string
}

export interface ISchool {
  id: string
  shortName: string
  longName: string
}

export interface ITeacher {
  id: string
  username: string
  email: string
  fullName: string
}

export interface ICategory {
  id: string
  name: string
}

export interface ITicket {
  id: string
  ticketNumber: number
  message: string
  school: ISchool
  teacher: ITeacher
  category: ICategory
  createdAt: Date
  status: TicketStatus
}

export enum TicketStatus {
  pending = 'pending',
  unsolved = 'unsolved',
  solved = 'solved'
}

export interface ITicketsResponse {
  count: number
  data: ITicket[]
}

export interface ITicketsCategories {
  id: string
  name: string
}

export interface ITicketsState {
  isLoading: boolean
  isNewTicketSending: boolean
  count: number
  tickets: ITicket[] | []
  ticketCategories: ITicketsCategories[]
  schools: ISchoolInfo[] | []
}

export interface ISendNewTicketPayload {
  message: string
  category: string
  school: string
}
