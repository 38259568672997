import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISchool, ISchoolState } from '../types'
import { getStudentsSchool, getTeacherSchool } from '../services'

const initialState: ISchoolState = {
  isLoading: false,
  schools: null
}

export const schoolsSlice = createSlice({
  name: 'schools',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentsSchool.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getStudentsSchool.fulfilled,
      (state, { payload }: PayloadAction<ISchool>) => {
        state.isLoading = false
        state.schools = payload
      }
    )
    builder.addCase(getStudentsSchool.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getTeacherSchool.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getTeacherSchool.fulfilled,
      (state, { payload }: PayloadAction<ISchool>) => {
        state.isLoading = false
        state.schools = payload
      }
    )
    builder.addCase(getTeacherSchool.rejected, (state) => {
      state.isLoading = false
    })
  }
})

export const { clearState } = schoolsSlice.actions
export default schoolsSlice.reducer
