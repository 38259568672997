import { styled } from '@mui/material/styles'

export const StyledComingSoonWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 50vh;
  background-image: url('/assets/coming-soon-bg.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`

export const StyledComingSoonTitle = styled('h3')`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.customColors.grayMedium};
  max-width: 406px;
`
