import { useAppSelector } from '../../../hooks'
import {
  getIsProfileLoadingSelector,
  getIsStudentActivitiesLoadingSelector,
  getStudentActivitiesSelector
} from '../selectors'
import { useMemo } from 'react'

export const useStudentDetailsLoading = () => {
  const getProfileLoading = useAppSelector(getIsProfileLoadingSelector)
  const getActivitiesLoading = useAppSelector(
    getIsStudentActivitiesLoadingSelector
  )

  return useMemo(
    () => getProfileLoading || getActivitiesLoading,
    [getProfileLoading, getActivitiesLoading]
  )
}

export const useActivitiesPageList = () => {
  const activities = useAppSelector(getStudentActivitiesSelector)

  return useMemo(
    () =>
      activities?.activities.map((el) => ({ name: el.title, id: el.id })) || [],
    [activities]
  )
}
