import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../containers/Login/slices'
import profileReducer from '../containers/Profile/slices'
import schoolReducer from '../containers/Schools/slices'
import homeReducer from '../containers/Home/slices'
import classReducer from '../containers/MyClasses/slices'
import coursesReducer from '../containers/Courses/slices'
import studentsReducer from '../containers/Students/slices'
import studentDetailsReducer from '../containers/StudentDetails/slices'
import studentEditReducer from '../containers/StudentEdit/slices'
import courseDetailsReducer from '../containers/UnitsByCourse/slices'
import resultsReducer from '../containers/Results/slices'
import classDetailsReducer from '../containers/ClassDetails/slices'
import notifications from '../containers/Notifications/slices'
import unitDetailsReducer from '../containers/UnitDetails/slices'
import unitInfoReducer from '../containers/UnitInfo/slices'
import activityReducer from '../containers/Activity/slices'
import resultDetailsReducer from '../containers/ResultDetails/slices'
import resultsStudentsReducer from '../containers/ResultsByStudents/slices'
import ticketsReducer from '../containers/AskForAssistance/slices'
import forgotPasswordReducer from '../containers/ForgotPassword/slices'
import resetPasswordReducer from '../containers/ResetPassword/slices'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    profile: profileReducer,
    schools: schoolReducer,
    dashboard: homeReducer,
    classes: classReducer,
    courses: coursesReducer,
    students: studentsReducer,
    studentDetails: studentDetailsReducer,
    studentEdit: studentEditReducer,
    courseDetails: courseDetailsReducer,
    results: resultsReducer,
    classDetails: classDetailsReducer,
    unitDetails: unitDetailsReducer,
    notifications: notifications,
    unitInfo: unitInfoReducer,
    activity: activityReducer,
    resultDetails: resultDetailsReducer,
    resultsStudents: resultsStudentsReducer,
    tickets: ticketsReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
