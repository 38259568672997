import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

export const StyledPositionText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '16px',
  color: theme.palette.customColors.blue
}))

export const StyledPositionBlackText = styled(StyledPositionText)(
  ({ theme }) => ({
    color: theme.palette.customColors.black,
    width: '100%'
  })
)
