import { createAsyncThunk } from '@reduxjs/toolkit'
import { IProfileExtended } from '../../Profile/types'
import { del, get } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { ICommonChunkConfig, IErrorResponse } from '../../../types'
import {
  IDeleteStudentCourseRequest,
  IGetStudentDetailsPayload,
  IStudentActivities,
  IStudentProfileExtended
} from '../types'

export const getStudentActivities = createAsyncThunk<
  IStudentActivities,
  IGetStudentDetailsPayload,
  ICommonChunkConfig
>(
  'studentDetails/fetchStudentActivitiesInfo',
  async (_data, { rejectWithValue }) => {
    try {
      const response = await get({
        path: API_ROUTES.TEACHERS_STUDENT_INFO.replace(
          ':username',
          _data.username
        ),
        params: {}
      })
      return response as IStudentActivities
    } catch (error) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const deleteStudentCourse = createAsyncThunk<
  void,
  IDeleteStudentCourseRequest,
  ICommonChunkConfig
>('studentDetails/deleteStudentCourse', async (_data, { rejectWithValue }) => {
  try {
    return await del<void>({
      path: `${API_ROUTES.ASSIGN_COURSE}/${_data.courseId}`,
      data: {
        studentId: _data.studentId,
        sendNotification: _data.sendNotification
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})
