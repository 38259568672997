import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getStudents, sendMessageToAccount } from '../services'
import { IStudentList, IStudentsState } from '../types'
import {
  findSchools,
  getClasses,
  getTeacherCourses
} from '../../../core/services'
import { IClassList, ICoursesList, IFindSchools } from '../../../types'

const initialState: IStudentsState = {
  isLoading: false,
  isMessageSending: false,
  classes: [],
  schools: [],
  courses: null,
  students: null,
  count: 0
}

export const studentSlice = createSlice({
  name: 'myStudents',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getStudents.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getStudents.fulfilled,
      (state, { payload }: PayloadAction<IStudentList>) => {
        state.isLoading = false
        state.students = payload.data
        state.count = payload.count
      }
    )
    builder.addCase(getStudents.rejected, (state) => {
      state.isLoading = false
    })

    /*LIBS*/

    builder.addCase(
      getTeacherCourses.fulfilled,
      (state, { payload }: PayloadAction<ICoursesList>) => {
        state.courses = payload.data
      }
    )

    builder.addCase(
      findSchools.fulfilled,
      (state, { payload }: PayloadAction<IFindSchools>) => {
        state.schools = payload.data
      }
    )

    builder.addCase(
      getClasses.fulfilled,
      (state, { payload }: PayloadAction<IClassList>) => {
        state.classes = payload.data
      }
    )

    /*LIBS*/

    builder.addCase(sendMessageToAccount.pending, (state) => {
      state.isMessageSending = true
    })
    builder.addCase(sendMessageToAccount.fulfilled, (state) => {
      state.isMessageSending = false
    })
    builder.addCase(sendMessageToAccount.rejected, (state) => {
      state.isMessageSending = false
    })
  }
})

export const { clearState } = studentSlice.actions
export default studentSlice.reducer
