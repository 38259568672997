import { APP_ROUTES } from '../core/router/appRoutes'

const Config = {
  apiUrl: process.env.REACT_APP_PUBLIC_API_BASE_URL as string,
  vrUrl: process.env.REACT_APP_3D_SCENE_BASE_URL as string,
  myLoginClientId: process.env.REACT_APP_MY_LOGIN_CLIENT_ID as string,
  myLoginAuthUrl:
    process.env.REACT_APP_MY_LOGIN_AUTH_URL ||
    ('https://app.mylogin.com/oauth/authorize' as string),
  host: window.location.origin,
  eram: {
    authorizationEndpoint: process.env.REACT_APP_ERAM_HOST + '/oidc/authorize',
    clientId: process.env.REACT_APP_ERAM_CLIENT_ID as string,
    redirectUri: window.location.origin + APP_ROUTES.OIDC_ERAM,
    idRessourse: process.env.REACT_APP_ERAM_ID_RESSOURCE
  }
}

export default Config
