import { RootState } from '../../../store'

export const getTeacherUnitsSelector = (state: RootState) =>
  state.courseDetails.teacherUnits
export const getStudentUnitsSelector = (state: RootState) =>
  state.courseDetails.studentUnits

export const getCourse = (state: RootState) => state.courseDetails.course
export const getUnitsCountSelector = (state: RootState) =>
  state.courseDetails.count
export const getStudentsForClassSelector = (state: RootState) =>
  state.courseDetails.studentsClass
export const getAssignCourse = (state: RootState) =>
  state.courseDetails.assignCourse
