import { StyledNavLink, StyledBottomMenuItem } from './styles'
import { CustomSvgIcon } from '../../../../../../../components/CustomSvgIcon'
import React, { memo } from 'react'
import { IBottomMenuItemProps } from './types'
import { useLocation } from 'react-router-dom'

const BottomMenuItem = memo((props: IBottomMenuItemProps) => {
  const { pathname } = useLocation()
  const isActive = pathname === props.path
  const iconColor = isActive ? 'white' : props.color || 'black'

  return (
    <StyledBottomMenuItem isActive={isActive} style={props.style}>
      <StyledNavLink to={props.path}>
        {props.iconPath && (
          <CustomSvgIcon
            path={props.iconPath}
            color={iconColor}
            title={`${props.locationName} icon`}
          />
        )}
        {!props.isTablet ? props.locationName : null}
      </StyledNavLink>
    </StyledBottomMenuItem>
  )
})

export default BottomMenuItem
