import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITicketsCategories, ITicketsResponse, ITicketsState } from '../types'
import { getTickets, getTicketsCategory, sendNewTicket } from '../services'
import { findSchools } from '../../../core/services'
import { IFindSchools } from '../../../types'

const initialState: ITicketsState = {
  isLoading: false,
  isNewTicketSending: false,
  tickets: [],
  schools: [],
  ticketCategories: [],
  count: 0
}

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getTickets.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getTickets.fulfilled,
      (state, { payload }: PayloadAction<ITicketsResponse>) => {
        state.isLoading = false
        state.tickets = payload.data
        state.count = payload.count
      }
    )
    builder.addCase(getTickets.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(findSchools.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      findSchools.fulfilled,
      (state, { payload }: PayloadAction<IFindSchools>) => {
        state.isLoading = false
        state.schools = payload.data
      }
    )
    builder.addCase(findSchools.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getTicketsCategory.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getTicketsCategory.fulfilled,
      (state, { payload }: PayloadAction<ITicketsCategories[]>) => {
        state.isLoading = false
        state.ticketCategories = payload
      }
    )
    builder.addCase(getTicketsCategory.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(sendNewTicket.pending, (state) => {
      state.isNewTicketSending = true
    })
    builder.addCase(sendNewTicket.fulfilled, (state) => {
      state.isNewTicketSending = false
    })
    builder.addCase(sendNewTicket.rejected, (state) => {
      state.isNewTicketSending = false
    })
  }
})

export const { clearState } = ticketsSlice.actions
export default ticketsSlice.reducer
