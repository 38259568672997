import axios, { AxiosHeaders } from 'axios'
import { refreshAccessToken } from './refreshToken'
import Cookies from 'js-cookie'
import { REFRESH_TOKEN_NAME } from '../utils/constants'

const axiosApiInstance = axios.create()

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const accessToken = Cookies.get('accessToken')
    if (accessToken) {
      ;(config.headers as AxiosHeaders).set(
        'Authorization',
        `Bearer ${accessToken}`
      )
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const accessToken = await refreshAccessToken()
      if (accessToken) {
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + REFRESH_TOKEN_NAME
        return axiosApiInstance(originalRequest)
      }
    }
    return Promise.reject(error)
  }
)

export default axiosApiInstance
