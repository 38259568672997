import React, { memo, useState } from 'react'
import {
  StyledClassDetailsItem,
  StyledClassDetailsItemTitle,
  StyledClassDetailsItemDescription,
  StyledDescriptionsWrapper,
  StyledDescriptionItem
} from './styles'
import { MAX_DISPLAY_LINES } from '../../../../utils/constants'
import uuid from 'react-uuid'
import { useTranslation } from 'react-i18next'
import { StyledShowMoreButton } from '../../../../common/styles'

const ClassDetailsItem = (props: {
  name: string
  description: JSX.Element[] | string | string[]
}) => {
  const [isMoreItemsShow, setIsMoreItemsShow] = useState(false)
  const { t } = useTranslation()

  return (
    <StyledClassDetailsItem key={uuid()}>
      <StyledClassDetailsItemTitle key={uuid()} variant={'h3'}>
        {props.name}
      </StyledClassDetailsItemTitle>
      {typeof props.description === 'string' ? (
        <StyledClassDetailsItemDescription key={uuid()} variant={'h3'}>
          {props.description}
        </StyledClassDetailsItemDescription>
      ) : (
        <StyledDescriptionsWrapper>
          {props.description.map((item, key) => {
            if (key < 2) {
              return (
                <StyledDescriptionItem key={uuid()}>
                  {item}
                </StyledDescriptionItem>
              )
            } else if (isMoreItemsShow) {
              return (
                <StyledDescriptionItem key={uuid()}>
                  {item}
                </StyledDescriptionItem>
              )
            }
          })}
          {props.description.length > MAX_DISPLAY_LINES && (
            <StyledShowMoreButton
              key={uuid()}
              variant={'outlined'}
              color={'primary'}
              onClick={() => setIsMoreItemsShow(!isMoreItemsShow)}
            >
              {isMoreItemsShow
                ? t('showLess')
                : `${t('showMore')} ${
                    props.description.length - MAX_DISPLAY_LINES
                  }`}
            </StyledShowMoreButton>
          )}
        </StyledDescriptionsWrapper>
      )}
    </StyledClassDetailsItem>
  )
}

export default memo(ClassDetailsItem)
