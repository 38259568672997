import { styled } from '@mui/material/styles'

export const StyledInputRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'center',
  marginTop: '12px'
}))

export const StyledErrorMessage = styled('div')(({ theme }) => ({
  margin: '3px 14px 0',
  fontSize: '12px',
  color: `${theme.palette.customColors.error}`
}))

export const StyledCategoryContainer = styled('div')`
  &::-webkit-scrollbar {
    width: 12px; /* Ширина скроллбара */
    height: 12px; /* Высота скроллбара */
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Цвет фона трека */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Цвет ползунка */
    border-radius: 6px; /* Скругленность углов ползунка */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Новый цвет при наведении */
  }
  max-height: 150px;
  margin: 12px 14px;
  overflow-y: scroll;
`

export const StyledCategoryItem = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  maxWidth: '100%',
  overflow: 'hidden',
  alignItems: 'center',
  flexShrink: '0',

  '&>*:first-of-type': {
    minWidth: 0,
    overflow: 'hidden',
    '&>*:first-of-type': {
      flexShrink: 0
    },
    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
}))
