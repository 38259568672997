import { TFunction } from 'i18next'
import * as yup from 'yup'

export const basicString = yup.string().trim()
export const password = (t: TFunction) =>
  yup
    .string()
    .min(8, t('valueMustBeAtLeastCountCharacters', { number: 8 })!)
    .max(30, t('valueMustBeAtMostCountCharacters', { number: 30 })!)
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{8,}$/,
      t('passwordMustBeValid')
    )
