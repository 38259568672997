import { styled } from '@mui/system'
import { Button, IconButton } from '@mui/material'

export const StyledAvatarEditorWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

export const StyledZoomWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '16px'
}))

export const StyledIconButton = styled(IconButton)(() => ({
  padding: 0,
  '&:first-of-type': {
    marginRight: '16px'
  },
  '&:last-of-type': {
    marginLeft: '16px'
  }
}))

export const StyledImgIcon = styled('img')(() => ({
  width: '24px',
  height: '24px'
}))

export const StyledRangeInput = styled('input')(() => ({
  width: '360px'
}))

export const StyledSaveButton = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '442px',
  boxSizing: 'border-box',
  marginTop: '12px'
}))
