import React, { memo } from 'react'
import { StyledComingSoonTitle, StyledComingSoonWrapper } from './styles'
import { useTranslation } from 'react-i18next'

const ComingSoonPage = memo(() => {
  const { t } = useTranslation()
  return (
    <StyledComingSoonWrapper>
      <StyledComingSoonTitle>{t('comingSoonResults')}</StyledComingSoonTitle>
    </StyledComingSoonWrapper>
  )
})

export default ComingSoonPage
