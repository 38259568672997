import React, { FC, memo } from 'react'
import { StyledHeaderContainer, StyledTitle } from './styles'

interface Props {
  title: string
}

const Header: FC<Props> = ({ title }) => {
  return (
    <StyledHeaderContainer>
      <StyledTitle>{title}</StyledTitle>
    </StyledHeaderContainer>
  )
}

export default memo(Header)
