import React, { memo } from 'react'
import { IActivityNameProps } from './types'
import { StyledName, StyledNameWrapper } from './styles'

const UnitName = (props: IActivityNameProps) => {
  return (
    <StyledNameWrapper style={props.style}>
      {props.unitName && (
        <StyledName variant={'h6'}>{props.unitName}</StyledName>
      )}
      {props.activityName && (
        <StyledName variant={'h6'}>{props.activityName}</StyledName>
      )}
    </StyledNameWrapper>
  )
}

export default memo(UnitName)
