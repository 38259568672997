import { styled } from '@mui/system'
import { colors } from '../../../../../types'

export const StyledText = styled('p')(() => ({
  margin: '0 0 24px 16px',
  color: colors.gray,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px'
}))

export const StyledEmail = styled('span')(() => ({
  color: colors.blue
}))
