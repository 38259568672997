import { useFormikContext } from 'formik'
import { memo, useCallback } from 'react'
import { CustomMultySelect } from '../../CustomMultySelect'
import { FieldValue } from '../types'
import { Props } from './types'

const FormMultiSelectNonMemo = <FormValues extends FieldValue>({
  name: fieldName,
  items,
  label,
  placeholder
}: Props<FormValues>) => {
  const formContext = useFormikContext<FormValues>()
  const { setFieldValue } = formContext
  const isTouched = formContext.touched[fieldName]
  const isError = isTouched && !!formContext.errors[fieldName]

  const error = isError
    ? (formContext.errors as Record<string, string>)[fieldName]
    : ''

  const onChange = useCallback(
    (value: any) => setFieldValue(fieldName, value),
    []
  )

  return (
    <CustomMultySelect
      values={formContext.values[fieldName]}
      items={items}
      setValues={onChange}
      error={error}
      label={label}
      placeholder={placeholder}
    />
  )
}

const FormMultiSelect = memo(
  FormMultiSelectNonMemo
) as typeof FormMultiSelectNonMemo

export default FormMultiSelect
