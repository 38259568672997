import { RootState } from '../../../store'

export const getResultDetailsSelector = (state: RootState) =>
  state.resultDetails.resultDetails

export const getIsLoadingResultDetailsSelector = (state: RootState) =>
  state.resultDetails.isLoading

export const getIsShowSuggestionsSelector = (state: RootState) =>
  state.resultDetails.showSuggestions

export const getSuggestionsSelector = (state: RootState) =>
  state.resultDetails.suggestions

export const getAssignedActivitiesSelector = (state: RootState) =>
  state.resultDetails.assignedActivities
