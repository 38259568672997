import { styled } from '@mui/system'
import { Button, Typography } from '@mui/material'

export const StyledPageContentWrapper = styled('div')(() => ({
  width: '100%',
  marginTop: '44px'
}))

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px'
}))

export const StyledTitleWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const StyledAssignmentButtonsWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  marginRight: '12px'
}))

export const StyledPageActions = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px'
}))

export const StyledButton = styled(Button)(() => ({
  boxSizing: 'border-box',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  width: 'fit-content',
  padding: '10px 32px',
  '&:first-of-type': {
    marginBottom: '12px'
  }
}))

export const StyledBackButton = styled('button')(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.customColors.white,
  '&:before': {
    content: 'url(/assets/arrow-left-icon.svg)',
    cursor: 'pointer'
  }
}))
