import { styled } from '@mui/system'
import { Button, Typography } from '@mui/material'

export const StyledPageContentWrapper = styled('div')(() => ({
  width: '100%',
  marginTop: '44px'
}))

export const StyledActionButton = styled(Button)(() => ({
  width: 'max-content',
  '&:first-of-type': {
    marginBottom: '12px'
  }
}))

export const FullWidthActionButton = styled(Button)(() => ({
  width: '100%'
}))

export const StyledButtonsWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end'
}))

export const StyledTitleWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '16px'
}))

export const StyledBackButton = styled('button')(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.customColors.white,
  '&:before': {
    content: 'url(/assets/arrow-left-icon.svg)',
    cursor: 'pointer'
  }
}))

export const StyledTableContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '12px',
  borderRadius: '16px',
  backgroundColor: theme.palette.customColors.bg,
  boxSizing: 'border-box'
}))

export const StyledTableRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  padding: '12px',
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.customColors.white
  },
  width: '100%',
  boxSizing: 'border-box'
}))

export const StyledTableContents = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '12px',
  boxSizing: 'border-box'
}))

export const RowTitle = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px'
}))

export const SmallRow = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  gap: '4px',
  boxSizing: 'border-box'
}))

export const Divider = styled('div')(({ theme }) => ({
  width: '100%',
  borderBottom: `0.5px dotted ${theme.palette.customColors.violetGrey}`,
  boxSizing: 'border-box'
}))

export const RowTextLeft = styled(Typography)(() => ({
  fontSize: '10px',
  fontWeight: 600,
  lineHeight: '12px'
}))

export const RowTextRight = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
  whiteSpace: 'nowrap'
}))
