import { LevelType } from '../containers/Home/types'
import { ISchoolInfo } from '../containers/Schools/types'
import { IClassListItem } from '../containers/MyClasses/types'

export enum colors {
  bg = '#F0F7FC',
  black = '#010121',
  blue = '#2626EA',
  blueDark = '#09095D',
  blueHover = '#5C5CEF',
  blueLight = '#D0D0FA',
  error = '#d32f2f',
  gray = '#374957',
  grayLight = '#D0DAE1',
  grayMedium = '#7B8E9C',
  green = '#35ED99',
  greenDark = '#085E37',
  greenLight = '#D0FBE7',
  linear = '#C7DAFE',
  sky = '#12A5FF',
  skyDark = '#003F66',
  skyLight = '#CCECFF',
  violet = '#7A27F2',
  violetDark = '#2A0660',
  violetGrey = '#9E9DB0',
  violetLight = '#E1CFFC',
  white = '#FFFFFF',
  red = '#FF3A52',
  redLight = '#FFDFE3',
  redDark = '#9F2433',
  muiDarkGrey = '#B9BEC1',
  yellow = '#E4F61C',
  yellowLight = '#FDFFE2',
  yellowDark = '#808914',
  muiDarkRed = '#d32f2f'
}

export interface IErrorResponse {
  message: string | Record<string, string[]>
}

export interface ICommonListResponse<D> {
  count: number
  data: D[]
}

export interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export interface ISortCallbackParams {
  page: number
  sort: string
  filter?: string[]
  search?: string
}

export interface IExportCallbackParams extends ISortCallbackParams {
  format: 'csv' | 'pdf'
}

export type ActivityToStart = {
  activityId: string
  assignedActivityId: string
  courseId: string
  unitId: string
  title: string
  level: LevelType
  description: string
  questionsCount: number
  timer?: number
  logoUrl: string
}

export interface ISendMessagePayload {
  text: string
  entityId: string
}

export interface IGetListPayload {
  page: number
  pageSize?: number
  sort: string
  search?: string
}

export interface IGetClassesPayload extends IGetListPayload {
  filters?: string[]
}

export interface IGetCoursesPayload extends IGetListPayload {}

export interface ICoursesListItem {
  id: string
  name: string
  unitsCount: number
  activitiesCount: number
}

export interface ICoursesList {
  count: number
  data: ICoursesListItem[]
}

export interface IFindSchools {
  count: number
  data: ISchoolInfo[]
}

export interface IClassList {
  count: number
  data: IClassListItem[]
}

export interface ISelectOption<V = string, N = string> {
  value: V
  name: N
}

export interface IFilesSettings {
  maxSize: number
  accept: {
    [key: string]: any[]
  }
}

export interface IAccountItem {
  email: string
  fullName: string
  id: string
  username: string
}

export type LOCALES = 'en' | 'fr' | 'it' | 'ko' | 'lv' | 'ru' | 'sp'

export interface IIdNameItem {
  id: string
  name: string
}

export enum GrammarTags {
  present = 'present',
  past = 'past',
  future = 'future',
  subjunctive = 'subjunctive',
  conditional = 'conditional',
  comparatives = 'comparatives',
  superlatives = 'superlatives'
}

export enum SyntaxTags {
  affirmativeSentences = 'affirmativeSentences',
  negativeSentences = 'negativeSentences',
  interrogativeSentences = 'interrogativeSentences',
  wordOrder = 'wordOrder'
}

export enum VocabTags {
  nouns = 'nouns',
  verbs = 'verbs',
  adjectives = 'adjectives',
  adverbs = 'adverbs',
  pronouns = 'pronouns',
  conjunctions = 'conjunctions',
  prepositions = 'prepositions',
  workVocabulary = 'workVocabulary',
  technicalVocabulary = 'technicalVocabulary'
}
