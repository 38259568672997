import { styled as muiStyled } from '@mui/system'
import { Typography } from '@mui/material'

export const StyledActivityCard = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  backgroundColor: theme.palette.customColors.bg,
  border: `1px solid ${theme.palette.customColors.linear}`,
  width: '100%',
  minHeight: '110px',
  padding: '16px',
  borderRadius: '16px',
  marginBottom: '12px'
}))

export const StyledTitleCard = muiStyled(Typography)(() => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  wordBreak: 'break-all',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  marginRight: '12px'
}))

export const StyledChipContainer = muiStyled('div')(() => ({
  marginLeft: 'auto'
}))

export const StyledUnitBottom = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: '12px',
  marginTop: '16px'
}))

export const StyledUnitBottomBreadcrumbs = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center'
}))

export const StyledUnitBottomLeft = muiStyled('div')(() => ({
  overflow: 'hidden',
  minWidth: '0'
}))

export const StyledUnitBottomRight = muiStyled('div')(() => ({
  marginLeft: 'auto'
}))
export const StyledUnitWrapper = muiStyled('div')(() => ({
  display: 'flex',
  marginTop: '12px',
  alignItems: 'center'
}))

export const StyledCourseCard = muiStyled(Typography)(() => ({
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  wordBreak: 'break-all',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  marginRight: '4px',
  minWidth: '100px'
}))

export const StyledUnitCard = muiStyled(Typography)(() => ({
  marginLeft: '4px',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  wordBreak: 'break-all',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}))

export const StyledDueDateCard = muiStyled(StyledUnitCard)(() => ({
  marginLeft: '0px'
}))

export const StyledInfoWrapper = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minWidth: '0',
  flexGrow: '1'
}))

export const StyledButtonWrapper = muiStyled('button')(({ theme }) => ({
  height: '36px',
  width: '36px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '30px',
  border: 'none',
  backgroundColor: theme.palette.customColors.blue,
  '&:hover': {
    cursor: 'pointer'
  }
}))
