import React, { memo, useCallback } from 'react'
import {
  StyledBackButton,
  StyledPageContentWrapper,
  StyledStudentActions,
  StyledStudentContent,
  StyledStudentImgContainer,
  StyledStudentInfo,
  StyledStudentInfoSubtitle,
  StyledStudentInfoTitle,
  StyledStudentInfoTitleContainer,
  StyledStudentListContainer,
  StyledStudentRow,
  StyledTitle,
  StyledTitleWrapper
} from '../../styles'
import Loader from '../../../../components/Loader'
import CustomImage from '../../../../components/CustomImage'
import StudentInfoItem from '../StudentInfoItem'
import { PageList } from '../../../../components/PageList'
import DeleteStudentCourseModal from '../DeleteStudentCourseModal'
import { APP_ROUTES } from '../../../../core/router/appRoutes'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IStudentDetailsWrapperProps } from '../../types'
import { StyledCustomButton, StyledText } from './styles'

const baseImg = '/assets/teacher-image-for-form.png'

const StudentDetailsWrapper = memo<IStudentDetailsWrapperProps>(
  ({
    deleteCourseId,
    isLoading,
    studentProfile,
    studentUsername,
    studentActivities,
    onClose,
    onDelete,
    onDeleteClick,
    activitiesPageList
  }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const handleBackButton = useCallback(() => {
      navigate(`${APP_ROUTES.STUDENTS}`)
    }, [])

    const onStudentEditHandler = useCallback(
      () =>
        navigate(
          APP_ROUTES.STUDENT_DETAIL_EDIT.replace(':username', studentUsername)
        ),
      [studentUsername]
    )

    return (
      <StyledPageContentWrapper>
        <StyledTitleWrapper>
          <StyledBackButton
            aria-label={'Previous page button'}
            onClick={handleBackButton}
          />
          <StyledTitle variant={'h2'}>{t('studentsList')}</StyledTitle>
        </StyledTitleWrapper>
        {isLoading && <Loader />}
        {!isLoading && studentProfile && (
          <StyledStudentContent>
            <StyledStudentRow>
              <StyledStudentImgContainer>
                <CustomImage
                  borderRadius={'16px'}
                  src={studentProfile?.avatarUrl || baseImg}
                  width={'192px'}
                  height={'192px'}
                  alt={'Avatar'}
                  errorSrc={baseImg}
                />
              </StyledStudentImgContainer>
              <StyledStudentInfo>
                <StyledStudentInfoTitleContainer>
                  <StyledStudentInfoTitle>
                    {studentProfile.fullName}
                  </StyledStudentInfoTitle>
                  <StyledStudentInfoSubtitle>
                    {studentProfile.email}
                  </StyledStudentInfoSubtitle>
                </StyledStudentInfoTitleContainer>
                <StudentInfoItem label={t('schools')}>
                  {studentProfile?.schools
                    ?.map((school) => school.shortName)
                    .join(', ')}
                </StudentInfoItem>
                <StudentInfoItem label={t('classes')}>
                  {studentProfile?.classes?.map((el) => el.name).join(', ')}
                </StudentInfoItem>
                <StudentInfoItem label={t('averageScore')}>
                  {studentActivities?.averageScore}
                </StudentInfoItem>
              </StyledStudentInfo>
              <StyledStudentActions>
                <StyledCustomButton
                  type={'button'}
                  size={'large'}
                  variant={'outlined'}
                  color={'primary'}
                  onClick={onStudentEditHandler}
                >
                  {t('editInformation')}
                </StyledCustomButton>
              </StyledStudentActions>
            </StyledStudentRow>
            {studentActivities && (
              <>
                <StyledStudentRow>
                  <StyledStudentListContainer>
                    <StudentInfoItem label={t('courses')}>
                      <PageList
                        items={studentActivities.courses}
                        onDelete={onDeleteClick}
                      />
                    </StudentInfoItem>
                  </StyledStudentListContainer>
                </StyledStudentRow>
                <StyledStudentRow>
                  <StyledStudentListContainer>
                    <StudentInfoItem label={t('activities')}>
                      <PageList items={activitiesPageList} />
                    </StudentInfoItem>
                  </StyledStudentListContainer>
                </StyledStudentRow>
              </>
            )}
          </StyledStudentContent>
        )}
        <DeleteStudentCourseModal
          courseId={deleteCourseId}
          onCloseModal={onClose}
          onDelete={onDelete}
        >
          <StyledText>
            {t('deleteCourseFor', {
              courseName: studentActivities?.courses?.find(
                (course) => course.id === deleteCourseId
              )?.name,
              studentName: studentProfile?.fullName
            })}
          </StyledText>
        </DeleteStudentCourseModal>
      </StyledPageContentWrapper>
    )
  }
)

export default StudentDetailsWrapper
