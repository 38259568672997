import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ICourses,
  IDashboardActivity,
  IDashboardState,
  IStudents
} from '../types'
import {
  getTeacherActivities,
  getStudentsActivities,
  getStudentCourses,
  getTeacherStudents
} from '../services'
import { getTeacherCourses } from '../../../core/services'

const initialState: IDashboardState = {
  isLoadingActivities: false,
  isLoadingCourses: false,
  activities: [],
  courses: null,
  students: []
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getTeacherActivities.pending, (state) => {
      state.isLoadingActivities = true
    })
    builder.addCase(
      getTeacherActivities.fulfilled,
      (state, { payload }: PayloadAction<IDashboardActivity[]>) => {
        state.isLoadingActivities = false
        state.activities = payload
      }
    )
    builder.addCase(getTeacherActivities.rejected, (state) => {
      state.isLoadingActivities = false
    })
    builder.addCase(getStudentsActivities.pending, (state) => {
      state.isLoadingActivities = true
    })
    builder.addCase(
      getStudentsActivities.fulfilled,
      (state, { payload }: PayloadAction<IDashboardActivity[]>) => {
        state.isLoadingActivities = false
        state.activities = payload
      }
    )
    builder.addCase(getStudentsActivities.rejected, (state) => {
      state.isLoadingActivities = false
    })
    builder.addCase(getStudentCourses.pending, (state) => {
      state.isLoadingCourses = true
    })
    builder.addCase(
      getStudentCourses.fulfilled,
      (state, { payload }: PayloadAction<ICourses>) => {
        state.isLoadingCourses = false
        state.courses = payload
      }
    )
    builder.addCase(getStudentCourses.rejected, (state) => {
      state.isLoadingCourses = false
    })
    builder.addCase(getTeacherCourses.pending, (state) => {
      state.isLoadingCourses = true
    })
    builder.addCase(
      getTeacherCourses.fulfilled,
      (state, { payload }: PayloadAction<ICourses>) => {
        state.isLoadingCourses = false
        state.courses = payload
      }
    )
    builder.addCase(getTeacherCourses.rejected, (state) => {
      state.isLoadingCourses = false
    })

    builder.addCase(getTeacherStudents.pending, (state) => {
      state.isLoadingCourses = true
    })
    builder.addCase(
      getTeacherStudents.fulfilled,
      (state, { payload }: PayloadAction<IStudents[]>) => {
        state.isLoadingCourses = false
        state.students = payload
      }
    )
    builder.addCase(getTeacherStudents.rejected, (state) => {
      state.isLoadingCourses = false
    })
  }
})

export const { clearState } = homeSlice.actions
export default homeSlice.reducer
