import { styled } from '@mui/system'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'

export const StyledMenuItems = styled('div')(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '182px',
  margin: 0
}))

export const StyledMenuLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  padding: '4px 8px',
  boxSizing: 'border-box',
  width: '100%',
  color: theme.palette.customColors.black,
  '&:hover': {
    cursor: 'pointer',
    borderRadius: '20px',
    backgroundColor: theme.palette.customColors.bg
  }
}))

export const StyledSendMessageButton = styled('button')(({ theme }) => ({
  border: 'none',
  textDecoration: 'none',
  backgroundColor: theme.palette.customColors.white,
  fontSize: '14px',
  fontFamily: 'Inter',
  textAlign: 'start',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  padding: '4px 8px',
  boxSizing: 'border-box',
  width: '100%',
  color: theme.palette.customColors.gray,
  '&:hover': {
    cursor: 'pointer',
    borderRadius: '20px',
    backgroundColor: theme.palette.customColors.bg
  }
}))

export const StyledTooltipButton = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '38px',
  height: '24px',
  margin: '0 auto',
  '&::after': {
    width: '24px',
    height: '24px',
    content: 'url(/assets/ellipsis-icon.svg)'
  }
}))
