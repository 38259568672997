import React, { FC } from 'react'
import AuthLayout from '../../core/Layouts/AuthLayout'
import SendLink from './components/SendLink'

const ForgotPassword: FC = () => {
  return (
    <AuthLayout>
      <SendLink />
    </AuthLayout>
  )
}

export default ForgotPassword
