import { memo, useEffect } from 'react'
import LoginOAuth2 from '@okteto/react-oauth2-login'
import { CustomSvgIcon } from '../../../../components/CustomSvgIcon'
import Config from '../../../../config'
import { IMyLoginSuccess } from '../../types'

interface Props {
  onSuccess: (params: IMyLoginSuccess) => void
}

export const MyLoginOAuth2 = memo(({ onSuccess }: Props) => {
  // eslint-disable-next-line no-console
  const onFailure = (response: any) => console.error(response)

  useEffect(() => {
    const btn = document.getElementsByClassName('my-login-btn')[0]
    if (btn) {
      btn.setAttribute('type', 'button')
    }
  }, [])

  return (
    <LoginOAuth2
      className="my-login-btn"
      clientId={Config.myLoginClientId}
      authorizeUri="https://app.mylogin.com/oauth/authorize"
      redirectUri={Config.host + '/login'}
      onSuccess={onSuccess}
      onFailure={onFailure}
      scope="openid"
      params={{
        response_type: 'code',
        state: 'my-login'
      }}
      onClick={() => undefined}
    >
      <CustomSvgIcon path="/assets/sign_in_dark_rounded.svg" height="40px" />
    </LoginOAuth2>
  )
})
