import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

export const StyledPageContentWrapper = styled('div')(() => ({
  width: '100%',
  marginTop: '44px'
}))

export const StyledTitleWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '16px'
}))

export const StyledBackButton = styled('button')(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.customColors.white,
  '&:before': {
    content: 'url(/assets/arrow-left-icon.svg)',
    cursor: 'pointer'
  }
}))

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px'
}))

export const StyledClassDetailsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: '44px',
  borderRadius: '16px',
  backgroundColor: theme.palette.customColors.bg,
  boxSizing: 'border-box',
  padding: '24px'
}))

export const StyledLink = styled(Link)(() => ({
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': {
    color: 'inherit'
  }
}))
