import React, { FC, memo } from 'react'
import uuid from 'react-uuid'
import { StyledColoredSpanItem } from '../../styles'

interface IPronounceItemProps {
  color?: string
  children: JSX.Element
}

const PronounceItem: FC<IPronounceItemProps> = memo(({ children, color }) => (
  <span key={uuid()}>
    <StyledColoredSpanItem key={uuid()} {...(color ? { $bgColor: color } : {})}>
      {children}
    </StyledColoredSpanItem>
    <br key={uuid()} />
  </span>
))

export default PronounceItem
