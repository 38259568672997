import { createAsyncThunk } from '@reduxjs/toolkit'
import { get, post } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { IErrorResponse } from '../../../types'
import {
  IGetTicketsPayload,
  ISendNewTicketPayload,
  ITicketsCategories,
  ITicketsResponse
} from '../types'
import { CLASSES_PAGE_SIZE } from '../../../utils/constants'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const getTickets = createAsyncThunk<
  ITicketsResponse,
  IGetTicketsPayload,
  ICommonChunkConfig
>('notifications/fetchTickets', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.TICKETS,
      params: {
        pageSize: CLASSES_PAGE_SIZE,
        ..._data
      }
    })
    return response as ITicketsResponse
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const sendNewTicket = createAsyncThunk<
  ITicketsResponse,
  ISendNewTicketPayload,
  ICommonChunkConfig
>('notifications/postNewTicket', async (_data, { rejectWithValue }) => {
  try {
    const response = await post({
      path: API_ROUTES.TICKETS,
      data: _data
    })
    return response as ITicketsResponse
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getTicketsCategory = createAsyncThunk<
  ITicketsCategories[],
  void,
  ICommonChunkConfig
>('notifications/getTicketsCategory', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.TICKET_CATEGORIES,
      params: {}
    })
    return response as ITicketsCategories[]
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
