import { createAsyncThunk } from '@reduxjs/toolkit'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { ICommonChunkConfig, IErrorResponse } from '../../../types'
import { IEditStudentClassesRequest, IEditStudentRequest } from '../types'
import { patch } from '../../../axios'

export const editStudent = createAsyncThunk<
  void,
  IEditStudentRequest,
  ICommonChunkConfig
>('studentEdit/editStudent', async (_data, { rejectWithValue }) => {
  const { username, ...data } = _data
  try {
    return await patch(
      {
        path: API_ROUTES.EDIT_STUDENT.replace(':username', username),
        data
      },
      {
        username
      }
    )
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const editStudentClasses = createAsyncThunk<
  void,
  IEditStudentClassesRequest,
  ICommonChunkConfig
>('studentEdit/editStudentClasses', async (_data, { rejectWithValue }) => {
  const { id, ...data } = _data
  try {
    return await patch(
      {
        path: API_ROUTES.EDIT_STUDENT_CLASSES.replace(':id', id),
        data
      },
      {
        id
      }
    )
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})
