import { styled } from '@mui/system'
import { Typography } from '@mui/material'

export const StyledPageContentWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: '44px',
  backgroundColor: theme.palette.customColors.bg,
  border: `1px solid ${theme.palette.customColors.bg}`,
  borderRadius: '24px',
  boxSizing: 'border-box',
  padding: '24px'
}))

export const StyledTitleWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '16px'
}))

export const StyledBackButton = styled('button')(({ theme }) => ({
  border: 'none',
  backgroundColor: 'transparent',
  '&:before': {
    content: 'url(/assets/arrow-left-icon.svg)',
    cursor: 'pointer'
  }
}))

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px'
}))

export const StyledClassDetailsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: '44px',
  borderRadius: '16px',
  backgroundColor: theme.palette.customColors.bg,
  boxSizing: 'border-box',
  padding: '24px'
}))

export const StyledStudentContent = styled('div')(() => ({
  marginTop: '20px'
}))

export const StyledStudentRow = styled('div')(() => ({
  display: 'grid',
  columnCount: 3,
  gridTemplateColumns: '192px 2fr 1fr',
  gap: '6px'
}))

export const StyledStudentImgContainer = styled('div')(() => ({
  gridColumn: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

export const StyledStudentImg = styled('img')(({ theme }) => ({
  width: '192px',
  flexShrink: '0',
  borderRadius: '8px',
  overflow: 'hidden',
  display: 'block',
  backgroundColor: theme.palette.customColors.white,
  minHeight: '90px',
  maxHeight: '200px',
  marginBottom: 'auto'
}))

export const StyledStudentInfo = styled('div')(() => ({
  gridColumn: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginLeft: '18px',
  padding: '12px 0px',
  gap: '24px'
}))

export const StyledStudentInfoTitleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const StyledStudentInfoTitle = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '24px',
  marginBottom: '12px'
}))

export const StyledStudentInfoSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.palette.customColors.gray
}))

export const StyledStudentActions = styled('div')(() => ({
  gridColumn: 3,
  display: 'flex',
  justifyContent: 'flex-end'
}))

export const StyledStudentListContainer = styled('div')(({ theme }) => ({
  gridColumn: '2/4',
  display: 'grid',
  columnCount: 2,
  gridTemplateColumns: '2fr 1fr',
  padding: '16px 18px',
  margin: '32px 0 12px',
  border: `1px solid ${theme.palette.customColors.linear}`,
  borderRadius: '16px',
  backgroundColor: theme.palette.customColors.white
}))
