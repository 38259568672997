import React, { memo } from 'react'
import {
  Divider,
  RowTextLeft,
  RowTitle,
  SmallRow,
  StyledTableContents,
  StyledTableMobileRow
} from '../../styles'
import { IMobileRowProps } from '../../types'
import uuid from 'react-uuid'

const MobileRow = ({
  row,
  buttons,
  rowTitle,
  tableHead,
  rowAction
}: IMobileRowProps) => {
  return (
    <StyledTableMobileRow key={uuid()} onClick={rowAction}>
      {rowTitle && <RowTitle>{rowTitle}</RowTitle>}
      <StyledTableContents>
        {tableHead.map((header) =>
          row[header.cellName] ? (
            <SmallRow key={uuid()}>
              <RowTextLeft>{header.name}</RowTextLeft>
              <Divider />
              {row[header.cellName]}
            </SmallRow>
          ) : null
        )}
      </StyledTableContents>
      {buttons ? buttons.map((btn) => btn) : null}
    </StyledTableMobileRow>
  )
}

export default memo(MobileRow)
