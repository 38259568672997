import { RootState } from '../../../store'

export const getStudentProfileSelector = (state: RootState) =>
  state.studentDetails.profile
export const getStudentActivitiesSelector = (state: RootState) =>
  state.studentDetails.activities
export const getIsStudentActivitiesLoadingSelector = (state: RootState) =>
  state.studentDetails.isActivitiesLoading
export const getIsProfileLoadingSelector = (state: RootState) =>
  state.studentDetails.isProfileLoading
