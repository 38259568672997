import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  StyledAvatarWrapper,
  StyledBackButton,
  StyledChangeAvatarButton,
  StyledPersonalInfo,
  StyledInfoDescription,
  StyledInfoTitle,
  StyledName,
  StyledProfile,
  StyledProfileInfo,
  StyledProfileInfoWrapper,
  StyledTitle,
  StyledTitleWrapper,
  StyledStudentInfo,
  StyledEditInfoButton,
  StyledSaveChangesButton,
  StyledStudentInfoWrapper,
  StyledPersonalInfoWrapper,
  StyledEmail,
  StyledEditButtonsWrapper,
  StyledCancelChangesButton,
  StyledInfoDescriptionWrapper,
  StyledLoaderWrapper
} from './styles'
import { useAppDispatch, useAppSelector, useDropZoneBags } from '../../hooks'
import {
  changeProfileAvatar,
  changeProfileInfo,
  getProfileExtended
} from './services'
import {
  getProfileExtendedSelector,
  isAvatarUpdatingSelector,
  isPersonalInfoUpdatingSelector
} from './selectors'
import { UserType } from '../Login/types'
import { useNavigate } from 'react-router-dom'
import { Form, Formik, FormikProps, FormikValues } from 'formik'
import { getDefaultFormValues } from '../../utils/helpers'
import FormInput from '../../components/FormInput'
import v from '../../validation/validation'
import schemas from './schemas'
import { useTranslation } from 'react-i18next'
import {
  IChangeProfileInfoPayload,
  InterfaceType,
  ScoreFormatType
} from './types'
import customToast from '../../components/CustomToast'
import { authSlice } from '../Login/slices'
import { WIDTH_TITLE_COMPANY, WIDTH_TITLE_SCHOOL } from '../../utils/constants'
import CustomSelect from '../../components/CustomSelect'
import { clearState, profileSlice } from './slices'
import EditAvatarModal from '../../components/EditAvatarModal'
import AvatarEditor from 'react-avatar-editor'
import { filesSettings } from './constants'
import CustomImage from '../../components/CustomImage'
import CustomLoader from '../../components/CustomLoader'

function Profile() {
  const formRef = useRef<FormikProps<FormikValues> | null>(null)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const profile = useAppSelector(getProfileExtendedSelector)
  const isUpdating = useAppSelector(isPersonalInfoUpdatingSelector)
  const isAvatarUpdating = useAppSelector(isAvatarUpdatingSelector)

  const { updateCurrentUserFullName, updateCurrentUserAvatarUrl } =
    authSlice.actions
  const { updateCurrentUserScoreFormat } = profileSlice.actions

  const [isEditMode, setIsEditMode] = useState(false)
  const [scoreFormat, setScoreFormat] = useState<ScoreFormatType | ''>('')
  const [avatar, setAvatar] = useState<File | undefined>()
  const [isEditAvatarModalOpen, setIsEditAvatarModalOpen] = useState(false)
  const [editor, setEditor] = useState<AvatarEditor | null>(null)

  const profileInfoFields = ['fullName', 'email']
  const profileInfoValues = getDefaultFormValues(
    profile || {},
    profileInfoFields
  )
  const changeInfoValidationSchema = v.utils.createPartialObjectSchema(
    schemas(t),
    profileInfoFields
  )

  useEffect(() => {
    dispatch(getProfileExtended())
    return () => {
      dispatch(clearState())
    }
  }, [])

  useEffect(() => {
    if (profile) {
      setScoreFormat(profile.scoreFormat)
    }
  }, [profile])

  useEffect(() => {
    if (avatar) {
      setIsEditAvatarModalOpen(true)
    }
  }, [avatar])

  const { open, getInputProps } = useDropZoneBags(
    avatar,
    setAvatar,
    filesSettings
  )

  const handleScoreFormatChange = (scoreFormat: ScoreFormatType) => {
    setScoreFormat(scoreFormat)
    dispatch(changeProfileInfo({ scoreFormat })).then((res) => {
      if (changeProfileInfo.fulfilled.match(res)) {
        dispatch(updateCurrentUserScoreFormat({ scoreFormat }))
        customToast({ message: 'Success!', type: 'success' })
      }
    })
  }

  const redirectToPreviousPage = () => {
    navigate(-1)
  }

  const getSchools = () => {
    if (profile?.schools?.length === 0) {
      return <StyledInfoDescription>N/A</StyledInfoDescription>
    }
    return profile?.schools?.map((school) => {
      return (
        <StyledInfoDescription key={school.id}>
          {school.shortName}
        </StyledInfoDescription>
      )
    })
  }

  const getClasses = () => {
    if (profile?.classes?.length === 0) {
      return <StyledInfoDescription>N/A</StyledInfoDescription>
    }
    return profile?.classes?.map((cl) => {
      return (
        <StyledInfoDescription key={cl.id}>{cl.name}</StyledInfoDescription>
      )
    })
  }

  const handleCloseEditAvatarModal = useCallback(() => {
    setIsEditAvatarModalOpen(false)
    setAvatar(undefined)
  }, [])

  const handleSaveAvatar = useCallback(() => {
    if (editor && avatar) {
      const canvas = editor.getImageScaledToCanvas()
      canvas.toBlob(function (blob) {
        if (blob) {
          const formData = new FormData()
          formData.append('file', blob, 'filename.png')

          dispatch(changeProfileAvatar({ file: formData })).then((res) => {
            if (changeProfileAvatar.fulfilled.match(res)) {
              dispatch(getProfileExtended()).then((res) => {
                if (getProfileExtended.fulfilled.match(res)) {
                  dispatch(
                    updateCurrentUserAvatarUrl({
                      avatarUrl: res.payload.avatarUrl
                    })
                  )
                  customToast({ message: 'Success!', type: 'success' })
                  handleCloseEditAvatarModal()
                }
              })
            }
          })
        }
      })
    }
  }, [editor, avatar, handleCloseEditAvatarModal])

  const getEditModeButton = () => {
    if (!isEditMode) {
      return (
        <StyledEditInfoButton
          type={'submit'}
          size={'large'}
          variant={'outlined'}
          color={'primary'}
          onClick={() => setIsEditMode(true)}
        >
          {t('editInformation')}
        </StyledEditInfoButton>
      )
    }
    if (isEditMode) {
      return (
        <StyledEditButtonsWrapper>
          <StyledSaveChangesButton
            type={'submit'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              if (formRef.current) {
                formRef.current.handleSubmit()
              }
            }}
          >
            {isUpdating ? `${t('saving')}...` : t('saveChanges')}
          </StyledSaveChangesButton>
          <StyledCancelChangesButton
            type={'submit'}
            size={'large'}
            variant={'outlined'}
            color={'primary'}
            onClick={() => setIsEditMode(false)}
          >
            {t('cancel')}
          </StyledCancelChangesButton>
        </StyledEditButtonsWrapper>
      )
    }
  }

  const scoreOptions = [
    {
      name: `${t('scale')} %`,
      value: 'percent'
    },
    {
      name: `${t('scale')} 0/10`,
      value: 'ten'
    },
    {
      name: `${t('scale')} 0/20`,
      value: 'twenty'
    },
    {
      name: `${t('scale')} 0/30`,
      value: 'thirty'
    },
    {
      name: `${t('scale')} A-F`,
      value: 'letters_af'
    }
  ]

  const getChangeScoreFormatSelect = () => {
    if (!isEditMode && profile) {
      return (
        <CustomSelect
          aria-label={'Select format'}
          label={t('convertResultsTo')}
          value={scoreFormat}
          options={scoreOptions}
          onChange={(event) => {
            handleScoreFormatChange(event.target.value as ScoreFormatType)
          }}
          width={'202px'}
        />
      )
    }
  }

  const saveEditedInfo = (values: FormikValues) => {
    dispatch(changeProfileInfo(values as IChangeProfileInfoPayload)).then(
      (res) => {
        if (changeProfileInfo.fulfilled.match(res)) {
          dispatch(updateCurrentUserFullName({ fullName: values.fullName }))
          customToast({ message: 'Success!', type: 'success' })
          setIsEditMode(false)
        }
      }
    )
  }

  const width =
    profile?.interfaceType === InterfaceType.school
      ? WIDTH_TITLE_SCHOOL
      : WIDTH_TITLE_COMPANY

  return (
    <StyledProfile aria-label={'Your profile page'}>
      <StyledTitleWrapper>
        <StyledBackButton
          aria-label={'Previous page button'}
          onClick={redirectToPreviousPage}
        />
        <StyledTitle variant={'h2'}>{t('yourProfile')}</StyledTitle>
      </StyledTitleWrapper>
      <StyledProfileInfoWrapper aria-label={'Profile info container'}>
        {profile ? (
          <>
            <StyledAvatarWrapper>
              <CustomImage
                borderRadius={'16px'}
                src={profile?.avatarUrl || '/assets/defaultLogo.svg'}
                width={'192px'}
                height={'192px'}
                alt={'Avatar'}
                errorSrc="/assets/defaultLogo.svg"
              />
              <input {...getInputProps()} />
              <StyledChangeAvatarButton onClick={open}>
                {t('changePhoto')}
              </StyledChangeAvatarButton>
            </StyledAvatarWrapper>
            <StyledProfileInfo aria-label={'Profile info'}>
              <StyledPersonalInfoWrapper aria-label={'Personal info'}>
                <StyledPersonalInfo>
                  {!isEditMode && (
                    <>
                      <StyledName variant={'h3'}>
                        {profile?.fullName}
                      </StyledName>
                      <StyledEmail variant={'h4'}>{profile?.email}</StyledEmail>
                    </>
                  )}
                  {isEditMode && (
                    <Formik
                      innerRef={formRef}
                      onSubmit={saveEditedInfo}
                      initialValues={profileInfoValues}
                      validationSchema={changeInfoValidationSchema}
                    >
                      <Form>
                        <FormInput
                          label={t('name')}
                          name={'fullName'}
                          width={'100%'}
                          placeholder={t('name')}
                        />
                        <FormInput
                          label={t('email')}
                          name={'email'}
                          width={'100%'}
                          placeholder={t('email')}
                        />
                      </Form>
                    </Formik>
                  )}
                </StyledPersonalInfo>
                <div>
                  {profile?.type === UserType.teacher && getEditModeButton()}
                  {getChangeScoreFormatSelect()}
                </div>
              </StyledPersonalInfoWrapper>
              {!isEditMode && (
                <StyledStudentInfoWrapper
                  aria-label={'Schools and courses info'}
                >
                  <StyledStudentInfo>
                    <StyledInfoTitle variant={'h3'} width={width}>
                      {profile?.interfaceType === InterfaceType.school
                        ? t('schools')
                        : t('companies')}
                    </StyledInfoTitle>
                    <StyledInfoDescriptionWrapper>
                      {getSchools()}
                    </StyledInfoDescriptionWrapper>
                  </StyledStudentInfo>
                  <StyledStudentInfo>
                    <StyledInfoTitle variant={'h3'} width={width}>
                      {profile?.interfaceType === InterfaceType.school
                        ? t('classes')
                        : t('departments')}
                    </StyledInfoTitle>
                    <StyledInfoDescriptionWrapper>
                      {getClasses()}
                    </StyledInfoDescriptionWrapper>
                  </StyledStudentInfo>
                </StyledStudentInfoWrapper>
              )}
            </StyledProfileInfo>
          </>
        ) : (
          <StyledLoaderWrapper>
            <CustomLoader size={64} />
          </StyledLoaderWrapper>
        )}
      </StyledProfileInfoWrapper>
      {avatar && (
        <EditAvatarModal
          isOpen={isEditAvatarModalOpen}
          handleClose={handleCloseEditAvatarModal}
          file={avatar}
          setEditor={setEditor}
          handleSave={handleSaveAvatar}
          isLoading={isAvatarUpdating}
        />
      )}
    </StyledProfile>
  )
}

export default Profile
