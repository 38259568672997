import { styled } from '@mui/system'
import { Form } from 'formik'
import { Button, Typography } from '@mui/material'

export const StyledForm = styled(Form)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%'
}))

export const StyledContentWrapper = styled('div')(() => ({
  width: '356px'
}))

export const StyledInfoItemWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '16px'
}))

export const StyledActivityDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.customColors.gray,
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '12px',
  marginTop: '16px'
}))

export const StyledInfoItem = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.palette.customColors.black
}))

export const StyledButtonsWrapper = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  marginTop: '24px'
}))

export const StyledButton = styled(Button)(() => ({
  '&:nth-of-type(1)': {
    flex: 1
  },
  '&:nth-of-type(2)': {
    flex: 2,
    marginLeft: '12px'
  }
}))
