import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { editStudent, editStudentClasses } from '../services'
import { IStudentEditState } from '../types'
import {
  findSchools,
  getClasses,
  getStudentProfileExtended
} from '../../../core/services'
import { IStudentProfileExtended } from '../../StudentDetails/types'

const initialState: IStudentEditState = {
  isProfileLoading: false,
  isLoading: false,
  profile: null,
  schools: [],
  classes: []
}

export const studentEditSlice = createSlice({
  name: 'studentEdit',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentProfileExtended.pending, (state) => {
      state.isProfileLoading = true
    })
    builder.addCase(
      getStudentProfileExtended.fulfilled,
      (state, { payload }: PayloadAction<IStudentProfileExtended>) => {
        state.isProfileLoading = false
        state.profile = payload
      }
    )
    builder.addCase(getStudentProfileExtended.rejected, (state) => {
      state.isProfileLoading = false
    })

    builder.addCase(findSchools.fulfilled, (state, { payload }) => {
      state.schools = payload.data
    })

    builder.addCase(getClasses.fulfilled, (state, { payload }) => {
      state.classes = payload.data
    })

    builder.addCase(editStudent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(editStudent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(editStudent.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(editStudentClasses.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(editStudentClasses.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(editStudentClasses.rejected, (state) => {
      state.isLoading = false
    })
  }
})

export const { clearState } = studentEditSlice.actions
export default studentEditSlice.reducer
