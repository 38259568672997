import en_US from 'antd/lib/date-picker/locale/en_US'
import fr_FR from 'antd/lib/date-picker/locale/fr_FR'
import it_IT from 'antd/lib/date-picker/locale/it_IT'
import ko_KR from 'antd/lib/date-picker/locale/ko_KR'
import lv_LV from 'antd/lib/date-picker/locale/lv_LV'
import ru_RU from 'antd/lib/date-picker/locale/ru_RU'
import es_ES from 'antd/lib/date-picker/locale/es_ES'
import { GrammarTags, SyntaxTags, VocabTags } from '../types'
import { Item } from '../components/CustomMultySelect/types'

export const ACCESS_TOKEN_NAME = 'accessToken'
export const REFRESH_TOKEN_NAME = 'refreshToken'
export const COUNT_PER_PAGE = 6
export const CLASSES_PAGE_SIZE = 8
export const STUDENTS_PAGE_SIZE = 10
export const INFINITY_PAGE_SIZE = 10000
export const FIND_SCHOOLS_PAGE_SIZE = 100
export const DEFAULT_LIST_ITEM_SIZE = 100
export const WIDTH_TITLE_SCHOOL = '92px'
export const WIDTH_TITLE_COMPANY = '120px'
export const MAX_DISPLAY_LINES = 2
export const IMAGE_MAX_WEIGHT = 31457280 // 30 mb
export const BREAKPOINTS = {
  mobile: 600,
  tabletSm: 768,
  tabletLg: 1140,
  desktop: 1440
}

export const ANTD_LOCALES = {
  en: en_US,
  fr: fr_FR,
  it: it_IT,
  ko: ko_KR,
  lv: lv_LV,
  ru: ru_RU,
  sp: es_ES
}

export const SPEECH_LANGUAGES: Record<string, string> = {
  en: 'en-US',
  fr: 'fr-FR',
  it: 'it-IT',
  ko: 'ko',
  sp: 'es-ES',
  ge: 'de-DE',
  ch: 'zh-CN'
}

export const PRE_SELECTED_ACTIVITY_KEY = 'preSelectedActivityId'

export const GRAMMARS: Item<GrammarTags>[] = [
  {
    name: 'Present',
    value: GrammarTags.present
  },
  {
    name: 'Past',
    value: GrammarTags.past
  },
  {
    name: 'Future',
    value: GrammarTags.future
  },
  {
    name: 'Subjunctive',
    value: GrammarTags.subjunctive
  },
  {
    name: 'Conditional',
    value: GrammarTags.conditional
  },
  {
    name: 'Comparatives',
    value: GrammarTags.comparatives
  },
  {
    name: 'Superlatives',
    value: GrammarTags.superlatives
  }
]

export const SYNTAX: Item<SyntaxTags>[] = [
  {
    name: 'Affirmative sentences',
    value: SyntaxTags.affirmativeSentences
  },
  {
    name: 'Negative sentences',
    value: SyntaxTags.negativeSentences
  },
  {
    name: 'Interrogative sentences',
    value: SyntaxTags.interrogativeSentences
  },
  {
    name: 'Word order',
    value: SyntaxTags.wordOrder
  }
]

export const VOCAB: Item<VocabTags>[] = [
  {
    name: 'Nouns',
    value: VocabTags.nouns
  },
  {
    name: 'Verbs',
    value: VocabTags.verbs
  },
  {
    name: 'Adjectives',
    value: VocabTags.adjectives
  },
  {
    name: 'Adverbs',
    value: VocabTags.adverbs
  },
  {
    name: 'Pronouns',
    value: VocabTags.pronouns
  },
  {
    name: 'Conjunctions',
    value: VocabTags.conjunctions
  },
  {
    name: 'Prepositions',
    value: VocabTags.prepositions
  },
  {
    name: 'Work vocabulary',
    value: VocabTags.workVocabulary
  },
  {
    name: 'Technical vocabulary',
    value: VocabTags.technicalVocabulary
  }
]
