import { createTheme } from '@mui/material/styles'
import { colors } from '../types'

export const theme = createTheme({
  palette: {
    customColors: {
      bg: colors.bg,
      black: colors.black,
      blue: colors.blue,
      blueDark: colors.blueDark,
      blueHover: colors.blueHover,
      blueLight: colors.blueLight,
      error: colors.error,
      gray: colors.gray,
      grayLight: colors.grayLight,
      grayMedium: colors.grayMedium,
      green: colors.green,
      greenDark: colors.greenDark,
      greenLight: colors.greenLight,
      linear: colors.linear,
      sky: colors.sky,
      skyDark: colors.skyDark,
      skyLight: colors.skyLight,
      violet: colors.violet,
      violetDark: colors.violetDark,
      violetGrey: colors.violetGrey,
      violetLight: colors.violetLight,
      white: colors.white,
      red: colors.red,
      redLight: colors.redLight,
      redDark: colors.redDark,
      muiDarkGrey: colors.muiDarkGrey,
      yellow: colors.yellow,
      yellowLight: colors.yellowLight,
      yellowDark: colors.yellowDark,
      muiDarkRed: colors.muiDarkRed
    }
  },
  typography: {
    fontFamily: 'Inter'
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: 'Inter',
          width: '100%'
        },
        outlinedPrimary: {
          backgroundColor: colors.white,
          border: `1px solid ${colors.linear}`,
          color: colors.gray,
          ':hover': {
            backgroundColor: colors.white,
            border: `1px solid ${colors.blueHover}`
          },
          ':disabled': {
            backgroundColor: colors.grayMedium,
            border: `1px solid ${colors.grayMedium}`,
            color: colors.grayMedium
          }
        },
        containedPrimary: {
          backgroundColor: colors.blue,
          color: colors.white,
          ':hover': {
            backgroundColor: colors.blueHover
          },
          ':disabled': {
            backgroundColor: colors.grayMedium,
            color: colors.white
          }
        },
        sizeLarge: {
          height: '40px',
          borderRadius: '20px',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '20px'
        },
        sizeMedium: {
          height: '28px',
          borderRadius: '20px',
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '20px'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: 'SpaceMono'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            height: '36px'
          },
          '& .MuiInputBase-multiline': {
            minHeight: '64px',
            height: 'auto',
            borderRadius: '16px',
            border: `1px solid ${colors.linear}`,
            background: colors.bg
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-select': {
            height: '36px',
            boxSizing: 'border-box',
            padding: '8px 16px'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: `${colors.black}!important`
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.black
        }
      }
    }
  }
})
