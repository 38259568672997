import { TFunction } from 'i18next'
import * as yup from 'yup'

export const studentFormSchema = (t: TFunction) =>
  yup.object().shape({
    fullName: yup.string().required(t('nameRequired')).min(4, t('min4')),
    email: yup.string().email(t('invalidEmail')).required(t('emailRequired')),
    schoolIds: yup.array().of(yup.string()).min(1, t('schoolRequired')),
    classIds: yup.array().of(yup.string()).min(1, t('classRequired'))
  })
