import { memo, useEffect, useState } from 'react'

import {
  StyledSchoolsPage,
  StyledTitle,
  StyledSchoolsContainer
} from './styles'
import { UserType } from '../Login/types'
import { Pagination, Stack } from '@mui/material'
import { getStudentsSchool, getTeacherSchool } from './services'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getStudentsSchoolSelector } from './selectors'
import { getCurrentUserSelector } from '../../core/selectors'
import { COUNT_PER_PAGE } from '../../utils/constants'
import { InterfaceType } from '../Profile/types'
import { useTranslation } from 'react-i18next'
import { clearState } from './slices'
import School from './components/School'

function Schools() {
  const [page, setPage] = useState(1)
  const { t } = useTranslation()
  const [pageQty, setPageQty] = useState(0)
  const dispatch = useAppDispatch()
  const schools = useAppSelector(getStudentsSchoolSelector)
  const currentUser = useAppSelector(getCurrentUserSelector)

  useEffect(() => {
    if (currentUser?.type === UserType.teacher) {
      dispatch(getTeacherSchool({ page }))
    } else {
      dispatch(getStudentsSchool({ page }))
    }
    setPageQty(Math.ceil(Number(schools?.count) / COUNT_PER_PAGE))
    if (pageQty < page) {
      setPage(1)
    }
  }, [page])

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [])

  return (
    <StyledSchoolsPage>
      {schools?.data.length === 0 ? (
        <StyledTitle variant={'h2'} aria-label={'title'}>
          {t('noAssignedSchools')}
        </StyledTitle>
      ) : (
        <>
          <StyledTitle variant={'h2'} aria-label={'Title'}>
            {currentUser?.interfaceType === InterfaceType.school
              ? t('mySchools')
              : t('myCompanies')}
          </StyledTitle>
          <Stack spacing={2}>
            <StyledSchoolsContainer>
              {schools?.data?.map((school) => (
                <School school={school} key={school.id} />
              ))}
            </StyledSchoolsContainer>
            {!!pageQty && pageQty > 1 && (
              <Pagination
                count={pageQty}
                page={page}
                onChange={(_, num) => setPage(num)}
                variant="outlined"
                color="primary"
              />
            )}
          </Stack>
        </>
      )}
    </StyledSchoolsPage>
  )
}

export default memo(Schools)
