import { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AccordionContent from '../../../../components/AccordionContent'
import {
  BREAKPOINTS,
  DEFAULT_LIST_ITEM_SIZE
} from '../../../../utils/constants'
import useWindowSize from '../../../../utils/hooks'
import { AssignToActivityReq, TableColumn } from '../../../UnitDetails/types'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { getCurrentUserSelector } from '../../../../core/selectors'
import { UserType } from '../../../Login/types'
import { CustomChip } from '../../../../components/CustomChip'
import { getCustomChipOptions } from '../../../../utils/helpers'
import UnitName from '../../../../components/UnitName'
import CustomTable from '../../../../components/CustomTable'
import CustomTableMobile from '../../../../components/CustomTableMobile'
import { Button, Tooltip } from '@mui/material'
import Tags from '../../../../components/Tags'
import MobileRow from '../../../../components/CustomTableMobile/components/MobileRow'
import { useParams } from 'react-router-dom'
import { LevelType } from '../../../Home/types'
import { getSuggestionsSelector } from '../../selectors'
import {
  getAssignedActivitiesSuggestions,
  getStudentResultSuggestions,
  getTeacherResultSuggestions
} from '../../services'
import { assignToActivity } from '../../../UnitDetails/services'
import { RecommendationBlock } from '../../styles'

interface Props {
  classId: string
  studentId: string
}

const Suggestions: FC<Props> = ({ classId, studentId }) => {
  const params = useParams()
  const id = params.assignedActivityResultId!
  const suggestions = useAppSelector(getSuggestionsSelector)
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector(getCurrentUserSelector)
  const { t } = useTranslation()
  const [windowWidth] = useWindowSize()
  const isTablet = useMemo(
    () => windowWidth <= BREAKPOINTS.tabletSm,
    [windowWidth]
  )

  const fetchSuggestions = useCallback(() => {
    if (currentUser?.type === UserType.teacher) {
      dispatch(getTeacherResultSuggestions({ id }))
    }
    if (currentUser?.type === UserType.student) {
      dispatch(getStudentResultSuggestions({ id }))
    }
  }, [id, currentUser])

  const prepareTableHead = useCallback(() => {
    if (!currentUser) return []

    const tableHead: TableColumn[] = [
      {
        name: t('activityName'),
        ariaLabel: 'Activity name',
        cellName: 'activity',
        sortable: false
      },
      {
        name: t('level'),
        ariaLabel: 'Unit level',
        cellName: 'level',
        cellAlign: 'center',
        sortable: false
      },
      {
        name: t('tags'),
        ariaLabel: 'Tags',
        cellName: 'tags',
        cellAlign: 'center',
        sortable: false
      }
    ]

    if (currentUser.type === UserType.teacher && !isTablet) {
      tableHead.push({
        name: t('assignActivity'),
        ariaLabel: 'Assign activity action',
        cellName: 'assignActivity',
        sortable: false,
        cellAlign: 'center'
      })
    }

    return tableHead
  }, [currentUser, isTablet, t])

  const handleAssignToActivity = useCallback(
    (activityId: string) => {
      const data: AssignToActivityReq = {
        classId,
        studentId,
        activityId,
        sendNotification: true,
        resultId: id
      }
      dispatch(assignToActivity(data)).then((res) => {
        if (assignToActivity.fulfilled.match(res)) {
          fetchSuggestions()
          if (currentUser) {
            dispatch(
              getAssignedActivitiesSuggestions({ id, type: currentUser.type })
            )
          }
        }
      })
    },
    [currentUser]
  )

  const prepareLevel = useCallback(
    (level: LevelType) => {
      const chipOptions = getCustomChipOptions(level)
      return (
        <CustomChip
          aria-label="Unit level"
          bgColor={chipOptions.bgColor}
          label={chipOptions.name}
          textColor={chipOptions.textColor}
          borderColor={chipOptions.borderColor}
          height="20px"
        />
      )
    },
    [getCustomChipOptions]
  )

  const prepareActivityNameStyle = useCallback(
    (isAvailable: boolean) => {
      return currentUser?.type === UserType.student && !isAvailable
        ? { opacity: 0.5 }
        : {}
    },
    [currentUser]
  )

  const prepareTableData = useCallback(() => {
    if (!currentUser) return []

    return suggestions.map((suggestion) => {
      const tags = [
        ...(suggestion.grammarTags || []),
        ...(suggestion.syntaxTags || []),
        ...(suggestion.vocabTags || [])
      ]

      const row: Record<string, JSX.Element> = {
        activity: (
          <UnitName
            activityName={suggestion.title}
            style={prepareActivityNameStyle(suggestion.isAvailable)}
          />
        ),
        level: prepareLevel(suggestion.level),
        tags: <Tags tags={tags} style={{ justifyContent: 'center' }} />
      }

      if (currentUser.type === UserType.teacher) {
        row.assignActivity = (
          <Tooltip
            title={!suggestion.isAvailable ? t('contactTheDirector') : ''}
          >
            <div>
              <Button
                type="button"
                size="medium"
                variant="contained"
                disabled={!suggestion.isAvailable}
                color="primary"
                style={{ width: 'max-content' }}
                onClick={() => {
                  handleAssignToActivity(suggestion.id)
                }}
              >
                {t('assign')}
              </Button>
            </div>
          </Tooltip>
        )
      }

      return row
    })
  }, [t, suggestions, currentUser, prepareLevel, handleAssignToActivity])

  const prepareTableDataMobile = useCallback(() => {
    if (!currentUser) return []
    const header = prepareTableHead()

    return suggestions.map((suggestion) => {
      const buttons: JSX.Element[] = []
      if (currentUser.type === UserType.teacher) {
        buttons.push(
          <Button
            type="button"
            size="medium"
            variant="contained"
            disabled={!suggestion.isAvailable}
            color="primary"
            onClick={() => {
              handleAssignToActivity(suggestion.id)
            }}
          >
            {t('assign')}
          </Button>
        )
      }

      const tags = [
        ...(suggestion.grammarTags || []),
        ...(suggestion.syntaxTags || []),
        ...(suggestion.vocabTags || [])
      ]

      const row: Record<string, JSX.Element> = {
        activity: (
          <UnitName
            activityName={suggestion.title}
            style={prepareActivityNameStyle(suggestion.isAvailable)}
          />
        ),
        level: prepareLevel(suggestion.level),
        tags: <Tags tags={tags} style={{ justifyContent: 'flex-end' }} />
      }

      return (
        <MobileRow
          row={row}
          rowTitle={suggestion.title}
          buttons={buttons}
          tableHead={header}
        />
      )
    })
  }, [
    t,
    suggestions,
    currentUser,
    prepareLevel,
    handleAssignToActivity,
    prepareTableHead
  ])

  const desktopContent = useMemo(() => {
    return (
      <RecommendationBlock>
        <CustomTable
          tableAriaLabel={'Course details table'}
          tableHead={prepareTableHead()}
          dataCount={suggestions.length}
          rowsCount={DEFAULT_LIST_ITEM_SIZE}
          rows={prepareTableData()}
          tableSubtitle=""
        />
      </RecommendationBlock>
    )
  }, [t, suggestions, prepareTableHead, prepareTableData])

  const tabletContent = useMemo(() => {
    return (
      <RecommendationBlock>
        <CustomTableMobile
          tableAriaLabel={'Course details table'}
          tableHead={prepareTableHead()}
          dataCount={suggestions.length}
          rowsCount={DEFAULT_LIST_ITEM_SIZE}
          rows={prepareTableDataMobile()}
          tableSubtitle=""
        />
      </RecommendationBlock>
    )
  }, [t, suggestions, prepareTableHead, prepareTableDataMobile])

  useEffect(() => {
    fetchSuggestions()
  }, [])

  return (
    <AccordionContent
      hideTitle={t('hideSuggestions')}
      showTitle={t('showSuggestions')}
    >
      {isTablet ? tabletContent : desktopContent}
    </AccordionContent>
  )
}

export default Suggestions
