import React, { useCallback } from 'react'
import { useAppDispatch } from '../../hooks'
import { getCurrentUser, loginByMyLogin } from '../Login/services'
import { OauthMyLoginLogin } from './components/OauthMyLoginLogin'

function Oauth() {
  const dispatch = useAppDispatch()

  const getTokensByCode = useCallback((code: string) => {
    dispatch(loginByMyLogin({ code, state: 'my-login' })).then((res) => {
      if (loginByMyLogin.fulfilled.match(res)) {
        dispatch(getCurrentUser())
      }
    })
  }, [])

  return <OauthMyLoginLogin getTokensByCode={getTokensByCode} />
}

export default Oauth
