import { styled } from '@mui/system'
import { colors } from '../../../../../types'

export const StyledHeaderContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  marginBottom: '24px'
}))

export const StyledTitle = styled('h4')(() => ({
  margin: 0,
  color: colors.black,
  fontFamily: 'Inter',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '120%'
}))
