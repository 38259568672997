import { ScoreFormatType } from '../../Profile/types'

export enum Language {
  en = 'en',
  fr = 'fr',
  it = 'it',
  ko = 'ko',
  lv = 'lv',
  ru = 'ru',
  sp = 'sp'
}

export enum UserType {
  admin = 'admin',
  teacher = 'teacher',
  student = 'student'
}

export interface IUser {
  username: string
  fullName: string
  email: string | null
  interfaceType: 'school' | 'company'
  language: Language
  type: UserType
  avatarUrl: string
  scoreFormat: ScoreFormatType
}

export interface ILoginRequest {
  login: string
  password: string
}

export interface IMyLoginRequest {
  code: string
  state: string
}

export interface ILoginResponse {
  accessToken: string
  refreshToken: string
}

export interface ICurrentUserResponse extends IUser {}

export interface IUpdateCurrentUserFullNamePayload {
  fullName: string
}

export interface IUpdateCurrentUserAvatarUrlPayload {
  avatarUrl: string
}

export interface IAuthState {
  isLoading: boolean
  isLogIn: boolean
  accessToken: string | null
  refreshToken: string | null
  user: IUser | null
  isAdmin: boolean
}

export interface IMyLoginSuccess {
  code: string
  state: string
}
