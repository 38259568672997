import React from 'react'
import { IUpcomingActivityProps } from './types'
import {
  StyledActivityCard,
  StyledButtonWrapper,
  StyledChipContainer,
  StyledCourseCard,
  StyledDueDateCard,
  StyledInfoWrapper,
  StyledTitleCard,
  StyledUnitBottom,
  StyledUnitBottomBreadcrumbs,
  StyledUnitBottomLeft,
  StyledUnitBottomRight,
  StyledUnitCard,
  StyledUnitWrapper
} from './styles'
import { UserType } from '../../../Login/types'
import { CustomSvgIcon } from '../../../../components/CustomSvgIcon'
import { CustomChip } from '../../../../components/CustomChip'
import {
  formatDateByLocale,
  getCustomChipOptions
} from '../../../../utils/helpers'

export const UpcomingActivity = (props: IUpcomingActivityProps) => {
  const chipOptions = getCustomChipOptions(props.level)

  return (
    <StyledActivityCard aria-label={'course'}>
      <StyledInfoWrapper aria-labelledby={props.title}>
        <StyledUnitWrapper aria-label={props.subTitleCourse}>
          <StyledTitleCard variant={'h3'} id={props.title}>
            {props.title}
          </StyledTitleCard>
          <StyledChipContainer>
            <CustomChip
              aria-label={'Unit level'}
              bgColor={chipOptions?.bgColor}
              label={chipOptions?.name}
              textColor={chipOptions?.textColor}
              borderColor={chipOptions?.borderColor}
              height={'20px'}
            />
          </StyledChipContainer>
        </StyledUnitWrapper>

        <StyledUnitBottom>
          <StyledUnitBottomLeft>
            <StyledUnitBottomBreadcrumbs aria-label={props.subTitleCourse}>
              <StyledCourseCard aria-label={'Unit name'}>
                {props.subTitleCourse}
              </StyledCourseCard>
              /
              <StyledUnitCard aria-label={'Unit name'}>
                {props.subTitleUnit}
              </StyledUnitCard>
            </StyledUnitBottomBreadcrumbs>
            <StyledDueDateCard aria-label={'Due date'}>
              {formatDateByLocale(props.dueDate)}
            </StyledDueDateCard>
          </StyledUnitBottomLeft>
          {props.role === UserType.student && (
            <StyledUnitBottomRight>
              <StyledButtonWrapper
                onClick={props.onButtonClick}
                aria-label={'Link to course page'}
              >
                <CustomSvgIcon
                  color={'white'}
                  path={'/assets/arrow-right-icon.svg'}
                />
              </StyledButtonWrapper>
            </StyledUnitBottomRight>
          )}
        </StyledUnitBottom>
      </StyledInfoWrapper>
    </StyledActivityCard>
  )
}
