import React, { useCallback, useEffect, useMemo, useState } from 'react'
import CustomModal from '../CustomModal'
import AvatarEditor from 'react-avatar-editor'
import { IEditAvatarModalProps } from './types'
import { useTranslation } from 'react-i18next'
import {
  HORIZONTAL_BORDER_SIZE,
  MAX_SCALE,
  MIN_SCALE,
  SCALE_STEP,
  VERTICAL_BORDER_SIZE
} from './constants'
import {
  StyledAvatarEditorWrapper,
  StyledIconButton,
  StyledImgIcon,
  StyledRangeInput,
  StyledSaveButton,
  StyledZoomWrapper
} from './styles'

const EditAvatarModal = (props: IEditAvatarModalProps) => {
  const { isOpen, handleClose, file, setEditor, handleSave, isLoading } = props
  const [image, setImage] = useState<string | null>(null)
  const [scale, setScale] = useState<number>(1)

  const { t } = useTranslation()

  useEffect(() => {
    setImage(file && URL.createObjectURL(file))
  }, [file])

  const handleScaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const scale = parseFloat(event.target.value)
    setScale(scale)
  }

  const handleIncScaleChange = useCallback(() => {
    if (scale < MAX_SCALE) {
      setScale(scale + SCALE_STEP)
    }
  }, [scale])

  const handleDecScaleChange = useCallback(() => {
    if (scale > MIN_SCALE) {
      setScale(scale - SCALE_STEP)
    }
  }, [scale])

  const handleModalClose = useCallback(() => {
    handleClose()
    setScale(1)
  }, [handleClose])

  const renderSaveButtonContent = useMemo(() => {
    return !isLoading ? t('save') : <>{t('saving')}...</>
  }, [isLoading])

  return (
    <React.StrictMode>
      <CustomModal
        title={t('changePhoto')}
        open={isOpen}
        handleClose={handleModalClose}
        width={'auto'}
      >
        <StyledAvatarEditorWrapper>
          {image && (
            <div>
              <AvatarEditor
                ref={(ref) => setEditor(ref)}
                image={image}
                width={420}
                height={420}
                border={[HORIZONTAL_BORDER_SIZE, VERTICAL_BORDER_SIZE]}
                borderRadius={250}
                scale={scale}
                rotate={0}
              />
              <StyledZoomWrapper>
                <StyledIconButton onClick={handleDecScaleChange}>
                  <StyledImgIcon
                    src={'/assets/search-zoom-out.svg'}
                    alt={'zoom-out icon'}
                  />
                </StyledIconButton>
                <StyledRangeInput
                  type="range"
                  min="1"
                  max="2"
                  step="0.01"
                  value={scale}
                  onChange={handleScaleChange}
                />
                <StyledIconButton onClick={handleIncScaleChange}>
                  <StyledImgIcon
                    src={'/assets/search-zoom-in.svg'}
                    alt={'zoom-in icon'}
                  />
                </StyledIconButton>
              </StyledZoomWrapper>
            </div>
          )}
          <StyledSaveButton
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={handleSave}
            disabled={isLoading}
          >
            {renderSaveButtonContent}
          </StyledSaveButton>
        </StyledAvatarEditorWrapper>
      </CustomModal>
    </React.StrictMode>
  )
}

export default EditAvatarModal
