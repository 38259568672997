import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../hooks'
import { getCurrentUser, login, loginByMyLogin } from './services'
import { Formik, FormikValues } from 'formik'
import FormInput from '../../components/FormInput'
import { StyledLoginFormWrapper, StyledForm, StyledLink } from './styles'
import { getDefaultFormValues } from '../../utils/helpers'
import v from '../../validation/validation'
import schemas from './schemas'
import { ILoginRequest, IMyLoginSuccess, Language } from './types'
import FormOutlinedInput from '../../components/FormOutlinedInput'
import { Button } from '@mui/material'
import LogoMobile from './components/LogoMobile'
import { APP_ROUTES } from '../../core/router/appRoutes'
import { MyLoginOAuth2 } from './components/MyLoginOAuth2'
import i18n from 'i18next'

export const loginFields = ['login', 'password']
export const loginValues = getDefaultFormValues({}, loginFields)

function Login() {
  const { t } = useTranslation()
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const loginValidationSchema = v.utils.createPartialObjectSchema(
    schemas(t),
    loginFields
  )

  const dispatch = useAppDispatch()
  const onSubmit = (values: FormikValues) => {
    dispatch(login(values as ILoginRequest)).then((res) => {
      if (login.fulfilled.match(res)) {
        dispatch(getCurrentUser())
      }
    })
  }

  const changePasswordVisibility = useCallback(() => {
    setIsPasswordVisible(!isPasswordVisible)
  }, [isPasswordVisible])

  const onSuccessMyLogin = useCallback((data: IMyLoginSuccess) => {
    dispatch(loginByMyLogin(data)).then((res) => {
      if (loginByMyLogin.fulfilled.match(res)) {
        dispatch(getCurrentUser())
      }
    })
  }, [])

  return (
    <StyledLoginFormWrapper>
      <LogoMobile />
      <Formik
        onSubmit={onSubmit}
        initialValues={loginValues}
        validationSchema={loginValidationSchema}
      >
        <StyledForm>
          <FormInput
            label={t('emailUser')}
            name={'login'}
            width={'100%'}
            placeholder={t('yourEmail')}
          />
          <FormOutlinedInput
            label={t('password')}
            name={'password'}
            type={isPasswordVisible ? 'text' : 'password'}
            width={'100%'}
            placeholder={t('yourPassword')}
            iconPath={'/assets/eye-icon.svg'}
            iconAlt={'Eye icon'}
            iconOnClick={changePasswordVisibility}
          />
          <Button
            type={'submit'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
          >
            {t('login')}
          </Button>
          {i18n.language.includes('en') && (
            <MyLoginOAuth2 onSuccess={onSuccessMyLogin} />
          )}
          <StyledLink to={APP_ROUTES.FORGOT_PASSWORD}>{t('forgot')}</StyledLink>
        </StyledForm>
      </Formik>
    </StyledLoginFormWrapper>
  )
}

export default Login
