import React, { memo, useCallback, useRef } from 'react'
import { useState } from 'react'
import { StyledImgLoader, StyledImgWrapper, StyledImg } from './styles'
import CustomLoader from '../CustomLoader'
import { ICustomImageProps } from './types'

function CustomImage(props: ICustomImageProps) {
  const {
    src,
    width,
    alt,
    height,
    borderRadius,
    errorSrc = '',
    isContained = false,
    className
  } = props
  const [loading, setLoading] = useState(true)
  const imgRef = useRef<HTMLImageElement>(null)
  const [isError, setIsError] = useState(false)
  const onErrorHandler = useCallback(() => {
    if (isError || !imgRef.current) return

    setIsError(true)
    imgRef.current.src = errorSrc
  }, [isError])
  return (
    <StyledImgWrapper className={className} width={width} height={height}>
      <StyledImg
        isContained={isContained}
        src={src}
        borderRadius={borderRadius}
        height={height}
        display={loading ? 'none' : 'block'}
        alt={alt}
        onLoad={() => {
          setLoading(false)
        }}
        ref={imgRef}
        onError={() => {
          setLoading(false)
          onErrorHandler()
        }}
      />
      <StyledImgLoader display={loading ? 'block' : 'none'}>
        <CustomLoader />
      </StyledImgLoader>
    </StyledImgWrapper>
  )
}

export default memo(CustomImage)
