import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import {
  Button,
  Pagination,
  PaginationItem,
  TableHead,
  Paper,
  Typography
} from '@mui/material'
import { DatePicker } from 'antd'
const { RangePicker } = DatePicker
export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.customColors.bg,
    color: theme.palette.common.black,
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '12px'
  },
  border: 0,
  '&:last-child': {
    textAlign: 'center'
  }
}))

export const StyledTableBodyCell = styled(TableCell)(() => ({
  border: 0,
  wordBreak: 'normal',
  overflow: 'hidden',
  whiteSpace: 'normal',
  textOverflow: 'ellipsis',
  boxSizing: 'border-box',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  '&:last-of-type': {
    textAlign: 'center'
  }
}))

export const StyledTableRow = styled(TableRow)(({ theme, onClick }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.customColors.white
  },
  '&:nth-of-type(1)': {
    'td:first-of-type': {
      borderRadius: '16px 0 0 0'
    },
    'td:last-of-type': {
      borderRadius: '0 16px 0 0'
    }
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.customColors.bg
  },
  '&:hover': {
    cursor: onClick ? 'pointer' : '',
    boxShadow: onClick ? '0px 2px 8px 0px rgba(55, 82, 102, 0.16)' : '',
    transform: onClick ? 'translateY(-2px)' : '',
    transition: onClick ? 'transform 0.2s ease-in-out' : ''
  }
}))

export const StyledTableHead = styled(TableHead)(() => ({
  '&:after': {
    content: "''",
    display: 'block',
    height: '16px'
  }
}))

export const StyledPaper = styled(Paper)(({ theme }) => ({
  minWidth: 250,
  border: 'none',
  boxShadow: 'none',
  borderRadius: '16px',
  backgroundColor: theme.palette.customColors.bg
}))

export const StyledTableWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.customColors.bg,
  border: `1px solid ${theme.palette.customColors.bg}`,
  borderRadius: '24px',
  boxSizing: 'border-box',
  padding: '24px'
}))

export const StyledPaginationWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '24px'
}))

export const StyledWrapper = styled('div')(() => ({}))

export const StyledTableActionsWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px'
}))

export const StyledTableSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
  color: theme.palette.customColors.gray
}))

export const StyledTableFiltersWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '12px'
}))

export const StyledButton = styled(Button)(() => ({
  boxSizing: 'border-box',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  width: 'fit-content',
  padding: '10px 32px',
  '&:first-of-type': {
    marginRight: '12px'
  }
}))

export const StyledButtonWrapper = styled('div')(() => ({
  display: 'flex',
  position: 'absolute',
  right: '24px',
  top: '119px'
}))

export const StyledRangePicker = styled(RangePicker)(({ theme }) => ({
  marginRight: '12px',
  borderRadius: '24px',
  backgroundColor: theme.palette.customColors.bg,
  borderColor: theme.palette.customColors.muiDarkGrey,
  '&:hover': {
    borderColor: `${theme.palette.customColors.black}!important`
  },
  '&.ant-picker': {
    border: `1px solid ${theme.palette.customColors.muiDarkGrey}`,
    transition: 'none'
  },
  '&.ant-picker-focused': {
    borderColor: `${theme.palette.customColors.violet}!important`,
    border: '2px solid',
    boxShadow: 'none',
    padding: '2px 10px 2px',
    transition: 'none'
  }
}))

export const StyledPagination = styled(Pagination)(() => ({
  '& ul': {
    gap: '24px'
  }
}))

export const StyledPaginationItem = styled(PaginationItem)(
  ({ theme, selected, type }) => {
    const isNoneBorder =
      type &&
      [
        'first',
        'last',
        'next',
        'previous',
        'start-ellipsis',
        'end-ellipsis'
      ].includes(type)

    const border = isNoneBorder
      ? 'none'
      : `1px solid ${
          selected
            ? theme.palette.customColors.blueHover
            : theme.palette.customColors.linear
        } !important`

    const background = `${
      selected ? 'white' : theme.palette.customColors.bg
    } !important`

    const color = `${
      selected
        ? theme.palette.customColors.blue
        : theme.palette.customColors.gray
    } !important`

    return {
      border,
      background,
      color,
      margin: 0,
      padding: 0,
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '20px'
    }
  }
)
