import React, { memo, useCallback } from 'react'

import Loader from '../../../../components/Loader'
import { APP_ROUTES } from '../../../../core/router/appRoutes'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import StudentForm from '../StudentForm'
import {
  StyledBackButton,
  StyledPageContentWrapper,
  StyledTitle,
  StyledTitleWrapper
} from '../../styles'
import { IStudentEditWrapperProps } from '../../types'

const StudentEditWrapper = memo<IStudentEditWrapperProps>(
  ({ isProfileLoading, isLoading, profile, onSubmit, schools, classes }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const handleBackButton = useCallback(() => {
      navigate(`${APP_ROUTES.STUDENTS}`)
    }, [])

    return (
      <StyledPageContentWrapper>
        <StyledTitleWrapper>
          <StyledBackButton
            aria-label={'Previous page button'}
            onClick={handleBackButton}
          />
          <StyledTitle variant={'h2'}>{t('studentsList')}</StyledTitle>
        </StyledTitleWrapper>
        {isProfileLoading && <Loader />}
        {!isProfileLoading && profile && (
          <StudentForm
            onSubmit={onSubmit}
            isLoading={isLoading}
            schools={schools}
            classes={classes}
            initialValues={{
              id: profile.id,
              username: profile.username,
              avatarPath: profile.avatarUrl,
              fullName: profile.fullName,
              email: profile.email,
              schoolIds: profile.schools?.map((school) => school.id),
              classIds: profile.classes?.map((classItem) => classItem.id)
            }}
          />
        )}
      </StyledPageContentWrapper>
    )
  }
)

export default StudentEditWrapper
