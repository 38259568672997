import { basicString } from '../../../validation/primitives'
import { TFunction } from 'i18next'
import validator from 'validator'

type TType = TFunction

const changeProfileInfoSchema = (t: TType) => ({
  fullName: basicString.required(),
  email: basicString
    .email()
    .required()
    .test((value) => validator.isEmail(value))
})

export default changeProfileInfoSchema
