import { IMAGE_MAX_WEIGHT } from '../../../utils/constants'

export const filesSettings = {
  maxSize: IMAGE_MAX_WEIGHT,
  accept: {
    'image/jpeg': [],
    'image/jpg': [],
    'image/png': []
  }
}
