import { styled } from '@mui/system'
import { Typography } from '@mui/material'

export const StyledPageContentWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: '44px',
  backgroundColor: theme.palette.customColors.bg,
  border: `1px solid ${theme.palette.customColors.bg}`,
  borderRadius: '24px',
  boxSizing: 'border-box',
  padding: '24px'
}))

export const StyledTitleWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '16px'
}))

export const StyledBackButton = styled('button')(({ theme }) => ({
  border: 'none',
  backgroundColor: 'transparent',
  '&:before': {
    content: 'url(/assets/arrow-left-icon.svg)',
    cursor: 'pointer'
  }
}))

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px'
}))

export const StyledFormContainer = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '192px 328px auto',
  gridAutoRows: 'auto',
  width: '100%',
  boxSizing: 'border-box',
  marginTop: 32,
  overflow: 'hidden',
  gap: '24px',
  '@media (max-width: 1140px)': {
    gap: '12px',
    gridTemplateColumns: '172px calc(100% - 172px - 12px)'
  },
  '@media (max-width: 600px)': {
    gap: '24px',
    gridTemplateColumns: '100%',
    gridTemplateRows: '1fr auto auto'
  }
}))

export const StyledFormMainContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  maxWidth: 350,
  gap: '12px',
  '@media (max-width: 1140px)': {
    maxWidth: 'none',
    width: '100%'
  }
}))

export const StyledFormSupportContainer = styled('div')(() => ({
  marginLeft: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  '@media (max-width: 1140px)': {
    gridColumnStart: 2,
    gridColumnEnd: 3,
    marginLeft: 0
  },
  '@media (max-width: 600px)': {
    width: '100%',
    gridColumnStart: 1,
    gridColumnEnd: 2
  }
}))

export const StyledFormSupportContainerBottom = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  gap: '16px',
  '@media (max-width: 1140px)': {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    width: '100%'
  },
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    gap: '24px'
  }
}))
