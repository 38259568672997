import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import CustomModal from '../../../../components/CustomModal'
import { IAssignActivityModalProps } from './types'
import {
  getCustomChipOptions,
  getDateFormatByLanguage,
  getModalTitle,
  getSubtitle
} from '../../../../utils/helpers'
import {
  StyledActivityDescription,
  StyledButton,
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledInfoItem,
  StyledInfoItemWrapper,
  StyledSwitch
} from './styles'
import { CustomChip } from '../../../../components/CustomChip'
import { getClassesSelector } from '../../../MyClasses/selectors'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import CustomSelect from '../../../../components/CustomSelect'
import {
  assignToActivity,
  deAssignFromActivity,
  getStudentsForClass
} from '../../services'
import { getStudentsForClassSelector } from '../../selectors'
import { DatePicker, Space } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import CustomOutlinedInput from '../../../../components/CustomOutlinedInput'
import { ANTD_LOCALES } from '../../../../utils/constants'
import { useTranslation } from 'react-i18next'
import customToast from '../../../../components/CustomToast'
import { AssignmentTypes } from '../../../UnitsByCourse/types'
import StudentClassSelector from '../../../../components/StudentClassSelector'
import { StyledInfoWrapper } from '../../../../common/styles'
import { getClasses } from '../../../../core/services'
import i18n from 'i18next'
import { LOCALES } from '../../../../types'

const { RangePicker } = DatePicker
function AssignActivityModal(props: IAssignActivityModalProps) {
  const dispatch = useAppDispatch()
  const classes = useAppSelector(getClassesSelector)
  const students = useAppSelector(getStudentsForClassSelector)
  const {
    title,
    level,
    description,
    isOpen,
    handleClose,
    courseId,
    activityId,
    assignmentType
  } = props
  const [classCourse, setClassCourse] = useState('')
  const [studentCourse, setStudentCourse] = useState<string>()
  const [isDeadline, setIsDeadline] = useState(true)
  const [isTimer, setIsTimer] = useState(true)
  const [isNotification, setIsNotification] = useState(true)
  const [timeType, setTimeType] = useState('minutes')
  const [timeCount, setTimeCount] = useState('')
  const [startDate, setStartDate] = useState<string | Dayjs>()
  const [endDate, setEndDate] = useState<string | Dayjs>()
  const { t } = useTranslation()

  const timeOptions = [
    {
      name: t('minutes'),
      value: 'minutes'
    },
    {
      name: t('hours'),
      value: 'hours'
    }
  ]

  const chipOptions = getCustomChipOptions(level)

  const handleClassChange = useCallback(
    (selectClass: string) => {
      if (classCourse === selectClass) {
        return
      }
      setClassCourse(selectClass)
      setStudentCourse('reset')
    },
    [classCourse]
  )

  useEffect(() => {
    if (!classCourse || classCourse === 'reset') {
      return
    }
    dispatch(getStudentsForClass({ id: classCourse }))
  }, [classCourse])

  useEffect(() => {
    dispatch(
      getClasses({
        page: 1,
        filters: [`course:${courseId}`],
        sort: 'name:asc'
      })
    )
  }, [courseId])

  const handleStudentChange = useCallback(
    (selectStudent: string) => {
      if (studentCourse === selectStudent) {
        return
      }
      setStudentCourse(selectStudent)
    },
    [studentCourse]
  )

  const handleTimerChange = (selectTimer: string) => {
    if (timeType === selectTimer) {
      return
    }
    setTimeType(selectTimer)
  }

  const handleDateChange = (date: [Dayjs, Dayjs]) => {
    if (!date || (startDate === date[0] && endDate === date[1])) {
      return
    }
    setStartDate(dayjs(date[0]).format('YYYY-MM-DD'))
    setEndDate(dayjs(date[1]).format('YYYY-MM-DD'))
  }

  const getTimer = () => {
    if (timeCount) {
      let timer
      switch (timeType) {
        case 'minutes':
          timer = Number(timeCount) * 60
          break
        case 'hours':
          timer = Number(timeCount) * 3600
          break
        default:
          timer = 0
      }
      return timer
    }
  }

  const timer = getTimer()

  const handleAssignToActivity = useCallback(() => {
    if (activityId) {
      dispatch(
        assignToActivity({
          activityId: activityId,
          classId: classCourse,
          studentId: studentCourse !== 'reset' ? studentCourse : undefined,
          deadlineStart: startDate?.toString(),
          deadlineEnd: endDate?.toString(),
          timer: timer,
          sendNotification: isNotification
        })
      ).then((res) => {
        if (assignToActivity.fulfilled.match(res)) {
          customToast({
            message: 'Activity assigned successfully',
            type: 'success'
          })
        }
      })
      handleClose()
    }
  }, [
    activityId,
    classCourse,
    dispatch,
    endDate,
    handleClose,
    isNotification,
    startDate,
    studentCourse,
    timer
  ])

  const handleDeAssignFromActivity = useCallback(() => {
    if (activityId) {
      dispatch(
        deAssignFromActivity({
          activityId: activityId,
          classId: classCourse,
          studentId: studentCourse !== 'reset' ? studentCourse : undefined,
          sendNotification: isNotification
        })
      ).then((res) => {
        if (deAssignFromActivity.fulfilled.match(res)) {
          customToast({
            message: 'Activity de-assigned successfully',
            type: 'success'
          })
        }
      })
      handleClose()
    }
  }, [
    activityId,
    classCourse,
    dispatch,
    handleClose,
    isNotification,
    studentCourse
  ])

  const handleSubmitModal = useCallback(() => {
    if (assignmentType === AssignmentTypes.assignment) {
      handleAssignToActivity()
    } else if (assignmentType === AssignmentTypes.deAssignment) {
      handleDeAssignFromActivity()
    }
  }, [assignmentType, handleAssignToActivity, handleDeAssignFromActivity])

  const modalTitle = useMemo(
    () => getModalTitle(assignmentType, t),
    [assignmentType, t]
  )

  const subtitle = useMemo(
    () => getSubtitle(assignmentType, t),
    [assignmentType, t]
  )

  return (
    <CustomModal
      open={isOpen}
      width={'444px'}
      title={`${modalTitle} ${title}`}
      handleClose={handleClose}
    >
      <StyledContentWrapper>
        <StyledActivityDescription>{description}</StyledActivityDescription>
        <StyledInfoWrapper>
          <StyledInfoItem>{t('level')}</StyledInfoItem>
          <CustomChip
            aria-label={'Unit level'}
            bgColor={chipOptions?.bgColor}
            label={chipOptions?.name}
            textColor={chipOptions?.textColor}
            borderColor={chipOptions?.borderColor}
            height={'20px'}
          />
        </StyledInfoWrapper>
        <StyledInfoItemWrapper>
          <StyledInfoItem>{subtitle}</StyledInfoItem>
        </StyledInfoItemWrapper>
        <StudentClassSelector
          classCourse={classCourse}
          studentCourse={studentCourse}
          classes={classes}
          students={students}
          handleClassChange={handleClassChange}
          handleStudentChange={handleStudentChange}
        />
        {assignmentType === AssignmentTypes.assignment && (
          <>
            <StyledInfoWrapper>
              <StyledInfoItem>{t('deadlineTask')}</StyledInfoItem>
              <StyledSwitch
                defaultChecked
                inputProps={{ 'aria-label': 'Switch deadline' }}
                onChange={() => setIsDeadline(!isDeadline)}
              />
            </StyledInfoWrapper>
            <StyledInfoItemWrapper>
              <Space direction="vertical" size={14}>
                <RangePicker
                  placeholder={[t('from'), t('to')]}
                  popupStyle={{ zIndex: '2000' }}
                  format={getDateFormatByLanguage(i18n.language)}
                  disabled={!isDeadline}
                  locale={ANTD_LOCALES[i18n.language as LOCALES]}
                  onChange={(value) =>
                    handleDateChange(value as [Dayjs, Dayjs])
                  }
                />
              </Space>
            </StyledInfoItemWrapper>
            <StyledInfoWrapper>
              <StyledInfoItem>{t('setTimer')}</StyledInfoItem>
              <StyledSwitch
                defaultChecked
                inputProps={{ 'aria-label': 'Switch timer' }}
                onChange={() => setIsTimer(!isTimer)}
              />
            </StyledInfoWrapper>
            <StyledInfoWrapper>
              <CustomSelect
                aria-label={'Select time'}
                value={timeType}
                options={timeOptions}
                onChange={(event) =>
                  handleTimerChange(event.target.value as string)
                }
                width={'140px'}
                disabled={!isTimer}
                marginRight={'8px'}
              />
              <CustomOutlinedInput
                value={timeCount}
                onChange={(val) => {
                  setTimeCount(val.replace(/^0|[^\d]/g, ''))
                }}
                adornmentBg={'bg'}
                disabled={!isTimer}
                width={'140px'}
              />
            </StyledInfoWrapper>
          </>
        )}
        <StyledInfoWrapper>
          <StyledInfoItem>{t('sendNotification')}</StyledInfoItem>
          <StyledSwitch
            defaultChecked
            inputProps={{ 'aria-label': 'Switch notification' }}
            onChange={() => setIsNotification(!isNotification)}
          />
        </StyledInfoWrapper>
        <StyledButtonsWrapper>
          <StyledButton
            type={'button'}
            size={'large'}
            variant={'outlined'}
            color={'primary'}
            onClick={handleClose}
          >
            {t('cancel')}
          </StyledButton>
          <StyledButton
            type={'submit'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={handleSubmitModal}
            disabled={!classCourse || classCourse === 'reset'}
          >
            {t('confirm')}
          </StyledButton>
        </StyledButtonsWrapper>
      </StyledContentWrapper>
    </CustomModal>
  )
}

export default memo(AssignActivityModal)
