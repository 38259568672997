import React, { memo, useMemo } from 'react'
import { LOGO_SIZE } from './constants'
import useWindowSize from '../../../../../utils/hooks'
import { BREAKPOINTS } from '../../../../../utils/constants'
import { CustomSvgIcon } from '../../../../../components/CustomSvgIcon'

const Logo = () => {
  const [windowWidth] = useWindowSize()
  const logoSize = useMemo(() => {
    if (windowWidth <= BREAKPOINTS.mobile) {
      return LOGO_SIZE.mobile
    } else if (windowWidth <= BREAKPOINTS.tabletLg) {
      return LOGO_SIZE.tablet
    }
    return LOGO_SIZE.web
  }, [windowWidth])
  const { width, height } = logoSize

  return (
    <CustomSvgIcon
      path={'/assets/logo.svg'}
      width={width}
      height={height}
      title={'Beyond Words Logo'}
    />
  )
}

export default memo(Logo)
