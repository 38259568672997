import { styled } from '@mui/system'
import { Typography } from '@mui/material'

export const StyledHeaderWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '16px'
}))

export const StyledTitleWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const StyledBackButton = styled('button')(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.customColors.white,
  '&:before': {
    content: 'url(/assets/arrow-left-icon.svg)',
    cursor: 'pointer'
  }
}))

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px'
}))
