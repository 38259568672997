import { FC, memo } from 'react'
import { StyledFormRow, StyledFormRowLabel } from './styles'
import { IFormRowProps } from './types'

const FormRow: FC<IFormRowProps> = memo(({ type, children, label }) => {
  return (
    <StyledFormRow type={type}>
      <StyledFormRowLabel>{label}</StyledFormRowLabel>
      {children}
    </StyledFormRow>
  )
})

export default FormRow
