import { CSSProperties, FC, StyleHTMLAttributes, memo } from 'react'
import { TagsWrapper } from './styles'
import { GrammarTags, SyntaxTags, VocabTags } from '../../types'
import { getCustomChipTagOptions } from '../../utils/helpers'
import { CustomChip } from '../CustomChip'

interface Props {
  tags: (VocabTags | GrammarTags | SyntaxTags)[]
  style?: CSSProperties
}

const Tags: FC<Props> = ({ tags, style }) => {
  return (
    <TagsWrapper style={style}>
      {tags.map((tag) => {
        const tagOptions = getCustomChipTagOptions(tag)

        return (
          <CustomChip {...tagOptions} aria-label="Activity tag" height="20px" />
        )
      })}
    </TagsWrapper>
  )
}

export default memo(Tags)
