import { styled as muiStyled } from '@mui/system'
import { Typography } from '@mui/material'

export const StyledHomePage = muiStyled('div')(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
  gap: '50px',
  '@media (max-width: 1140px)': {
    flexDirection: 'column',
    gap: '12px'
  }
}))
export const StyledActivities = muiStyled('div')(() => ({
  width: '49%',
  display: 'flex',
  '@media (max-width: 1140px)': {
    width: '100%'
  }
}))

export const StyledUpcomingActivities = muiStyled('div')(() => ({
  display: 'flex',
  flexFlow: 'row wrap',
  alignContent: 'flex-start',
  height: '100%',
  width: '100%',
  justifyContent: 'space-between',
  '@media (max-width: 1140px)': {
    flexDirection: 'column'
  }
}))

export const StyledCourseCards = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.customColors.white
}))

export const StyledActivitiesBlock = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '70%',
  '@media (max-width: 1140px)': {
    width: '100%'
  }
}))

export const StyledBlock = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '24px'
}))

export const StyledBlockCourse = muiStyled(StyledBlock)(() => ({
  paddingRight: '0px',
  width: '30%',
  '@media (max-width: 1140px)': {
    width: '100%'
  }
}))

export const StyledTitle = muiStyled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  marginBottom: '16px'
}))

export const StyledInfoText = muiStyled(Typography)(() => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  wordBreak: 'break-all',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}))
