import { createAsyncThunk } from '@reduxjs/toolkit'
import { get, post } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import {
  ICoursesList,
  IErrorResponse,
  IGetCoursesPayload
} from '../../../types'
import { CLASSES_PAGE_SIZE } from '../../../utils/constants'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const getStudentCourses = createAsyncThunk<
  ICoursesList,
  IGetCoursesPayload,
  ICommonChunkConfig
>('courses/fetchStudentCourses', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.STUDENT_COURSES,
      params: {
        pageSize: CLASSES_PAGE_SIZE,
        ..._data
      }
    })
    return response as ICoursesList
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
