import { styled as muiStyled } from '@mui/system'
import { NavLink } from 'react-router-dom'
import { colors } from '../../../../../../../../types'

export const StyledBottomMenuItem = muiStyled('div')<any>(
  ({ theme, isActive }) => ({
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: '8px 16px',
    width: '100%',
    backgroundColor: isActive ? theme.palette.customColors.blue : '',
    borderRadius: '20px',
    '@media (max-width: 1140px)': {
      width: 'fit-content',
      padding: '8px',
      minWidth: '56px',
      justifyContent: 'center'
    },
    '@media (max-width: 600px)': {
      boxSizing: 'border-box',
      padding: '4px 12px',
      minWidth: '0',
      width: 'auto'
    }
  })
)

export const StyledNavLink = muiStyled(NavLink)(() => ({
  textDecoration: 'none',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  fontSize: '12px',
  gap: '8px',
  '@media (max-width: 1140px)': {
    justifyContent: 'center'
  },
  '@media (max-width: 600px)': {
    justifyContent: 'flex-start'
  }
}))
