import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { getTicketsCountSelector, getTicketsSelector } from '../../selectors'
import CustomTable from '../../../../components/CustomTable'
import { CLASSES_PAGE_SIZE } from '../../../../utils/constants'
import NotificationText from '../../../Notifications/components/NotificationText'
import {
  formatDateByLocale,
  getCustomChipStatusOptions
} from '../../../../utils/helpers'
import { ISortCallbackParams } from '../../../../types'
import { getTickets } from '../../services'
import { CustomChip } from '../../../../components/CustomChip'
import { TicketStatus } from '../../types'

function TicketsTable() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const tickets = useAppSelector(getTicketsSelector)
  const ticketsCount = useAppSelector(getTicketsCountSelector)

  const prepareTableHead = useMemo(() => {
    return [
      {
        name: `№ ${t('ticket')}`,
        ariaLabel: 'Sender name',
        cellName: 'ticketNumber',
        sortable: false
      },
      {
        name: t('category'),
        ariaLabel: 'Text of notification',
        cellName: 'categoryName',
        sortable: false
      },
      {
        name: t('commentary'),
        ariaLabel: 'Commentary',
        cellName: 'message',
        sortable: false
      },
      {
        name: t('sentOn'),
        ariaLabel: 'Sent date',
        cellName: 'createdAt',
        sortable: false
      },
      {
        name: t('status'),
        ariaLabel: 'Status',
        cellName: 'status',
        sortable: false
      }
    ]
  }, [t])

  const prepareTableData = useMemo(() => {
    if (!tickets) return []

    return tickets.map((ticket) => {
      const chipOptions = getCustomChipStatusOptions(ticket.status)
      return {
        ticketNumber: ticket.ticketNumber,
        categoryName: <NotificationText text={ticket.category.name} />,
        message: <NotificationText text={ticket.message} />,
        createdAt: formatDateByLocale(ticket.createdAt),
        status: (
          <CustomChip
            aria-label={'Status label'}
            bgColor={chipOptions?.bgColor}
            label={chipOptions?.name}
            textColor={chipOptions?.textColor}
            borderColor={chipOptions?.borderColor}
            height={'20px'}
          />
        )
      }
    })
  }, [tickets])

  const sortCallbackHandler = (params: ISortCallbackParams) => {
    dispatch(getTickets(params))
  }

  const getTableCourseFiltersData = () => {
    const tableResultsFiltersData: {
      status: { value: string; name: string }[]
    } = {
      status: [
        { value: TicketStatus.solved, name: 'Solved' },
        { value: TicketStatus.unsolved, name: 'Unsolved' },
        { value: TicketStatus.pending, name: 'Pending' }
      ]
    }

    return tableResultsFiltersData
  }

  return (
    <>
      {tickets && (
        <CustomTable
          tableAriaLabel={'Ask For Assistance table'}
          dataCount={ticketsCount}
          tableHead={prepareTableHead}
          rowsCount={CLASSES_PAGE_SIZE}
          rows={prepareTableData}
          defaultSortColumn={'name'}
          sortCallback={sortCallbackHandler}
          tableFiltersData={getTableCourseFiltersData()}
          tableSubtitle={t('youHaveEntityAndName', {
            entityCount: ticketsCount,
            entityName: t('asks', { count: ticketsCount }).toLowerCase()
          })}
          searchPlaceholder={t('searchByTickets')}
        />
      )}
    </>
  )
}

export default memo(TicketsTable)
