import { styled } from '@mui/system'
import { Button, Typography } from '@mui/material'

export const StyledProfile = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: '44px',
  borderRadius: '16px',
  backgroundColor: theme.palette.customColors.bg,
  boxSizing: 'border-box',
  padding: '24px'
}))

export const StyledTitleWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '16px'
}))

export const StyledBackButton = styled('button')(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.customColors.bg,
  '&:before': {
    content: 'url(/assets/arrow-left-icon.svg)',
    cursor: 'pointer'
  }
}))

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px'
}))

export const StyledChangeAvatarButton = styled('button')(({ theme }) => ({
  marginTop: '8px',
  border: 'none',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.palette.customColors.gray,
  backgroundColor: theme.palette.customColors.bg,
  '&:hover': {
    cursor: 'pointer'
  }
}))

export const StyledEditInfoButton = styled(Button)(({ theme }) => ({
  marginBottom: '24px',
  display: 'flex',
  alignItems: 'center',
  width: '202px',
  boxSizing: 'border-box',
  marginLeft: 'auto',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.palette.customColors.gray,
  '&::after': {
    marginLeft: '8px',
    marginTop: '4px',
    content: 'url(/assets/pencil-icon.svg)'
  }
}))

export const StyledSaveChangesButton = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '202px',
  boxSizing: 'border-box',
  marginLeft: 'auto',
  '&:hover': {
    cursor: 'pointer'
  }
}))

export const StyledCancelChangesButton = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '154px',
  boxSizing: 'border-box',
  marginLeft: 'auto'
}))

export const StyledProfileInfoWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

export const StyledAvatarWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content'
}))

export const StyledProfileInfo = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingTop: '16px'
}))

export const StyledPersonalInfo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '328px',
  width: '100%'
}))

export const StyledPersonalInfoWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%'
}))

export const StyledStudentInfo = styled('div')(() => ({
  display: 'flex',
  '&:first-of-type': {
    marginBottom: '24px'
  }
}))

export const StyledStudentInfoWrapper = styled('div')(() => ({}))

export const StyledInfoTitle = styled(Typography)<{
  marginRight?: string
  width?: string
}>(({ theme, marginRight, width }) => ({
  marginRight: marginRight ? marginRight : '0',
  width: width ? width : '',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
  color: theme.palette.customColors.grayMedium
}))

export const StyledInfoDescriptionWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const StyledInfoDescription = styled(Typography)<{
  marginTop?: string
  marginLeft?: string
}>(({ theme, marginTop, marginLeft }) => ({
  marginTop: marginTop ? marginTop : '0',
  marginLeft: marginLeft ? marginLeft : '0',
  marginRight: '8px',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
  color: theme.palette.customColors.black
}))

export const StyledName = styled(Typography)(() => ({
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '120%',
  marginBottom: '12px'
}))

export const StyledEmail = styled(Typography)(({ theme }) => ({
  color: theme.palette.customColors.gray,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '16px',
  marginBottom: '12px'
}))

export const StyledEditButtonsWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginLeft: '16px',
  height: '100%'
}))

export const StyledLoaderWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '218px',
  width: '100%'
}))
