import React, { memo } from 'react'
import CustomModal from '../CustomModal'
import { ISendMessageModalProps } from './types'
import FormInput from '../FormInput'
import { Formik, FormikValues } from 'formik'
import { getDefaultFormValues } from '../../utils/helpers'
import sendMessageSchema from './schemas'
import v from '../../validation/validation'
import { useTranslation } from 'react-i18next'
import { StyledButton, StyledButtonsWrapper, StyledForm } from './styles'

export const messageFields = ['text']
export const messageValues = getDefaultFormValues({}, messageFields)

function SendMessageModal(props: ISendMessageModalProps) {
  const {
    isSendMessageModalOpen,
    handleCloseModal,
    title = 'Send message to class',
    placeholder = 'Message to class...',
    onSubmit,
    isLoading
  } = props

  const { t } = useTranslation()

  const validationSchema = v.utils.createPartialObjectSchema(
    sendMessageSchema(t),
    messageFields
  )

  const handleSubmit = (values: FormikValues) => {
    onSubmit(values.text)
  }

  return (
    <CustomModal
      width={'458px'}
      open={isSendMessageModalOpen}
      title={title}
      handleClose={handleCloseModal}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={messageValues}
        validationSchema={validationSchema}
      >
        <StyledForm>
          <FormInput
            name={'text'}
            width={'458px'}
            placeholder={placeholder}
            multiline={true}
          />
          <StyledButtonsWrapper>
            <StyledButton
              type={'button'}
              size={'large'}
              variant={'outlined'}
              color={'primary'}
              onClick={handleCloseModal}
            >
              {t('cancel')}
            </StyledButton>
            <StyledButton
              type={'submit'}
              size={'large'}
              variant={'contained'}
              color={'primary'}
            >
              {t(isLoading ? 'sending' : 'sendMessage')}
            </StyledButton>
          </StyledButtonsWrapper>
        </StyledForm>
      </Formik>
    </CustomModal>
  )
}

export default memo(SendMessageModal)
