import { styled } from '@mui/system'
import { Button, Switch, Typography } from '@mui/material'

export const StyledContentWrapper = styled('div')(() => ({
  width: '444px'
}))

export const StyledInfoItemWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '16px',
  alignItems: 'baseline'
}))

export const StyledInfoItem = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.palette.customColors.black,
  marginRight: '24px'
}))

export const StyledButtonsWrapper = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  marginTop: '24px'
}))

export const StyledButton = styled(Button)(() => ({
  '&:nth-of-type(1)': {
    flex: 1
  },
  '&:nth-of-type(2)': {
    flex: 2,
    marginLeft: '12px'
  }
}))

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#2626EA',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#F0F7FC',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 13,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1
  }
}))
