import { RootState } from '../../../store'

export const getStudentResultsSelector = (state: RootState) =>
  state.results.studentResults

export const getTeacherResultsSelector = (state: RootState) =>
  state.results.teacherResults

export const getResultsCountSelector = (state: RootState) => state.results.count

export const getAllCoursesSelector = (state: RootState) => state.results.courses
export const getAllClassesSelector = (state: RootState) => state.results.classes

export const getStudentResultDetailsSelector = (state: RootState) =>
  state.results.resultDetails
export const getStudentResultDetailsCountSelector = (state: RootState) =>
  state.results.resultDetailsCount
