import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getStudentActivities, getTeacherActivities } from './services'
import {
  getActivitiesCountSelector,
  getStudentActivitiesSelector,
  getTeacherActivitiesSelector,
  getUnitNameSelector
} from './selectors'
import { getCurrentUserSelector } from '../../core/selectors'
import { useLocation, useParams } from 'react-router-dom'
import {
  BREAKPOINTS,
  CLASSES_PAGE_SIZE,
  PRE_SELECTED_ACTIVITY_KEY
} from '../../utils/constants'
import { UserType } from '../Login/types'
import {
  FullWidthActionButton,
  RowTextRight,
  StyledActionButton,
  StyledButtonsWrapper,
  StyledPageContentWrapper
} from './styles'
import { formatDateByLocale, getCustomChipOptions } from '../../utils/helpers'
import { CustomChip } from '../../components/CustomChip'
import UnitDeadline from './components/UnitDeadline'
import UnitName from '../../components/UnitName'
import { AssignToActivity, TableColumn } from './types'
import StartActivityModal from './components/StartActivity'
import { ActivityToStart, ISortCallbackParams } from '../../types'
import AssignActivityModal from './components/AssignActivity'
import { useTranslation } from 'react-i18next'
import { getUnitInfo } from '../UnitInfo/services'
import {
  getUnitInfoIsPendingSelector,
  getUnitInfoSelector
} from '../UnitInfo/selectors'
import CustomTitleDescription from '../../components/CustomTitleDescription'
import AssignUnitModal from './components/AssignUnitModal'
import { AssignmentType, AssignmentTypes } from '../UnitsByCourse/types'
import { clearState } from './slices'
import CustomTableMobile from '../../components/CustomTableMobile'
import useWindowSize from '../../utils/hooks'
import CustomTable from '../../components/CustomTable'
import MobileRow from '../../components/CustomTableMobile/components/MobileRow'
import UnitTitle from './components/UnitTitle'
import Tags from '../../components/Tags'
import PreviewModal from './components/PreviewModal'

function UnitDetails() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const preSelectedActivityId = queryParams.get(PRE_SELECTED_ACTIVITY_KEY)

  const { unitId, id: courseId } = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [windowWidth] = useWindowSize()
  const isTablet = useMemo(
    () => windowWidth <= BREAKPOINTS.tabletSm,
    [windowWidth]
  )
  const studentUnitDetails = useAppSelector(getStudentActivitiesSelector)
  const teacherUnitDetails = useAppSelector(getTeacherActivitiesSelector)
  const activitiesCount = useAppSelector(getActivitiesCountSelector)
  const unitInfo = useAppSelector(getUnitInfoSelector)
  const isPendingUnitInfo = useAppSelector(getUnitInfoIsPendingSelector)
  const unitName = useAppSelector(getUnitNameSelector)
  const currentUser = useAppSelector(getCurrentUserSelector)

  // activityStart
  const [activityToStart, setActivityToStart] =
    useState<ActivityToStart | null>(null)
  const [isStartActivityModalOpen, setIsStartActivityModalOpen] =
    useState(false)
  const [assignmentType, setAssignmentType] = useState<AssignmentType>()
  const [selectedActivityId, setSelectedActivityId] = useState<string | null>()
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState<boolean>(false)

  const handleCloseStartActivityModal = () => {
    setIsStartActivityModalOpen(false)
    setActivityToStart(null)
  }

  // assign to activity
  const [assignToActivity, setAssignToActivity] =
    useState<AssignToActivity | null>(null)
  const [isAssignActivityModalOpen, setIsAssignActivityModalOpen] =
    useState(false)
  const handleCloseAssignActivityModal = () => {
    setIsAssignActivityModalOpen(false)
    setAssignToActivity(null)
    setAssignmentType(undefined)
  }

  // assign to unit
  const [isAssignToUnitModalOpen, setIsAssignToUnitModalOpen] = useState(false)
  const handleCloseAssignToUnitModal = () => {
    setIsAssignToUnitModalOpen(false)
    setAssignmentType(undefined)
  }

  const handleAssignToUnitModalOpen = useCallback(
    (assignmentType: AssignmentTypes) => {
      setAssignmentType(assignmentType)
      setIsAssignToUnitModalOpen(true)
    },
    []
  )

  const handleAssignmentModalOpen = useCallback(
    (assignmentType: AssignmentTypes) => {
      setAssignmentType(assignmentType)
      setIsAssignActivityModalOpen(true)
    },
    []
  )

  useEffect(() => {
    unitId && dispatch(getUnitInfo(unitId))
  }, [unitId])

  useEffect(() => {
    if (currentUser?.type === UserType.student && unitId) {
      dispatch(
        getStudentActivities({ page: 1, sort: 'activity:asc', id: unitId })
      )
    } else if (currentUser?.type === UserType.teacher && unitId) {
      dispatch(
        getTeacherActivities({ page: 1, sort: 'activity:asc', id: unitId })
      )
    }
    return () => {
      dispatch(clearState())
    }
  }, [])

  const sortCallbackHandler = (params: ISortCallbackParams) => {
    if (currentUser?.type === UserType.student && unitId) {
      dispatch(getStudentActivities({ ...params, id: unitId }))
    } else if (currentUser?.type === UserType.teacher && unitId) {
      dispatch(getTeacherActivities({ ...params, id: unitId }))
    }
  }

  const togglePreviewModal = useCallback(
    (selectedActivityId: string | null) => {
      setSelectedActivityId(selectedActivityId)
      setIsOpenPreviewModal(!!selectedActivityId)
    },
    [setSelectedActivityId, setIsOpenPreviewModal]
  )

  const prepareTableHead = () => {
    const tableHead: TableColumn[] = [
      {
        name: t('activityName'),
        ariaLabel: 'Activity name',
        cellName: 'activity',
        sortable: true
      },
      {
        name: t('level'),
        ariaLabel: 'Unit level',
        cellName: 'level',
        cellAlign: 'center',
        sortable: true
      },
      {
        name: t('tags'),
        ariaLabel: 'Activity tags',
        cellName: 'tags',
        cellAlign: 'center',
        sortable: false
      }
    ]

    if (currentUser?.type === UserType.teacher) {
      tableHead.splice(1, 0, {
        name: t('preview'),
        ariaLabel: 'Preview button',
        cellName: 'preview',
        cellAlign: 'center',
        sortable: false
      })
      tableHead.push(
        {
          name: t('questions'),
          ariaLabel: 'Questions count',
          cellName: 'questionsCount',
          cellAlign: 'center',
          sortable: false
        },
        {
          name: t('assignActivity'),
          ariaLabel: 'Assign activity action',
          cellName: 'assignActivity',
          sortable: false
        },
        {
          name: t('deAssignActivity'),
          ariaLabel: 'De-assign activity action',
          cellName: 'deAssignActivity',
          sortable: false
        }
      )
    } else if (currentUser?.type === UserType.student) {
      tableHead.push(
        {
          name: t('deadline'),
          ariaLabel: 'Deadline for activity',
          cellName: 'deadline',
          cellAlign: 'center',
          sortable: false
        },
        {
          name: t('score'),
          ariaLabel: 'Your score',
          cellName: 'score',
          sortable: true
        },
        {
          name: t('startActivity'),
          ariaLabel: 'Start activity action',
          cellName: 'startActivity',
          cellAlign: 'center',
          sortable: false
        }
      )
    }
    return tableHead
  }

  const prepareTableHeadMobile = () => {
    const tableHead: TableColumn[] = [
      {
        name: t('activity'),
        ariaLabel: 'Activity',
        cellName: 'activity',
        sortable: true
      },
      {
        name: t('level'),
        ariaLabel: 'Unit level',
        cellName: 'level',
        cellAlign: 'center',
        sortable: true
      },
      {
        name: t('tags'),
        ariaLabel: 'Activity tags',
        cellName: 'tags',
        sortable: false
      }
    ]

    if (currentUser?.type === UserType.student) {
      tableHead.push({
        name: t('score'),
        ariaLabel: 'Your score',
        cellName: 'score',
        sortable: true
      })
    }
    return tableHead
  }

  const prepareTableData = () => {
    if (currentUser?.type === UserType.student && studentUnitDetails) {
      return studentUnitDetails.map((item) => {
        const chipOptions = getCustomChipOptions(item.level)
        const tags = [
          ...(item.grammarTags || []),
          ...(item.syntaxTags || []),
          ...(item.vocabTags || [])
        ]

        return {
          activity: <UnitName activityName={item.title} />,
          level: (
            <CustomChip
              aria-label={'Unit level'}
              bgColor={chipOptions?.bgColor}
              label={chipOptions?.name}
              textColor={chipOptions?.textColor}
              borderColor={chipOptions?.borderColor}
              height={'20px'}
            />
          ),
          tags: <Tags style={{ justifyContent: 'center' }} tags={tags} />,
          deadline: (
            <UnitDeadline
              deadlineStart={item.deadlineStart}
              deadlineEnd={item.deadlineEnd}
            />
          ),
          startActivity: (
            <StyledActionButton
              type={'submit'}
              size={'medium'}
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                setActivityToStart({
                  assignedActivityId: item.id,
                  activityId: item.activityId,
                  title: item.title,
                  level: item.level,
                  description: item.description,
                  questionsCount: item.questionsCount,
                  timer: item.timer,
                  logoUrl: item.unit.logoUrl,
                  courseId: item.course.id,
                  unitId: item.unit.id
                })
                setIsStartActivityModalOpen(true)
              }}
            >
              {t('start')}
            </StyledActionButton>
          ),
          score: item.score
        }
      })
    } else if (currentUser?.type === UserType.teacher && teacherUnitDetails) {
      return teacherUnitDetails.map((item) => {
        const chipOptions = getCustomChipOptions(item.level)
        const tags = [
          ...(item.grammarTags || []),
          ...(item.syntaxTags || []),
          ...(item.vocabTags || [])
        ]

        return {
          activity: <UnitName activityName={item.title} />,
          preview: (
            <StyledActionButton
              type="button"
              size="medium"
              variant="text"
              color="primary"
              name={`${item.id}-preview-btn`}
              onClick={() => togglePreviewModal(item.id)}
            >
              <img src="/assets/eye-icon.svg" alt="Eye icon" />
            </StyledActionButton>
          ),
          description: item.description,
          level: (
            <CustomChip
              aria-label={'Unit level'}
              bgColor={chipOptions?.bgColor}
              label={chipOptions?.name}
              textColor={chipOptions?.textColor}
              borderColor={chipOptions?.borderColor}
              height={'20px'}
            />
          ),
          tags: <Tags style={{ justifyContent: 'center' }} tags={tags} />,
          questionsCount: item.questionsCount,
          assignActivity: (
            <StyledActionButton
              type={'submit'}
              size={'medium'}
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                setAssignToActivity({
                  id: item.id,
                  title: item.title,
                  level: item.level,
                  description: item.description
                })
                handleAssignmentModalOpen(AssignmentTypes.assignment)
              }}
            >
              {t('assignTo')}
            </StyledActionButton>
          ),
          deAssignActivity: (
            <StyledActionButton
              type={'submit'}
              size={'medium'}
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                setAssignToActivity({
                  id: item.id,
                  title: item.title,
                  level: item.level,
                  description: item.description
                })
                handleAssignmentModalOpen(AssignmentTypes.deAssignment)
              }}
            >
              {t('deAssignFrom')}
            </StyledActionButton>
          )
        }
      })
    } else return []
  }

  const prepareTableDataMobile = () => {
    if (currentUser?.type === UserType.student && studentUnitDetails) {
      const header = prepareTableHead()
      return studentUnitDetails.map((item) => {
        const tags = [
          ...(item.grammarTags || []),
          ...(item.syntaxTags || []),
          ...(item.vocabTags || [])
        ]
        const chipOptions = getCustomChipOptions(item.level)
        const row = {
          level: (
            <CustomChip
              aria-label={'Unit level'}
              bgColor={chipOptions?.bgColor}
              label={chipOptions?.name}
              textColor={chipOptions?.textColor}
              borderColor={chipOptions?.borderColor}
              height={'20px'}
            />
          ),
          tags: (
            <Tags
              style={{ maxWidth: '300px', justifyContent: 'flex-end' }}
              tags={tags}
            />
          ),
          deadline:
            item.deadlineEnd && item.deadlineStart ? (
              <RowTextRight>{`${formatDateByLocale(
                item.deadlineStart
              )} - ${formatDateByLocale(item.deadlineStart)}`}</RowTextRight>
            ) : (
              <RowTextRight>N/A</RowTextRight>
            ),
          score: <RowTextRight>{item.score}</RowTextRight>
        }
        const buttons = [
          <FullWidthActionButton
            type={'submit'}
            size={'medium'}
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              setActivityToStart({
                assignedActivityId: item.id,
                activityId: item.activityId,
                title: item.title,
                level: item.level,
                description: item.description,
                questionsCount: item.questionsCount,
                timer: item.timer,
                logoUrl: item.unit.logoUrl,
                courseId: item.course.id,
                unitId: item.unit.id
              })
              setIsStartActivityModalOpen(true)
            }}
          >
            {t('start')}
          </FullWidthActionButton>
        ]
        return (
          <MobileRow
            row={row}
            rowTitle={item.title}
            buttons={buttons}
            tableHead={header}
          />
        )
      })
    } else if (currentUser?.type === UserType.teacher && teacherUnitDetails) {
      const header = prepareTableHead()
      return teacherUnitDetails.map((item) => {
        const tags = [
          ...(item.grammarTags || []),
          ...(item.syntaxTags || []),
          ...(item.vocabTags || [])
        ]
        const chipOptions = getCustomChipOptions(item.level)
        const buttons = [
          <FullWidthActionButton
            type="button"
            size="medium"
            variant="outlined"
            color="primary"
            name={`${item.id}-preview-btn`}
            onClick={() => togglePreviewModal(item.id)}
          >
            {t('preview')}
          </FullWidthActionButton>,
          <FullWidthActionButton
            type={'submit'}
            size={'medium'}
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              setAssignToActivity({
                id: item.id,
                title: item.title,
                level: item.level,
                description: item.description
              })
              handleAssignmentModalOpen(AssignmentTypes.assignment)
            }}
          >
            {t('assignTo')}
          </FullWidthActionButton>,
          <FullWidthActionButton
            type={'submit'}
            size={'medium'}
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              setAssignToActivity({
                id: item.id,
                title: item.title,
                level: item.level,
                description: item.description
              })
              handleAssignmentModalOpen(AssignmentTypes.deAssignment)
            }}
          >
            {t('deAssignFrom')}
          </FullWidthActionButton>
        ]
        const row = {
          questionsCount: <RowTextRight>{item.questionsCount}</RowTextRight>,
          rowTitle: <RowTextRight>{item.title}</RowTextRight>,
          level: (
            <CustomChip
              aria-label={'Unit level'}
              bgColor={chipOptions?.bgColor}
              label={chipOptions?.name}
              textColor={chipOptions?.textColor}
              borderColor={chipOptions?.borderColor}
              height={'20px'}
            />
          ),
          tags: (
            <Tags
              style={{ maxWidth: '300px', justifyContent: 'flex-end' }}
              tags={tags}
            />
          )
        }
        return (
          <MobileRow
            row={row}
            rowTitle={item.title}
            buttons={buttons}
            tableHead={header}
          />
        )
      })
    } else return []
  }

  const assignButtonForUnit = useMemo(() => {
    if (currentUser?.type === UserType.teacher) {
      return (
        <StyledButtonsWrapper>
          <StyledActionButton
            type={'submit'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              handleAssignToUnitModalOpen(AssignmentTypes.assignment)
            }}
          >
            {t('assignAUnit')}
          </StyledActionButton>
          <StyledActionButton
            type={'submit'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              handleAssignToUnitModalOpen(AssignmentTypes.deAssignment)
            }}
          >
            {t('deAssignAUnit')}
          </StyledActionButton>
        </StyledButtonsWrapper>
      )
    }

    return null
  }, [t])

  const openPreSelectedActivity = useCallback(
    (activityId: string) => {
      if (teacherUnitDetails) {
        const currentActivity = teacherUnitDetails.find(
          (item) => item.id === activityId
        )
        if (currentActivity) {
          setAssignToActivity({
            id: currentActivity.id,
            title: currentActivity.title,
            level: currentActivity.level,
            description: currentActivity.description
          })
          handleAssignmentModalOpen(AssignmentTypes.assignment)
        }
      }
    },
    [teacherUnitDetails, handleAssignmentModalOpen, setAssignToActivity]
  )

  useEffect(() => {
    if (currentUser?.type === UserType.teacher && preSelectedActivityId) {
      openPreSelectedActivity(preSelectedActivityId)
    }
  }, [currentUser, preSelectedActivityId, openPreSelectedActivity])

  return (
    <StyledPageContentWrapper>
      <UnitTitle unitName={unitName} />

      {!isPendingUnitInfo && unitInfo && (
        <CustomTitleDescription
          title={unitInfo.name}
          img={unitInfo.logoUrl}
          action={assignButtonForUnit}
        />
      )}
      {isTablet ? (
        <CustomTableMobile
          tableAriaLabel={'Course details table'}
          dataCount={activitiesCount}
          rowsCount={CLASSES_PAGE_SIZE}
          rows={prepareTableDataMobile()}
          defaultSortColumn={'activity'}
          sortCallback={sortCallbackHandler}
          tableSubtitle={``}
          tableHead={prepareTableHeadMobile()}
          searchPlaceholder={t('searchByActivity')}
        />
      ) : (
        <CustomTable
          tableAriaLabel={'Course details table'}
          dataCount={activitiesCount}
          tableHead={prepareTableHead()}
          rowsCount={CLASSES_PAGE_SIZE}
          rows={prepareTableData()}
          defaultSortColumn={'activity'}
          sortCallback={sortCallbackHandler}
          tableSubtitle={``}
          searchPlaceholder={t('searchByActivity')}
        />
      )}

      {currentUser?.type === UserType.student && activityToStart && (
        <StartActivityModal
          activityInfo={activityToStart}
          isOpen={isStartActivityModalOpen}
          handleClose={handleCloseStartActivityModal}
        />
      )}
      {currentUser?.type === UserType.teacher &&
        assignToActivity &&
        courseId &&
        assignmentType && (
          <AssignActivityModal
            assignmentType={assignmentType}
            courseId={courseId}
            activityId={assignToActivity.id}
            title={assignToActivity.title}
            level={assignToActivity.level}
            description={assignToActivity.description}
            isOpen={isAssignActivityModalOpen}
            handleClose={handleCloseAssignActivityModal}
          />
        )}

      {currentUser?.type === UserType.teacher &&
        unitInfo &&
        courseId &&
        assignmentType && (
          <AssignUnitModal
            assignmentType={assignmentType}
            courseId={courseId}
            unitId={unitInfo.id}
            title={unitInfo.name}
            isOpen={isAssignToUnitModalOpen}
            handleClose={handleCloseAssignToUnitModal}
          />
        )}
      {currentUser?.type === UserType.teacher && selectedActivityId && (
        <PreviewModal
          activityId={selectedActivityId}
          isOpen={isOpenPreviewModal}
          handleClose={() => togglePreviewModal(null)}
        />
      )}
    </StyledPageContentWrapper>
  )
}

export default UnitDetails
