import { createAsyncThunk } from '@reduxjs/toolkit'
import { IErrorResponse } from '../../../types'
import {
  IAssignedActivitySuggestion,
  IExtendedResultResponse,
  ISuggestionActivity,
  IToggleShowSuggestionsResponse
} from '../types'
import {
  IGetAssignedActivitiesResultPayload,
  IGetTeacherResultPayload,
  IToggleShowSuggestionsPayload
} from '../../Results/types'
import { get, patch } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { UserType } from '../../Login/types'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const getTeacherResult = createAsyncThunk<
  IExtendedResultResponse,
  IGetTeacherResultPayload,
  ICommonChunkConfig
>('resultDetails/fetchTeacherResults', async (_data, { rejectWithValue }) => {
  try {
    const { id } = _data
    const response = await get({
      path: `${API_ROUTES.TEACHERS_RESULTS_ID.replace(':id', id)}`,
      params: {}
    })
    return response as IExtendedResultResponse
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getStudentResult = createAsyncThunk<
  IExtendedResultResponse,
  IGetTeacherResultPayload,
  ICommonChunkConfig
>('resultDetails/fetchStudentResults', async (_data, { rejectWithValue }) => {
  try {
    const { id } = _data
    const response = await get({
      path: `${API_ROUTES.STUDENT_RESULTS_ID.replace(':id', id)}`,
      params: {}
    })
    return response as IExtendedResultResponse
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const toggleShowSuggestions = createAsyncThunk<
  IToggleShowSuggestionsResponse,
  IToggleShowSuggestionsPayload,
  ICommonChunkConfig
>('resultDetails/toggleShowSuggestions', async (_data, { rejectWithValue }) => {
  try {
    const { id, showSuggestions } = _data
    const response = await patch({
      path: `${API_ROUTES.TEACHERS_RESULTS_ID_SUGGESTIONS.replace(':id', id)}`,
      data: { showSuggestions }
    })
    return response as IToggleShowSuggestionsResponse
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getTeacherResultSuggestions = createAsyncThunk<
  ISuggestionActivity[],
  IGetTeacherResultPayload,
  ICommonChunkConfig
>(
  'resultDetails/fetchTeacherResultsSuggestions',
  async (_data, { rejectWithValue }) => {
    try {
      const { id } = _data
      const response = await get({
        path: `${API_ROUTES.TEACHERS_RESULTS_ID_SUGGESTIONS.replace(
          ':id',
          id
        )}`,
        params: {}
      })
      return response as ISuggestionActivity[]
    } catch (error) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const getAssignedActivitiesSuggestions = createAsyncThunk<
  IAssignedActivitySuggestion[],
  IGetAssignedActivitiesResultPayload,
  ICommonChunkConfig
>(
  'resultDetails/fetchAssignedActivitiesSuggestions',
  async (_data, { rejectWithValue }) => {
    try {
      const { id, type } = _data
      const currentRoute =
        type === UserType.teacher
          ? API_ROUTES.TEACHERS_RESULTS_ID_ASSIGNED_ACTIVITIES
          : API_ROUTES.STUDENT_RESULTS_ID_ASSIGNED_ACTIVITIES
      const response = await get({
        path: `${currentRoute.replace(':id', id)}`,
        params: {}
      })
      return response as IAssignedActivitySuggestion[]
    } catch (error) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const getStudentResultSuggestions = createAsyncThunk<
  ISuggestionActivity[],
  IGetTeacherResultPayload,
  ICommonChunkConfig
>(
  'resultDetails/fetchStudentResultsSuggestions',
  async (_data, { rejectWithValue }) => {
    try {
      const { id } = _data
      const response = await get({
        path: `${API_ROUTES.STUDENT_RESULTS_ID_SUGGESTIONS.replace(':id', id)}`,
        params: {}
      })
      return response as ISuggestionActivity[]
    } catch (error) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)
