import { createAsyncThunk } from '@reduxjs/toolkit'
import { del, get, post } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { IErrorResponse } from '../../../types'
import {
  IGetCourseDetailsList,
  IClass,
  AssignToActivityReq,
  IUserInfo,
  IAssignment,
  IStudentActivitiesList,
  ITeacherActivitiesList,
  AssignToUnit,
  DeAssignFromActivity
} from '../types'
import { CLASSES_PAGE_SIZE } from '../../../utils/constants'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const getStudentActivities = createAsyncThunk<
  IStudentActivitiesList,
  IGetCourseDetailsList,
  ICommonChunkConfig
>('unitDetails/fetchStudentActivities', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.STUDENT_ASSIGNED_ACTIVITIES}/${_data.id}`,
      params: {
        pageSize: CLASSES_PAGE_SIZE,
        ..._data
      }
    })
    return response as IStudentActivitiesList
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getTeacherActivities = createAsyncThunk<
  ITeacherActivitiesList,
  IGetCourseDetailsList,
  ICommonChunkConfig
>('unitDetails/fetchTeacherActivities', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.TEACHER_ACTIVITIES}/${_data.id}`,
      params: {
        pageSize: CLASSES_PAGE_SIZE,
        ..._data
      }
    })
    return response as ITeacherActivitiesList
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getStudentsForClass = createAsyncThunk<
  IUserInfo[],
  IClass,
  ICommonChunkConfig
>('courseDetails/fetchStudentsForClass', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.CLASSES}/${_data.id}/students`,
      params: {
        ..._data
      }
    })
    return response as IUserInfo[]
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const assignToActivity = createAsyncThunk<
  IAssignment[],
  AssignToActivityReq,
  ICommonChunkConfig
>('courseDetails/fetchAssignToActivity', async (_data, { rejectWithValue }) => {
  try {
    const response = await post({
      path: `${API_ROUTES.ASSIGN_ACTIVITY}`,
      data: _data
    })
    return response as IAssignment[]
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const deAssignFromActivity = createAsyncThunk<
  void,
  DeAssignFromActivity,
  ICommonChunkConfig
>(
  'courseDetails/fetchDeAssignFromActivity',
  async (_data, { rejectWithValue }) => {
    try {
      return await del({
        path: `${API_ROUTES.ASSIGN_ACTIVITY}/${_data.activityId}`,
        data: {
          studentId: _data.studentId,
          classId: _data.classId,
          sendNotification: _data.sendNotification
        }
      })
    } catch (error) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const assignToUnit = createAsyncThunk<
  IAssignment[],
  AssignToUnit,
  ICommonChunkConfig
>('courseDetails/fetchAssignToCourse', async (_data, { rejectWithValue }) => {
  try {
    const response = await post({
      path: API_ROUTES.ASSIGN_UNIT,
      data: _data
    })
    return response as IAssignment[]
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const deAssignFromUnit = createAsyncThunk<
  void,
  AssignToUnit,
  ICommonChunkConfig
>('courseDetails/fetchDeAssignFromUnit', async (_data, { rejectWithValue }) => {
  try {
    return await del({
      path: `${API_ROUTES.ASSIGN_UNIT}/${_data.unitId}`,
      data: {
        studentId: _data.studentId,
        classId: _data.classId,
        sendNotification: _data.sendNotification
      }
    })
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
