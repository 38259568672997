import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ICourseDetailsState,
  IStudentCourseDetailsList,
  IUserInfo,
  ITeacherCourseDetailsList,
  ICourseById,
  IAssignment
} from '../types'
import {
  getCourseById,
  getStudentsForClass,
  assignToCourse,
  getStudentUnits,
  getTeacherUnits,
  deAssignFromCourse
} from '../services'

const initialState: ICourseDetailsState = {
  isLoading: false,
  isDeAssignLoading: false,
  studentUnits: [],
  teacherUnits: [],
  studentsClass: [],
  assignCourse: [],
  course: null,
  count: 0
}

export const courseDetailsSlice = createSlice({
  name: 'coursesDetails',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentUnits.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getStudentUnits.fulfilled,
      (state, { payload }: PayloadAction<IStudentCourseDetailsList>) => {
        state.isLoading = false
        state.studentUnits = payload.data
        state.count = payload.count
      }
    )
    builder.addCase(getStudentUnits.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getTeacherUnits.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getTeacherUnits.fulfilled,
      (state, { payload }: PayloadAction<ITeacherCourseDetailsList>) => {
        state.isLoading = false
        state.teacherUnits = payload.data
        state.count = payload.count
      }
    )
    builder.addCase(getTeacherUnits.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getCourseById.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getCourseById.fulfilled,
      (state, { payload }: PayloadAction<ICourseById>) => {
        state.isLoading = false
        state.course = payload
      }
    )
    builder.addCase(getCourseById.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getStudentsForClass.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getStudentsForClass.fulfilled,
      (state, { payload }: PayloadAction<IUserInfo[]>) => {
        state.isLoading = false
        state.studentsClass = payload
      }
    )
    builder.addCase(getStudentsForClass.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(assignToCourse.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      assignToCourse.fulfilled,
      (state, { payload }: PayloadAction<IAssignment[]>) => {
        state.isLoading = false
        state.assignCourse = payload
      }
    )
    builder.addCase(assignToCourse.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(deAssignFromCourse.pending, (state) => {
      state.isDeAssignLoading = true
    })
    builder.addCase(deAssignFromCourse.fulfilled, (state) => {
      state.isDeAssignLoading = false
    })
    builder.addCase(deAssignFromCourse.rejected, (state) => {
      state.isDeAssignLoading = false
    })
  }
})
export const { clearState } = courseDetailsSlice.actions
export default courseDetailsSlice.reducer
