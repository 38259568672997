import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { StyledIFrame } from './styles'
import { APP_ROUTES } from '../../core/router/appRoutes'
import Cookies from 'js-cookie'
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../../utils/constants'
import Config from '../../config'

function Activity() {
  navigator.mediaDevices.getUserMedia({ audio: true })
  const navigate = useNavigate()
  const { activityId, assignedActivityId } = useParams()
  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  const handleMessage = useCallback((event: MessageEvent) => {
    if (event.data.key == 'finishActivity') {
      const { unitId, courseId } = event.data.data
      const currentRoute =
        APP_ROUTES.END_OF_ACTIVITY + `?unitId=${unitId}&courseId=${courseId}`
      navigate(currentRoute)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const src = useMemo(() => {
    const baseUrl = `${Config.vrUrl}/vr/${activityId}`
    if (assignedActivityId) {
      return `${baseUrl}/${assignedActivityId}`
    }
    return baseUrl
  }, [activityId])

  const onLoad = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(
        {
          key: 'tokens',
          data: {
            accessToken: Cookies.get(ACCESS_TOKEN_NAME),
            refreshToken: Cookies.get(REFRESH_TOKEN_NAME)
          }
        },
        '*'
      )
    }
  }

  return (
    <StyledIFrame
      src={src}
      ref={iframeRef}
      onLoad={onLoad}
      allow="camera *;microphone *; xr-spatial-tracking"
      allowFullScreen={true}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      allowvr="yes"
    />
  )
}

export default memo(Activity)
