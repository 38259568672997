import React from 'react'
import { Field, FieldAttributes } from 'formik'
import {
  StyledFormHelperText,
  StyledIconButton,
  StyledInputForm,
  StyledInputLabel,
  StyledOutlinedInput
} from './styles'
import { IFormInputProps } from './types'

const FormOutlinedInput = (props: IFormInputProps) => {
  const {
    name,
    label,
    id,
    onChange,
    type,
    width,
    placeholder,
    iconAlt,
    iconPath,
    iconOnClick
  } = props

  return (
    <Field name={name}>
      {({
        field: { value },
        form: { errors, touched, setFieldTouched, setFieldValue }
      }: FieldAttributes<any>) => {
        const elementId = id || 'form-el-' + name
        return (
          <StyledInputForm>
            {label && <StyledInputLabel>{label}</StyledInputLabel>}
            <StyledOutlinedInput
              name={name}
              id={elementId}
              value={value}
              width={width}
              error={!!errors[name] && touched[name]}
              type={type}
              placeholder={placeholder}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (setFieldValue) {
                  setFieldValue(e.target.name, e.target.value, true)
                }
                setFieldTouched(name, true, false)
                if (onChange) {
                  onChange(e)
                }
              }}
              endAdornment={
                <StyledIconButton type={'button'} onClick={iconOnClick}>
                  <img src={iconPath} alt={iconAlt} />
                </StyledIconButton>
              }
            />
            {errors[name] && touched[name] && (
              <StyledFormHelperText error>{errors[name]}</StyledFormHelperText>
            )}
          </StyledInputForm>
        )
      }}
    </Field>
  )
}

export default FormOutlinedInput
