import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import CustomModal from '../../../../components/CustomModal'
import { IAssignActivityModalProps } from './types'
import {
  StyledButton,
  StyledButtonsWrapper,
  StyledContentWrapper,
  StyledInfoItem,
  StyledInfoItemWrapper,
  StyledSwitch
} from './styles'
import { getClassesSelector } from '../../../MyClasses/selectors'
import { useAppDispatch, useAppSelector } from '../../../../hooks'

import {
  assignToCourse,
  deAssignFromCourse,
  getStudentsForClass
} from '../../services'
import { getStudentsForClassSelector } from '../../selectors'
import { useTranslation } from 'react-i18next'
import customToast from '../../../../components/CustomToast'
import { AssignmentTypes } from '../../types'
import StudentClassSelector from '../../../../components/StudentClassSelector'
import { StyledInfoWrapper } from '../../../../common/styles'
import { getClasses } from '../../../../core/services'

function AssignCourseModal(props: IAssignActivityModalProps) {
  const dispatch = useAppDispatch()
  const classes = useAppSelector(getClassesSelector)
  const students = useAppSelector(getStudentsForClassSelector)
  const { title, isOpen, handleClose, id, assignmentType } = props
  const [classCourse, setClassCourse] = useState('')
  const [studentCourse, setStudentCourse] = useState<string>()
  const [isNotification, setIsNotification] = useState(true)
  const { t } = useTranslation()

  const handleClassChange = (selectClass: string) => {
    if (classCourse === selectClass) {
      return
    }
    setClassCourse(selectClass)
    setStudentCourse('reset')
  }

  useEffect(() => {
    if (!classCourse || classCourse === 'reset') {
      return
    }
    dispatch(getStudentsForClass({ id: classCourse }))
  }, [classCourse])

  useEffect(() => {
    dispatch(
      getClasses({
        page: 1,
        filters: [`course:${id}`],
        sort: 'name:asc'
      })
    )
  }, [])

  const handleStudentChange = (selectStudent: string) => {
    if (studentCourse === selectStudent) {
      return
    }
    setStudentCourse(selectStudent)
  }

  const handleAssignToCourse = useCallback(() => {
    if (id) {
      dispatch(
        assignToCourse({
          courseId: id,
          classId: classCourse,
          studentId: studentCourse !== 'reset' ? studentCourse : undefined,
          sendNotification: isNotification
        })
      ).then((res) => {
        if (assignToCourse.fulfilled.match(res)) {
          customToast({
            message: 'Course assigned successfully',
            type: 'success'
          })
        }
      })
    }
  }, [classCourse, dispatch, id, isNotification, studentCourse])

  const handleDeAssignFromCourse = useCallback(() => {
    if (id && studentCourse) {
      dispatch(
        deAssignFromCourse({
          courseId: id,
          classId: classCourse,
          studentId: studentCourse !== 'reset' ? studentCourse : undefined
        })
      ).then((res) => {
        if (deAssignFromCourse.fulfilled.match(res)) {
          customToast({
            message: 'Course de-assigned successfully',
            type: 'success'
          })
        }
      })
    }
  }, [classCourse, dispatch, id, studentCourse])

  const handleSubmitModal = useCallback(() => {
    if (assignmentType === AssignmentTypes.assignment) {
      handleAssignToCourse()
      handleClose()
    } else if (assignmentType === AssignmentTypes.deAssignment) {
      handleDeAssignFromCourse()
      handleClose()
    }
  }, [
    assignmentType,
    handleAssignToCourse,
    handleClose,
    handleDeAssignFromCourse
  ])

  const modalTitle = useMemo(() => {
    if (assignmentType === AssignmentTypes.assignment) {
      return 'Assign'
    } else if (assignmentType === AssignmentTypes.deAssignment) {
      return 'De-assign'
    }
  }, [assignmentType])

  const getSubtitle = () => {
    if (assignmentType === AssignmentTypes.assignment) {
      return t('assignTo')
    } else if (assignmentType === AssignmentTypes.deAssignment) {
      return t('deAssignFrom')
    }
  }

  return (
    <CustomModal
      open={isOpen}
      width={'444px'}
      title={`${modalTitle} ${title}`}
      handleClose={handleClose}
    >
      <StyledContentWrapper>
        <StyledInfoItemWrapper>
          <StyledInfoItem>{getSubtitle()}</StyledInfoItem>
        </StyledInfoItemWrapper>
        <StudentClassSelector
          classCourse={classCourse}
          studentCourse={studentCourse}
          classes={classes}
          students={students}
          handleClassChange={handleClassChange}
          handleStudentChange={handleStudentChange}
        />
        <StyledInfoWrapper>
          <StyledInfoItem>{t('sendNotification')}</StyledInfoItem>
          <StyledSwitch
            defaultChecked
            inputProps={{ 'aria-label': 'Switch notification' }}
            onChange={() => setIsNotification(!isNotification)}
          />
        </StyledInfoWrapper>
        <StyledButtonsWrapper>
          <StyledButton
            type={'button'}
            size={'large'}
            variant={'outlined'}
            color={'primary'}
            onClick={handleClose}
          >
            {t('cancel')}
          </StyledButton>
          <StyledButton
            type={'submit'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={handleSubmitModal}
            disabled={!classCourse || classCourse === 'reset'}
          >
            {t('confirm')}
          </StyledButton>
        </StyledButtonsWrapper>
      </StyledContentWrapper>
    </CustomModal>
  )
}

export default memo(AssignCourseModal)
