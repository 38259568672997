import { basicString, password } from '../../../../../validation/primitives'
import { TFunction } from 'i18next'
import * as yup from 'yup'
import validator from 'validator'

type TType = TFunction

const resetSchema = (t: TType) => ({
  password: password(t).required(),
  confirmPassword: basicString.test(
    'passwordMatch',
    t('passwordMatch')!,
    function (value = '') {
      return validator.equals(value, this.resolve(yup.ref('password')))
    }
  )
})

export default resetSchema
