import React, { memo } from 'react'
import { StyledText, StyledTextWrapper } from './styles'
import { INotificationTextProps } from './types'

function NotificationText(props: INotificationTextProps) {
  return (
    <StyledTextWrapper>
      <StyledText>{props.text}</StyledText>
    </StyledTextWrapper>
  )
}

export default memo(NotificationText)
