import { memo, useEffect } from 'react'
import Loader from '../../../../components/Loader'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import Config from '../../../../config'

interface Props {
  getTokensByCode: (code: string) => void
}

export const OauthMyLoginLogin = memo(({ getTokensByCode }: Props) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const authorize = async () => {
    const authorizationUrl =
      `${Config.myLoginAuthUrl}?` +
      `response_type=code&` +
      `client_id=${Config.myLoginClientId}&` +
      'scope=openid&' +
      'state=my-login&' +
      `redirect_uri=${encodeURIComponent(Config.host + '/login')}`

    // window.location.href = authorizationUrl
    await axios.get(authorizationUrl)
  }

  const fetchRequest = async () => {
    const code = queryParams.get('code')
    if (code) {
      getTokensByCode(code)
    } else {
      await authorize()
    }
  }

  useEffect(() => {
    fetchRequest()
  }, [])

  return <Loader />
})
