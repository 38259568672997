import { createAsyncThunk } from '@reduxjs/toolkit'
import { IErrorResponse } from '../../../types'
import { ISendLinkPayload } from '../types'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { post } from '../../../axios'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const sendResetLinkByEmail = createAsyncThunk<
  ISendLinkPayload,
  ISendLinkPayload,
  ICommonChunkConfig
>(API_ROUTES.PASSWORD_RESET, async (_data, { rejectWithValue }) => {
  try {
    return await post({
      path: API_ROUTES.PASSWORD_RESET,
      data: _data
    })
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
