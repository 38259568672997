import React, { JSX, memo, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { getCurrentUserSelector } from '../../selectors'
import { getCurrentUser } from '../../../containers/Login/services'
import { ACCESS_TOKEN_NAME } from '../../../utils/constants'
import MainLayout from '../MainLayout'
import { useLocation } from 'react-router-dom'
import { matchPath } from 'react-router'
import { APP_ROUTES } from '../../router/appRoutes'

interface IBaseLayoutProps {
  children: JSX.Element
}

const BaseLayout = memo<IBaseLayoutProps>(({ children }) => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const currentUser = useAppSelector(getCurrentUserSelector)
  const accessToken = Cookies.get(ACCESS_TOKEN_NAME)

  useEffect(() => {
    if (!currentUser && accessToken) {
      dispatch(getCurrentUser())
    }
  }, [currentUser, accessToken, dispatch])

  const isVrPath = !!matchPath(APP_ROUTES.VR_INTERACTIVE, pathname)
  const isEndOfActivityPath = !!matchPath(APP_ROUTES.END_OF_ACTIVITY, pathname)

  return (
    <>
      {!currentUser || isVrPath || isEndOfActivityPath ? (
        children
      ) : (
        <MainLayout>{children}</MainLayout>
      )}
    </>
  )
})

export default BaseLayout
