import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INotificationsList, INotificationsState } from '../types'
import { getNotifications } from '../services'

const initialState: INotificationsState = {
  isLoading: false,
  notifications: [],
  count: 0
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getNotifications.fulfilled,
      (state, { payload }: PayloadAction<INotificationsList>) => {
        state.isLoading = false
        state.notifications = payload.data
        state.count = payload.count
      }
    )
    builder.addCase(getNotifications.rejected, (state) => {
      state.isLoading = false
    })
  }
})

export const { clearState } = notificationsSlice.actions
export default notificationsSlice.reducer
