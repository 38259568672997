import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getClassDetails } from './services'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  StyledPageContentWrapper,
  StyledTitleWrapper,
  StyledTitle,
  StyledBackButton,
  StyledClassDetailsWrapper,
  StyledLink
} from './styles'
import { getClassDetailsSelector } from './selectors'
import ClassDetailsItem from './components/ClassDetailsItem'
import CustomTable from '../../components/CustomTable'
import { INFINITY_PAGE_SIZE } from '../../utils/constants'
import { InterfaceType } from '../Profile/types'
import { getCurrentUserSelector } from '../../core/selectors'
import { UserType } from '../Login/types'
import { APP_ROUTES } from '../../core/router/appRoutes'
import { clearState } from './slices'
import { TableColumn } from './types'

function ClassDetails() {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const classDetails = useAppSelector(getClassDetailsSelector)
  const currentUser = useAppSelector(getCurrentUserSelector)

  const isSchoolInterface = currentUser?.interfaceType === InterfaceType.school

  const redirectToPreviousPage = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (id) {
      dispatch(getClassDetails({ id }))
    }
    return () => {
      dispatch(clearState())
    }
  }, [])

  const getTeachers = () => {
    if (classDetails) {
      return classDetails.teachers.map((teacher) => teacher.fullName)
    }
    return []
  }

  const getStudents = () => {
    if (classDetails) {
      return classDetails.students.map((student) => (
        <StyledLink to={APP_ROUTES.STUDENTS.concat(`/${student.username}`)}>
          {student.fullName}
        </StyledLink>
      ))
    }
    return []
  }

  const prepareTableHead = (): TableColumn[] => {
    return [
      {
        name: t('nameOfCourse'),
        ariaLabel: 'Name of course',
        cellName: 'name',
        sortable: false
      },
      {
        name: t('weekActiveTime'),
        ariaLabel: 'Active time this week',
        cellName: 'activeTimeThisWeek',
        cellAlign: 'center',
        sortable: false
      },
      {
        name: t('averageScore'),
        ariaLabel: 'Average score',
        cellName: 'averageScore',
        sortable: false
      }
    ]
  }

  const prepareTableData = () => {
    if (!classDetails) return []

    return classDetails.courses.map((course) => {
      return {
        name: course.name,
        activeTimeThisWeek: course.activeTimeThisWeek,
        averageScore: course.averageScore,
        rowAction:
          currentUser?.type === UserType.teacher
            ? () =>
                navigate(APP_ROUTES.COURSE_DETAILS.replace(':id', course.id))
            : undefined
      }
    })
  }

  return (
    <StyledPageContentWrapper>
      {classDetails && (
        <>
          <StyledTitleWrapper>
            <StyledBackButton
              aria-label={'Previous page button'}
              onClick={redirectToPreviousPage}
            />
            <StyledTitle variant={'h2'}>{`${
              isSchoolInterface ? t('classDetails') : t('departmentDetails')
            }: ${classDetails.name}`}</StyledTitle>
          </StyledTitleWrapper>
          <StyledClassDetailsWrapper>
            <ClassDetailsItem
              name={t('name')}
              description={classDetails.name}
            />
            <ClassDetailsItem
              name={isSchoolInterface ? t('school') : t('company')}
              description={classDetails.school.longName}
            />
            <ClassDetailsItem
              name={isSchoolInterface ? t('teachers') : t('HRManagers')}
              description={getTeachers()}
            />
            <ClassDetailsItem
              name={isSchoolInterface ? t('students') : t('employees')}
              description={getStudents()}
            />
            <ClassDetailsItem name={t('courses')} description={''} />
            <CustomTable
              tableAriaLabel={'Courses table'}
              dataCount={classDetails.courses.length}
              tableHead={prepareTableHead()}
              rowsCount={INFINITY_PAGE_SIZE}
              rows={prepareTableData()}
              tableSubtitle={``}
            />
          </StyledClassDetailsWrapper>
        </>
      )}
    </StyledPageContentWrapper>
  )
}

export default ClassDetails
