import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { IErrorResponse } from '../../../types'
import { ISchool, ISchoolRequest } from '../types'
import { COUNT_PER_PAGE } from '../../../utils/constants'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const getTeacherSchool = createAsyncThunk<
  ISchool,
  ISchoolRequest,
  ICommonChunkConfig
>('schools/fetchTeacherSchools', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.TEACHERS_SCHOOLS,
      params: {
        page: _data.page,
        pageSize: COUNT_PER_PAGE
      }
    })
    return response as ISchool
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getStudentsSchool = createAsyncThunk<
  ISchool,
  ISchoolRequest,
  ICommonChunkConfig
>('schools/fetchStudentsSchools', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.STUDENTS_SCHOOLS,
      params: {
        page: _data.page,
        pageSize: COUNT_PER_PAGE
      }
    })
    return response as ISchool
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
