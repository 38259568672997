import React, { memo, useCallback, useEffect, useMemo } from 'react'
import CustomModal from '../../../../components/CustomModal'
import { IResultDetailsModalProps } from './types'
import {
  StyledContentWrapper,
  StyledTableTitle,
  StyledTableWrapper
} from './styles'
import { getStudentResultDetails } from '../../services'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  getStudentResultDetailsCountSelector,
  getStudentResultDetailsSelector
} from '../../selectors'
import { BREAKPOINTS, CLASSES_PAGE_SIZE } from '../../../../utils/constants'
import CustomTable from '../../../../components/CustomTable'
import { IStudentResultDetailsItem, TableColumn } from '../../types'
import {
  formatDateByLocale,
  formatSecondsToHours
} from '../../../../utils/helpers'
import { ISortCallbackParams } from '../../../../types'
import { useTranslation } from 'react-i18next'
import { APP_ROUTES } from '../../../../core/router/appRoutes'
import { useNavigate } from 'react-router-dom'
import useWindowSize from '../../../../utils/hooks'
import MobileRow from '../../../../components/CustomTableMobile/components/MobileRow'
import { RowTextRight } from '../../../../components/CustomTableMobile/styles'
import CustomTableMobile from '../../../../components/CustomTableMobile'

function ResultDetailsModal(props: IResultDetailsModalProps) {
  const { id, title, isOpen, handleClose, averageScore } = props
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const studentResultDetails = useAppSelector(getStudentResultDetailsSelector)
  const studentResultDetailsCount = useAppSelector(
    getStudentResultDetailsCountSelector
  )
  const [windowWidth] = useWindowSize()
  const isTablet = useMemo(
    () => windowWidth <= BREAKPOINTS.tabletSm,
    [windowWidth]
  )

  useEffect(() => {
    dispatch(getStudentResultDetails({ page: 1, id }))
  }, [])

  const prepareTableHead = (): TableColumn[] => {
    return [
      {
        name: t('resultOfActivity'),
        ariaLabel: 'Score of activity',
        cellName: 'score',
        sortable: false
      },
      {
        name: t('dateOfAttempt'),
        ariaLabel: 'Date of attempt',
        cellName: 'startedAt',
        sortable: false
      },
      {
        name: t('timeSpentOnTheActivity'),
        ariaLabel: 'Time spent on the activity',
        cellName: 'duration',
        sortable: false
      }
    ]
  }

  const getAction = useCallback((result: IStudentResultDetailsItem) => {
    navigate(
      APP_ROUTES.ACTIVITY_DEEP_RESULT.replace(
        ':assignedActivityResultId',
        result.id
      )
    )
  }, [])

  const prepareTableData = () => {
    if (!studentResultDetails) return []
    return studentResultDetails.map((result) => {
      return {
        score: result.score,
        startedAt: formatDateByLocale(result.startedAt),
        duration: formatSecondsToHours(result.duration),
        rowAction: () => getAction(result)
      }
    })
  }

  const prepareTableDataMobile = () => {
    if (!studentResultDetails) return []
    const header = prepareTableHead()
    return studentResultDetails.map((result) => {
      const row = {
        score: <RowTextRight>{result.score}</RowTextRight>,
        startedAt: (
          <RowTextRight>{formatDateByLocale(result.startedAt)}</RowTextRight>
        ),
        duration: (
          <RowTextRight>{formatSecondsToHours(result.duration)}</RowTextRight>
        )
      }
      return (
        <MobileRow
          row={row}
          tableHead={header}
          rowAction={() => getAction(result)}
        />
      )
    })
  }

  const sortCallbackHandler = (params: ISortCallbackParams) => {
    dispatch(getStudentResultDetails({ ...params, id }))
  }

  return (
    <CustomModal
      open={isOpen}
      width={'748px'}
      title={`${t('details')} ${title}`}
      handleClose={handleClose}
    >
      <StyledContentWrapper>
        <StyledTableTitle variant={'h4'}>
          {`${t('averageScoreIs')} ${averageScore}`}
        </StyledTableTitle>
        <StyledTableWrapper>
          {isTablet ? (
            <CustomTableMobile
              tableAriaLabel={'Result Details Table'}
              dataCount={studentResultDetailsCount}
              tableHead={prepareTableHead()}
              rowsCount={CLASSES_PAGE_SIZE}
              rows={prepareTableDataMobile()}
              defaultSortColumn={''}
              sortCallback={sortCallbackHandler}
              tableSubtitle={''}
              stickyHeader
            />
          ) : (
            <CustomTable
              tableAriaLabel={'Result Details Table'}
              dataCount={studentResultDetailsCount}
              tableHead={prepareTableHead()}
              rowsCount={CLASSES_PAGE_SIZE}
              rows={prepareTableData()}
              defaultSortColumn={''}
              sortCallback={sortCallbackHandler}
              tableSubtitle={''}
              stickyHeader
            />
          )}
        </StyledTableWrapper>
      </StyledContentWrapper>
    </CustomModal>
  )
}

export default memo(ResultDetailsModal)
