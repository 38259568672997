import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  getClassesCountSelector,
  getClassesSelector,
  getSchoolsSelector
} from './selectors'
import { useTranslation } from 'react-i18next'
import CustomTable from '../../components/CustomTable'
import { getCurrentUserSelector } from '../../core/selectors'
import { UserType } from '../Login/types'
import { StyledPageContentWrapper, StyledTitle } from './styles'
import { useNavigate } from 'react-router-dom'
import { CLASSES_PAGE_SIZE } from '../../utils/constants'
import { ITableClassData } from './types'
import { InterfaceType } from '../Profile/types'
import { ISortCallbackParams } from '../../types'
import { findSchools, getClasses } from '../../core/services'
import TableActionsTooltip from '../../components/TableActionsTooltip'
import SendMessageModal from '../../components/SendMessageModal'
import customToast from '../../components/CustomToast'
import { isMessageSendingSelector } from '../Students/selectors'
import { sendMessageToClass } from './services'
import { clearState } from './slices'

function MyClasses() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(getCurrentUserSelector)
  const classes = useAppSelector(getClassesSelector)
  const schools = useAppSelector(getSchoolsSelector)
  const classesCount = useAppSelector(getClassesCountSelector)
  const isMessageSending = useAppSelector(isMessageSendingSelector)

  const [isSendMessageModalOpen, setIsSendMessageModalOpen] = useState(false)
  const [chosenClassId, setChosenClassId] = useState<string>('')

  const isInterfaceTypeSchools =
    currentUser?.interfaceType === InterfaceType.school
  const getClassesOptions = () => {
    switch (currentUser?.interfaceType) {
      case InterfaceType.school:
        return {
          title: t('myClasses'),
          countPlace: t('classes', { count: classesCount }).toLowerCase(),
          place: t('class').toLowerCase(),
          searchBy: t('searchByClasses')
        }
      case InterfaceType.company:
        return {
          title: t('myDepartments'),
          countPlace: t('departments'),
          place: t('department').toLowerCase(),
          searchBy: t('searchByDepartments')
        }
      default:
        return {
          title: t('myClasses'),
          countPlace: t('classes', { count: classesCount }).toLowerCase(),
          place: 'Classes',
          searchBy: t('searchByClasses')
        }
    }
  }
  const getOptions = getClassesOptions()

  const handleModalSubmit = useCallback(
    (text: string) => {
      dispatch(sendMessageToClass({ text, entityId: chosenClassId })).then(
        (res) => {
          if (sendMessageToClass.fulfilled.match(res)) {
            customToast({ message: 'Success!', type: 'success' })
            handleCloseModal()
          }
        }
      )
    },
    [chosenClassId]
  )

  const handleOpenModal = useCallback((classId: string) => {
    setChosenClassId(classId)
    setIsSendMessageModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setChosenClassId('')
    setIsSendMessageModalOpen(false)
  }, [])

  useEffect(() => {
    dispatch(getClasses({ page: 1, sort: 'name:asc' }))
    dispatch(findSchools())
    return () => {
      dispatch(clearState())
    }
  }, [])

  const sortCallbackHandler = (params: ISortCallbackParams) => {
    dispatch(getClasses(params))
  }

  const prepareTableHead = () => {
    let tableHead = [
      {
        name: t('nameOfClass'),
        ariaLabel: 'Name of Class',
        cellName: 'name',
        sortable: true
      },
      {
        name: t('school'),
        ariaLabel: 'School Name',
        cellName: 'school',
        sortable: true
      },
      {
        name: t('numberOfStudents'),
        ariaLabel: 'Students count',
        cellName: 'students',
        sortable: true
      }
    ]
    if (currentUser?.interfaceType === InterfaceType.company) {
      tableHead = [
        {
          name: t('nameOfDepartment'),
          ariaLabel: 'Name of Department',
          cellName: 'name',
          sortable: true
        },
        {
          name: t('company'),
          ariaLabel: 'Company Name',
          cellName: 'school',
          sortable: true
        },
        {
          name: t('numberOfEmployees'),
          ariaLabel: 'Employees count',
          cellName: 'students',
          sortable: true
        }
      ]
    }

    if (currentUser && currentUser.type === UserType.teacher) {
      tableHead.push({
        name: t('actions'),
        ariaLabel: 'Row actions',
        cellName: 'actions',
        sortable: false
      })
    }
    return tableHead
  }

  const prepareTableData = (): ITableClassData[] => {
    if (!classes) return []

    return classes.map((cl) => {
      const tableData: ITableClassData = {
        name: cl.name,
        school: cl.school.longName,
        students: cl.studentsCount
      }

      if (currentUser && currentUser.type === UserType.teacher) {
        tableData.actions = (
          <TableActionsTooltip
            className={cl.name}
            entityId={cl.id}
            onClick={() => handleOpenModal(cl.id)}
            buttonTitle={t('sendMessageToClass')}
            linkTitle={t('goToClassDetails')}
          />
        )
      }

      if (currentUser && currentUser.type === UserType.student) {
        tableData.rowAction = () => navigate(`/classes/${cl.id}`)
      }
      return tableData
    })
  }

  const getTableSchoolFiltersData = () => {
    const tableSchoolFiltersData: {
      school: { value: string; name: string }[]
    } = {
      school: []
    }
    const seenIds = new Set()

    schools?.forEach((school) => {
      if (!seenIds.has(school.id)) {
        tableSchoolFiltersData.school.push({
          value: school.id,
          name: school.longName
        })
        seenIds.add(school.id)
      }
    })

    return tableSchoolFiltersData
  }

  const getTableCompanyFiltersData = () => {
    const tableCompanyFiltersData: {
      company: { value: string; name: string }[]
    } = {
      company: []
    }
    const seenIds = new Set()

    schools?.forEach((school) => {
      if (!seenIds.has(school.id)) {
        tableCompanyFiltersData.company.push({
          value: school.id,
          name: school.longName
        })
        seenIds.add(school.id)
      }
    })

    return tableCompanyFiltersData
  }

  return (
    <StyledPageContentWrapper>
      <StyledTitle variant={'h2'} aria-label={'My classes'}>
        {getOptions.title}
      </StyledTitle>
      {classes && (
        <CustomTable
          tableAriaLabel={'Course students table'}
          dataCount={classesCount}
          tableHead={prepareTableHead()}
          rowsCount={CLASSES_PAGE_SIZE}
          rows={prepareTableData()}
          defaultSortColumn={'name'}
          sortCallback={sortCallbackHandler}
          tableFiltersData={
            isInterfaceTypeSchools
              ? getTableSchoolFiltersData()
              : getTableCompanyFiltersData()
          }
          tableSubtitle={t('youHaveEntityAndName', {
            entityCount: classesCount,
            entityName: getOptions.countPlace
          })}
          searchPlaceholder={getOptions.searchBy}
        />
      )}
      <SendMessageModal
        isLoading={isMessageSending}
        title={t('sendMessageToClass')}
        placeholder={t('sendMessageToClass')}
        isSendMessageModalOpen={isSendMessageModalOpen}
        handleCloseModal={handleCloseModal}
        onSubmit={handleModalSubmit}
      />
    </StyledPageContentWrapper>
  )
}

export default MyClasses
