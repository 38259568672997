import React from 'react'
import { Field, FieldAttributes } from 'formik'
import {
  StyledHelperText,
  StyledInputForm,
  StyledInputLabel,
  StyledSelect
} from './styles'
import { IFormInputProps, IOption } from './types'
import { MenuItem } from '@mui/material'
import uuid from 'react-uuid'

const FormSelectInput = (props: IFormInputProps) => {
  const { name, label, id, onChange, width, options } = props

  return (
    <Field name={name}>
      {({
        field: { value },
        form: { errors, touched, setFieldTouched, setFieldValue }
      }: FieldAttributes<any>) => {
        const elementId = id || 'form-el-' + name
        return (
          <StyledInputForm>
            {label && <StyledInputLabel>{label}</StyledInputLabel>}
            <StyledSelect
              name={name}
              id={elementId}
              value={value}
              width={width}
              error={!!errors[name] && touched[name]}
              onChange={(e: any) => {
                if (setFieldValue) {
                  setFieldValue(e.target.name, e.target.value, true)
                }
                setFieldTouched(name, true, false)
                if (onChange) {
                  onChange(e)
                }
              }}
            >
              {options.map((option: IOption) => {
                return (
                  <MenuItem key={uuid()} value={option.value}>
                    {option.name}
                  </MenuItem>
                )
              })}
            </StyledSelect>
            {errors[name] && touched[name] ? (
              <StyledHelperText>{errors[name]}</StyledHelperText>
            ) : (
              ''
            )}
          </StyledInputForm>
        )
      }}
    </Field>
  )
}

export default FormSelectInput
