import React, { memo, useEffect, useMemo } from 'react'
import Sidebar from './components/Sidebar'
import { StyledContentWrapper, StyledMainLayout } from './styles/style'
import { getCurrentUser } from '../../../containers/Login/services'
import { getCurrentUserSelector } from '../../selectors'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { ACCESS_TOKEN_NAME, BREAKPOINTS } from '../../../utils/constants'
import Cookies from 'js-cookie'
import UserMenu from '../../../components/UserMenu'
import useWindowSize from '../../../utils/hooks'

interface IBaseLayoutProps {
  children: JSX.Element
}

const MainLayout = memo<IBaseLayoutProps>(({ children }) => {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(getCurrentUserSelector)
  const accessToken = Cookies.get(ACCESS_TOKEN_NAME)
  const [windowWidth] = useWindowSize()
  const isMobile = useMemo(
    () => windowWidth <= BREAKPOINTS.mobile,
    [windowWidth]
  )

  useEffect(() => {
    if (!currentUser && accessToken) {
      dispatch(getCurrentUser())
    }
  }, [currentUser, accessToken, dispatch])

  return (
    <React.StrictMode>
      <StyledMainLayout>
        <Sidebar />
        <StyledContentWrapper>
          {!isMobile ? <UserMenu /> : null}
          {children}
        </StyledContentWrapper>
      </StyledMainLayout>
    </React.StrictMode>
  )
})

export default MainLayout
