import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICoursesState } from '../types'
import { getStudentCourses } from '../services'
import { getTeacherCourses } from '../../../core/services'
import { ICoursesList } from '../../../types'

const initialState: ICoursesState = {
  isLoading: false,
  courses: [],
  count: 0
}

export const coursesSlice = createSlice({
  name: 'myCourses',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentCourses.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getStudentCourses.fulfilled,
      (state, { payload }: PayloadAction<ICoursesList>) => {
        state.isLoading = false
        state.courses = payload.data
        state.count = payload.count
      }
    )
    builder.addCase(getStudentCourses.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getTeacherCourses.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getTeacherCourses.fulfilled,
      (state, { payload }: PayloadAction<ICoursesList>) => {
        state.isLoading = false
        state.courses = payload.data
        state.count = payload.count
      }
    )
    builder.addCase(getTeacherCourses.rejected, (state) => {
      state.isLoading = false
    })
  }
})

export const { clearState } = coursesSlice.actions
export default coursesSlice.reducer
