import { styled } from '@mui/system'
import { Form } from 'formik'
import { Button } from '@mui/material'

export const StyledForm = styled(Form)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%'
}))

export const StyledButtonsWrapper = styled('div')(() => ({
  display: 'flex',
  width: '100%'
}))

export const StyledButton = styled(Button)(() => ({
  '&:nth-of-type(1)': {
    flex: 1
  },
  '&:nth-of-type(2)': {
    flex: 2,
    marginLeft: '12px'
  }
}))
