import { createAsyncThunk } from '@reduxjs/toolkit'
import { get, post } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import {
  ICommonChunkConfig,
  IErrorResponse,
  ISendMessagePayload
} from '../../../types'
import { IGetStudentsPayload, IStudentList } from '../types'
import { STUDENTS_PAGE_SIZE } from '../../../utils/constants'

export const getStudents = createAsyncThunk<
  IStudentList,
  IGetStudentsPayload,
  ICommonChunkConfig
>('students/fetchStudents', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.TEACHERS_STUDENTS,
      params: {
        ..._data,
        pageSize: STUDENTS_PAGE_SIZE
      }
    })
    return response as IStudentList
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const sendMessageToAccount = createAsyncThunk<
  void,
  ISendMessagePayload,
  ICommonChunkConfig
>('students/postMessageToClass', async (_data, { rejectWithValue }) => {
  try {
    await post({
      path: `${API_ROUTES.SEND_MESSAGE_TO_ACCOUNT.replace(
        ':id',
        _data.entityId
      )}`,
      data: { text: _data.text }
    })
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
