import { Modal } from '@mui/material'
import { IModalProps } from './types'
import { memo } from 'react'
import {
  StyledBox,
  StyledCloseModalButton,
  StyledModalHeader,
  StyledModalTitle
} from './styles'

const CustomModal = (props: IModalProps) => {
  const { open, handleClose, children, title, width } = props
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledBox width={width}>
        <StyledModalHeader>
          <StyledModalTitle id="modal-modal-title" variant="h4">
            {title}
          </StyledModalTitle>
          <StyledCloseModalButton type={'button'} onClick={handleClose} />
        </StyledModalHeader>
        {children}
      </StyledBox>
    </Modal>
  )
}

export default memo(CustomModal)
