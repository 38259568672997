import React, { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getIsLoadingResultDetailsSelector,
  getIsShowSuggestionsSelector,
  getResultDetailsSelector
} from './selectors'
import {
  getStudentResult,
  getTeacherResult,
  toggleShowSuggestions
} from './services'
import { clearState } from './slices'
import ResultDetailsPageWrapper from './components/ResultDetailsPageWrapper'
import { getCurrentUserSelector } from '../../core/selectors'
import { UserType } from '../Login/types'

function ResultDetails() {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector(getCurrentUserSelector)
  const resultDetails = useAppSelector(getResultDetailsSelector)
  const isResultsLoading = useAppSelector(getIsLoadingResultDetailsSelector)
  const isShowSuggestions = useAppSelector(getIsShowSuggestionsSelector)

  const userType = useMemo(() => currentUser?.type, [currentUser?.type])

  const id = params.assignedActivityResultId!

  const toggleSuggestionsSwitch = (isShow: boolean) => {
    dispatch(toggleShowSuggestions({ id, showSuggestions: isShow }))
  }

  useEffect(() => {
    if (userType === UserType.teacher) {
      dispatch(getTeacherResult({ id }))
    }
    if (userType === UserType.student) {
      dispatch(getStudentResult({ id }))
    }
  }, [id])

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [])

  const redirectToPreviousPage = () => {
    navigate(-1)
  }

  return (
    <ResultDetailsPageWrapper
      isLoading={isResultsLoading}
      isShowSuggestions={isShowSuggestions}
      onClickBack={redirectToPreviousPage}
      toggleSuggestionsSwitch={toggleSuggestionsSwitch}
      resultDetails={resultDetails}
    />
  )
}

export default ResultDetails
