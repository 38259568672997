import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { IErrorResponse } from '../../../types'
import {
  IGetStudentResultDetailsPayload,
  IGetStudentResultsPayload,
  IGetTeacherResultsPayload,
  IStudentResultDetailsList,
  IStudentResultsList,
  ITeacherResultsResponse
} from '../types'
import { CLASSES_PAGE_SIZE, STUDENTS_PAGE_SIZE } from '../../../utils/constants'
import { ICourses } from '../../Home/types'
import { COURSES_COUNT_PER_PAGE } from '../constants'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const getStudentResults = createAsyncThunk<
  IStudentResultsList,
  IGetStudentResultsPayload,
  ICommonChunkConfig
>('results/fetchStudentResults', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.STUDENT_RESULTS}`,
      params: {
        pageSize: CLASSES_PAGE_SIZE,
        ..._data
      }
    })
    return response as IStudentResultsList
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getAllStudentCourses = createAsyncThunk<
  ICourses,
  void,
  ICommonChunkConfig
>('results/fetchStudentCourses', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.STUDENTS_COURSES,
      params: {
        page: 1,
        pageSize: COURSES_COUNT_PER_PAGE
      }
    })
    return response as ICourses
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getStudentResultDetails = createAsyncThunk<
  IStudentResultDetailsList,
  IGetStudentResultDetailsPayload,
  ICommonChunkConfig
>('results/fetchStudentResultDetails', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.STUDENT_RESULTS}/${_data.id}`,
      params: {
        pageSize: CLASSES_PAGE_SIZE,
        ..._data
      }
    })
    return response as IStudentResultDetailsList
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getTeacherResults = createAsyncThunk<
  ITeacherResultsResponse,
  IGetTeacherResultsPayload,
  ICommonChunkConfig
>('results/fetchTeacherResults', async (_data, { rejectWithValue }) => {
  try {
    const { pageSize = STUDENTS_PAGE_SIZE } = _data
    const response = await get({
      path: `${API_ROUTES.TEACHERS_RESULTS}`,
      params: {
        ..._data,
        pageSize
      }
    })
    return response as ITeacherResultsResponse
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
