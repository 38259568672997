import { styled } from '@mui/system'
import { Typography } from '@mui/material'

export const StyledTextWrapper = styled('div')(() => ({
  whiteSpace: 'break-spaces'
}))

export const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.customColors.black,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px'
}))
