import { styled } from '@mui/material/styles'
import { Box, Button, Typography } from '@mui/material'

export const StyledBox = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFFFFF',
  color: '#253242',
  borderRadius: '24px',
  padding: '24px',
  maxHeight: '90vh',
  overflow: 'hidden auto',
  outline: 0,
  '@media (max-width: 1140px)': {
    width: '85%'
  },
  '@media (max-width: 600px)': {
    width: '80%'
  }
}))

export const StyledModalHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  marginBottom: '16px'
}))

export const StyledModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '32px',
  color: theme.palette.customColors.black
}))

export const StyledCloseModalButton = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: '24px',
  maxWidth: '24px',
  margin: 0,
  width: '24px',
  height: '24px',
  marginLeft: '24px',
  '&::after': {
    content: 'url(/assets/close-icon.svg)',
    maxWidth: '24px',
    width: '24px',
    height: '24px'
  }
}))
