import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStudentsResultsResponse, IStudentsResultsState } from '../types'
import { getStudentsResults } from '../services'

const initialState: IStudentsResultsState = {
  isLoading: false,
  count: 0,
  studentResults: []
}

export const courseDetailsSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentsResults.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getStudentsResults.fulfilled,
      (state, { payload }: PayloadAction<IStudentsResultsResponse>) => {
        state.isLoading = false
        state.studentResults = payload.data
        state.count = payload.count
      }
    )
    builder.addCase(getStudentsResults.rejected, (state) => {
      state.isLoading = false
    })
  }
})

export const { clearState } = courseDetailsSlice.actions
export default courseDetailsSlice.reducer
