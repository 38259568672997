import { styled } from '@mui/material/styles'

export const StyledTitleDescriptionWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.customColors.bg,
  border: `1px solid ${theme.palette.customColors.bg}`,
  borderRadius: '16px',
  boxSizing: 'border-box',
  padding: '24px',
  display: 'grid',
  overflow: 'hidden',
  gap: '16px',
  gridTemplateColumns: '80px 1fr',
  marginBottom: '8px'
}))

export const StyledTitleTop = styled('div')(() => ({
  display: 'flex',
  gap: '12px'
}))

export const StyledTitleActionContainer = styled('div')(() => ({
  flexShrink: 0,
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px 12px',
  marginLeft: 'auto'
}))
export const StyledTitleHeading = styled('div')(() => ({
  marginTop: '8px',
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '1.2'
}))

export const StyledWrapper = styled('div')(() => ({}))
