import { t } from 'i18next'
import { FC, memo, useEffect, useMemo, useRef } from 'react'
import {
  StyledSchoolWrapper,
  StyledAvatarWrapper,
  StyledCustomImage,
  StyledSchoolInfoWrapper,
  StyledDetailInfoWrapper,
  StyledInfo,
  StyledName,
  StyledCountry,
  StyledEmail,
  StyledExpiredLabel,
  StyledSchoolType
} from '../../styles'
import { ISchoolInfo } from '../../types'
import customToast from '../../../../components/CustomToast'

interface Props {
  school: ISchoolInfo
}

const School: FC<Props> = ({ school }) => {
  const defaultImg = useRef<string>('/assets/school-image-for-form.png')

  const country = useMemo(
    () => school.country[0]?.toUpperCase() + school.country.slice(1),
    [school.country]
  )
  const level = useMemo(
    () =>
      school.level
        ? school.level[0]?.toUpperCase() + school.level.slice(1)
        : '',
    [school.level]
  )

  const isActiveLicense = useMemo(
    () => new Date(school.licenseEnd) >= new Date(),
    [school.licenseEnd]
  )

  useEffect(() => {
    if (!isActiveLicense) {
      customToast({
        message: t('expiredSchoolLicenseNotification', {
          name: school.longName
        }),
        type: 'warning'
      })
    }
  }, [isActiveLicense])

  return (
    <StyledSchoolWrapper key={school.id} aria-label={'School container'}>
      <StyledAvatarWrapper>
        <StyledCustomImage
          disabled={!isActiveLicense}
          borderRadius={'16px'}
          src={school.logoUrl || defaultImg.current}
          width={'auto'}
          height={'100%'}
          alt={'School logo'}
          errorSrc={defaultImg.current}
        />
      </StyledAvatarWrapper>
      <StyledSchoolInfoWrapper aria-label={'School info'}>
        <StyledDetailInfoWrapper>
          <StyledInfo>
            <StyledName variant={'h3'}>{school.longName}</StyledName>
            <StyledCountry variant={'h4'}>{country}</StyledCountry>
          </StyledInfo>
          <StyledEmail variant={'h4'}>{school.managerEmail}</StyledEmail>
          <StyledExpiredLabel>
            {!isActiveLicense && t('expiredLicense')}
          </StyledExpiredLabel>
        </StyledDetailInfoWrapper>
        <StyledDetailInfoWrapper>
          {school.level && <StyledSchoolType>{level}</StyledSchoolType>}
        </StyledDetailInfoWrapper>
      </StyledSchoolInfoWrapper>
    </StyledSchoolWrapper>
  )
}

export default memo(School)
