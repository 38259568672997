import { basicString } from '../../../validation/primitives'
import { TFunction } from 'i18next'

type TType = TFunction

const sendMessageSchema = (t: TType) => ({
  text: basicString.required()
})

export default sendMessageSchema
