import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getStudents, sendMessageToAccount } from './services'
import {
  getStudentCountSelector,
  getStudentsSelector,
  getTeacherClassesSelector,
  getTeacherCoursesSelector,
  getTeacherSchoolsSelector,
  isMessageSendingSelector
} from './selectors'
import { useTranslation } from 'react-i18next'
import CustomTable from '../../components/CustomTable'
import { getCurrentUserSelector } from '../../core/selectors'
import { UserType } from '../Login/types'
import TableActionsTooltip from '../../components/TableActionsTooltip'
import SendMessageModal from '../../components/SendMessageModal'
import { StyledPageContentWrapper, StyledTitle } from './styles'
import {
  DEFAULT_LIST_ITEM_SIZE,
  STUDENTS_PAGE_SIZE
} from '../../utils/constants'
import { ITableStudentData } from './types'
import { ISelectOption, ISortCallbackParams } from '../../types'
import { findSchools, getClasses, getTeacherCourses } from '../../core/services'
import { APP_ROUTES } from '../../core/router/appRoutes'
import customToast from '../../components/CustomToast'
import { clearState } from './slices'
import { formatDateByLocale } from '../../utils/helpers'

function Students() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(getCurrentUserSelector)
  const studentsCount = useAppSelector(getStudentCountSelector)
  const students = useAppSelector(getStudentsSelector)
  const schools = useAppSelector(getTeacherSchoolsSelector)
  const courses = useAppSelector(getTeacherCoursesSelector)
  const classes = useAppSelector(getTeacherClassesSelector)
  const isMessageSending = useAppSelector(isMessageSendingSelector)

  const [isSendMessageModalOpen, setIsSendMessageModalOpen] = useState(false)
  const [chosenEntityId, setChosenEntityId] = useState<string>('')

  const handleModalSubmit = useCallback(
    (text: string) => {
      dispatch(sendMessageToAccount({ text, entityId: chosenEntityId })).then(
        (res) => {
          if (sendMessageToAccount.fulfilled.match(res)) {
            customToast({ message: 'Success!', type: 'success' })
            setIsSendMessageModalOpen(false)
          }
        }
      )
    },
    [chosenEntityId]
  )

  const handleOpenModal = useCallback((entityId: string) => {
    setChosenEntityId(entityId)
    setIsSendMessageModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setChosenEntityId('')
    setIsSendMessageModalOpen(false)
  }, [])

  useEffect(() => {
    dispatch(getStudents({ page: 1, sort: 'fullName:asc' }))
    dispatch(findSchools())
    dispatch(
      getClasses({
        page: 1,
        pageSize: DEFAULT_LIST_ITEM_SIZE,
        sort: 'name:asc'
      })
    )
    dispatch(
      getTeacherCourses({
        page: 1,
        sort: 'name:asc',
        pageSize: DEFAULT_LIST_ITEM_SIZE
      })
    )
    return () => {
      dispatch(clearState())
    }
  }, [])

  const sortCallbackHandler = (params: ISortCallbackParams) => {
    dispatch(getStudents(params))
  }

  const prepareTableHead = useCallback(
    () => [
      {
        name: t('name'),
        ariaLabel: 'Name of Student',
        cellName: 'fullName',
        sortable: true
      },
      {
        name: t('school'),
        ariaLabel: 'School Names',
        cellName: 'school',
        sortable: false
      },
      {
        name: t('class'),
        ariaLabel: 'Class Name',
        cellName: 'class',
        sortable: false
      },
      {
        name: t('assignedActivities'),
        ariaLabel: 'Assigned Activities',
        cellName: 'activitiesDone',
        sortable: true
      },
      {
        name: t('averageScore'),
        ariaLabel: 'Average score',
        cellName: 'averageScore',
        sortable: true
      },
      {
        name: t('lastActivity'),
        ariaLabel: 'Last activity',
        cellName: 'lastActivity',
        sortable: false
      },
      {
        name: t('actions'),
        ariaLabel: 'Row actions',
        cellName: 'actions',
        sortable: false
      }
    ],
    [t]
  )

  const prepareTableData = (): ITableStudentData[] => {
    if (!students) return []

    return students.map((student) => {
      const tableData: ITableStudentData = {
        fullName: student.fullName,
        school: student.schools
          .map((studentSchool) => studentSchool.shortName)
          .join(', '),
        class: student.classes
          .map((studentClass) => studentClass.name)
          .join(', '),
        activitiesDone: student.activitiesDone,
        averageScore: student.averageScore,
        lastActivity: formatDateByLocale(student.lastActivity)
      }

      if (currentUser && currentUser.type === UserType.teacher) {
        tableData.actions = (
          <TableActionsTooltip
            className={student.fullName}
            entityId={student.accountId}
            onClick={() => handleOpenModal(student.accountId)}
            buttonTitle={t('sendMessageToStudent')}
            linkRoute={APP_ROUTES.STUDENT_DETAIL.replace(
              ':username',
              student.username
            )}
            linkTitle={t('linkToStudentDetail')}
          />
        )
      }

      return tableData
    })
  }

  const getTableFiltersData = useMemo(() => {
    const tableFiltersData: {
      school: ISelectOption[]
      course: ISelectOption[]
      class: ISelectOption[]
    } = {
      school: [],
      course: [],
      class: []
    }
    const uniqIdsSet = new Set()

    schools?.forEach((school) => {
      if (!uniqIdsSet.has(school.id)) {
        tableFiltersData.school.push({
          value: school.id,
          name: school.longName
        })
        uniqIdsSet.add(school.id)
      }
    })

    courses?.forEach((course) => {
      if (!uniqIdsSet.has(course.id)) {
        tableFiltersData.course.push({
          value: course.id,
          name: course.name
        })
        uniqIdsSet.add(course.id)
      }
    })

    classes?.forEach((classItem) => {
      if (!uniqIdsSet.has(classItem.id)) {
        tableFiltersData.class.push({
          value: classItem.id,
          name: classItem.name
        })
        uniqIdsSet.add(classItem.id)
      }
    })

    return tableFiltersData
  }, [schools, courses, classes])

  return (
    <StyledPageContentWrapper>
      <StyledTitle variant={'h2'} aria-label={'My classes'}>
        {t('myStudents')}
      </StyledTitle>
      {students && (
        <CustomTable
          tableAriaLabel={'Course students table'}
          dataCount={studentsCount}
          tableHead={prepareTableHead()}
          rowsCount={STUDENTS_PAGE_SIZE}
          rows={prepareTableData()}
          defaultSortColumn={'fullName'}
          sortCallback={sortCallbackHandler}
          tableFiltersData={getTableFiltersData}
          tableSubtitle={t('youHaveEntityAndName', {
            entityCount: studentsCount,
            entityName: t('students', { count: studentsCount }).toLowerCase()
          })}
          searchPlaceholder={t('searchByStudents')}
        />
      )}
      <SendMessageModal
        isSendMessageModalOpen={isSendMessageModalOpen}
        handleCloseModal={handleCloseModal}
        title={t('sendMessageToStudent')}
        placeholder={t('messageToStudent')}
        onSubmit={handleModalSubmit}
        isLoading={isMessageSending}
      />
    </StyledPageContentWrapper>
  )
}

export default Students
