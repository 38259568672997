import { styled } from '@mui/system'

export const StyledStudentInfoItemLabel = styled('div')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '20px',
  color: theme.palette.customColors.grayMedium,
  marginRight: '16px'
}))

export const StyledStudentInfoItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline'
}))

export const StyledStudentInfoItemContent = styled('div')(({ theme }) => ({
  minWidth: '180px',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '20px',
  color: theme.palette.customColors.black,

  '& a': {
    color: theme.palette.customColors.black,
    '&:hover': {
      textDecoration: 'none'
    }
  }
}))
