import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '../../axios'
import { API_ROUTES } from '../router/apiRoutes'
import {
  CLASSES_PAGE_SIZE,
  FIND_SCHOOLS_PAGE_SIZE
} from '../../utils/constants'
import {
  IClassList,
  ICommonChunkConfig,
  ICoursesList,
  IErrorResponse,
  IFindSchools,
  IGetClassesPayload,
  IGetCoursesPayload
} from '../../types'
import {
  IGetStudentDetailsPayload,
  IStudentProfileExtended
} from '../../containers/StudentDetails/types'

export const getTeacherCourses = createAsyncThunk<
  ICoursesList,
  IGetCoursesPayload,
  ICommonChunkConfig
>('courses/fetchTeacherCourses', async (_data, { rejectWithValue }) => {
  try {
    const { pageSize = CLASSES_PAGE_SIZE } = _data
    const response = await get({
      path: API_ROUTES.TEACHER_COURSES,
      params: {
        ..._data,
        pageSize
      }
    })
    return response as ICoursesList
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const findSchools = createAsyncThunk<
  IFindSchools,
  void,
  ICommonChunkConfig
>('profile/fetchSchools', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.FIND_SCHOOLS,
      params: {
        page: 1,
        pageSize: FIND_SCHOOLS_PAGE_SIZE
      }
    })
    return response as IFindSchools
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getClasses = createAsyncThunk<
  IClassList,
  IGetClassesPayload,
  ICommonChunkConfig
>('profile/fetchClasses', async (_data, { rejectWithValue }) => {
  try {
    const { pageSize = CLASSES_PAGE_SIZE } = _data
    const response = await get({
      path: API_ROUTES.CLASSES,
      params: {
        ..._data,
        pageSize
      }
    })
    return response as IClassList
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getStudentProfileExtended = createAsyncThunk<
  IStudentProfileExtended,
  IGetStudentDetailsPayload,
  ICommonChunkConfig
>('studentDetails/fetchProfileExtended', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.PROFILE_EXTENDED.replace(
        ':username',
        _data.username
      )}/${_data.username}`,
      params: {
        username: _data.username
      }
    })
    return response as IStudentProfileExtended
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
