import { RootState } from '../../../store'

export const getStudentProfileSelector = (state: RootState) =>
  state.studentEdit.profile
export const getStudentClassesSelector = (state: RootState) =>
  state.studentEdit.classes
export const getStudentSchoolsSelector = (state: RootState) =>
  state.studentEdit.schools
export const getIsProfileLoadingSelector = (state: RootState) =>
  state.studentEdit.isProfileLoading
export const getIsLoadingSelector = (state: RootState) =>
  state.studentEdit.isLoading
