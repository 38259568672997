import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IResultsState,
  IStudentResultDetailsList,
  IStudentResultsList,
  ITeacherResultsResponse
} from '../types'
import {
  getAllStudentCourses,
  getStudentResultDetails,
  getStudentResults,
  getTeacherResults
} from '../services'
import { IClassList, ICoursesList } from '../../../types'
import { getClasses, getTeacherCourses } from '../../../core/services'

const initialState: IResultsState = {
  isLoading: false,
  isResultDetailsLoading: false,
  studentResults: [],
  teacherResults: [],
  courses: [],
  classes: [],
  count: 0,
  resultDetails: [],
  resultDetailsCount: 0
}

export const courseDetailsSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    clearState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentResults.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getStudentResults.fulfilled,
      (state, { payload }: PayloadAction<IStudentResultsList>) => {
        state.isLoading = false
        state.studentResults = payload.data
        state.count = payload.count
      }
    )
    builder.addCase(getStudentResults.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getAllStudentCourses.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getAllStudentCourses.fulfilled,
      (state, { payload }: PayloadAction<ICoursesList>) => {
        state.isLoading = false
        state.courses = payload.data
      }
    )
    builder.addCase(getAllStudentCourses.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getStudentResultDetails.pending, (state) => {
      state.isResultDetailsLoading = true
    })
    builder.addCase(
      getStudentResultDetails.fulfilled,
      (state, { payload }: PayloadAction<IStudentResultDetailsList>) => {
        state.isResultDetailsLoading = false
        state.resultDetails = payload.data
      }
    )
    builder.addCase(getStudentResultDetails.rejected, (state) => {
      state.isResultDetailsLoading = false
    })

    builder.addCase(getTeacherResults.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getTeacherResults.fulfilled,
      (state, { payload }: PayloadAction<ITeacherResultsResponse>) => {
        state.isLoading = false
        state.teacherResults = payload.data
        state.count = payload.count
      }
    )
    builder.addCase(getTeacherResults.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(
      getTeacherCourses.fulfilled,
      (state, { payload }: PayloadAction<ICoursesList>) => {
        state.courses = payload.data
      }
    )

    builder.addCase(
      getClasses.fulfilled,
      (state, { payload }: PayloadAction<IClassList>) => {
        state.classes = payload.data
      }
    )
  }
})

export const { clearState } = courseDetailsSlice.actions
export default courseDetailsSlice.reducer
