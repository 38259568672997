import { createAsyncThunk } from '@reduxjs/toolkit'
import { del, get, post } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { IErrorResponse } from '../../../types'
import {
  IStudentCourseDetailsList,
  IGetCourseDetailsList,
  ITeacherCourseDetailsList,
  IClass,
  AssignToCourse,
  IUserInfo,
  IAssignment,
  ICourseById,
  IGetCourseById,
  IDeAssignToCourse
} from '../types'
import { CLASSES_PAGE_SIZE } from '../../../utils/constants'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const getStudentUnits = createAsyncThunk<
  IStudentCourseDetailsList,
  IGetCourseDetailsList,
  ICommonChunkConfig
>('courseDetails/fetchStudentUnits', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.STUDENT_COURSE_DETAILS}/${_data.id}`,
      params: {
        pageSize: CLASSES_PAGE_SIZE,
        ..._data
      }
    })
    return response as IStudentCourseDetailsList
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getTeacherUnits = createAsyncThunk<
  ITeacherCourseDetailsList,
  IGetCourseDetailsList,
  ICommonChunkConfig
>('courseDetails/fetchTeacherUnits', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.TEACHER_COURSE_DETAILS}/${_data.id}`,
      params: {
        pageSize: CLASSES_PAGE_SIZE,
        ..._data
      }
    })
    return response as ITeacherCourseDetailsList
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getCourseById = createAsyncThunk<
  ICourseById,
  IGetCourseById,
  ICommonChunkConfig
>('courseDetails/fetchCourseById', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.COURSES_BY_ID}/${_data.id}`,
      params: {}
    })
    return response as ICourseById
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getStudentsForClass = createAsyncThunk<
  IUserInfo[],
  IClass,
  ICommonChunkConfig
>('courseDetails/fetchStudentsForClass', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.CLASSES}/${_data.id}/students`,
      params: {
        ..._data
      }
    })
    return response as IUserInfo[]
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const assignToCourse = createAsyncThunk<
  IAssignment[],
  AssignToCourse,
  ICommonChunkConfig
>('courseDetails/fetchAssignToCourse', async (_data, { rejectWithValue }) => {
  try {
    const response = await post({
      path: `${API_ROUTES.ASSIGN_COURSE}`,
      data: _data
    })
    return response as IAssignment[]
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const deAssignFromCourse = createAsyncThunk<
  void,
  IDeAssignToCourse,
  ICommonChunkConfig
>(
  'courseDetails/fetchDeAssignFromCourse',
  async (_data, { rejectWithValue }) => {
    try {
      await del({
        path: `${API_ROUTES.ASSIGN_COURSE}/${_data.courseId}`,
        data: {
          studentId: _data.studentId,
          classId: _data.classId
        }
      })
    } catch (error) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)
