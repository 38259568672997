import { styled } from '@mui/system'
import { Typography } from '@mui/material'

export const StyledName = styled(Typography)(() => ({
  margin: 0,
  padding: 0,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px'
}))

export const StyledNameWrapper = styled('div')(() => ({
  whiteSpace: 'break-spaces'
}))
