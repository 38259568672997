import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { APP_ROUTES } from '../../core/router/appRoutes'
import {
  findSchools,
  getClasses,
  getStudentProfileExtended
} from '../../core/services'
import {
  getIsLoadingSelector,
  getIsProfileLoadingSelector,
  getStudentClassesSelector,
  getStudentProfileSelector,
  getStudentSchoolsSelector
} from './selectors'
import { DEFAULT_LIST_ITEM_SIZE } from '../../utils/constants'
import StudentForm from './components/StudentForm'
import StudentEditWrapper from './components/StudentEditWrapper'
import { IStudentForm } from './types'
import { editStudent, editStudentClasses } from './services'
import customToast from '../../components/CustomToast'
import { useTranslation } from 'react-i18next'

const StudentEdit = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const queryParams = useParams()

  const studentUsername = queryParams.username!

  const isProfileLoading = useAppSelector(getIsProfileLoadingSelector)
  const isLoading = useAppSelector(getIsLoadingSelector)
  const studentProfile = useAppSelector(getStudentProfileSelector)
  const classes = useAppSelector(getStudentClassesSelector)
  const schools = useAppSelector(getStudentSchoolsSelector)

  const onSuccessHandler = useCallback(() => {
    navigate(`${APP_ROUTES.STUDENTS}`)
  }, [])

  const onSubmit = useCallback((values: IStudentForm) => {
    const { id, fullName, username, email, classIds } = values

    dispatch(
      editStudent({
        username: username || fullName.toLowerCase().replace(' ', '_').trim(),
        fullName,
        email
      })
    ).then((res) => {
      if (editStudent.fulfilled.match(res)) {
        dispatch(
          editStudentClasses({
            id: id || '',
            classIds
          })
        ).then((response) => {
          if (editStudentClasses.fulfilled.match(response)) {
            customToast({ message: t('success'), type: 'success' })
            onSuccessHandler()
          }
        })
      }
    })
  }, [])

  useEffect(() => {
    dispatch(getStudentProfileExtended({ username: studentUsername }))
  }, [studentUsername])

  useEffect(() => {
    dispatch(findSchools())
    dispatch(
      getClasses({
        page: 1,
        pageSize: DEFAULT_LIST_ITEM_SIZE,
        sort: 'name:asc'
      })
    )
  }, [])

  return (
    <StudentEditWrapper
      onSubmit={onSubmit}
      classes={classes}
      isLoading={isLoading}
      isProfileLoading={isProfileLoading}
      profile={studentProfile}
      schools={schools}
    />
  )
}

export default StudentEdit
