import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { IErrorResponse } from '../../../types'
import { IActivityInfo, IGetActivityInfo } from '../types'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const getActivityInfo = createAsyncThunk<
  IActivityInfo,
  IGetActivityInfo,
  ICommonChunkConfig
>('activity/fetchActivityInfo', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.ACTIVITY_BY_ID}/${_data.id}`,
      params: { id: _data.id }
    })
    return response as IActivityInfo
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
