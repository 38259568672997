import { basicString } from '../../../../../validation/primitives'
import { TFunction } from 'i18next'
import validator from 'validator'

type TType = TFunction

const resetSchema = (t: TType) => ({
  email: basicString
    .email(t('emailMustBeValid'))
    .required()
    .test((value) => validator.isEmail(value))
})

export default resetSchema
