import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { IErrorResponse } from '../../../types'
import { IGetStudentsResultsPayload, IStudentsResultsResponse } from '../types'
import { STUDENTS_PAGE_SIZE } from '../../../utils/constants'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const getStudentsResults = createAsyncThunk<
  IStudentsResultsResponse,
  IGetStudentsResultsPayload,
  ICommonChunkConfig
>('results/fetchStudentsResults', async (_data, { rejectWithValue }) => {
  try {
    const { pageSize = STUDENTS_PAGE_SIZE } = _data
    const response: IStudentsResultsResponse = await get({
      path: `${API_ROUTES.TEACHERS_RESULTS_BY_ACTIVITY.replace(
        ':id',
        _data.id
      )}`,
      params: {
        pageSize,
        page: _data.page,
        sort: _data.sort,
        search: _data.search
      }
    })
    const result: IStudentsResultsResponse = {
      count: response.count,
      data: response.data.map((item) => {
        return {
          ...item,
          results: Array.from(item.results).sort(
            (prevResult, nextResult) =>
              new Date(
                nextResult?.finishedAt ?? nextResult.startedAt
              ).getTime() -
              new Date(prevResult?.finishedAt ?? prevResult.startedAt).getTime()
          )
        }
      })
    }
    return result
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
