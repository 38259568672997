import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { IErrorResponse } from '../../../types'
import {
  ICourses,
  IDashboardActivity,
  IGetStudentsPayload,
  IStudents
} from '../types'
import { COUNT_PER_PAGE } from '../../../utils/constants'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const getTeacherActivities = createAsyncThunk<
  IDashboardActivity[],
  void,
  ICommonChunkConfig
>('activities/fetchTeacherActivities', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.TEACHERS_MOST_PLAYED_ACTIVITIES,
      params: {
        limit: COUNT_PER_PAGE
      }
    })
    return response as IDashboardActivity[]
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getStudentsActivities = createAsyncThunk<
  IDashboardActivity[],
  void,
  ICommonChunkConfig
>('activities/fetchStudentActivities', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.STUDENTS_UPCOMING_ACTIVITIES,
      params: {
        limit: COUNT_PER_PAGE
      }
    })
    return response as IDashboardActivity[]
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getStudentCourses = createAsyncThunk<
  ICourses,
  void,
  ICommonChunkConfig
>('courses/fetchStudentCourses', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.STUDENTS_COURSES,
      params: {
        page: 1,
        pageSize: COUNT_PER_PAGE
      }
    })
    return response as ICourses
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getTeacherStudents = createAsyncThunk<
  IStudents[],
  IGetStudentsPayload,
  ICommonChunkConfig
>('courses/fetchTeacherStudents', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.TEACHERS_DASHBOARD_STUDENTS,
      params: {
        ..._data
      }
    })
    return response as IStudents[]
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
