import React, { memo, useMemo } from 'react'
import CustomSelect from '../CustomSelect'
import { IStudentClassSelector } from './types'
import { useTranslation } from 'react-i18next'
import { StyledInfoWrapper } from '../../common/styles'

function StudentClassSelector(props: IStudentClassSelector) {
  const {
    classCourse,
    studentCourse,
    classes,
    students,
    handleClassChange,
    handleStudentChange
  } = props
  const { t } = useTranslation()

  const optionClass = useMemo(() => {
    const options: { value: string; name: string }[] = []
    classes?.map((cl) =>
      options.push({
        value: cl.id,
        name: `${cl.school.shortName} - ${cl.name}`
      })
    )
    return options
  }, [classes])

  const optionStudent = useMemo(() => {
    const options: { value: string; name: string }[] = []
    students?.map((student) =>
      options.push({ value: student.id, name: student.username })
    )
    return options
  }, [students])

  return (
    <StyledInfoWrapper>
      <CustomSelect
        aria-label={'Select class'}
        value={classCourse || 'reset'}
        options={[
          { value: 'reset', name: t('class') },
          ...(optionClass ? optionClass : [])
        ]}
        onChange={(event) => handleClassChange(event.target.value as string)}
        width={'140px'}
        marginRight={'8px'}
      />
      <CustomSelect
        aria-label={'Select student'}
        value={studentCourse || 'reset'}
        options={[{ value: 'reset', name: t('student') }, ...optionStudent]}
        onChange={(event) => handleStudentChange(event.target.value as string)}
        width={'140px'}
        disabled={!classCourse || classCourse === 'reset'}
      />
    </StyledInfoWrapper>
  )
}

export default memo(StudentClassSelector)
