import { styled } from '@mui/system'
import { Typography } from '@mui/material'

export const StyledPageContentWrapper = styled('div')(() => ({
  width: '100%',
  marginTop: '44px'
}))

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  marginBottom: '22px'
}))
