import React, { memo, useState } from 'react'
import {
  StyledMenuItems,
  StyledMenuLink,
  StyledSendMessageButton,
  StyledTooltipButton
} from './styles'
import {
  ClickAwayListener,
  Tooltip,
  tooltipClasses,
  TooltipProps
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { APP_ROUTES } from '../../core/router/appRoutes'

const HtmlTooltip = styled(
  ({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.customColors.white,
    maxWidth: 400,
    padding: '8px',
    boxSizing: 'border-box',
    margin: '0 !important',
    borderRadius: '16px',
    boxShadow: '0px 2px 8px 0px rgba(55, 82, 102, 0.16)'
  }
}))

export interface ITableActionsTooltipProps {
  entityId: string
  className: string
  onClick: () => void
  linkTitle: string
  linkRoute?: string
  buttonTitle: string
}

const TableActionsTooltip = memo((props: ITableActionsTooltipProps) => {
  const {
    entityId,
    className,
    onClick,
    linkRoute = APP_ROUTES.CLASSES_DETAIL.replace(':id', entityId),
    linkTitle,
    buttonTitle
  } = props
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const handleOpenModal = () => {
    setIsTooltipOpen(false)
    onClick()
  }

  return (
    <ClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
      <div>
        <HtmlTooltip
          PopperProps={{
            disablePortal: false
          }}
          aria-label={'Menu tooltip'}
          open={isTooltipOpen}
          onClose={() => setIsTooltipOpen(false)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-end"
          title={
            <StyledMenuItems>
              <StyledSendMessageButton onClick={handleOpenModal}>
                {buttonTitle}
              </StyledSendMessageButton>
              <StyledMenuLink
                aria-label={`Link to ${className} class`}
                to={linkRoute}
              >
                {linkTitle}
              </StyledMenuLink>
            </StyledMenuItems>
          }
        >
          <StyledTooltipButton
            onClick={() => setIsTooltipOpen(!isTooltipOpen)}
          />
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  )
})

export default TableActionsTooltip
