import { styled } from '@mui/system'
import { Button, Typography } from '@mui/material'

export const StyledPageContentWrapper = styled('div')(() => ({
  width: '100%',
  marginTop: '44px'
}))

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  marginBottom: '22px',
  display: 'flex',
  justifyContent: 'space-between'
}))

export const StyledButton = styled(Button)(() => ({
  boxSizing: 'border-box',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  width: 'fit-content',
  padding: '10px 32px'
}))
