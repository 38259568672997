import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import CustomImage from '../../../components/CustomImage'

export const StyledSchoolsPage = styled('div')(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
  flexDirection: 'column',
  marginTop: '24px'
}))

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  marginBottom: '16px'
}))
export const StyledSchoolsContainer = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row wrap',
  alignContent: 'flex-start',
  height: '100%',
  width: '100%',
  justifyContent: 'space-between'
}))

export const StyledSchoolWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '226px',
  borderRadius: '16px',
  width: '46%',
  backgroundColor: theme.palette.customColors.bg,
  padding: '20px',
  marginBottom: '12px',
  '@media (max-width: 1282px)': {
    width: '45%'
  },
  '@media (max-width: 1140px)': {
    width: '44%'
  },
  '@media (max-width: 1000px)': {
    width: '100%'
  },
  '@media (max-width: 600px)': {
    height: 'max-content',
    flexDirection: 'column',
    padding: '12px'
  }
}))
export const StyledAvatarWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '16px',
  width: '50%',
  '@media (max-width: 600px)': {
    marginRight: '0px',
    marginBottom: '16px'
  }
}))

export const StyledSchoolInfoWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: '50%',
  '@media (max-width: 600px)': {
    width: '100%'
  }
}))

export const StyledDetailInfoWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    marginBottom: '16px'
  }
}))

export const StyledInfo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    justifyContent: 'space-between',
    flexDirection: 'row'
  }
}))

export const StyledExpiredLabel = styled('span')(() => ({
  color: 'red'
}))

export const StyledName = styled(Typography)(({ theme }) => ({
  color: theme.palette.customColors.black,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
  marginBottom: '6px'
}))

export const StyledCountry = styled(Typography)(({ theme }) => ({
  color: theme.palette.customColors.black,
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '16px',
  marginBottom: '18px',
  '@media (max-width: 600px)': {
    marginBottom: '0px'
  }
}))

export const StyledEmail = styled(Typography)(({ theme }) => ({
  color: theme.palette.customColors.gray,
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '16px',
  marginBottom: '6px'
}))

export const StyledSchoolType = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.customColors.white,
  borderRadius: '12px',
  padding: '4px 12px',
  color: theme.palette.customColors.gray,
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '12px',
  border: '1px solid grey',
  fontFamily: 'SpaceMono',
  width: 'max-content',
  marginBottom: '8px'
}))

export const StyledCustomImage = styled(CustomImage)<{ disabled: boolean }>(
  ({ disabled }) => ({
    opacity: disabled ? 0.5 : 1
  })
)
