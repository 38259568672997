import { createAsyncThunk } from '@reduxjs/toolkit'
import { get, post } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { IErrorResponse } from '../../../types'
import { ISendMessageToClassPayload } from '../types'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const sendMessageToClass = createAsyncThunk<
  void,
  ISendMessageToClassPayload,
  ICommonChunkConfig
>('profile/postMessageToClass', async (_data, { rejectWithValue }) => {
  try {
    await post({
      path: `${API_ROUTES.SEND_MESSAGE_TO_CLASS}/${_data.entityId}`,
      data: { text: _data.text }
    })
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
