import React, { memo } from 'react'
import {
  StyledLoaderContainer,
  StyledLoaderGear1,
  StyledLoaderGear2,
  StyledLoaderGear3
} from './styles'

const Loader = memo(() => {
  return (
    <StyledLoaderContainer>
      <StyledLoaderGear1 />
      <StyledLoaderGear2 />
      <StyledLoaderGear3 />
    </StyledLoaderContainer>
  )
})

export default Loader
