import React, { memo, useCallback, useMemo } from 'react'
import CustomModal from '../../../../components/CustomModal'
import { StyledContentWrapper, StyledTableTitle } from './styles'
import CustomTable from '../../../../components/CustomTable'
import { TableColumn } from '../../../Results/types'
import {
  formatDateByLocale,
  formatSecondsToHours
} from '../../../../utils/helpers'
import { useTranslation } from 'react-i18next'
import { ITeacherActivityResultDetailsModalProps } from './types'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '../../../../core/router/appRoutes'
import { RowTextRight } from '../../../../components/CustomTableMobile/styles'
import MobileRow from '../../../../components/CustomTableMobile/components/MobileRow'
import useWindowSize from '../../../../utils/hooks'
import { BREAKPOINTS } from '../../../../utils/constants'
import CustomTableMobile from '../../../../components/CustomTableMobile'
import { Language } from '../../../Login/types'

const TeacherActivityResultDetailsModal = (
  props: ITeacherActivityResultDetailsModalProps
) => {
  const { isOpen, handleClose, activityResults } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [windowWidth] = useWindowSize()
  const isTablet = useMemo(
    () => windowWidth <= BREAKPOINTS.tabletSm,
    [windowWidth]
  )

  const prepareTableHead = (): TableColumn[] => {
    return [
      {
        name: t('resultOfActivity'),
        ariaLabel: 'Score of activity',
        cellName: 'score',
        sortable: false
      },
      {
        name: t('dateOfAttempt'),
        ariaLabel: 'Date of attempt',
        cellName: 'finishedAt',
        sortable: false
      },
      {
        name: t('timeSpentOnTheActivity'),
        ariaLabel: 'Time spent on the activity',
        cellName: 'duration',
        sortable: false
      }
    ]
  }

  const getAction = useCallback((language: Language, id: string) => {
    navigate(
      APP_ROUTES.ACTIVITY_DEEP_RESULT.replace(':assignedActivityResultId', id)
    )
  }, [])

  const prepareTableData = () => {
    if (!activityResults) return []
    return activityResults.results.map((result) => {
      const finishedAt = result.finishedAt
        ? formatDateByLocale(result.finishedAt)
        : ''
      const duration = formatSecondsToHours(result.duration ?? 0)
      return {
        score: result.score,
        finishedAt,
        duration,
        ...(result
          ? {
              rowAction: () => getAction(activityResults.language, result.id)
            }
          : {})
      }
    })
  }
  const prepareTableDataMobile = () => {
    if (!activityResults) return []
    const header = prepareTableHead()
    return activityResults.results.map((result) => {
      const finishedAt = formatDateByLocale(result?.finishedAt)
      const duration = formatSecondsToHours(result?.duration ?? 0)
      const row = {
        score: <RowTextRight>{result?.score}</RowTextRight>,
        finishedAt: <RowTextRight>{finishedAt}</RowTextRight>,
        duration: <RowTextRight>{duration}</RowTextRight>
      }
      return (
        <MobileRow
          row={row}
          tableHead={header}
          rowAction={
            result
              ? () => getAction(activityResults.language, result.id)
              : undefined
          }
        />
      )
    })
  }

  return (
    <>
      {activityResults ? (
        <CustomModal
          open={isOpen}
          width={'748px'}
          title={`${t('studentAttempts', {
            studentName: activityResults.studentName
          })}`}
          handleClose={handleClose}
        >
          <StyledContentWrapper>
            <StyledTableTitle variant={'h4'}>
              {`${t('averageScoreIs')} ${activityResults.averageScore}`}
            </StyledTableTitle>
            {isTablet ? (
              <CustomTableMobile
                tableAriaLabel={'Result Details Table'}
                dataCount={activityResults.results.length}
                tableHead={prepareTableHead()}
                rowsCount={activityResults.results.length}
                rows={prepareTableDataMobile()}
                defaultSortColumn={''}
                tableSubtitle={''}
                stickyHeader
              />
            ) : (
              <CustomTable
                tableAriaLabel={'Result Details Table'}
                dataCount={activityResults.results.length}
                tableHead={prepareTableHead()}
                rowsCount={activityResults.results.length}
                rows={prepareTableData()}
                defaultSortColumn={''}
                tableSubtitle={''}
                stickyHeader
              />
            )}
          </StyledContentWrapper>
        </CustomModal>
      ) : null}
    </>
  )
}

export default memo(TeacherActivityResultDetailsModal)
