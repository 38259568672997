import { styled as muiStyled } from '@mui/system'

export const StyledNavMenu = muiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.customColors.bg,
  width: '224px',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '24px',
  gap: '20px',
  '@media (max-width: 1140px)': {
    width: '100px',
    padding: '32px 0px'
  }
}))
