import { toast } from 'react-toastify'
import { ICustomToastProps } from './types'

const getMessageArray = (
  message: string | Record<string, string[]>
): string[] => {
  if (typeof message === 'string') {
    return [message]
  } else {
    const keys = Object.keys(message)
    return keys.map((key) => {
      return `${key}: ${message[key]}`
    })
  }
}

const customToast = (props: ICustomToastProps) => {
  const { type, message } = props
  const messages = getMessageArray(message)

  return toast(
    <div>
      {messages.map((mess) => (
        <p key={mess}>{mess}</p>
      ))}
    </div>,
    {
      position: 'top-right',
      type: type,
      autoClose: 3000
    }
  )
}
export default customToast
