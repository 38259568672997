import { styled } from '@mui/material/styles'

export const StyledImgWrapper = styled('div')<{
  width: string
  height?: string
}>(({ width, height }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: width,
  height: height
}))

export const StyledImg = styled('img')<{
  display: string
  borderRadius: string
  isContained: boolean
}>(({ display, borderRadius, isContained }) => ({
  width: '100%',
  borderRadius,
  display,
  objectFit: isContained ? 'contain' : 'cover',
  objectPosition: isContained ? 'top' : 'center'
}))

export const StyledImgLoader = styled('div')<{ display: string }>(
  ({ display }) => ({
    display
  })
)
