import { styled } from '@mui/system'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'

export const StyledMenu = styled('div')(({ theme }) => ({
  borderRadius: '52px',
  border: `1px solid ${theme.palette.customColors.linear}`,
  maxWidth: '410px',
  width: '100%',
  height: '54px',
  padding: '6px 18px 6px 0px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

export const StyledUser = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}))

export const StyledUserName = styled(Typography)(() => ({
  border: 'none',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  marginLeft: '16px'
}))

export const StyledMenuLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  padding: '6px 12px 6px 8px',
  boxSizing: 'border-box',
  width: '100%',
  color: theme.palette.customColors.black,
  '&:hover': {
    cursor: 'pointer',
    borderRadius: '52px',
    backgroundColor: theme.palette.customColors.bg
  }
}))

export const StyledNotificationsLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  width: '32px',
  height: '32px',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    cursor: 'pointer',
    borderRadius: '52px',
    backgroundColor: theme.palette.customColors.bg
  }
}))
