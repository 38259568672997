import axios from 'axios'
import Cookies from 'js-cookie'
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../utils/constants'
import Config from '../config'

const refreshTokenFn = async () => {
  const refreshToken = Cookies.get('refreshToken')

  try {
    const { data } = await axios.post(`${Config.apiUrl}/auth/refresh`, {
      refreshToken
    })

    if (!data?.accessToken || !data?.refreshToken) {
      localStorage.removeItem(ACCESS_TOKEN_NAME)
      localStorage.removeItem(REFRESH_TOKEN_NAME)
      Cookies.remove(ACCESS_TOKEN_NAME)
      Cookies.remove(REFRESH_TOKEN_NAME)
    }

    localStorage.setItem(ACCESS_TOKEN_NAME, data.accessToken)
    localStorage.setItem(REFRESH_TOKEN_NAME, data.refreshToken)
    Cookies.set(ACCESS_TOKEN_NAME, data.accessToken)
    Cookies.set(REFRESH_TOKEN_NAME, data.refreshToken)

    return data.accessToken
  } catch (error) {
    localStorage.removeItem(ACCESS_TOKEN_NAME)
    localStorage.removeItem(REFRESH_TOKEN_NAME)
    Cookies.remove(ACCESS_TOKEN_NAME)
    Cookies.remove(REFRESH_TOKEN_NAME)
  }
}

export const refreshAccessToken = refreshTokenFn
