import React, { memo } from 'react'
import { StyledBackButton, StyledTitle, StyledTitleWrapper } from './styles'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { IUnitTitleProps } from './types'

function UnitTitle({ unitName }: IUnitTitleProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const redirectToPreviousPage = () => {
    navigate(-1)
  }

  return (
    <StyledTitleWrapper>
      <StyledBackButton
        aria-label={'Previous page button'}
        onClick={redirectToPreviousPage}
      />
      {unitName && (
        <StyledTitle variant={'h2'} aria-label={'Unit details'}>
          {unitName} {t('details').toLowerCase()}
        </StyledTitle>
      )}
    </StyledTitleWrapper>
  )
}

export default memo(UnitTitle)
