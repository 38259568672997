import React, { FC } from 'react'
import Logo from './components/Logo'
import { StyledContentContainer, StyledPageContainer } from './styles'

interface Props {
  children: React.ReactNode
}

const AuthLayout: FC<Props> = ({ children }) => {
  return (
    <StyledPageContainer>
      <Logo />
      <StyledContentContainer>{children}</StyledContentContainer>
    </StyledPageContainer>
  )
}

export default AuthLayout
