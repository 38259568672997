import { AxiosRequestConfig, ResponseType } from 'axios'
import axios from './interceptors'
import Config from '../config'

interface IGetOptions {
  path: string
  params: { [key: string]: any }
  responseType?: ResponseType
}

interface IAxiosResponse {
  [key: string]: any
}

export default async function get(options: IGetOptions): Promise<any> {
  const { path, params = {}, responseType = 'json' } = options
  const axiosOptions: AxiosRequestConfig = {
    params,
    responseType
  }

  const res: IAxiosResponse = await axios.get<IAxiosResponse>(
    `${Config.apiUrl + path}`,
    axiosOptions
  )
  if (res.data.error) {
    throw res.data.error
  }
  return res.data
}
