import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '../../../axios'
import { API_ROUTES } from '../../../core/router/apiRoutes'
import { IErrorResponse } from '../../../types'
import { IGetNotificationsPayload, INotificationsList } from '../types'
import { CLASSES_PAGE_SIZE } from '../../../utils/constants'

interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export const getNotifications = createAsyncThunk<
  INotificationsList,
  IGetNotificationsPayload,
  ICommonChunkConfig
>('notifications/fetchNotifications', async (_data, { rejectWithValue }) => {
  try {
    const response = await get({
      path: API_ROUTES.NOTIFICATIONS,
      params: {
        pageSize: CLASSES_PAGE_SIZE,
        ..._data
      }
    })
    return response as INotificationsList
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
