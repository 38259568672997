import { Typography } from '@mui/material'
import { styled as muiStyled } from '@mui/system'
import { Link } from 'react-router-dom'

export const StyledSidebar = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  width: '224px',
  position: 'fixed',
  zIndex: 6,
  padding: '16px',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.customColors.bg,
  height: '100%',
  right: 0,
  boxShadow: '0px 2px 8px 0px #1D1F212E'
}))

export const StyledSidebarContainer = muiStyled('div')(() => ({
  width: '100%',
  position: 'fixed',
  zIndex: 3,
  background: 'rgba(0, 0, 0, 0.20)',
  backdropFilter: 'blur(2px)',
  height: '100%',
  right: 0
}))

export const StyledSidebarMainItems = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '8px'
}))

export const SidebarLogout = muiStyled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  border: 'none',
  textDecoration: 'none',
  backgroundColor: theme.palette.customColors.bg,
  boxSizing: 'border-box',
  width: '100%',
  '&:hover': {
    cursor: 'pointer'
  },
  gap: '8px',
  paddingLeft: '12px'
}))

export const StyledAvatarContainer = muiStyled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  alignItems: 'center',
  textDecoration: 'none',
  color: theme.palette.customColors.black,
  '&:hover': {
    cursor: 'pointer',
    borderRadius: '52px',
    backgroundColor: theme.palette.customColors.bg
  }
}))

export const StyledUserName = muiStyled(Typography)(() => ({
  color: 'inherit',
  border: 'none',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px'
}))

export const StyledSidebarHeader = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  gap: '4px',
  marginBottom: '8px'
}))

export const StyledSidebarCloseBtn = muiStyled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  border: 'none',
  textDecoration: 'none',
  background: 'none',
  padding: '0',
  boxSizing: 'border-box',
  width: 'auto',
  '&:hover': {
    cursor: 'pointer'
  }
}))
