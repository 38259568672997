import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { StyledButton, StyledPageContentWrapper, StyledTitle } from './styles'
import { getTickets, getTicketsCategory, sendNewTicket } from './services'
import { clearState } from './slices'
import TicketsTable from './components/TicketTable'
import SendTicketModal from './components/SendTicketModal'
import { findSchools } from '../../core/services'
import {
  getCategoriesSelector,
  getIsNewTicketSending,
  getSchoolsSelector
} from './selectors'
import customToast from '../../components/CustomToast'

function Tickets() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const schools = useAppSelector(getSchoolsSelector)
  const ticketCategories = useAppSelector(getCategoriesSelector)
  const isNewTicketSending = useAppSelector(getIsNewTicketSending)
  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false)

  useEffect(() => {
    dispatch(getTickets({ page: 1 }))
    dispatch(findSchools())
    dispatch(getTicketsCategory())
    return () => {
      dispatch(clearState())
    }
  }, [])

  const onModalSubmit = useCallback((values: any) => {
    dispatch(sendNewTicket(values)).then((res) => {
      if (sendNewTicket.fulfilled.match(res)) {
        dispatch(getTickets({ page: 1 })).then((res) => {
          if (getTickets.fulfilled.match(res)) {
            customToast({ message: 'Success!', type: 'success' })
            setIsTicketModalOpen(false)
          }
        })
      }
    })
  }, [])

  return (
    <StyledPageContentWrapper>
      <StyledTitle variant={'h2'} aria-label={'My asks'}>
        {t('myAsks')}
        <StyledButton
          size={'large'}
          variant={'contained'}
          color={'primary'}
          onClick={() => setIsTicketModalOpen(true)}
        >
          {t('askAQuestion')}
        </StyledButton>
      </StyledTitle>
      <TicketsTable />
      <SendTicketModal
        schools={schools}
        categories={ticketCategories}
        isTicketModalOpen={isTicketModalOpen}
        handleCloseModal={() => setIsTicketModalOpen(false)}
        title={t('askForAssistance')}
        onSubmit={onModalSubmit}
        isLoading={isNewTicketSending}
      />
    </StyledPageContentWrapper>
  )
}

export default Tickets
